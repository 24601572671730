export interface Shift {
  id: string;
  driverId?: null;
  driver?: Driver;
  zoneId?: string;
  shiftType: string;
  startTime: string;
  endTime: string;
  estimatedStartTime: number;
  estimatedEndTime: number;
  startTimeFormatted?: string;
  endTimeFormatted?: string;
  dateString?: string;
  shiftDurationString: string;
  actualStartTime: null;
  actualEndTime: null;
  status: string;
  totalDistance: number;
  totalCost: number;
  row?: number;
}

export interface Driver {
  id: string;
  firstName: string;
  lastName: string;
  rowIndex?: number;
  kiwiShift?: boolean;
  city: string;
  address: Address;
}

interface Address {
  city: City;
}

export interface City {
  id: string;
  name: string;
  timezone: string;
}

export enum ViewType {
  DAILY = 1,
  WEEKLY = 2,
}
export interface GetDriverShiftsParams {
  driverId?: string;
  estimatedStartTime?: number;
  estimatedEndTime?: number;
  locationId?: string;
  page?: number;
  perPage?: number;
  shiftIds?: string[];
  status?: string;
  zoneId?: string;
}
