/* eslint-disable no-nested-ternary */
import React, { useContext, useState, useEffect } from 'react';
import { Modal } from 'antd';
import { Table, Row } from 'antd';
import { useHistory } from 'react-router';
import { MODAL_TYPES } from '../../interfaces';
import { OrderDetailsContext } from '../../contexts/OrderDetailsContext';


function RouteInfoModal() {
  const history = useHistory();
  const { order, toggleModalVisibility, isRouteInfoModalVisible } = useContext(
    OrderDetailsContext,
  );


  const handleModal = () => {
    toggleModalVisibility(MODAL_TYPES.showRouteInfo);
    history.push('/routes')
  };


  const handleCancelModal = () => {
    toggleModalVisibility(MODAL_TYPES.showRouteInfo);
  };

  const Columns = [
    {
      title: 'Order Id',
      dataIndex: 'orderId',
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: 'Order Number',
      dataIndex: 'orderNumber',
      render: (text: string) => <p>{text ?? '-'}</p>,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      render: (text: string) => <p>{text}</p>,
    },
    {
      title: 'Job Type',
      dataIndex: 'type',
      render: (text: string) => <p>{text}</p>,
    },
  ];

  return (
    <Modal
      okText="Go to Routes Page"
      cancelText="Cancel"
      width={580}
      title={
        <Row justify={'space-between'}><strong>Route Overview</strong>
          <p style={{
            fontSize: 12,
            color: "gray",
            marginRight: 15
          }}
          >{order?.driverName ? `This route was assigned to ${order.driverName}` : `Unassigned route`}</p>
        </Row>
      }
      visible={isRouteInfoModalVisible}
      onOk={handleModal}
      onCancel={handleCancelModal}
    >
      <Table
        columns={Columns}
        dataSource={order?.routeLegs}
        rowKey={(record) => record.id}
        pagination={false}
        scroll={{ x: 500, y: 450 }}
      />
    </Modal>
  );
}

export default RouteInfoModal;
