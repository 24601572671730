import React, { useContext, useEffect, useState } from 'react';

import { Col, Row, Switch, Table, Input, Select, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import moment from 'moment';

import { Level, MODAL_TYPES, Product, PRODUCT_STATUS_TYPES } from './interfaces';
import ConfirmationSwitchModal from './components/ConfirmationSwitchModal';
import EditStockModal from './components/EditStockModal';
import { ReactComponent as EditIcon } from '../../../img/editIcon.svg';
import DownloadCsvButton from './components/DownloadCsvButton';
import { ReactComponent as InfoCircle } from 'img/informationCircle.svg';
import useWarehouses from 'hooks/useWarehouses';
import useInventory from 'hooks/useInventory';
import styles from './Inventory.module.css';
import { InventoryPageFiltersContext } from 'contexts/InventoryPageFiltersContext';
import { SCREEN_BREAKPOINTS } from 'constants/sizes';

const { Option } = Select;
const { Title } = Typography;

const Inventory: React.FC = () => {
  const [productRecord, setProductRecord] = useState<Product>();
  const [selectedProduct, setSelectedProduct] = useState<Product>({} as Product);
  const [productLevel, setProductLevel] = useState<Level>({} as Level);

  const { paginatedWarehouses, fetchWarehouses } = useWarehouses();
  const {
    setWarehouseId,
    warehouseId,
    searchText,
    setSearchText,
    filterType,
    setFilterType,
    productStatus,
    setProductStatus,
  } = useContext(InventoryPageFiltersContext);

  const {
    fetchProducts,
    paginatedProducts,
    toggleModalVisibility,
    loading,
    storeProduct
  } = useInventory();

  const history = useHistory();

  const handleSearch = (page?: number) => {
    const params: any = {};

    if (warehouseId && warehouseId !== "all") params.locationId = warehouseId;
    if (page) params.page = page;
    if (productStatus){
      if (productStatus === PRODUCT_STATUS_TYPES.DISCONTINUED) params.isDiscontinued = true;
      else if (productStatus !== PRODUCT_STATUS_TYPES.ALL){
        params.active = productStatus === PRODUCT_STATUS_TYPES.ON;
      }
    }
    if (searchText !== '') {
      if (filterType === 'sku') params.sku = searchText;
      else params.name = searchText;
    }

    fetchProducts(params);
  };

  useEffect(() => {
    if (paginatedWarehouses?.results.length === 0) fetchWarehouses();
  }, [paginatedWarehouses]);

  useEffect(() => {
    handleSearch();
  }, [productStatus, warehouseId]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (value: string, record: any) => (
        <div className={styles.nameContainer}>
          <InfoCircle
            className={record.isDiscontinued ? styles.disabledButton : styles.goToDetailsButton}
            onClick={() => {
              storeProduct(record);
              history.push(`/inventory/${record.id}`, { product: record })
            }}
          />
          <p className={record.isDiscontinued ? styles.disabledText : styles.text}>{value}</p>
        </div>
      )
    },
    {
      title: 'On/Off',
      dataIndex: 'active',
      render: (value: boolean, record: any) => (
        <>
          {record.isDiscontinued ? (
            <div className={styles.trace}/>
          ) : (
            <Switch
              checked={value}
              onClick={() => {
                toggleModalVisibility(MODAL_TYPES.confirmationSwitch);
                setProductRecord(record);
              }}
            />
          )}
        </>
      ),
    },
    {
      title: 'Product ID',
      dataIndex: 'id',
      render: (value: string, record: any) => (
        <>
          <p className={record.isDiscontinued ? styles.disabledText : styles.text}>{value}</p>
        </>
      )
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      render: (value: number, record: any) => (
        <>
          <p className={record.isDiscontinued ? styles.disabledText : styles.text}>{value}</p>
        </>
      )
    },
    {
      title: 'Available Stock',
      dataIndex: 'availableStock',
      render: (value: number, record: any) => (
        <div className={styles.increaseStockContainer}>

          <p className={record.isDiscontinued ? styles.disabledText : styles.text}>{value >= 0 ? `${value} units` : 'No levels found'}</p>
          {value >= 0 && (
            <EditIcon
              className={record.isDiscontinued ? styles.editIconDisabled : styles.editIcon}
              onClick={() => {
                if (!record.isDiscontinued){
                  setSelectedProduct(record);
                  setProductLevel(record.levels[0]);
                  toggleModalVisibility(MODAL_TYPES.editStock);
                }
              }}
            />
          )}
        </div>
      ),
      sorter: (a: any, b: any) => a.totalStock - b.totalStock,
    },
    {
      title: 'Warehouse',
      dataIndex: 'locationName',
      render: (value: number, record: any) => (
        <>
          <p className={record.isDiscontinued ? styles.disabledText : styles.text}>{value}</p>
        </>
      )
    },
    {
      title: 'Last Edition',
      dataIndex: 'updatedAt',
      render: (value: string, record: any) => (
        <p className={record.isDiscontinued ? styles.disabledText : styles.text}>
          {moment(new Date(value)).format('MM-DD-YYYY')}
        </p>
      )
    },
  ];

  return (
    <div className={styles.container}>
        <Row className={styles.rowContainer} gutter={[0, 15]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
            <Row justify="start" align="bottom" gutter={24}>
              <Col>
                <Title level={3}>Inventory</Title>
              </Col>
              <Col>
                <DownloadCsvButton />
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={18} xxl={18}>
            <Row justify="end" align="bottom" gutter={[16, 16]}>

              <Col xs={24} sm={24} md={24} lg={8} xl={4} xxl={3}>
                <Select
                  onChange={(e) => {
                    setProductStatus(e);
                  }}
                  className={styles.filtersseSelect}
                  value={productStatus}
                  placeholder="Product Status"
                >
                  <Option key="on" value="on">On</Option>
                  <Option key="off" value="off">Off</Option>
                  <Option key="discontinued" value="discontinued">Discontinued</Option>
                  <Option key="all" value="all">All Statuses</Option>
                </Select>
              </Col>

              <Col xs={24} sm={24} md={24} lg={8} xl={4} xxl={3}>
                <Select
                  onChange={(e) => {
                    setWarehouseId(e);
                  }}
                  className={styles.filtersseSelect}
                  value={warehouseId}
                  placeholder="Warehouse"
                >
                  <Option key="all" value="all">
                    All Warehouses
                  </Option>
                  {paginatedWarehouses?.results &&
                    paginatedWarehouses?.results.map((location) => (
                      <Option key={location.id} value={location.id}>
                        {location.name}
                      </Option>
                    ))}
                </Select>
              </Col>

              <Col xs={24} sm={24} md={24} lg={8} xl={4} xxl={3}>
                <Select
                  onChange={(e) => {
                    setFilterType(e);
                  }}
                  className={styles.filtersseSelect}
                  value={filterType}
                >
                  <Option value="sku">SKU</Option>
                  <Option value="name">Name</Option>
                </Select>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
                <Input
                  placeholder="Search here..."
                  onChange={(e) => setSearchText(e.target.value)}
                  suffix={<SearchOutlined onClick={() => handleSearch()}/>}
                  value={searchText}
                  allowClear
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSearch();
                    }
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>

      <Table<Product>
        className={styles.table}
        rowKey={(record) => record.id}
        pagination={{
          defaultPageSize: paginatedProducts?.perPage,
          total: paginatedProducts?.totalItems || undefined,
          current: paginatedProducts?.currentPage,
          onChange: (currentPage: number) => {
            handleSearch(currentPage);
          },
          showSizeChanger: false,
          pageSize: paginatedProducts?.perPage || undefined,
        }}
        columns={columns}
        dataSource={paginatedProducts?.results}
        loading={loading}
        scroll={{ x: SCREEN_BREAKPOINTS.MEDIUM }}
      />

      <ConfirmationSwitchModal handleSuccess={() => handleSearch()} record={productRecord} />
      <EditStockModal
        productId={selectedProduct?.id}
        productName={selectedProduct?.name}
        locationId={productLevel?.locationId}
        availableStock={productLevel?.availableStock}
        totalStock={productLevel?.totalStock}
        handleSearch={() => handleSearch()}
      />
    </div>
  );
};

export default Inventory;
