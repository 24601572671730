import React, { useEffect, useState } from 'react';

import { DatePicker, Form, InputNumber, Modal, Select } from 'antd';

import styles from './AddTransactionModal.module.css';
import formatToDollar from 'util/formatToDollar';
import useWallets from 'hooks/useWallets';
import moment from 'moment';
import { useParams } from 'react-router';
import { Wallet } from 'store/Wallet.slice';
import { CREDIT_TYPES, DEBIT_TYPES, TRANSACTION_TYPES } from 'constants/kiwiCredits';

interface AddTransactionModalProps{
  visible: boolean;
  setVisible: (visible: boolean) => void;
  selectedWallet: Wallet
}

const { Option } = Select;

const AddTransactionModal: React.FC<AddTransactionModalProps> = ({visible, setVisible, selectedWallet}) => {
  const [form] = Form.useForm();
  const [type, setType] = useState<TRANSACTION_TYPES>();
  const [reason, setReason] = useState<string | null>();

  const {
    createWalletAndInitialTransaction,
    createWalletTransaction,
    successCreateTransaction,
    customerDontHaveWallets,
  } = useWallets();

  const { customerId }: any = useParams();

  useEffect(() => {
    if (successCreateTransaction){
      setVisible(false);
    }
  }, [successCreateTransaction])


  const handleAddTransaction = () => {
    form
      .validateFields()
      .then(async (values) => {

        if (customerDontHaveWallets){
          try {
            const walletTransactions = [{
              type: values.type,
              quantity: values.quantity,
              reason: values.reason
            }];

            const body = { currency: "CAD", customerId, walletTransactions }

            createWalletAndInitialTransaction(body);
          } catch (error) {
            console.log(error)
          } finally {
            form.resetFields();
          }
        } else {
          try {
            const body = {...values};
            body.customerId = customerId;

            const params = { body, walletId: selectedWallet.id}

            createWalletTransaction(params);

          } catch (error) {
            console.log(error);
          } finally {
            form.resetFields();
          }
        }
      })
  };

  const handleChangeTransactionType = (value: TRANSACTION_TYPES) => {
    setType(value);
    form.setFieldsValue({
      reason: null,
   });
  }

  const handleCancelModal = () => {
    setVisible(false);
    form.resetFields();
  }

  return (
    <Modal
      visible={visible}
      onCancel={handleCancelModal}
      onOk={handleAddTransaction}
      okText="Create"
    >
      <h1 className={styles.title}>Create Transaction</h1>
      <p>Fields marked with (*) are mandatory</p>
      <Form form={form}>
        <strong>Type:*</strong>
        <Form.Item
          name="type"
          rules={[
            {
              required: true,
              message: 'Please select a type!',
            },
          ]}
        >
          <Select onChange={handleChangeTransactionType}>
            <Option key={TRANSACTION_TYPES.CREDIT} value={TRANSACTION_TYPES.CREDIT}>Credit</Option>
            <Option key={TRANSACTION_TYPES.DEBIT} value={TRANSACTION_TYPES.DEBIT}>Debit</Option>
          </Select>
        </Form.Item>

        <strong>Quantity ({selectedWallet?.currency || 'CAD'}):*</strong>
        <Form.Item
          name="quantity"
          rules={[
            {
              required: true,
              message: 'Please enter a quantity!',
            },
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            formatter={(value: number) => `$ ${formatToDollar(value || 0)}`}
            parser={(value) => Number(value?.replace(/\D/g, ''))}
          />
        </Form.Item>

        <strong>Reason:*</strong>
        <Form.Item
          name="reason"
          rules={[
            {
              required: true,
              message: 'Please select a reason!',
            },
          ]}
        >
          <Select value={reason || ''} onChange={(value) => setReason(value)}>
              {type === TRANSACTION_TYPES.CREDIT ? (
                <>
                  {CREDIT_TYPES.map((item) => (
                    <Option key={item} value={item}>{item}</Option>
                  ))}
                </>
              ) : (
                <>
                  {DEBIT_TYPES.map((item) => (
                    <Option key={item} value={item}>{item}</Option>
                  ))}
                </>
              )
            }
          </Select>
        </Form.Item>

      </Form>
    </Modal>
  )
}

export default AddTransactionModal;
