import React, { useState, useEffect, useContext } from 'react';

import { LeftOutlined } from '@ant-design/icons';
import { Col, notification, Row, Space, Switch } from 'antd';
import { useHistory } from 'react-router';

import { ReactComponent as LockIcon } from '../../../img/lock.svg';
import { ReactComponent as UnlockIcon } from '../../../img/unlock.svg';
import { ReactComponent as EditIcon } from '../../../img/editIcon.svg';
import { formatDate } from 'util/dateAndTime';
import { MODAL_TYPES } from '../Inventory/interfaces';
import EditProduct from './components/EditProduct';
import useInventory from 'hooks/useInventory';
import Title from 'antd/lib/typography/Title';
import ConfirmationSwitchModal from '../Inventory/components/ConfirmationSwitchModal';
import EditStockModal from '../Inventory/components/EditStockModal';
import DiscontinuedItemModal from './components/DiscontinuedItemModal';
import styles from './ProductDetails.module.css';
import { InventoryPageFiltersContext } from 'contexts/InventoryPageFiltersContext';

const ProductDetails: React.FC = () => {

  const [isEditingItem, setIsEditingItem] = useState<boolean>(false);

  const history = useHistory();
  const pageState: any = history.location.state;
  const id = pageState.product.id;

  const {
    toggleModalVisibility,
    selectedProduct,
    fetchProduct
  } = useInventory();

  const refetchProduct = () => {
    try {
      fetchProduct(id);
    } catch (error) {
      const err: any = error;
      notification.error({
        message: 'Error editing product!',
        description: err.message,
      });
    }
  }

  useEffect(() => {
    if (!selectedProduct) refetchProduct();
  }, [])


  return (
    <div className={styles.container}>

      <h1 className={styles.title}>Inventory</h1>

      <div className={styles.goBackContainer} onClick={() => history.goBack()}>
        <LeftOutlined className={styles.goBackIcon} />
        <h1 className={styles.goBackTitle}>Go back</h1>
      </div>

      <div className={styles.detailsContainer}>
        <Row className={styles.rowContainer}>
          <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
            <p className={styles.detailsTitle}>Name</p>
          </Col>

          <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
            <p className={styles.detailsTitle}>Product ID</p>
          </Col>

          <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
            <p className={styles.detailsTitle}>SKU</p>
          </Col>

          <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
            <p className={styles.detailsTitle}>Warehouse</p>
          </Col>

          <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
            <p className={styles.detailsTitle}>Last Edition</p>
          </Col>
        </Row>
        <div className={styles.divider}/>
        <Row className={styles.rowContainer}>
          <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
            <p className={styles.detailsText}>{selectedProduct?.name || '-'}</p>
          </Col>

          <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
            <p className={styles.detailsText}>{pageState.product.id || '-'}</p>
          </Col>

          <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
            <p className={styles.detailsText}>{pageState.product.sku || '-'}</p>
          </Col>

          <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
            <p className={styles.detailsText}>{pageState.product?.catalog.locations[0]?.name || '-'}</p>
          </Col>

          <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
            <p className={styles.detailsText}>{formatDate(pageState.product.updatedAt) || '-'}</p>
          </Col>
        </Row>
      </div>
      <Row justify='space-between' gutter={32}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <div className={styles.management}>
            <div>
              <Row>
                <h1 className={styles.editContainerTitle}>Management</h1>
                <div className={styles.editContainerDivider}/>
              </Row>

              <div>
                <Title level={4}>On / Off</Title>
                {selectedProduct?.isDiscontinued ? (
                  <div className={styles.trace}/>
                ) : (
                  <Switch
                    checked={selectedProduct?.active}
                    onClick={() => toggleModalVisibility(MODAL_TYPES.confirmationSwitch)}
                  />
                )}
              </div>
            </div>

            <div>
                <Title level={4}>Available Stock</Title>
                <div className={selectedProduct?.isDiscontinued ? styles.disabled : styles.increaseStockContainer}>

                  <p className={styles.text}>{selectedProduct?.levels[0] ? `${selectedProduct?.levels[0]?.availableStock} units` : "No Levels Found"}  </p>
                  {selectedProduct?.levels[0] && (
                    <EditIcon
                      className={selectedProduct?.isDiscontinued ? styles.editIconDisabled : styles.editIcon}
                      onClick={() => {
                        if (!selectedProduct?.isDiscontinued){
                          toggleModalVisibility(MODAL_TYPES.editStock)
                        }
                      }}
                    />
                  )}
                </div>
              </div>

            <div>
              <Title level={4}>Discontinued</Title>
              {selectedProduct?.isDiscontinued ? (
                <div className={styles.orangeCircle} onClick={() => toggleModalVisibility(MODAL_TYPES.discontinued)}/>
              ) : (
                <div className={styles.grayCircle}  onClick={() => toggleModalVisibility(MODAL_TYPES.discontinued)}/>
              )}
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
          <div className={styles.editContainer}>
            <Row justify='space-between'>
              <Col>
                <h1 className={styles.editContainerTitle}>Product Details</h1>
              </Col>
              <Col>
                {isEditingItem ? (
                  <div className={styles.unlockIcon} onClick={() => setIsEditingItem(!isEditingItem)}>
                    <UnlockIcon />
                  </div>
                ) : (
                  <div
                    className={selectedProduct?.isDiscontinued ? styles.unlockDisabled : styles.unlockIcon}
                    onClick={() => {
                      if (!selectedProduct?.isDiscontinued){
                        setIsEditingItem(!isEditingItem)
                      }
                    }}>
                    <LockIcon />
                  </div>
                )}
              </Col>
              <div className={styles.editContainerDivider}/>
            </Row>

            <EditProduct
              isEditingItem={isEditingItem}
              setIsEditingItem={setIsEditingItem}
              record={pageState.product}
            />
          </div>
        </Col>
      </Row>
      <ConfirmationSwitchModal handleSuccess={refetchProduct} record={selectedProduct}/>
      <EditStockModal
        productId={pageState.product?.id}
        productName={pageState.product?.name}
        locationId={pageState.product?.levels[0]?.locationId}
        availableStock={pageState.product?.levels[0]?.availableStock || 0}
        totalStock={pageState.product?.levels[0]?.totalStock || 0}
        handleSearch={refetchProduct}
      />
      <DiscontinuedItemModal handleSearch={refetchProduct} record={selectedProduct} />
    </div>
  )
}

export default ProductDetails;
