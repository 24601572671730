import React, { useContext, useEffect, useState } from 'react';

import { notification, Popover, Spin } from 'antd';

import moment from 'moment-timezone';
import { FORMATTED_HOURS } from '../../constants';
import { DriverShiftsContext } from '../../contexts/DriverShiftsContext';
import { Driver, ViewType } from '../../interfaces';
import { createShifts } from '../../services/api';
import {
  convertHoursToIsoString,
  convertIsoStringToUnixMilliseconds,
  generateTimeByColumnName,
  getDateAndTimeString,
} from '../../utils';
import Select from '../Select';
import styles from './AddShiftPopover.module.css';
import { DEFAULT_TIMEZONE } from 'util/dateAndTime';

interface PopoverProps {
  column: string;
  driverId: string;
  drivers: Driver[];
  driverIndex: number;
  isKiwiShift: boolean;
}

const AddShiftPopover: React.FC<PopoverProps> = ({ column, driverId, driverIndex, drivers, isKiwiShift }) => {
  const [selectedDriver, setSelectedDriver] = useState<any>();
  const [startTime, setStartTime] = useState<any>();
  const [endTime, setEndTime] = useState<any>();
  const [loadingCreateShift, setLoadingCreateShift] = useState<boolean>(false);

  const { fetchShifts, selectedDate, selectedWeek, viewType, zoneId } = useContext(DriverShiftsContext);

  useEffect(() => {
    const defaultTime = generateTimeByColumnName(column);
    setStartTime(defaultTime);
  }, [column]);

  const handleCreateShift = async () => {
    setLoadingCreateShift(true);

    const TIMEZONE: any = DEFAULT_TIMEZONE;
    const shift: any = {};

    shift.zoneId = zoneId;

    const date = viewType === ViewType.WEEKLY ? selectedWeek : selectedDate;

    const localStartTimeISO: any = convertHoursToIsoString(startTime, date, column, viewType);
    const localEndTimeISO: any = convertHoursToIsoString(endTime, date, column, viewType);

    // returns a string to use with moment.tz() - example: 2021-07-20 06:00:00
    const startDate = getDateAndTimeString(localStartTimeISO);
    const endDate = getDateAndTimeString(localEndTimeISO);

    // gets the corresponding time of a specific city based on the time selected locally
    const startTimeAtSpecificCity = moment.tz(startDate, TIMEZONE);
    const endTimeAtSpecificCity = moment.tz(endDate, TIMEZONE);

    // converts the date to UTC ISO string before send to the API
    const startTimeISOStringUTC = startTimeAtSpecificCity.utc().format();
    const endTimeISOStringUTC = endTimeAtSpecificCity.utc().format();

    const startTimeUnixMilliseconds = convertIsoStringToUnixMilliseconds(startTimeISOStringUTC);
    const endTimeUnixMilliseconds = convertIsoStringToUnixMilliseconds(endTimeISOStringUTC);

    if (startTime) shift.estimatedStartTime = startTimeUnixMilliseconds;
    if (endTime) shift.estimatedEndTime = endTimeUnixMilliseconds;

    if (selectedDriver) {
      if (selectedDriver === 'null') shift.driverId = null;
      shift.driverId = selectedDriver;
    } else if (driverId === 'null') shift.driverId = null;
    else shift.driverId = driverId;

    shift.shiftType = 'REGULAR';

    await createShifts(shift)
      .then((res) => {
        if (res.status === 'error') {
          notification.error({
            message: 'Error!',
            description: res.message,
          });
        } else {
          notification.success({
            message: 'Success!',
            description: 'Shift was created!',
          });
          fetchShifts(selectedDate, selectedWeek);
        }
      })
      .then(() => {
        setLoadingCreateShift(false);
      });
  };

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      content={
        <>
          <h1 className={styles.popoverTitle}>Add Shift</h1>
          {!isKiwiShift && (
            <>
              <p className={styles.selectLabel}>Driver Name:</p>
              <Select
                value={selectedDriver}
                defaultValue={driverId}
                handleChange={(value: string) => setSelectedDriver(value)}
                options={drivers}
                optionDisplayName="firstName"
              />
            </>
          )}
          <div className={styles.row}>
            <div className={styles.selectContainer}>
              <p className={styles.selectLabel}>Start Time:</p>
              <Select
                value={startTime}
                defaultValue={startTime}
                handleChange={(value: string) => setStartTime(value)}
                options={FORMATTED_HOURS}
                optionDisplayName="hours"
              />
            </div>
            <div className={styles.selectContainer}>
              <p className={styles.selectLabel}>End Time:</p>
              <Select
                value={endTime}
                defaultValue=""
                handleChange={(value: string) => setEndTime(value)}
                options={FORMATTED_HOURS}
                optionDisplayName="hours"
              />
            </div>
          </div>
          <div className={styles.buttonRow}>
            {loadingCreateShift ? (
              <Spin />
            ) : (
              <button type="button" onClick={handleCreateShift} className={styles.createButton}>
                Create
              </button>
            )}
          </div>
        </>
      }
    >
      {viewType === ViewType.WEEKLY && column.includes('0500') ? (
        <div className={styles.cellWithBorders} style={{ gridColumn: column, gridRow: driverIndex + 2 }} />
      ) : (
        <div
          className={driverIndex % 2 === 0 ? styles.gridCell : styles.gridCellOdd}
          style={{ gridColumn: column, gridRow: driverIndex + 2 }}
        />
      )}
    </Popover>
  );
};

export default AddShiftPopover;
