import React, { useEffect, useState } from 'react';

import { notification, Spin } from 'antd';
import { request } from 'util/request';
import { CSVLink } from 'react-csv';


import styles from './DownloadCsvButton.module.css';
import moment from 'moment';
import { Product } from '../../interfaces';
import { ReactComponent as DownloadIcon } from '../../../../../img/download.svg';

const DownloadCsvButton: React.FC = () => {
  const [loadingCsv, setLoadingCsv] = useState<boolean>(false);
  const [csvData, setCsvData] = useState<any>('');

  useEffect(() => {
    getAllProducts();
  }, []);

  const generateCsv = (allProducts: Product[]) => {

        const productsValues = allProducts.map((product: Product) => {
          const valuesForCsv = [
            product.id,
            product.active,
            product.sku,
            product.upc === null || product.upc === 'null' ? 0 : product.upc,
            product.name,
            product.unitOfMeasurement,
            product.price / 100,
            product.marketPrice / 100,
            product.catalog.provider,
            product.category.name,
            product.levels[0]?.availableStock ?? "-",
            product.isDiscontinued
          ];

          return valuesForCsv;
        });

        const rows = [
          [
            'id',
            'active',
            'sku',
            'upc', //(when upc is null or upc = 'null' then '0)
            'name',
            'unit_of_measurement',
            'price', //price/100
            'market_price', //market price/100
            'provider ',
            'category_name',
            'available_stock',
            'is_discontinued'
          ],
          ...productsValues,
        ];

        setCsvData(rows);
  }

  const getAllProducts = async () => {
    setLoadingCsv(true);

    try {
      const requestToGetTotalProducts = await request('inventory/products');
      const allProducts = await request('inventory/products', { perPage: requestToGetTotalProducts.totalItems }, 'GET');
      generateCsv(allProducts.results);

    } catch (err) {
      const error:any = err;

      notification.error({
        message: 'Error fetching products!',
        description: error.message,
      });
    } finally {
      setLoadingCsv(false);
    }
  }

  return (
    <>
      {loadingCsv ? (
        <div className={styles.spinContainer}>
          <p className={styles.csvText}>Generating CSV</p>
          <Spin/>
        </div>

      ) : (
        <CSVLink data={csvData} filename={`${moment().format('YYYY-MM-DD')}_all_products.csv`}>
          <DownloadIcon/>
          {` Download Products`}
        </CSVLink>
      )}
    </>
  )
}

export default DownloadCsvButton;
