import React, { useEffect, useState } from 'react';
import { Modal, notification, Table, AutoComplete, } from 'antd';
import { getDeliveryLegsByOrderId, updateDeliveryLegs } from 'services/DeliveryLegs.service';
import { LEG_TYPE, OrderLegType } from '../../interfaces';
import { ReactComponent as LockIcon } from '../../../../../img/lock.svg';
import { ReactComponent as UnlockIcon } from '../../../../../img/unlock.svg';
import debounce from 'lodash/debounce';
import { getAccessToken } from 'util/auth';
import { getAddressDetails, getAddressLocation, updateOrderAddress } from '../../services/api';

function EditLegsAddressModal({ isModalOpen, orderId, handleChangeModalVisibility }: any) {
  const [autocompleteOptions, setAutocompleteOptions] = useState<any>();
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [rowKey, setRowKey] = useState<string>('');
  const [orderLegs, setOrderLegs] = useState<OrderLegType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [addressValue, setAddressValue] = useState<any | null>(null);

  const { Option } = AutoComplete;
  const { token } = getAccessToken();

  const handleCancel = () => {
    setIsLocked(!isLocked);
    handleChangeModalVisibility(false);
  };

  const fetchOrderLegs = async (orderId: string) => {
    try {
      setLoading(true)
      const response = await getDeliveryLegsByOrderId({
        orderIds: [orderId],
      });
      if (response.error || response.status === 'error') throw new Error(response.message);
      const alphabeticallySortedLegs = response.sort(function (a: any, b: any) {
        if (a.type < b.type) { return -1; }
        if (a.type > b.type) { return 1; }
        return 0;
      })
      setOrderLegs(alphabeticallySortedLegs);
    } catch (err) {
      const error: any = err;
      notification['error']({
        message: 'Error fetching order legs',
        description: error.message,
      });
    } finally {
      setLoading(false)
    }
  }

  const onChangeAddress = async (newAddressValue: any) => {
    if (!newAddressValue) return;
    const addressDetails = await getAddressDetails(newAddressValue, token);
    setAutocompleteOptions(addressDetails.predictions);
  };

  const onSelectAddress = (addressValue: any) => {
    setAddressValue(addressValue);
  };

  const handleLockRow = async (leg: any) => {
    const { id, type, address } = leg;
    setIsLocked(!isLocked);
    if (addressValue) {
      try {
        if (addressValue?.value !== address) {

          const {
            result: {
              geometry: {
                location: { lat, lng },
              },
            },
          } = await getAddressLocation(addressValue.key, token);

          if (type === LEG_TYPE.COLLECT) {
            const response = await updateDeliveryLegs({
              id: id,
              body: {
                address: addressValue?.value,
                latitude: lat,
                longitude: lng,
              },
            });

            if (response.error || response.status === 'error') throw new Error(response.message);
            notification.success({
              message: 'Success!',
              description: 'The address has been successfully changed.',
            });
            fetchOrderLegs(orderId)
            setAddressValue(null)

          } else {

            const response = await updateOrderAddress({
              id: orderId,
              body: {
                address: addressValue?.value,
                lat,
                lng,
              },
            });
            if (response.error || response.status === 'error') throw new Error(response.message);
            notification.success({
              message: 'Success!',
              description: 'The address has been successfully changed.',
            });

            fetchOrderLegs(orderId)
            setAddressValue(null)
          }
        }
      } catch (err) {
        const error: any = err;
        notification.error({
          message: 'Error changing leg address!',
          description: error.message,
        });
        return error.message
      }
    }
  };

  async function handleUnlockRow(record: any) {
    setIsLocked(!isLocked);
    setRowKey(record.id);
  }

  useEffect(() => {
    if (orderId) fetchOrderLegs(orderId)
  }, [orderId]);

  const columns = [
    {
      dataIndex: 'action',
      key: 'action',
      render: (_: any, record: any) => {
        return isLocked && rowKey === record.id ? (
          <div style={{ display: 'flex', cursor: 'pointer' }}>
            <UnlockIcon onClick={() => handleLockRow(record)} />
          </div>
        ) : (
          <div style={{ display: 'flex', cursor: 'pointer' }}>
            <LockIcon onClick={() => handleUnlockRow(record)} />
          </div>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Warehouse',
      dataIndex: 'instructions',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      render: (value: string, record: any) => (
        <>
          {isLocked && rowKey === record.id ? (
            <AutoComplete
              defaultValue={value}
              style={{ width: 200 }}
              defaultActiveFirstOption
              onChange={debounce(onChangeAddress, 300)}
              onSelect={(_, option) => onSelectAddress(option)}
              placeholder="Address"
            >
              {autocompleteOptions?.map(({ description, place_id }: any) => (
                <Option key={place_id} value={description}>
                  {description}
                </Option>
              ))}
            </AutoComplete>
          ) : (
            <p>{value}</p>
          )}
        </>
      ),
    },
  ]


  return (
    <Modal title="Legs" visible={isModalOpen} onOk={handleCancel} onCancel={handleCancel}>
      <Table
        loading={loading}
        rowKey={(record) => record.id}
        dataSource={orderLegs}
        columns={columns}
        pagination={false}
      />
    </Modal>
  )
}

export default EditLegsAddressModal;
