export const ORDER_STATUSES_ARRAY = [
  'CREATED',
  'IN_PICKING',
  'PREPARED',
  'COLLECTED',
  'DELIVERED',
  'CANCELLED_BY_CUSTOMER',
  'CANCELLED_BY_KIWIME',
];

export const FILTER_STATUSES_ARRAY = [...ORDER_STATUSES_ARRAY, 'WAITING_CONFIRMATION'];
