import React from 'react';
import { Input, Row, Col, Modal, Form, Select, notification, Radio, Typography } from 'antd';
import MaskedInput from 'antd-mask-input';
import { createDriver } from 'services/Driver.service'
import cognitoRegister from '../../../../../util/cognitoRegister'
import { DRIVER_STATUS } from '../../interfaces'
import useDrivers from 'hooks/useDrivers';

function AddDriverModal() {
  const { fetchDrivers, paginatedDrivers, changeAddModalVisibility, isAddModalVisible } = useDrivers();
  const [form] = Form.useForm();

  const { Option } = Select;
  const { Paragraph } = Typography;

  const handleOkAddModal = () => {
    const status = DRIVER_STATUS.ACTIVE;
    const addressLine2 = "";
    form
      .validateFields()
      .then(async (values) => {
        form.resetFields();
        const response = await createDriver({
          ...values,
          status,
          addressLine2,
        });
        if (response.error || response.status === 'error') throw new Error(response.message);
        notification['success']({
          message: 'Success!',
          description: 'The driver has been successfully added.',
        });
        await cognitoRegister(response)
        await fetchDrivers({ page: paginatedDrivers?.currentPage });
        form.resetFields();
        changeAddModalVisibility(!isAddModalVisible);
      })
      .catch((error) => {
        notification['error']({
          message: 'Error adding a driver!',
          description: error.message,
        });
      });
  };

  const handleCancelAddModal = () => {
    form.resetFields();
    changeAddModalVisibility(!isAddModalVisible);
  };

  return (
    <Modal
      title="Add New Driver"
      visible={isAddModalVisible}
      onOk={handleOkAddModal}
      onCancel={handleCancelAddModal}
    >
      <Form form={form} layout={'vertical'} autoComplete={'off'} scrollToFirstError={true}>
        <Row>
          <Col lg={24} md={24} xs={24}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: 'Please input the First Name!',
                },
              ]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
          </Col>
          <Col lg={24} md={24} xs={24}>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: 'Please input the Last Name!',
                },
              ]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
          </Col>
          <Col lg={24} md={24} xs={24}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid Email!',
                },
                {
                  required: true,
                  message: 'Please input your Email!',
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>
          <Col lg={24} md={24} xs={24}>
            <Form.Item
              label="Phone Number"
              name="phoneNumber"
              rules={[
                { required: true, message: 'Please input your phone number!' },
                {
                  pattern: /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/,
                  message: 'Please enter a valid phone number',
                },
              ]}
            >
              <MaskedInput
                mask="+1 (111) 111-1111"
                name="phoneNumber"
                placeholder="+1 (111) 111-1111"
              />
            </Form.Item>
          </Col>
          <Col lg={24} md={24} xs={24}>
            <Form.Item
              label="Address"
              name="addressLine1"
              rules={[
                {
                  required: true,
                  message: 'Please input the address!',
                },
              ]}
            >
              <Input placeholder="address" />
            </Form.Item>
          </Col>
          <Col lg={24} md={24} xs={24}>
            <Form.Item
              label="City"
              name="city"
              rules={[
                {
                  required: true,
                  message: 'Please select the city',
                },
              ]}
            >
              <Select
              >
                <Option value="Calgary">Calgary</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24} md={24} xs={24}>
            <Row gutter={4}>
              <Col span={12}>
                <Form.Item
                  label="State/Province"
                  name="provinceState"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the State/Province',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value ||
                          getFieldValue('provinceState').length === 2
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            'State/Province not valid, must be only two characters!'
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    placeholder="AB"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Country"
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the Country',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('country').length === 2) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            'Country not valid, must be only two characters!'
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select the country"
                    optionFilterProp="children"
                  >
                    <Option value="CA">CA</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Row align="middle" justify='space-between'>
                  <Paragraph strong={false}>Alcohol Driver:</Paragraph>
                  <Form.Item
                    name="alcoholDeliveryAllowed"
                    rules={[
                      {
                        required: true,
                        message: 'Please select an option',
                      }
                    ]}
                  >
                    <Radio.Group>
                      <Radio value={true}>YES</Radio>
                      <Radio value={false}>NO</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default AddDriverModal;
