import React from "react";

import { Button } from 'antd';

import styles from './PrimaryButton.module.css';
interface ButtonProps {
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean
}

const PrimaryButton: React.FC<ButtonProps> = ({
  children,
  onClick,
  disabled,
  loading
}) => {
  return (
    <Button type="primary" className={styles.button}
      disabled={disabled}
      loading={loading}
      onClick={onClick}>{children}</Button>
  );
}

export default PrimaryButton;
