import React, { useContext } from 'react';

import { Button, Col, Row, Spin } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import PrimaryButton from 'ui/components/PrimaryButton';
import moment from 'moment';

import { DateAndSlotsProps } from '../../interfaces';
import { RoutesContext } from '../../contexts/RoutesContext';
import { formatDeliveryWindowsIds } from '../../utils';
import DateInputRadio from '../DateInputRadio';
import Slots from '../Slots';
import styles from './DateAndSlotsSelect.module.css';

interface DateSelectProps {
  setSelectedDateInfo: (date: DateAndSlotsProps) => void;
  selectedDateInfo: DateAndSlotsProps;
  selectedSlots: any;
  setSelectedSlots: (slots: any) => void;
}

const DateAndSlotsSelect: React.FC<DateSelectProps> = ({
  setSelectedDateInfo,
  selectedDateInfo,
  selectedSlots,
  setSelectedSlots,
}) => {
  const {
    handleFilterLegsBySlotIds,
    setFilteredTheLegs,
    fetchUnassignedLegs,
    loadingLegs,
    setSelectedDate,
    dates,
    loadingSlots,
  } = useContext(RoutesContext);

  const datesKeys = Object.keys(dates);

  const sortedDates = datesKeys.sort((a, b) => {
    return moment(a).unix() - moment(b).unix();
  });

  const filterLegs = () => {
    const deliveryWindowsIdsString = formatDeliveryWindowsIds(selectedSlots);
    handleFilterLegsBySlotIds(deliveryWindowsIdsString);
  };

  const clearFilters = () => {
    setFilteredTheLegs(false);
    setSelectedDateInfo({} as DateAndSlotsProps);
    setSelectedSlots({});
    fetchUnassignedLegs();
  };

  return (
    <Col className={styles.column}>
      {loadingSlots ? (
        <div className={styles.container}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Row>
            {Object.keys(dates).length > 0 ? (
              <>
                {sortedDates.map((item: any) => (
                  <DateInputRadio
                    key={item}
                    data={dates[item]}
                    currentDate={selectedDateInfo}
                    onChange={(data) => {
                      setSelectedDateInfo(data);
                      setSelectedDate(data.date);
                    }}
                  />
                ))}
              </>
            ) : (
              <div className={styles.container}>
                <WarningOutlined className={styles.noSlotsIcon} />
                <h1 className={styles.noSlotsMessage}>No slots found!</h1>
              </div>
            )}
          </Row>
          <Slots selectedDateInfo={selectedDateInfo} setSlotIds={setSelectedSlots} slotIds={selectedSlots} />
          {Object.values(selectedSlots).length > 0 && (
            <Row align="middle">
              <PrimaryButton onClick={filterLegs} loading={loadingLegs}>
                Filter Legs
              </PrimaryButton>
              <Button className={styles.clearButton} onClick={clearFilters}>
                Clear Filters
              </Button>
            </Row>
          )}
        </>
      )}
    </Col>
  );
};

export default DateAndSlotsSelect;
