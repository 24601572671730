import React from 'react';
import { DateAndSlotsProps } from '../../interfaces';

import styles from './DateInputRadio.module.css';

interface DateInputRadioProps {
  onChange: (date: DateAndSlotsProps) => void;
  data: DateAndSlotsProps;
  currentDate: DateAndSlotsProps;
}

const DateInputRadio: React.FC<DateInputRadioProps> = ({ data, onChange, currentDate }) => {
  return (
    <>
      <input
        type="radio"
        name="date"
        checked={currentDate.date === data.date}
        id={data.date}
        value={data.date}
        className={data.isToday ? styles.todayInput : styles.input}
        onChange={() => onChange(data)}
      />
      <label htmlFor={data.date}>
        {data.isToday && (
          <div className={styles.customBorder}>
            <p>Today</p>
          </div>
        )}
        <p className={styles.day}>{data.day}</p>
        <p className={styles.weekDay}>{data.weekDay}</p>
      </label>
    </>
  );
};

export default DateInputRadio;
