import { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import * as ZoneActions from "../store/Zone.slice";
import { selectZones, selectZonesFetching, selectDefaultZone } from "selectors/Zones";

export default function useZones() {
  const dispatch = useDispatch();

  const zones = useSelector(selectZones);
  const defaultZone = useSelector(selectDefaultZone);
  const loading = useSelector(selectZonesFetching);

  const fetchZones = useCallback(
    async function () {
      dispatch(ZoneActions.fetchZones());
    },
    [dispatch]
  );


  return {
    loading,
    defaultZone,
    fetchZones,
    zones
  };
}
