import React, { useState, useContext, useEffect } from 'react';

import { Modal, Tabs, Card, Radio, Space } from 'antd';
import moment from 'moment';

import { OrderDetailsContext } from '../../contexts/OrderDetailsContext';
import { MODAL_TYPES } from '../../interfaces';
import { unixDateToTimeZone } from 'util/dateAndTime';
import styles from './EditWindowsModal.module.css';

function EditWindowsModal() {
  const {
    order,
    isEditWindowModalVisible,
    toggleModalVisibility,
    deliveryWindows,
    getWindows,
    changeOrderWindow
  } = useContext(OrderDetailsContext);
  const [selectedWindow, setSelectedWindow] = useState(order?.deliveryWindowId);

  const { TabPane } = Tabs;

  const handleOk = () => {
    if (selectedWindow) changeOrderWindow({ orderId: order?.id, windowId: selectedWindow })
    toggleModalVisibility(MODAL_TYPES.editWindowModal);
  };

  const handleCancel = () => {
    toggleModalVisibility(MODAL_TYPES.editWindowModal);
  };

  const onChangeValue = (e: any) => {
    setSelectedWindow(e.target.value);
  };

  const getDefaultKey = (deliveryWindows: any, orderDeliveryWindowId: any) => {
    let defaultKey;
    deliveryWindows.forEach((deliveryWindow: any, index: number) => {
      deliveryWindow.windows.forEach((window: any) => {
        if (orderDeliveryWindowId === window.id) {
          defaultKey = index;
        }
      });
    });
    return defaultKey ? String(defaultKey) : "0";
  }

  useEffect(() => {
    if (order) {
      getWindows(order?.zoneId);
    }
  }, [order])

  return (
    <Modal
      title="Delivery Windows"
      width={580}
      visible={isEditWindowModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Save"
      cancelText="Cancel"
    >
      <Tabs tabBarGutter={4}
        defaultActiveKey={getDefaultKey(deliveryWindows, order?.deliveryWindowId)}
      >
        {deliveryWindows && deliveryWindows.map((deliveryWindow, index) =>
          <TabPane
            className={styles.tabPane}
            tab={
              <Card.Grid className={styles.cardGrid}>
                {moment(deliveryWindow.date).isSame(moment(), "day") ? <div className={styles.todayDateContainer}>
                  <p className={styles.todayDate}>Today</p>
                </div> : null}
                <strong className={styles.monthDay}>{moment(deliveryWindow.date).format("D")}</strong>
                <p className={styles.week}>{moment(deliveryWindow.date).format("ddd")}</p>
              </Card.Grid>
            }
            key={String(index)}
          >
            <>
              {deliveryWindow.windows?.map((window) => {
                return (
                  <div
                    key={window.id}
                    style={{ display: 'block' }}
                  >
                    <Radio.Group
                      onChange={onChangeValue}
                      defaultValue={order?.deliveryWindowId}
                      value={selectedWindow}>
                      <Space direction="vertical">
                        <Radio value={window.id}>{`${unixDateToTimeZone(window.startTime).format("HH:mm")} to ${unixDateToTimeZone(window.endTime).format("HH:mm")}`}</Radio>
                      </Space>
                    </Radio.Group>
                  </div>
                )
              })}
            </>
          </TabPane>
        )}
      </Tabs>
    </Modal>
  )
}

export default EditWindowsModal
