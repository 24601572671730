export enum MODAL_TYPES {
  'changeWarehouseStatus' = 0,
  'showDeliveryWindows' = 1,
  'addDeliveryWindows' = 2,
  'editDeliveryWindows' = 3,
}

export const STATUSES_TYPES: any = {
  PREPARED: '#fbff00',
  COLLECTED: '#00BFFF',
  PICKING: '#483D8B',
  CREATED: '#D2691E',
  CANCELLED: '#FF0000',
  DELIVERED: '#ADFF2F',
  CANCELLED_BY_CUSTOMER: '##111111 ',
  CANCELLED_BY_KIWIME: '##ffcbdb ',
  TOTAL_CAPACITY: '#D4D4D4',
}
