import moment from 'moment-timezone';

export const disabledHours = () => [0, 1, 2, 3, 4];
export const timeFormat = 'HH:mm';
export const minuteStep = 30;
export const DEFAULT_TIMEZONE = 'America/Edmonton';

export const getUnixTimeByTimezone = (date: string | number, hours: number, minutes: number, timezone: string) => {
  const unixTime = moment(date).tz(timezone).hour(hours).minute(minutes).second(0).milliseconds(0).valueOf();
  return unixTime;
};

export const createUnixUtcDateTime = (dateString: string, times: any): number[] => {
  const startDateTimeString = `${dateString} ${moment(times[0]).format('HH:mm')}`;
  const unixStartDateTime = moment(startDateTimeString, 'MM-DD-YYYY HH:mm:ss').tz('UTC').valueOf();

  const endDateTimeString = `${dateString} ${moment(times[1]).format('HH:mm')}`;
  const unixEndDateTime = moment(endDateTimeString, 'MM-DD-YYYY HH:mm:ss').tz('UTC').valueOf();

  return [unixStartDateTime, unixEndDateTime];
};

export const formatDate = (date: number) => {
  return moment(date).tz(DEFAULT_TIMEZONE).format('DD/MM/YYYY HH:mm') || '-'
}

export const unixDateToTimeZone = (unixDate: number) => {
  return moment(unixDate).tz(DEFAULT_TIMEZONE);
};
