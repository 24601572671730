import {
  createAsyncThunk,
  createReducer,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { notification } from 'antd';
import { getZones } from "services/Zones.service";

export interface Zone {
  id: string,
  name: string,
  status: string,
  timezone: string
}

interface ZoneSliceState {
  results: Zone[];
  defaultZoneId: string,
  fetching: boolean;
}

const initialState: ZoneSliceState = {
  fetching: false,
  defaultZoneId: '',
  results: []
};

export const fetchZones = createAsyncThunk(
  "zones/fetchZones",
  async function () {
    try {
      const response = await getZones();
      return response;
    } catch (err) {
      const error: any = err;
      notification.error({
        message: 'Error!',
        description: error.message,
      });
    }
  }
);

export const zonesReducer = createReducer(initialState, (builder) => {
  builder

    .addCase(fetchZones.fulfilled, (state, action) => {
      state.results = action.payload;
      if (state.results.length > 0) state.defaultZoneId = action.payload[0].id;
    })
    .addMatcher(isPending, (state) => {
      state.fetching = true;
    })
    .addMatcher(isFulfilled, (state) => {
      state.fetching = false;
    })
    .addMatcher(isRejected, (state) => {
      state.fetching = false;
    });
});
