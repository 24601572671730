/* eslint-disable no-nested-ternary */
/* eslint-disable block-scoped-var */
/* eslint-disable vars-on-top */
import React, { useContext, useState, useEffect } from 'react';

import { InputNumber, Modal, Switch, notification, Select } from 'antd';
import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import _ from 'lodash';
import { getProductsByIds, getCouponByName } from '../../services/api';
import styles from './EditProductModal.module.css';
import { OrderDetailsContext } from '../../contexts/OrderDetailsContext';
import { ExpensesType, MODAL_TYPES, EXPENSES_VALUES, InventoryProduct } from '../../interfaces';
import { checkReplacementAvailability, filterProductsByTaxesAndPrice, generateExpensesArray, removeReplacedProduct, transformBigDecimal } from '../../utils';

const { Option } = Select;

const EditProductModal: React.FC<any> = () => {
  const {
    order,
    toggleModalVisibility,
    changeOrderModalVisible,
    productRecord,
    orderHasChanges,
    orderCoupon,
    setOrderExpenses,
    getAllProducts,
    allProducts,
    loadingAllProducts,
    setReplacedProduct,
    setReplacementProduct,
    quantity,
    setQuantity,
    availability,
    setAvailability,
    replacementProduct,
    replacedProduct,
    willReplaceProduct,setWillReplaceProduct,
    setSelectedReplacementId,
    selectedReplacementId
  } =
    useContext(OrderDetailsContext);

  const [filteredProducts, setFilteredProducts] = useState<InventoryProduct[]>([]);

  const handleCancel = () => {
    toggleModalVisibility(MODAL_TYPES.changeOrder);
    const { qty, unavailable } = productRecord;
    setQuantity(qty);
    setAvailability(unavailable);
    setWillReplaceProduct(false);
    setSelectedReplacementId(undefined);
  };

  const handleUpdateOrder = async () => {

    const editableStatuses = ['CREATED', 'IN_PICKING'];
    const { productId, originalQty } = productRecord;
    const { items, discount, deliveryFee, tip, walletBalanceUsed, deliveryFeeTax, serviceFee, serviceFeeTax } = order;
    const { type, name } = orderCoupon;

    if (editableStatuses.includes(order.status)) {
      const productToBeEditedIndex = items.findIndex((product: any) => product.productId === productId);

      const clonedOrderItems = [...order.items];

      clonedOrderItems[productToBeEditedIndex].qty = quantity;
      clonedOrderItems[productToBeEditedIndex].originalQty = originalQty;
      clonedOrderItems[productToBeEditedIndex].unavailable = availability;

      const availableProducts = clonedOrderItems.filter((product: any) => !product.unavailable);
      const hasAvailableProducts = availableProducts.length > 0;
      const productsIds = availableProducts.map((product: any) => {
        return product.productId;
      });

      if (hasAvailableProducts) {
        const productsListWithTaxes = await getProductsByIds({
          ids: productsIds,
        });
        const availableProductsWithTax = _.map(productsListWithTaxes, (item) => {
          return _.extend(item, _.find(availableProducts, { productId: item.id }));
        });

        const deliveryFeeTaxExpenses = order.expenses.find((expense: ExpensesType) => expense.type === EXPENSES_VALUES.DELIVERY_FEE_TAX);
        const newOrderExpenses = generateExpensesArray(productsListWithTaxes, deliveryFeeTaxExpenses?.value || null);
        setOrderExpenses(newOrderExpenses);

        const Newtaxes = transformBigDecimal(
          availableProductsWithTax.reduce((acc: any, curr: any) => {
            return acc + curr.qty * curr.tax;
          }, 0),
        );

        const newSubTotal = transformBigDecimal(
          availableProductsWithTax.reduce((acc: any, curr: any) => {
            return acc + curr.qty * curr.price;
          }, 0),
        );

        const totalPlusTaxesMinusKiwiCredits = transformBigDecimal(
          newSubTotal + Newtaxes + deliveryFee +  tip + deliveryFeeTax + serviceFee + serviceFeeTax - walletBalanceUsed
        );

        let newDiscount = 0;

        if (orderCoupon) {
          if (type === 'PERCENTAGE') {
            try {
              const response = await getCouponByName({ name, orderValue: newSubTotal, isOrderUpdate: true });
              newDiscount = response.calculatedDiscountValue;
            } catch (err) {
              const error: any = err;
              notification.error({
                message: 'Error getting coupon!',
                description: error.message,
              });
            }
          } else {
            newDiscount = discount;
          }
        }

        const newTotal = String(totalPlusTaxesMinusKiwiCredits - newDiscount).replace('.', '');

        const newDiscountFormatted = String(newDiscount).replace('.', '');
        order.taxes = Newtaxes;
        order.discount = Number(newDiscountFormatted);
        order.subTotal = newSubTotal;
        order.total = Number(newTotal) <= 0 ? 50 : Number(newTotal);
        order.items = clonedOrderItems;

        orderHasChanges(true);
        toggleModalVisibility(MODAL_TYPES.changeOrder);
      }

      order.items = clonedOrderItems;
      toggleModalVisibility(MODAL_TYPES.changeOrder);
    } else {
      order.taxes = 0;
      order.subTotal = 0;
      order.total = 0;
      notification.error({
        message: 'Error editing product!',
        description: 'Trying to update Order with this Status is not possible. This order is already immutable.',
      });
    }

    setWillReplaceProduct(false);
    setSelectedReplacementId(undefined);
  };

  useEffect(() => {
    if (allProducts.length === 0) getAllProducts();

    if (productRecord) {
      setReplacedProduct(productRecord);
      const { qty, unavailable } = productRecord;
      setQuantity(qty);
      setAvailability(unavailable);

      if (allProducts) {
        const { productId } = productRecord;
        const productsExceptReplacedProduct = removeReplacedProduct(allProducts, productId);
        const filteredProducts = filterProductsByTaxesAndPrice(productsExceptReplacedProduct, productRecord);
        setFilteredProducts(filteredProducts);
      }
    }
  }, [productRecord, allProducts]);

  const handleOk = () => {
    if (willReplaceProduct) {

      const replacementContainsEnoughStock = checkReplacementAvailability(replacedProduct.qty, replacementProduct);

      if (replacementContainsEnoughStock){
        toggleModalVisibility(MODAL_TYPES.confirmReplacement);
      } else {
        notification['error']({
          message: 'Error!',
          description: `Product ${replacementProduct.name} does not contain enought stock for this replacement!`
        });
      }

    } else handleUpdateOrder();
  }

  const handleSelectReplacementProduct = (value: string) => {
    const findProduct: any = allProducts.find(item => item.id === value);
    setReplacementProduct(findProduct);
    setSelectedReplacementId(value);
  }

  return (
    <Modal
      className={styles.modal}
      okText="OK"
      visible={changeOrderModalVisible}
      onCancel={handleCancel}
      onOk={handleOk}
      forceRender
    >
      <h1 className={styles.title}>{`Editing product ${productRecord?.productId}`}</h1>
      <p className={styles.subtitle}>{productRecord?.name}</p>
      {!productRecord?.unavailable && (
        <div className={styles.editQuantityContainer}>
          <MinusCircleFilled
            className={styles.icon}
            onClick={() => {
              if (quantity >= 2) setQuantity(quantity - 1);
            }}
          />
          <InputNumber
            min={1}
            precision={0}
            type="number"
            className={styles.numberOfUnits}
            onChange={(e) => setQuantity(e)}
            value={quantity}
          />
          <PlusCircleFilled className={styles.icon} onClick={() => setQuantity(quantity + 1)} />
        </div>
      )}
      <div className={styles.editAvalabilityContainer}>
        <h1>Available</h1>
        <Switch checked={!availability} onClick={() => setAvailability(!availability)} />
      </div>

      {availability && (
        <div className={styles.unavailableOptionsContainer}>
          <h1>Do you want to replace the product?</h1>
          <Select
            defaultValue="yes"
            value={willReplaceProduct ? 'yes' : 'no'}
            className={styles.yesOrNoSelect}
            onChange={(value) => setWillReplaceProduct(value === 'yes')}
          >
            <Option value="yes">YES</Option>
            <Option value="no">NO</Option>
          </Select>
        </div>
      )}
      {willReplaceProduct && availability && (
        <div className={styles.replacementContainer}>
          <h1>Replacement Product</h1>
          <Select
            loading={loadingAllProducts}
            showSearch
            optionFilterProp="children"
            value={selectedReplacementId}
            className={styles.replacementProductSelect}
            onChange={handleSelectReplacementProduct}
          >
            {filteredProducts.length > 0 && filteredProducts.map(item => (
              <Option value={item.id}>{item.catalog.provider} - {item.name}</Option>
            ))}
          </Select>
        </div>
      )}
    </Modal>
  );
};

export default EditProductModal;
