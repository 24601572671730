import React, { useContext, useEffect, useState } from 'react';

import { Button, Card, Row, Spin, Table, Typography } from 'antd';

import { LeftOutlined } from '@ant-design/icons';
import Steps from 'ui/components/Steps';
import { EditRouteContext } from '../../contexts/EditRouteContext';
import RoutesTable from '../Tables/RoutesTable';
import styles from './EditRouteSteps.module.css';
import AssignedLegsTable from '../Tables/AssignedLegsTable';
import { RoutesContext } from '../../contexts/RoutesContext';
import DateAndSlotsSelect from '../DateAndSlotsSelect';
import { DateAndSlotsProps } from '../../interfaces';

const { Title } = Typography;

const EditRouteSteps: React.FC = () => {
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [selectedDateInfo, setSelectedDateInfo] = useState<DateAndSlotsProps>({} as DateAndSlotsProps);
  const [selectedSlots, setSelectedSlots] = useState<any>({});

  const CHOOSE_ROUTE_STEP = 1;
  const FILTER_LEGS_STEP = 0;

  const {
    setAssignedLegs,
    assignedLegs,
    setSelectedDriverShift,
    selectedDriverShift,
    setSelectedRowKeys,
    fetchUnassignedLegs,
    city,
    setCheckedSlots,
    setFilteredTheLegs,
  } = useContext(RoutesContext);

  const {
    editRouteStep,
    setEditRouteStep,
    selectedRoute,
    setSelectedRoute,
    loadingEditRoute,
    handleEditRoute,
    fetchRoutes,
    setRoutesTableKeys,
    setEditRoutesUnassignedLegs,
  } = useContext(EditRouteContext);

  useEffect(() => {
    fetchRoutes();
  }, []);

  useEffect(() => {
    setRoutesTableKeys([selectedRoute.id]);
  }, []);

  // when changing the selected route, the assigned legs go back to unassigned table
  useEffect(() => {
    setAssignedLegs([]);
    setSelectedRowKeys([]);
    if (Object.keys(selectedRoute).length > 0) {
      // checks if there is a selected route
      setSelectedDriverShift(selectedRoute.scheduledShift);
      setButtonDisabled(false);
      setAssignedLegs([...selectedRoute.legs]);
    }
  }, [selectedRoute]);

  useEffect(() => {
    if (editRouteStep === FILTER_LEGS_STEP) setButtonDisabled(false);
    else if (editRouteStep === CHOOSE_ROUTE_STEP) {
      if (Object.keys(selectedRoute).length > 0) {
        // checks if there is a selected route
        setButtonDisabled(false);
      } else setButtonDisabled(true);
    }
  }, [editRouteStep]);

  const CurrentDriverColumns = [
    {
      title: 'Name',
      dataIndex: 'driverName',
    },
    {
      title: 'Phone number',
      dataIndex: 'driverPhone',
    },
    {
      title: 'Date',
      dataIndex: 'formattedStartTime',
    },
    {
      title: 'Shift',
      dataIndex: 'shiftStartAndEndTime',
    },
  ];

  const editRouteSteps = [
    {
      content: (
        <>
          <Title level={4} className={styles.optionalStepTitle}>
            Optional Step
          </Title>
          <Title level={5} className={styles.title}>
            Select the date and the delivery slots of the legs to be assigned:
          </Title>
          <DateAndSlotsSelect
            setSelectedDateInfo={setSelectedDateInfo}
            selectedDateInfo={selectedDateInfo}
            selectedSlots={selectedSlots}
            setSelectedSlots={setSelectedSlots}
          />
        </>
      ),
    },
    {
      content: (
        <>
          <Row align="middle">
            <LeftOutlined className={styles.arrowIcon} onClick={() => setEditRouteStep(editRouteStep - 1)} />
            <Title className={styles.title} level={5}>
              Select Route to Edit
            </Title>
          </Row>
          <Card size="small">
            <RoutesTable />
          </Card>
        </>
      ),
    },
    {
      content: (
        <>
          <Row align="middle">
            <LeftOutlined className={styles.arrowIcon} onClick={() => setEditRouteStep(editRouteStep - 1)} />
            <Title className={styles.title} level={5}>
              Sort legs
            </Title>
          </Row>
          <Card size="small">
            <AssignedLegsTable
              assignedLegs={assignedLegs}
              setAssignedLegs={setAssignedLegs}
              setSelectedRowKeys={setSelectedRowKeys}
            />
          </Card>
        </>
      ),
    },
    {
      content: (
        <>
          <Row align="middle" className={styles.rowContainer}>
            <Row align="middle">
              <LeftOutlined className={styles.arrowIcon} onClick={() => setEditRouteStep(editRouteStep - 1)} />
              <Title className={styles.title} level={5}>
                Driver
              </Title>
            </Row>
            <p className={styles.routeMessage}>You are editing route {selectedRoute.id}</p>
          </Row>
          <Table
            className={styles.currentDriverTable}
            columns={CurrentDriverColumns}
            dataSource={[selectedRoute]}
            rowKey="id"
            pagination={{ defaultPageSize: 1, hideOnSinglePage: true }}
          />
          <p className={styles.driverShiftsMessage}>
            You can change the driver in <strong>Shifts</strong> page, located in the Logistics tab.
          </p>
        </>
      ),
    },
  ];

  const clearAllSteps = () => {
    setFilteredTheLegs(false);
    setSelectedRowKeys([]);
    setEditRoutesUnassignedLegs([]);
    setRoutesTableKeys([]);
    setAssignedLegs([]);
    setSelectedRoute({});
    setEditRouteStep(0);
    setSelectedSlots({});
    setCheckedSlots([]);
    fetchUnassignedLegs();
  };

  useEffect(() => {
    clearAllSteps();
  }, [city]);

  return (
    <div className={styles.background}>
      <div className={styles.stepsContent}>{editRouteSteps[editRouteStep].content}</div>
      <Row align="middle" className={styles.stepAndButtonsContainer}>
        <Steps steps={editRouteSteps} currentStep={editRouteStep} />
        <Row align="middle" className={styles.rowContainer}>
          {Object.keys(selectedRoute).length > 0 && (
            <Button className={styles.cancelButton} onClick={clearAllSteps} disabled={loadingEditRoute}>
              Cancel
            </Button>
          )}
          {editRouteStep !== editRouteSteps.length - 1 && (
            <Button
              type="primary"
              className={styles.button}
              disabled={buttonDisabled}
              onClick={() => setEditRouteStep(editRouteStep + 1)}
            >
              Next
            </Button>
          )}
          {editRouteStep === editRouteSteps.length - 1 && (
            <Button
              type="primary"
              className={styles.button}
              disabled={loadingEditRoute}
              onClick={() => {
                handleEditRoute(assignedLegs, selectedDriverShift?.id, setSelectedDateInfo, setSelectedSlots);
              }}
            >
              Send
            </Button>
          )}
          {loadingEditRoute && <Spin className={styles.spin} />}
        </Row>
      </Row>
    </div>
  );
};

export default EditRouteSteps;
