import React, { useContext } from 'react';

import { Row, Select } from 'antd';
import styles from './InputWithFilters.module.css';
import { DriverShiftsContext } from '../../contexts/DriverShiftsContext';
import { ViewType } from '../../interfaces';

export interface Option{
  value: string,
  title?: string
}

const { Option } = Select;

const Filters: React.FC = () => {

  const {
    setSelectedDriverId,
    selectedDriverId,
    setLoading,
    setViewType,
    drivers,
    viewType,
  } = useContext(DriverShiftsContext);

  const driversFilterData = drivers
  .filter(item => item.id !== "null")
  .map(item => ({
      title: `${item.firstName} ${item.lastName}`,
      value: item.id
    })
  );

  const viewTypeOptions = [
    {
      title: "Daily View",
      value: ViewType.DAILY
    },
    {
      title: "Weekly View",
      value: ViewType.WEEKLY
    }
  ]

  const handleChangeViewType = (value: ViewType) => {
    setViewType(value);
    setLoading(true);
  }

  const handleChangeDriver = (value: string) => {
    setLoading(true);
    setSelectedDriverId(value)
  }


  return (
      <Row className={styles.rowContainer}>
        <Select
          showSearch
          value={selectedDriverId}
          placeholder="Driver"
          onChange={handleChangeDriver}
          className={styles.select}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optionA, optionB) =>
            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          }
        >
          {driversFilterData.map(item => (
            <Option key={item.value} value={item.value}>{item.title}</Option>
          ))}
        </Select>
        <Select
          defaultValue={ViewType.DAILY}
          value={viewType}
          onChange={handleChangeViewType}
          className={styles.select}
        >
          {viewTypeOptions.map(item => (
            <Option key={item.value} value={item.value}>{item.title}</Option>
          ))}
        </Select>
      </Row>
  )
}

export default Filters;
