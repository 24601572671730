import React, { useContext, useEffect, useState } from 'react';

import { Input, Popconfirm, Table } from 'antd';

import { EditRouteContext } from '../../../contexts/EditRouteContext';
import styles from './RoutesTable.module.css';
import { DeleteOutlined } from '@ant-design/icons';
import { Route } from 'ui/pages/Routes/interfaces';
import { RoutesContext } from 'ui/pages/Routes/contexts/RoutesContext';

const RoutesTable: React.FC = () => {
  const [filteredTable, setFilteredTable] = useState<Route[]>([]);

  const {
    routes,
    loadingRoutes,
    setSelectedRoute,
    routesTableKeys,
    setRoutesTableKeys,
    handleDeleteRoute,
    setEditRoutesUnassignedLegs,
    routesPerPage,
    fetchRoutes,
    totalItems,
    setCurrentPage,
    currentPage
  } = useContext(EditRouteContext)

  const { deliveryLegs } = useContext(RoutesContext);

  const RoutesColumns = [
    {
      title: 'Route Id',
      dataIndex: 'id',
      render: (text: string) => <p className={styles.columnText}>{text}</p>,
    },
    {
      title: 'Driver',
      dataIndex: 'driverName',
      render: (text: string) => <p className={styles.columnText}>{text}</p>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text: string) => <p className={styles.columnText}>{text}</p>,
    },
    {
      title: '',
      dataIndex: 'remove',
      width: '80px',
      render: (text: any, record: any) => (
        <Popconfirm
          title="Are you sure to delete this route?"
          onConfirm={() => handleDeleteRoute(record.id)}
          placement="left"
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined />
        </Popconfirm>
      )
    }
  ]

  const routesSelection = {
    selectedRowKeys: routesTableKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setEditRoutesUnassignedLegs(deliveryLegs);
      setRoutesTableKeys(selectedRowKeys);
      setSelectedRoute(selectedRows[0]);
    },
  };

  useEffect(() => {
    handleSearch();
  }, []);

  const handleSearch = async (page?: number) => {
    fetchRoutes(page);
  }

  const search = (value: string) => {

    const filterTable = routes.filter((o: any) =>
      Object.keys(o).some(k =>
        String(o[k])
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    );

    setFilteredTable(filterTable);
  };

  return (
    <>
      {/* <Input.Search  COMMENTED UNTILL WE HAVE A FILTER BY ROUTE ID
        className={styles.inputSearch}
        placeholder="Search by route id, driver name or status"
        enterButton
        allowClear
        onSearch={search}
      /> */}
      <Table
        loading={loadingRoutes}
        rowKey="id"
        rowSelection={{
          type: 'radio',
          ...routesSelection
        }}
        className={styles.table}
        dataSource={filteredTable.length === 0 ? routes : filteredTable}
        columns={RoutesColumns}
        pagination={{
          current: currentPage,
          defaultPageSize: routesPerPage || undefined,
          total: totalItems || undefined,
          onChange: (page: number) => {
            handleSearch(page);
            setCurrentPage(page);
          },
          showSizeChanger: false
        }}
        scroll={{ x: 500, y: 450 }}
      />
    </>
  )
}

export default RoutesTable;
