import { ORDER_STATUSES } from "./interfaces";

export const buildOrderFiltersQueryString = (ordersFilters: any, statuses: ORDER_STATUSES[]) => {
  let statusesQueryString;
  if (statuses) statusesQueryString = statuses.map((item: any) => `status=${item}`).join('&');

  const filtersKeyAndValueArrays = Object.entries(ordersFilters);
  const filtersSingleString = filtersKeyAndValueArrays.map((item: any) => item.join('=')).join('&');

  return `${filtersSingleString}${statusesQueryString ? `&${statusesQueryString}` : ''}`;
};
