import { request } from 'util/request';

async function getCatalogs(params?: any) {
  return request('inventory/catalogs', params, 'GET');
}

async function getCatalog(catalogId?: string) {
  return request(`inventory/catalogs/${catalogId}`);
}

export { getCatalogs, getCatalog }
