import React, { useEffect, useState } from 'react';

import { Button, Col, Input, Select, Table } from 'antd';
import { useHistory } from 'react-router';

import { ReactComponent as InfoCircle } from 'img/informationCircle.svg';
import { SearchOutlined } from '@ant-design/icons';
import { FilterTypes } from './interfaces';
import useCustomers from 'hooks/useCustomers';
import styles from './Customers.module.css';
import formatPhoneNumber from 'util/formatPhoneNumber';
import moment from 'moment';
import { formatDate } from 'util/dateAndTime';

const { Option } = Select;

const Customers: React.FC = () => {
  const [searchText, setSearchText] = useState<string>('');
  const [filterType, setFilterType] = useState<FilterTypes>(FilterTypes.NAME);

  const history = useHistory();
  const { fetchCustomers, paginatedCustomers, loadingCustomers } = useCustomers();

  useEffect(() => {
    fetchCustomers();
  }, [])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (id: string, record: any) => (
        <div className={styles.nameContainer}>
          <InfoCircle
            className={styles.goToDetailsButton}
            onClick={() => history.push(`/customers/${record.id}`, { customer: record })}
          />
          <p className={styles.text}>{id}</p>
        </div>
      ),
    },
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNumber',
      render: (value: string) => (
        <p className={styles.text}>{formatPhoneNumber(value) || "-"}</p>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (value: number) => (
        <p className={styles.text}>{formatDate(value)}</p>
      ),
    },
  ];

  const handleSearch = async (page?: number) => {
    const params: any = {};

    if (page) params.page = page;
    if (searchText !== '') params[filterType] = searchText

    fetchCustomers(params);
  };

  const selectAfter = (
    <Select
      className="select-after"
      style={{ width: '180px', backgroundColor: '#d9d9d6'}}
      value={filterType}
      onChange={(value) => setFilterType(value)}
    >
      <Option value={FilterTypes.NAME}>Name</Option>
      <Option value={FilterTypes.EMAIL}>Email</Option>
      <Option value={FilterTypes.PHONE}>Phone</Option>
    </Select>
  );

  return (
    <div className={styles.cardContainer}>
      <Col lg={24} md={24} xs={24}>
        <div className={styles.compoundFilter}>
          <Input
            className={styles.inputSearch}
            addonBefore={selectAfter}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            allowClear
          />
          <Button disabled={false} onClick={() => handleSearch()} type="primary" icon={<SearchOutlined />}>
            Search
          </Button>
        </div>
      </Col>
      <Table
        loading={loadingCustomers}
        dataSource={paginatedCustomers?.results}
        columns={columns}
        scroll={{ x: 1270 }}
        pagination={{
          onChange: (page: number) => {
            handleSearch(page);
          },
          total: paginatedCustomers?.totalItems,
          pageSize: 10,
          showSizeChanger: false,
          current: paginatedCustomers?.currentPage,
        }}
      />
    </div>
  )
}

export default Customers;
