import React, { useState, createContext, ReactNode, useContext } from 'react';

import { notification } from 'antd';
import moment from 'moment-timezone';

import { deleteRoute, editRoute, getRoutes } from '../services/api';
import { DateAndSlotsProps, LegProps, Route } from '../interfaces';
import { RoutesContext } from './RoutesContext';
import { formatStartAndEndTime, getDateAndTimeString } from '../utils';
import { DEFAULT_TIMEZONE } from 'util/dateAndTime';

export interface EditRouteContextData {
  setEditRouteStep: (step: number) => void;
  setRoutesTableKeys: (keys: React.Key[]) => void;
  fetchRoutes: (page?: number) => void;
  setSelectedRoute: (route: any) => void;
  handleEditRoute: (
    legs: LegProps[],
    driverId: string | undefined,
    setSelectedDateAndSlots: (dateAndSlots: DateAndSlotsProps) => void,
    setSelectedSlots: (slots: any) => void,
  ) => void;
  handleDeleteRoute: (routeId: string) => void;
  setIsEditingRoute: (isEditing: boolean) => void;
  setEditRoutesUnassignedLegs: (legs: LegProps[]) => void;
  editRoutesUnassignedLegs: LegProps[];
  loadingEditRoute: boolean;
  loadingRoutes: boolean;
  editRouteStep: number;
  routes: any[];
  selectedRoute: any;
  routesTableKeys: React.Key[];
  isEditingRoute: boolean;
  routesPerPage: number;
  totalItems: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}
export interface EditRouteProviderProps {
  children: ReactNode;
}

export const EditRouteContext = createContext({} as EditRouteContextData);

const EditRouteProvider = ({ children }: EditRouteProviderProps) => {
  const [editRoutesUnassignedLegs, setEditRoutesUnassignedLegs] = useState<LegProps[]>([]);
  const [editRouteStep, setEditRouteStep] = useState<number>(0);
  const [selectedRoute, setSelectedRoute] = useState<Route>({} as Route);
  const [routes, setRoutes] = useState<Route[]>([]);
  const [routesPerPage, setRoutesPerPage] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [loadingRoutes, setLoadingRoutes] = useState<boolean>(false);
  const [loadingEditRoute, setLoadingEditRoute] = useState<boolean>(false);
  const [routesTableKeys, setRoutesTableKeys] = useState<React.Key[]>([]);
  const [isEditingRoute, setIsEditingRoute] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const {
    setDeliveryLegs,
    fetchUnassignedLegs,
    setUnassignedLegs,
    setSelectedRowKeys,
    setAssignedLegs,
    city,
    setFilteredTheLegs,
  } = useContext(RoutesContext);

  const fetchRoutes = async (page?: number) => {
    setRoutes([]);
    setLoadingRoutes(true);

    try {
      const response = await getRoutes(page);
      setRoutesPerPage(response.perPage);
      setTotalItems(response.totalItems);

      if (response.error || response.status == "error") {
        throw new Error(response.message);
      } else {
        const formattedResponse = response.results.map((route: Route) => {
          const scheduledShift = { ...route.shift };
          const driver = { ...scheduledShift.driver };

          const isoStartTime = moment(scheduledShift.estimatedStartTime).toISOString();
          const startTime = getDateAndTimeString(isoStartTime);
          const startDate = moment.tz(startTime, city.timezone).format('YYYY-MM-DDTHH:mm:ss');
          const startDateFormatted = startDate.split('T')[0];
          const routeTimezone = DEFAULT_TIMEZONE;

          const routesInfo = {
            ...route,
            driverName: `${driver.firstName} ${driver.lastName}` || 'No driver name found',
            driverPhone: driver.phoneNumber || 'No phone number found',
            formattedStartTime: startDateFormatted,
            shiftStartAndEndTime: formatStartAndEndTime(
              scheduledShift.estimatedStartTime,
              scheduledShift.estimatedEndTime,
              routeTimezone,
            ),
          };

          return routesInfo;
        });
        setRoutes(formattedResponse);
      }
    } catch (err) {
      const error: any = err;
      notification['error']({
        message: 'Error loading the routes!',
        description: error.message,
      });
    } finally {
      setLoadingRoutes(false);
    }
  };

  const clearSteps = () => {
    setLoadingEditRoute(false);
    setEditRouteStep(0);
    setSelectedRowKeys([]);
    setSelectedRoute({} as Route);
    setRoutesTableKeys([]);
    setAssignedLegs([]);
    setUnassignedLegs([]);
    setDeliveryLegs([]);
    setRoutes([]);
    fetchUnassignedLegs();
    fetchRoutes(currentPage || 1);
    setFilteredTheLegs(false);
  };

  const onRouteDeleteEnd = () => {
    clearSteps();
    fetchRoutes(currentPage || 1);
  };

  const handleEditRoute = async (
    legs: LegProps[],
    shiftId: string,
    setSelectedDateAndSlots: (dateAndSlots: DateAndSlotsProps) => void,
    setSelectedSlots: (slots: any) => void,
  ) => {
    setLoadingEditRoute(true);

    const legsFormatted = legs.map((item: LegProps, index: number) => ({
      id: item.id,
      position: index + 1,
    }));

    const body = {
      shiftId,
      legs: legsFormatted,
    };

    await editRoute(body, selectedRoute.id)
      .then((res) => {
        if (res.status !== 'error') {
          notification.success({
            message: 'Success!',
            description: 'The route was updated.',
          });
        } else throw new Error(res.message);
      })
      .catch((error) => {
        notification.error({
          message: 'Error!',
          description: error.message,
        });
      });

    setSelectedSlots({});
    setSelectedDateAndSlots({} as DateAndSlotsProps);
    clearSteps();
  };

  const handleDeleteRoute = async (routeId: string) => {
    setLoadingRoutes(true);

    await deleteRoute(routeId)
      .then((res) => {
        if (res.status !== 'error') {
          notification.success({
            message: 'Success!',
            description: 'The route was removed.',
          });
        } else throw new Error(res.message);
      })
      .catch((error) => {
        notification.error({
          message: 'Error!',
          description: error.message,
        });
      });

    onRouteDeleteEnd();
  };

  return (
    <EditRouteContext.Provider
      value={{
        fetchRoutes,
        setEditRouteStep,
        routes,
        loadingRoutes,
        loadingEditRoute,
        editRouteStep,
        selectedRoute,
        setSelectedRoute,
        handleEditRoute,
        routesTableKeys,
        setRoutesTableKeys,
        handleDeleteRoute,
        isEditingRoute,
        setIsEditingRoute,
        setEditRoutesUnassignedLegs,
        editRoutesUnassignedLegs,
        routesPerPage,
        totalItems,
        setCurrentPage,
        currentPage,
      }}
    >
      {children}
    </EditRouteContext.Provider>
  );
};

export default EditRouteProvider;
