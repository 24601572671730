import { RootState } from "store";

function selectCustomersFetching(state: RootState) {
  return state.customer.fetchingCustomers;
}

function selectCustomerOrdersFetching(state: RootState) {
  return state.customer.fetchingOrders;
}

function selectPaginatedCustomers(state: RootState) {
  return state.customer.paginated;
}

function selectPaginatedCustomerOrders(state: RootState) {
  return state.customer.paginatedOrders;
}

function selectModalVisibility(state: RootState) {
  return state.customer.isModalVisible;
}

function selectNewStatus(state: RootState) {
  return state.customer.newStatus;
}

function selectCustomerOrdersQuery(state: RootState) {
  return state.order.currentQuery;
}

function selectSelectedCustomer(state: RootState) {
  return state.customer.selectedCustomer;
}

function selectCustomerCoupon(state: RootState) {
  return state.customer.customerCoupon;
}

function selectLoadingCustomerCoupon(state: RootState) {
  return state.customer.fetchingCoupon;
}

function selectCouponOrders(state: RootState) {
  return state.customer.couponOrders;
}

export {
  selectCustomersFetching,
  selectPaginatedCustomers,
  selectCustomerOrdersFetching,
  selectPaginatedCustomerOrders,
  selectModalVisibility,
  selectNewStatus,
  selectCustomerOrdersQuery,
  selectSelectedCustomer,
  selectCustomerCoupon,
  selectLoadingCustomerCoupon,
  selectCouponOrders
}
