import React, { useState, createContext, ReactNode } from 'react';

import moment from 'moment-timezone';
import { notification } from 'antd';

import { City, DateAndSlotsProps, DriverShift, LegProps } from '../interfaces';
import {
  createRoute,
  getCities,
  getDatesAndSlotsByCity,
  getOrdersByDeliveryWindowIds,
  getDriverShifts,
  getDeliveryLegsBasedOnOrders,
} from '../services/api';
import { formatDatesAndSlotsResponse, formatDriverShifts, formatLegsResponse } from '../utils';
import { DEFAULT_TIMEZONE, getUnixTimeByTimezone } from 'util/dateAndTime';

export interface RoutesContextData {
  fetchUnassignedLegs: () => void;
  setDeliveryLegs: (legs: LegProps[]) => void;
  getDatesAndSlots: (city: string) => void;
  fetchDriverShifts: (cityId: string) => void;
  handleCreateRoute: (
    driverId: string | undefined,
    legs: LegProps[],
    setSelectedDateAndSlots: (dateAndSlots: DateAndSlotsProps) => void,
    setSelectedSlots: (slots: any) => void,
  ) => void;
  handleFilterLegsBySlotIds: (slotIds: string) => void;
  setSelectedDriverShift: (driverShift: DriverShift) => void;
  setAssignedLegs: (legs: LegProps[]) => void;
  setUnassignedLegs: (legs: LegProps[]) => void;
  setCreateRouteStep: (step: number) => void;
  setCity: (city: City) => void;
  setSelectedRowKeys: (keys: React.Key[]) => void;
  setFilteredTheLegs: (value: boolean) => void;
  setCheckedSlots: (slots: string[]) => void;
  setSelectedDate: (date: string) => void;
  selectedDate: string;
  filteredTheLegs: boolean;
  deliveryLegs: LegProps[];
  dates: DateAndSlotsProps[];
  assignedLegs: LegProps[];
  unassignedLegs: LegProps[];
  driverShifts: DriverShift[];
  selectedDriverShift: DriverShift;
  loadingLegs: boolean;
  loadingCreateRoute: boolean;
  defaultCity: City;
  createRouteStep: number;
  city: City;
  selectedRowKeys: React.Key[];
  checkedSlots: string[];
  loadingSlots: boolean;
  cities: City[];
  generateDefaultCity: () => void;
}

export interface RoutesProviderProps {
  children: ReactNode;
}

export const RoutesContext = createContext({} as RoutesContextData);

const RoutesProvider = ({ children }: RoutesProviderProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [deliveryLegs, setDeliveryLegs] = useState<LegProps[]>([]);
  const [unassignedLegs, setUnassignedLegs] = useState<LegProps[]>([]);
  const [assignedLegs, setAssignedLegs] = useState<LegProps[]>([]);
  const [loadingLegs, setLoadingLegs] = useState<boolean>(false);
  const [loadingSlots, setLoadingSlots] = useState<boolean>(true);
  const [dates, setDates] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [driverShifts, setDriverShifts] = useState<DriverShift[]>([]);
  const [selectedDriverShift, setSelectedDriverShift] = useState<DriverShift>({} as DriverShift);
  const [city, setCity] = useState<City>({} as City);
  const [cities, setCities] = useState<City[]>([]);
  const [defaultCity, setDefaultCity] = useState<City>({} as City);
  const [createRouteStep, setCreateRouteStep] = useState<number>(0);
  const [loadingCreateRoute, setLoadingCreateRoute] = useState<boolean>(false);
  const [filteredTheLegs, setFilteredTheLegs] = useState<boolean>(false);
  const [checkedSlots, setCheckedSlots] = useState<string[]>([]);

  async function generateDefaultCity() {
    const fetchedCities = await getCities();
    const { results } = fetchedCities;
    setCities(results);

    const defaultCityObject = {
      name: results[0]?.name,
      id: results[0]?.id,
      zoneId: results[0]?.zoneId,
      timezone: DEFAULT_TIMEZONE, // fetchedCities[0]?.timezone,
    };
    setDefaultCity(defaultCityObject);
  }

  const fetchUnassignedLegs = async (orderIds?: string[]) => {
    setLoadingLegs(true);
    const response = await getDeliveryLegsBasedOnOrders(orderIds);

    if (response) {
      if (response.error) {
        notification.error({
          message: 'Error getting the legs!',
          description: 'Please contact support.',
        });
        // TODO: shouldn't we setLoadingLegs(false); here?
      } else {
        const formattedLegs = formatLegsResponse(response);
        setDeliveryLegs(formattedLegs);
        setLoadingLegs(false);

        if (formattedLegs?.length === 0) {
          notification.warning({
            message: 'No legs!',
            description: 'Can´t add legs because there is none.',
          });
        }
      }
    }
  };

  const getDatesAndSlots = async (zoneId: string) => {
    setLoadingSlots(true);

    const startDate = moment().format();
    const endDate = moment().add(4, 'days').format();

    const unixStartTime = getUnixTimeByTimezone(startDate, 5, 0, DEFAULT_TIMEZONE);
    const unixEndTime = getUnixTimeByTimezone(endDate, 23, 40, DEFAULT_TIMEZONE);

    const response = await getDatesAndSlotsByCity({ zoneId, startTime: unixStartTime, endTime: unixEndTime });

    if (response) {
      const formattedDates = formatDatesAndSlotsResponse(response);
      setDates(formattedDates);
      setLoadingSlots(false);
    }
  };

  const fetchDriverShifts = async (cityIds: string) => {
    const TIMEZONE = DEFAULT_TIMEZONE; // city.timezone;

    let estimatedStartTime = moment();
    let estimatedEndTime = moment();

    if (selectedDate !== '') {
      const timeNow = moment().format('HH:mm');
      estimatedStartTime = moment(`${selectedDate} ${timeNow}`);
      estimatedEndTime = moment(`${selectedDate} ${timeNow}`);
    }

    const unixStartTime = getUnixTimeByTimezone(estimatedStartTime.format(), 5, 0, TIMEZONE);
    const unixEndTime = getUnixTimeByTimezone(estimatedEndTime.format(), 23, 40, TIMEZONE);

    try {
      const response = await getDriverShifts({
        zoneId: cityIds,
        estimatedStartTime: unixStartTime,
        estimatedEndTime: unixEndTime,
      });
      if (response.results) {
        const formattedDriverShifts = formatDriverShifts(response.results, city.timezone || TIMEZONE);
        setDriverShifts(formattedDriverShifts);
      } else throw new Error('Error fetching the shifts.');
    } catch (err) {
      const error: any = err;
      notification.error({
        message: 'Error fetching shifts!',
        description: error.message,
      });
    }
  };

  const onRouteCreationEnd = () => {
    setCreateRouteStep(0);
    setSelectedRowKeys([]);
    setAssignedLegs([]);
    setSelectedDriverShift({} as DriverShift);
    setDeliveryLegs([]);
    setFilteredTheLegs(false);
    setLoadingCreateRoute(false);
    fetchUnassignedLegs();
  };

  const handleCreateRoute = async (
    shiftId: string,
    legs: any,
    setSelectedDateAndSlots: (dateAndSlots: DateAndSlotsProps) => void,
    setSelectedSlots: (slots: any) => void,
  ) => {
    setLoadingCreateRoute(true);

    const legsFormatted = legs.map((item: LegProps, index: number) => ({
      id: item.id,
      position: index + 1,
    }));

    const data = {
      shiftId,
      legs: legsFormatted,
    };

    await createRoute(data)
      .then((res) => {
        if (res.status !== 'error') {
          notification.success({
            message: 'Success!',
            description: 'A new route was created.',
          });
        } else throw new Error(res.message);
      })
      .catch((error) => {
        notification.error({
          message: 'Error creating route!',
          description: error.message,
        });
      });

    setSelectedSlots({});
    setSelectedDateAndSlots({} as DateAndSlotsProps);
    onRouteCreationEnd();
  };

  const handleFilterLegsBySlotIds = async (deliveryWindowsIds: string) => {
    setLoadingLegs(true);

    if (deliveryWindowsIds === '') {
      notification.error({
        message: 'Error filtering legs!',
        description: 'Select at least one slot to filter.',
      });
      setLoadingLegs(false);
    } else {
      setUnassignedLegs([]);
      setFilteredTheLegs(true);

      await getOrdersByDeliveryWindowIds(deliveryWindowsIds)
        .then((response) => {
          if (response.status !== 'error') {
            notification.success({
              message: 'Success!',
              description: 'The legs were filtered.',
            });

            const orderIds: string[] = [];
            response.results.forEach((order: any) => {
              orderIds.push(order.id);
            });

            if (orderIds.length === 0) {
              setUnassignedLegs([]);
              setLoadingLegs(false);
            } else fetchUnassignedLegs(orderIds); // CHANGE LOGIC LATER
          } else throw new Error(response.message);
        })
        .catch((error) => {
          notification.error({
            message: 'Error filtering legs!',
            description: error.message,
          });
          setLoadingLegs(false);
        });
    }
  };

  return (
    <RoutesContext.Provider
      value={{
        handleCreateRoute,
        getDatesAndSlots,
        setAssignedLegs,
        setSelectedDriverShift,
        setCreateRouteStep,
        setDeliveryLegs,
        selectedDriverShift,
        unassignedLegs,
        setUnassignedLegs,
        assignedLegs,
        fetchDriverShifts,
        deliveryLegs,
        createRouteStep,
        dates,
        driverShifts,
        defaultCity,
        loadingLegs,
        loadingCreateRoute,
        handleFilterLegsBySlotIds,
        city,
        cities,
        setCity,
        selectedRowKeys,
        setSelectedRowKeys,
        filteredTheLegs,
        setFilteredTheLegs,
        checkedSlots,
        setCheckedSlots,
        setSelectedDate,
        selectedDate,
        loadingSlots,
        generateDefaultCity,
        fetchUnassignedLegs,
      }}
    >
      {children}
    </RoutesContext.Provider>
  );
};

export default RoutesProvider;
