import {
  createAction,
  createAsyncThunk,
  createReducer,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { RootState } from '.';
import { notification } from 'antd';
import { editWarehouse, getWarehouses } from "services/Warehouse.service";

interface Warehouse {
  id: string,
  catalogId: string,
  zoneId: string,
  addressLine1: string,
  addressLine2: string,
  name: string,
  city: string,
  province: string,
  country:string,
  latitude: number,
  longitude: number,
  status: string
}
interface WarehousePaginated {
  totalItems: number;
  currentPage: number;
  perPage: number;
  totalPages: number;
  results: Warehouse[],
}
interface WarehouseSliceState {
  paginated?: WarehousePaginated;
  fetching: boolean;
  currentQuery: string;
  newStatus?: string;
  isModalVisible: boolean;
}

const initialState: WarehouseSliceState = {
  fetching: false,
  currentQuery: '',
  newStatus: '',
  isModalVisible: false,
  paginated: {
    totalItems: 0,
    currentPage: 1,
    perPage: 0,
    totalPages: 1,
    results: [],
  },
};

export const fetchWarehouses = createAsyncThunk(
  "warehouse/fetchWarehouses",
  async function (query: string) {
    try {
      const response = await getWarehouses(query);
      return response;
    } catch (err) {
      const error: any = err;
      notification.error({
        message: 'Error!',
        description: error.message,
      });
    }
  }
);

export const updateWarehouse = createAsyncThunk(
  "warehouse/updateWarehouse",
  async function (params: any, { getState, dispatch }) {
    const {
      warehouse: { newStatus, isModalVisible, currentQuery },
    } = getState() as RootState;
    try {
      const body: any = {};
      body.status = newStatus;
      const response = await editWarehouse(params.id, body);

      if (response.error) throw new Error(response.message);

      dispatch(changeModalVisibility(!isModalVisible));
      dispatch(fetchWarehouses(currentQuery));

      notification.success({
        message: 'Success!',
        description: 'The location status was updated.',
      });
    } catch (err) {
      const error: any = err;
      notification.error({
        message: 'Error!',
        description: error.message,
      });
    }
  }
);

export const storeQuery = createAction('warehouse/storeQuery', (payload: string) => ({ payload }));

export const storeNewStatus = createAction('warehouse/storeNewStatus', (payload: string) => ({ payload }));

export const changeModalVisibility = createAction('warehouse/changeModalVisibility', (payload: boolean) => ({ payload }));

export const warehouseReducer = createReducer(initialState, (builder) => {
  builder

    .addCase(changeModalVisibility, (state, action) => {
      state.isModalVisible = action.payload;
    })
    .addCase(storeNewStatus, (state, action) => {
      state.newStatus = action.payload;
    })
    .addCase(storeQuery, (state, action) => {
      state.currentQuery = action.payload;
    })
    .addCase(fetchWarehouses.fulfilled, (state, action) => {
      state.paginated = action.payload;
    })
    .addMatcher(isPending, (state) => {
      state.fetching = true;
    })
    .addMatcher(isFulfilled, (state) => {
      state.fetching = false;
    })
    .addMatcher(isRejected, (state) => {
      state.fetching = false;
    });
});
