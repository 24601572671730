import { request } from 'util/request';

export { getDrivers } from 'services/Driver.service';

export async function getOrder(orderId: string) {
  return request(`order/orders/${orderId}`);
}

export async function getProductsByIds(params?: any) {
  return request('inventory/products/cart', params, 'POST');
}

export async function getProductsById(productId: string) {
  return request(`inventory/products/${productId}`);
}

export async function getProducts(params?: any){
  return request('inventory/products', params, 'GET');
}

export async function getLocations(params?: any) {
  return request('inventory/locations', params, 'GET');
}

export async function getLocationById(id: string) {
  return request(`inventory/locations/${id}`);
}

export async function updateOrder(params: any) {
  const { id, body } = params;
  return request(`order/orders/${id}`, body, 'PATCH');
}

export async function updateOrderStatus(params: any) {
  const { id, body } = params;
  return request(`order/orders/${id}/status`, body, 'PATCH');
}

export async function updateOrderAddress(params: any) {
  const { id, body } = params;
  return request(`order/orders/${id}/address`, body, 'PATCH');
}

export async function updateOrderWindow(params: any) {
  const { orderId, windowId } = params;
  return request(`order/orders/${orderId}/delivery-windows/${windowId}`, null, 'PATCH');
}

export async function getCustomer(customerId: string) {
  return request(`customer/customers/${customerId}`);
}

export async function getCouponByName(params: any) {
  return request(`coupon/coupons/${params.name}`, params, 'GET');
}

export async function getDeliveryLegsByOrderId(orderId?: any) {
  return request('delivery/legs/search', orderId, 'POST');
}

export async function getRoute(routeId?: string) {
  return request(`delivery/routes/${routeId}`);
}

export async function getAddressDetails(address: string, token: string) {
  return request(`geo/autocomplete?address=${address}&sessionToken=${token}`);
}

export async function getAddressLocation(id: string, token: string) {
  return request(`/geo/places/${id}?sessionToken=${token}`);
}
