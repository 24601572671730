export interface DriverType {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  status: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  provinceState: string;
  country: string;
  latitude: 0,
  longitude: 0,
  cityName: string;
}


export enum DRIVER_STATUS {
  'ACTIVE' = 'ACTIVE',
  'INACTIVE' = 'INACTIVE',
}
