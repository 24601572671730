import React, { useEffect, useState } from 'react';

import { Input, Row, Col, Modal, Form, Select, notification } from 'antd';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

import useWarehouses from 'hooks/useWarehouses';
import generateRandomPassword from 'util/createRandomPassword';
import { COGNITO_PICKING_CLIENT_ID, COGNITO_PICKING_USER_POOL_ID } from 'constants/cognitoIds';
import { addPickingUser } from 'services/PickingUsers.service';

interface AddPickingUserModalProps{
  modalVisible: boolean;
  setModalVisible: (value: boolean) => void;
  fetchPickingUsers: () => void;
}

const AddPickingUserModal: React.FC<AddPickingUserModalProps> = ({modalVisible, setModalVisible, fetchPickingUsers}) => {
  const [locations, setLocations] = useState([]);
  const [form] = Form.useForm();

  const { Option } = Select;

  const { paginatedWarehouses, fetchWarehouses, loading } = useWarehouses();

  useEffect(() => {
    fetchWarehouses();
  }, [])

  const cognitoRegister = async (email: string) => {
    const randomPassword = generateRandomPassword();

    const poolData: AmazonCognitoIdentity.ICognitoUserPoolData = {
      UserPoolId: COGNITO_PICKING_USER_POOL_ID || '',
      ClientId: COGNITO_PICKING_CLIENT_ID || '',
    };

    console.log("UserPoolId: ", COGNITO_PICKING_USER_POOL_ID);
    console.log("ClientId: ", COGNITO_PICKING_CLIENT_ID)

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    const attributeList: any[] = [];

    const dataEmail = {
      Name: 'email',
      Value: email,
    };

    const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(dataEmail);
    attributeList.push(attributeEmail);

    userPool.signUp(email, randomPassword, attributeList, attributeList, function (err, result) {
      if (err) {
        notification['error']({
          message: 'Error registering Picking user on cognito!',
          description: err.message || JSON.stringify(err),
        });
        return;
      }
      const cognitoUser = result?.user;
      notification['success']({
        message: 'Success registering Picking user on cognito!',
        description: `Created cognito username ${cognitoUser?.getUsername()}`,
      });
    });
  };

  const handleOkAddModal = () => {
    form
      .validateFields()
      .then(async (values) => {

        const response = await addPickingUser(values);

        if (response.error || response.status === 'error') throw new Error(response.message);

        notification['success']({
          message: 'Success!',
          description: 'The Picking User has been successfully added.',
        });

        await cognitoRegister(values.email);

        await fetchPickingUsers();

        form.resetFields();
        setModalVisible(!modalVisible);
      })
      .catch((error) => {
        notification['error']({
          message: 'Error adding a Picking User!',
          description: error.message,
        });
      });
  };

  const handleCancelAddModal = () => {
    form.resetFields();
    setModalVisible(!modalVisible);
  };

  return (
    <Modal
      title="Add New Picking User"
      visible={modalVisible}
      onOk={handleOkAddModal}
      onCancel={handleCancelAddModal}
    >
      <Form form={form} layout='vertical' autoComplete={'off'} scrollToFirstError={true}>
        <Row>
          <Col lg={24} md={24} xs={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input the Name!',
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col lg={24} md={24} xs={24}>
            <Form.Item
              label="City Name"
              name="cityName"
              rules={[
                {
                  required: true,
                  message: 'Please input the City Name!',
                },
              ]}
            >
              <Input placeholder="City Name" />
            </Form.Item>
          </Col>
          <Col lg={24} md={24} xs={24}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid Email!',
                },
                {
                  required: true,
                  message: 'Please input your Email!',
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Locations"
              name="locations"
              rules={[
                {
                  required: true,
                  message: 'Please select at least one location!',
                },
              ]}
            >
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                value={locations}
                placeholder="Select the locations"
                optionLabelProp="label"
                loading={loading}
              >
                {paginatedWarehouses?.results.map(item => (
                  <Option key={item.id} value={item.id} label={item.name}>{item.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default AddPickingUserModal;
