import { request } from 'util/request';

export async function getCoupons(params?: any) {
  return request('coupon/coupons', params, 'GET');
}

export async function addDiscount(params: any) {
  return request('coupon/coupons', params, 'POST');
}

export async function editDiscount(params: any) {
  const {id,body} = params;
  return request(`coupon/coupons/${id}`, body, 'PATCH');
}

