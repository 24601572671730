import { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import * as DeliveryWindowsActions from "../store/DeliveryWindow.slice";
import { GetDeliveryWindowsParams } from "services/DeliveryWindow.service";
import {
  selectDeliveryWindows,
  selectDeliveryWindowsFetching,
  selectWindowVisualizationModalVisible,
  selectAddWindowModalVisible,
  selectEditWindowModalVisible,
  selectWindowOrders,
  selectDate,
  selectZone,
  selectOrdersFetching
} from "selectors/DeliveryWindows";
import { MODAL_TYPES } from "ui/pages/DeliveryWindows/interfaces";

export default function useDeliveryWindows() {
  const dispatch = useDispatch();

  const deliveryWindows = useSelector(selectDeliveryWindows);
  const windowOrders = useSelector(selectWindowOrders);
  const loading = useSelector(selectDeliveryWindowsFetching);
  const loadingOrders = useSelector(selectOrdersFetching);
  const isWindowsVisualizationModalVisible = useSelector(selectWindowVisualizationModalVisible);
  const isEditWindowModalVisible = useSelector(selectEditWindowModalVisible);
  const isAddWindowModalVisible = useSelector(selectAddWindowModalVisible);
  const selectedDate = useSelector(selectDate);
  const selectedZone = useSelector(selectZone);

  const fetchDeliveryWindows = useCallback(
    async function (query: GetDeliveryWindowsParams) {
      dispatch(DeliveryWindowsActions.fetchDeliveryWindowsByDate(query));
    },
    [dispatch]
  );

  const changeModalVisibility = useCallback(
    async function (modalToBeOpen: MODAL_TYPES) {
        switch (modalToBeOpen) {
          case MODAL_TYPES.showDeliveryWindows:
            dispatch(DeliveryWindowsActions.toggleVisualizeDeliveryWindowsModal())
            break;
          case MODAL_TYPES.addDeliveryWindows:
            dispatch(DeliveryWindowsActions.toggleAddDeliveryWindowModal())
            break;
          case MODAL_TYPES.editDeliveryWindows:
            dispatch(DeliveryWindowsActions.toggleEditDeliveryWindowModal())
            break;
          default:
            break;
        }

    },
    [dispatch]
  );

  const fetchOrdersByDeliveryWindowId = useCallback(
    async function (query: string) {
      dispatch(DeliveryWindowsActions.fetchOrdersByDeliveryWindowId(query))
    },
    [dispatch]
  );

  const addDeliveryWindow = useCallback(
    async function (params: any) {
      dispatch(DeliveryWindowsActions.addDeliveryWindow(params))
    },
    [dispatch]
  );

  const updateDeliveryWindow = useCallback(
    async function (params: any) {
      dispatch(DeliveryWindowsActions.updateDeliveryWindow(params))
    },
    [dispatch]
  );

  const deleteDeliveryWindow = useCallback(
    async function (id: string) {
      dispatch(DeliveryWindowsActions.deleteDeliveryWindow(id))
    },
    [dispatch]
  );

  const changeZone = useCallback(
    async function (zone: string) {
      dispatch(DeliveryWindowsActions.storeZone(zone))
    },
    [dispatch]
  );

  const changeDate = useCallback(
    async function (date: number) {
      dispatch(DeliveryWindowsActions.storeDate(date))
    },
    [dispatch]
  );


  return {
    loading,
    deliveryWindows,
    fetchDeliveryWindows,
    changeModalVisibility,
    isAddWindowModalVisible,
    isEditWindowModalVisible,
    isWindowsVisualizationModalVisible,
    fetchOrdersByDeliveryWindowId,
    deleteDeliveryWindow,
    updateDeliveryWindow,
    addDeliveryWindow,
    windowOrders,
    selectedDate,
    selectedZone,
    changeZone,
    loadingOrders,
    changeDate
  };
}
