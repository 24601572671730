import { request } from 'util/request';

async function getCampaigns(params?: any) {
  return request('marketing/campaigns', params, 'GET');
}

async function createCampaign(body: any) {

  return request('marketing/campaigns', body, 'POST', 'multipart/form-data');
}


export { getCampaigns, createCampaign }
