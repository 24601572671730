import moment from 'moment-timezone';
import { DEFAULT_TIMEZONE } from 'util/dateAndTime';

export const COLUMNS = [
  'time-0500',
  'time-0530',
  'time-0600',
  'time-0630',
  'time-0700',
  'time-0730',
  'time-0800',
  'time-0830',
  'time-0900',
  'time-0930',
  'time-1000',
  'time-1030',
  'time-1100',
  'time-1130',
  'time-1200',
  'time-1230',
  'time-1300',
  'time-1330',
  'time-1400',
  'time-1430',
  'time-1500',
  'time-1530',
  'time-1600',
  'time-1630',
  'time-1700',
  'time-1730',
  'time-1800',
  'time-1830',
  'time-1900',
  'time-1930',
  'time-2000',
  'time-2030',
  'time-2100',
  'time-2130',
  'time-2200',
  'time-2230',
  'time-2300',
  'time-2330',
];

export const HOURS = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const WEEK_DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const WEEK_DAYS_ABBREVIATED = ['mon', 'tues', 'wed', 'thurs', 'fri', 'sat', 'sun'];

const COLUMNS_WEEKLY = WEEK_DAYS_ABBREVIATED.map((day) => {
  const formattedDayTimes = COLUMNS.map((time) => `${day}-${time}`);
  return [...formattedDayTimes];
});
export const CONCATED_WEEKLY_COLUMNS = COLUMNS_WEEKLY.flat();

const today = moment().tz(DEFAULT_TIMEZONE);

export const todayIsoString = today.toISOString();

export const FULL_HOURS = HOURS.map((hour: number) => {
  const filteredHourIds = COLUMNS.filter((hourId) => {
    const stringCorrespondingHour = hourId.slice(5, 7);
    if (Number(stringCorrespondingHour) === hour) return hourId;
    return 0;
  });

  return { first: filteredHourIds[0], second: filteredHourIds[1], title: hour };
});

export const DAYS_COLUMNS = WEEK_DAYS_ABBREVIATED.map((day: string) => {
  const dayFirstThreeLetters = day.slice(0, 3);
  const filteredDaysIds = CONCATED_WEEKLY_COLUMNS.filter((dayId) => {
    const stringCorrespondingDay = dayId.slice(0, 3);
    if (stringCorrespondingDay === dayFirstThreeLetters) return dayId;
    return 0;
  });

  return { first: filteredDaysIds[0], second: filteredDaysIds[37], title: day };
});

export const FORMATTED_HOURS = [
  { hours: '05:00', id: '05:00' },
  { hours: '05:30', id: '05:30' },
  { hours: '06:00', id: '06:00' },
  { hours: '06:30', id: '06:30' },
  { hours: '07:00', id: '07:00' },
  { hours: '07:30', id: '07:30' },
  { hours: '08:00', id: '08:00' },
  { hours: '08:30', id: '08:30' },
  { hours: '09:00', id: '09:00' },
  { hours: '09:30', id: '09:30' },
  { hours: '10:00', id: '10:00' },
  { hours: '10:30', id: '10:30' },
  { hours: '11:00', id: '11:00' },
  { hours: '11:30', id: '11:30' },
  { hours: '12:00', id: '12:00' },
  { hours: '12:30', id: '12:30' },
  { hours: '13:00', id: '13:00' },
  { hours: '13:30', id: '13:30' },
  { hours: '14:00', id: '14:00' },
  { hours: '14:30', id: '14:30' },
  { hours: '15:00', id: '15:00' },
  { hours: '15:30', id: '15:30' },
  { hours: '16:00', id: '16:00' },
  { hours: '16:30', id: '16:30' },
  { hours: '17:00', id: '17:00' },
  { hours: '17:30', id: '17:30' },
  { hours: '18:00', id: '18:00' },
  { hours: '18:30', id: '18:30' },
  { hours: '19:00', id: '19:00' },
  { hours: '19:30', id: '19:30' },
  { hours: '20:00', id: '20:00' },
  { hours: '20:30', id: '20:30' },
  { hours: '21:00', id: '21:00' },
  { hours: '21:30', id: '21:30' },
  { hours: '22:00', id: '22:00' },
  { hours: '22:30', id: '22:30' },
  { hours: '23:00', id: '23:00' },
  { hours: '23:30', id: '23:30' },
];
