import React, { useContext, useState } from 'react';

import moment from 'moment-timezone';
import { DeleteOutlined } from '@ant-design/icons';
import { notification, Popover, Spin } from 'antd';

import { Driver, Shift as ShiftInterface, ViewType } from '../../interfaces';
import { convertHoursToIsoString, convertIsoStringToUnixMilliseconds, getDateAndTimeString } from '../../utils';
import { FORMATTED_HOURS } from '../../constants';
import { deleteShift, updateShift } from '../../services/api';
import { DriverShiftsContext } from '../../contexts/DriverShiftsContext';
import styles from './Shift.module.css';
import Select from '../Select';
import { DEFAULT_TIMEZONE } from 'util/dateAndTime';

interface ShiftProps {
  item: ShiftInterface;
  drivers: Driver[];
}

const Shift: React.FC<ShiftProps> = ({ item, drivers }) => {
  const { selectedDate, viewType, selectedWeek, fetchShifts } = useContext(DriverShiftsContext);

  const [startTime, setStartTime] = useState<any>();
  const [endTime, setEndTime] = useState<any>();
  const [selectedDriver, setSelectedDriver] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const fetchUpdateShift = async (shiftId: string) => {
    setLoading(true);

    const TIMEZONE: any = DEFAULT_TIMEZONE;
    const body: any = {};

    const date = viewType === ViewType.WEEKLY ? selectedWeek : selectedDate;
    const column = item.shiftDurationString.split('/')[0];

    const startTimeISO: any = convertHoursToIsoString(startTime, date, column, viewType);
    const endTimeISO: any = convertHoursToIsoString(endTime, date, column, viewType);

    // returns a string to use with moment.tz() - example: 2021-07-20 06:00:00
    const startDate = getDateAndTimeString(startTimeISO);
    const endDate = getDateAndTimeString(endTimeISO);

    // gets the corresponding time of a specific city based on the time selected locally
    const startTimeAtSpecificCity = moment.tz(startDate, TIMEZONE);
    const endTimeAtSpecificCity = moment.tz(endDate, TIMEZONE);

    // converts the date to UTC ISO string before send to the API
    const startTimeISOStringUTC = startTimeAtSpecificCity.utc().format();
    const endTimeISOStringUTC = endTimeAtSpecificCity.utc().format();

    const startTimeUnixMilliseconds = convertIsoStringToUnixMilliseconds(startTimeISOStringUTC);
    const endTimeUnixMilliseconds = convertIsoStringToUnixMilliseconds(endTimeISOStringUTC);

    if (startTime) body.estimatedStartTime = startTimeUnixMilliseconds;
    if (endTime) body.estimatedEndTime = endTimeUnixMilliseconds;
    if (selectedDriver) {
      if (selectedDriver === 'null') {
        notification.error({
          message: 'Error!',
          description: `Can't reassign the driver to Kiwi Shift`,
        });
        setLoading(false);
      } else {
        body.driverId = selectedDriver;
      }
    }

    if (selectedDriver !== 'null') {
      await updateShift(shiftId, body)
        .then((res) => {
          if (res.ok) {
            notification.success({
              message: 'Success!',
              description: 'Shift was updated!',
            });
            fetchShifts(selectedDate, selectedWeek);
          } else {
            notification.error({
              message: 'Error!',
              description: res.message,
            });
          }
        })
        .then(() => {
          setLoading(false);
        });
    }
  };

  const handleDeleteShift = async () => {
    setLoading(true);
    await deleteShift(item.id)
      .then((res) => {
        if (res.status !== 'error') {
          fetchShifts(selectedDate, selectedWeek);
          notification.success({
            message: 'Success!',
            description: 'Shift was deleted!',
          });
        } else {
          notification.error({
            message: 'Error!',
            description: res.message,
          });
        }
      })
      .then(() => setLoading(false));
  };

  return (
    <Popover
      onVisibleChange={() => {
        setSelectedDriver(item.driver?.id);
        setStartTime(item.startTimeFormatted);
        setEndTime(item.endTimeFormatted);
      }}
      trigger="click"
      placement="bottomLeft"
      content={
        <>
          <h1 className={styles.popoverTitle}>Edit Shift - {item.dateString}</h1>
          <p className={styles.selectLabel}>Driver Name:</p>
          <Select
            value={selectedDriver}
            defaultValue={item.driver?.id || 'null'}
            handleChange={(value: string) => setSelectedDriver(value)}
            options={drivers}
            optionDisplayName="firstName"
          />
          <div className={styles.row}>
            <div className={styles.selectContainer}>
              <p className={styles.selectLabel}>Start Time:</p>
              <Select
                value={startTime}
                defaultValue={item.startTimeFormatted}
                handleChange={(value: string) => setStartTime(value)}
                options={FORMATTED_HOURS}
                optionDisplayName="hours"
              />
            </div>
            <div className={styles.selectContainer}>
              <p className={styles.selectLabel}>End Time:</p>
              <Select
                value={endTime}
                defaultValue={item.endTimeFormatted}
                handleChange={(value: string) => setEndTime(value)}
                options={FORMATTED_HOURS}
                optionDisplayName="hours"
              />
            </div>
          </div>
          <div className={styles.buttonsRow}>
            {loading ? (
              <Spin />
            ) : (
              <>
                <div className={styles.trashIconContainer}>
                  <DeleteOutlined onClick={handleDeleteShift} className={styles.trashIcon} />
                </div>
                <button type="button" onClick={() => fetchUpdateShift(item.id)} className={styles.updateButton}>
                  Update
                </button>
              </>
            )}
          </div>
        </>
      }
    >
      <div
        className={styles.session}
        style={{
          gridColumn: item.shiftDurationString,
          gridRow: item.driver?.id ? item.row : 2,
          marginTop: item.driver?.id ? 0 : '5%',
        }}
      >
        {viewType !== ViewType.WEEKLY && `${item.startTimeFormatted} - ${item.endTimeFormatted}`}
      </div>
    </Popover>
  );
};

export default Shift;
