import { RootState } from "store";

function selectPaginatedWarehouses(state: RootState) {
  return state.warehouse.paginated;
}

function selectWarehousesFetching(state: RootState) {
  return state.warehouse.fetching;
}

function selectWarehousesIsModalVisible(state: RootState) {
  return state.warehouse.isModalVisible;
}

function selectWarehousesStoreNewStatus(state: RootState) {
  return state.warehouse.newStatus;
}

function selectWarehousesStoreQuery(state: RootState) {
  return state.warehouse.currentQuery;
}

export {
  selectPaginatedWarehouses,
  selectWarehousesFetching,
  selectWarehousesIsModalVisible,
  selectWarehousesStoreNewStatus,
  selectWarehousesStoreQuery
};
