import React, { useEffect } from 'react';

import { Modal } from 'antd';

import styles from './EditOrderStatusModal.module.css';

interface EditOrderStatusModalProps{
  orderId: string,
  changeModalVisibility: (visible: boolean) => void;
  isModalVisible: boolean;
  newStatus: string | undefined;
  updateOrder: (params: any) => void;
}

const EditOrderStatusModal: React.FC<EditOrderStatusModalProps> = (
  {
    orderId,
    changeModalVisibility,
    isModalVisible,
    newStatus,
    updateOrder
  }) => {

  const handleCancelChange = () => {
    changeModalVisibility(!isModalVisible)
  };

  const handleUpdateStatus = async () => {
    const params: any = {};
    params.id = orderId;
    params.body = { status: newStatus };
    updateOrder(params)
  };

  useEffect(() => {

  }, [])

  return (
    <Modal
      okText="Yes, change the status"
      cancelText="No, thanks"
      visible={isModalVisible}
      onCancel={handleCancelChange}
      onOk={handleUpdateStatus}
    >
      <h1 className={styles.title}>{`You are chaging the status of order ${orderId} to ${newStatus}`}</h1>
      <p className={styles.subtitle}>Do you confirm this changes ?</p>
    </Modal>
  );
};

export default EditOrderStatusModal;
