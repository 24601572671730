function generateRandomPassword() {
  var randomLetters = '';
  var str = 'abcdefghijklmnopqrstuvwxyz';
  let i
  for (i = 1; i <= 8; i++) {
    var char = Math.floor(Math.random()
      * str.length + 1);
    randomLetters += str.charAt(char)
  }
  const firstHalf = randomLetters.slice(0, 4).toUpperCase()
  const secondHalf = randomLetters.slice(4, 8).toLowerCase()
  const randomLettersFormatted = `@${firstHalf}${secondHalf}7`
  return randomLettersFormatted;
}

export default generateRandomPassword
