import React, { memo, useEffect, useState } from 'react';

import { Select } from 'antd';

import useZones from 'hooks/useZones';

const { Option } = Select;
interface ZoneSelectProps {
  onChange: (zoneId: string) => void;
}

function ZoneSelect({ onChange }: ZoneSelectProps) {
  const { loading, zones, defaultZone, fetchZones } = useZones();
  const [selectValue, setSelectValue] = useState('');

  const handleSelectChange = (id: string) => {
    setSelectValue(id);
    onChange(id);
  };

  useEffect(() => {
    if (zones.length === 0) fetchZones();
    if (defaultZone !== '') handleSelectChange(defaultZone);
  }, [defaultZone]);

  return (
    <Select
      style={{ width: '100%' }}
      showSearch
      optionFilterProp="children"
      onChange={handleSelectChange}
      filterOption={(input, option: any | null) =>
        option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
      }
      loading={loading}
      value={selectValue}
    >
      {zones.map((item: any) => (
        <Option key={item.id} value={item.id}>
          {item.name}
        </Option>
      ))}
    </Select>
  );
}

export default memo(ZoneSelect);
