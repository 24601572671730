import React, { useContext } from 'react';

import { Modal } from 'antd';

import styles from './ConfirmationModal.module.css';
import useWarehouses from 'hooks/useWarehouses';

interface ConfirmationModalProps {
  record: any;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ record }) => {

  const { isModalVisible, changeModalVisibility, newStatus, updateWarehouse } = useWarehouses();

  const handleConfirmChange = async () => {
    updateWarehouse({ id: record.id, body: { status: newStatus }})
  };

  return (
    <Modal
      visible={isModalVisible}
      onOk={handleConfirmChange}
      onCancel={() => changeModalVisibility(!isModalVisible)}
      cancelText="No, thanks"
      okText="Yes, change the status"
    >
      <h1 className={styles.title}>
        You are changing the status of the Warehouse {record.name} to {newStatus}
      </h1>
      <p>Do you confirm this changes?</p>
    </Modal>
  );
};

export default ConfirmationModal;
