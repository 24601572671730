import React from 'react';

import { Table } from 'antd';

import { LegProps } from '../../../interfaces';
import styles from './OverviewTable.module.css';

interface OverviewTableProps {
  assignedLegs: LegProps[]
}

const OverviewTable: React.FC<OverviewTableProps> = ({ assignedLegs }) => {


  const OverviewColumns = [
    {
      title: 'Order Id',
      dataIndex: 'orderId',
      render: (text: string) => <p className={styles.columnText}>{text}</p>,
    },
    {
      title: 'Order Number',
      dataIndex: 'orderNumber',
      className: styles.dragVisible,
      render: (text: string) => <p className={styles.listItem}>{text ?? '-'}</p>,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      render: (text: string) => <p className={styles.columnText}>{text}</p>,
    },
    {
      title: 'Job Type',
      dataIndex: 'type',
      render: (text: string) => <p className={styles.columnText}>{text}</p>,
    }
  ]

  return (
    <Table
      columns={OverviewColumns}
      dataSource={assignedLegs}
      rowKey="id"
      pagination={{ defaultPageSize: 8 }}
      scroll={{ x: 500 }}
    />
  )
}

export default OverviewTable;
