import { request } from 'util/request';

async function getOrders(params?: any) {
  return request(`order/orders?${params}`);
}

export async function getOrdersByFilters(params?: any) {
  return request('order/orders', params, 'GET');
}

async function updateOrder(params: any) {
  const { id, body } = params;
  return request(`order/orders/${id}`, body, 'PATCH');
}

async function updateOrderStatus(params: any) {
  const { id, body } = params;
  return request(`order/orders/${id}/status`, body, 'PATCH');
}

export { getOrders, updateOrder, updateOrderStatus }
