import React, { memo } from 'react';

import { Select } from 'antd';

import styles from './OrderStatusSelect.module.css';
import { ORDER_STATUSES_ARRAY } from 'constants/orderStatuses';

const { Option } = Select;

interface StatusSelectProps {
  onChange?: any;
  value?: any;
  disabled?: boolean;
  style?: any;
  allowClear?: boolean;
}

const StatusSelect: React.FC<StatusSelectProps> = ({ onChange, value, disabled, style, allowClear = true }) => {

  return (
    <Select
      allowClear={allowClear}
      className={styles.select}
      optionFilterProp="children"
      placeholder="Status"
      onChange={onChange}
      filterOption={(input, option: any | null) =>
        option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
      }
      value={value}
      disabled={disabled}
      style={style}
    >
      {ORDER_STATUSES_ARRAY.map((orderStatus: string) => (
        <Option key={orderStatus} value={orderStatus}>{orderStatus}</Option>
      ))}
    </Select>
  );
};

export default memo(StatusSelect);
