import moment from "moment";

import { DeliveryWindowResponse } from "store/DeliveryWindow.slice";
import { unixDateToTimeZone } from "util/dateAndTime";

export const extractFirstDeliveryWindow = (response: DeliveryWindowResponse[]) => {
  const formattedWindows = response[0]?.windows?.map((window: any) => ({
    ...window,
    date: moment(response[0]?.date).format('DD/MM/YYYY'),
    dateMonthDay: moment(response[0]?.date).format('MM/DD/YYYY'),
    timeWindowInterval: `${unixDateToTimeZone(window.startTime).format('HH:mm')} ${unixDateToTimeZone(window.endTime).format('HH:mm')}`,
    windowStart: unixDateToTimeZone(window.startTime).format('HH:mm'),
    windowEnd: unixDateToTimeZone(window.endTime).format('HH:mm'),
    startTime: unixDateToTimeZone(window.startTime),
    endTime: unixDateToTimeZone(window.endTime),
  }));

  const result = { date: response[0]?.date, windows: formattedWindows };

  return result;
}
