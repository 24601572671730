import React from 'react'
import { Typography } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

export interface ErrorDisplayProps {
  title?: string;
  message?: string;
  small?: boolean;
}

export default function ErrorDisplay(props: ErrorDisplayProps) {
  const { Title } = Typography;
  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 8,
    alignItems: 'center',
    color: '#274060'
  }}>
    <WarningOutlined
      style={{ fontSize: `${props.small ? '24px' : '48px'}` }}
    />

    <Title level={2}>
      {props.title || 'Error while rendering component'}
    </Title>

    <span style={{ fontFamily: '"Roboto Mono", monospace', fontSize: 12 }}>
      {props.message || 'Unknown error'}
    </span>
  </div>
}
