import {
  createAction,
  createAsyncThunk,
  createReducer,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { RootState } from '.';
import { notification } from 'antd';
import formatPhoneNumber from 'util/formatPhoneNumber';
import { getOrders, updateOrderStatus } from "services/Order.service";
import { ORDER_STATUSES } from "ui/pages/Orders/interfaces";
import { RESPONSE_STATUSES } from "util/request";
interface OrderPaginated {
  totalItems: number;
  currentPage: number;
  perPage: number;
  totalPages: number;
  results: [],
}
interface OrderSliceState {
  paginated?: OrderPaginated;
  fetching: boolean;
  currentQuery: string;
  newStatus?: string;
  isModalVisible: boolean;
}

const initialState: OrderSliceState = {
  fetching: false,
  currentQuery: '',
  newStatus: '',
  isModalVisible: false,
  paginated: {
    totalItems: 0,
    currentPage: 0,
    perPage: 0,
    totalPages: 1,
    results: [],
  },
};

export const fetchOrders = createAsyncThunk(
  "order/fetchOrders",
  async function (query: string) {
    try {
      const response = await getOrders(query);
      if (response.error || response.status === 'error') {
        throw new Error(response.message);
      }
      else {
        const formattedResponse = response.results?.map((order: any) => {
          const {
            id,
            status,
            customerDetails,
            estimatedCollectTime,
            destination,
          } = order;
          return {
            ...order,
            orderID: id || '',
            status: status || '',
            deliveryWindow: estimatedCollectTime,
            customerName: customerDetails?.displayName || '-',
            customerEmail: customerDetails?.email || '-',
            phoneNumber: formatPhoneNumber(customerDetails?.phone) || '-',
            city: 'Calgary',
            address: destination?.address || '-',
          };
        });
        response.results = formattedResponse;
        return response;
      }
    } catch (err) {
      const error: any = err;
      notification.error({
        message: 'Error fetching orders!',
        description: error.message,
      });
      return error.message
    }
  }
);

export const updateOrder = createAsyncThunk(
  "order/updateOrder",
  async function (params: string, { getState, dispatch }) {
    const {
      order: { newStatus, isModalVisible, currentQuery },
    } = getState() as RootState;

    try {
      const response = await updateOrderStatus(params);

      if (response.error || response.status === 'error') {
        if (newStatus === ORDER_STATUSES.PREPARED && response.statusCode === RESPONSE_STATUSES.PAYMENT_REQUIRED)
          throw new Error('Could not process payment - Card Declined');
        else throw new Error(response.message);
      } else {
        dispatch(changeModalVisibility(!isModalVisible))
        dispatch(fetchOrders(currentQuery));
        notification.success({
          message: 'Success editing order status!',
          description: `Status changed to ${newStatus}`,
        });
      }
    } catch (err) {
      const error: any = err;
      notification.error({
        message: 'Error editing order status!',
        description: error.message,
      });
    }
  }
);

export const storeQuery = createAction('order/storeQuery', (payload: string) => ({ payload }));

export const storeNewStatus = createAction('order/storeNewStatus', (payload: string) => ({ payload }));

export const changeModalVisibility = createAction('order/changeModalVisibility', (payload: boolean) => ({ payload }));

export const orderReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(changeModalVisibility, (state, action) => {
      state.isModalVisible = action.payload;
    })
    .addCase(storeQuery, (state, action) => {
      state.currentQuery = action.payload;
    })
    .addCase(storeNewStatus, (state, action) => {
      state.newStatus = action.payload;
    })
    .addCase(fetchOrders.fulfilled, (state, action) => {
      state.paginated = action.payload;
    })
    .addMatcher(isPending, (state) => {
      state.fetching = true;
    })
    .addMatcher(isFulfilled, (state) => {
      state.fetching = false;
    })
    .addMatcher(isRejected, (state) => {
      state.fetching = false;
    });
});
