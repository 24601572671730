import React, { useContext, useEffect, useState } from 'react';

import moment from 'moment';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { MONTHS, todayIsoString, WEEK_DAYS } from '../../constants';
import { DriverShiftsContext } from '../../contexts/DriverShiftsContext';
import { ViewType } from '../../interfaces';
import { checkTimeAndAddZero, convertIsoStringToDate, getDayOrdinal } from '../../utils';

import styles from './DateBox.module.css';

const DateBox: React.FC = () => {
  const [showDate, setShowDate] = useState(true);
  const [date, setDate] = useState<any>();
  const [currentMonthName, setCurrentMonthName] = useState<string>('');
  const [currentWeekDay, setCurrentWeekDay] = useState<string>('');
  const [currentYear, setCurrentYear] = useState<string>('');
  const [currentDay, setCurrentDay] = useState<number>(0);
  const [day, setDay] = useState<string>(todayIsoString);
  const [week, setWeek] = useState<string>(todayIsoString);

  const { fetchShifts, viewType, setLoading, setSelectedWeek, setSelectedDate } = useContext(DriverShiftsContext);

  useEffect(() => {
    if (viewType === ViewType.WEEKLY) setDate(convertIsoStringToDate(week));
    else setDate(convertIsoStringToDate(day));
  }, [day, week, viewType]);

  useEffect(() => {
    if (date) {
      const monthName = MONTHS[date.getMonth()];
      const weekDay = WEEK_DAYS[date.getDay()];
      const year = date.getFullYear();
      const dateDay = date.getDate();

      setCurrentMonthName(monthName);
      setCurrentWeekDay(weekDay);
      setCurrentYear(year);
      setCurrentDay(dateDay);
    }
  }, [date]);

  useEffect(() => {
    if (viewType === ViewType.WEEKLY) setShowDate(false);
    else setShowDate(true);
  }, [week, viewType]);

  const handleGoToPrevious = () => {
    setLoading(true);

    if (viewType === ViewType.WEEKLY) {
      const dateToConvert = moment(week).subtract(7, 'days');
      const isoDate = dateToConvert.toISOString();

      setSelectedWeek(isoDate);
      setWeek(isoDate);
      fetchShifts(undefined, isoDate);
    } else {
      const dateToConvert = moment(day).subtract(1, 'days');
      const isoDate = dateToConvert.toISOString();

      setSelectedDate(isoDate);
      setDay(isoDate);
      fetchShifts(isoDate);
    }
  };

  const handleGoToNext = () => {
    setLoading(true);

    if (viewType === ViewType.WEEKLY) {
      const dateToConvert = moment(week).add(7, 'days');
      const isoDate = dateToConvert.toISOString();

      setSelectedWeek(isoDate);
      setWeek(isoDate);
      fetchShifts(undefined, isoDate);
    } else {
      const dateToConvert = moment(day).add(1, 'days');
      const isoDate = dateToConvert.toISOString();

      setSelectedDate(isoDate);
      setDay(isoDate);
      fetchShifts(isoDate);
    }
  };

  return (
    <div className={styles.rowContainer}>
      <button
        type="button"
        className={styles.arrowContainer}
        onClick={handleGoToPrevious}
        style={{ gridColumn: 'drivers', gridRow: 'days' }}
      >
        <LeftOutlined className={styles.arrowIcon} />
      </button>
      <div className={styles.container}>
        <p className={!showDate ? styles.bigMonthAndYear : styles.monthAndYear}>
          {currentMonthName}, {currentYear}
        </p>
        {showDate && (
          <div style={{ width: 'fit-content' }}>
            <p className={styles.day}>
              {checkTimeAndAddZero(currentDay)}
              <sup className={styles.sup}>{getDayOrdinal(currentDay)}</sup>
              <sub className={styles.sub}>{currentWeekDay}</sub>
            </p>
          </div>
        )}
      </div>
      <button
        type="button"
        className={styles.arrowContainer}
        onClick={handleGoToNext}
        style={{ gridRow: 'days', gridColumn: 'arrowRight' }}
      >
        <RightOutlined className={styles.arrowIcon} />
      </button>
    </div>
  );
};

export default DateBox;
