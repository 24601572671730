import { RootState } from "store";

function selectPaginatedCampaigns(state: RootState) {
  return state.campaign.paginated;
}

function selectCampaignsFetching(state: RootState) {
  return state.campaign.fetching;
}

function selectAddCampaignModalVisible(state: RootState) {
  return state.campaign.isAddModalVisible;
}

export {
  selectPaginatedCampaigns,
  selectCampaignsFetching,
  selectAddCampaignModalVisible
};
