import { notification } from 'antd';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

import { COGNITO_USER_POOL_ID, COGNITO_CLIENT_ID } from '../constants/cognitoIds';
import createRandomPassword from './createRandomPassword';

const cognitoRegister = async (params) => {
  const randomPassword = createRandomPassword();
  const { email } = params;

  const poolData = {
    UserPoolId: COGNITO_USER_POOL_ID,
    ClientId: COGNITO_CLIENT_ID,
  };

  var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

  const attributeList = [];

  userPool.signUp(
    email,
    randomPassword,
    attributeList,
    null,
    function (err, result) {
      if (err) {
        notification['error']({
          message: 'Error registering driver on cognito!',
          description: err.message || JSON.stringify(err),
        });
        return;
      }
      const cognitoUser = result?.user;
      notification['success']({
        message: 'Success registering driver on cognito!',
        description: `Created cognito username ${cognitoUser?.getUsername()}`,
      });
    }
  );
};

export default cognitoRegister;
