import moment from 'moment';
import { OrderType, ProductType } from './interfaces';

export const getCsvColumns = (providerName: string) => {
  const providersColumns = [{
    provider: "Wallace and Carrey",
    columnsForCsv:
      [
        'Order Type',
        'PO Number',
        'Customer Number',
        'Distributor Customer No.',
        'Customer Name',
        'Customer Address',
        'Customer City',
        'Consumer Province',
        'Consumer Postal Code',
        'Product Code',
        'Item Code',
        'Item Description',
        'Qty Ordered',
        'UPC',
        'Lot Number',
        'Order Date',
        'Expected Ship Date',
        'Provider',
      ]
  }, {
    provider: "Pratts",
    columnsForCsv:
      [
        'PO Number',
        'Item Code',
        'Item Description',
        'Qty Ordered',
      ]
  },
  {
    provider: "BK Liquors",
    columnsForCsv:
      [
        'PO Number',
        'Item Description',
        'Size',
        'Qty Ordered',
        'Order Date',
        'Delivery Window start hour',
        'Delivery Window end hour',
      ]
  }];

  const csvProviderColumns = providersColumns.filter((template: any): any => template.provider === providerName);

  return csvProviderColumns[0]?.columnsForCsv;
}


export const getCsvValues = (order: OrderType, product: ProductType) => {
  const orderId = order?.id;
  const orderIdLast7Digits = orderId?.substr(orderId?.length - 7);
  const orderDate = moment.utc(order?.estimatedDeliveryTime).local().format('YYYY-MM-DD');
  const DeliveryWindowStart = moment.utc(order?.estimatedCollectTime).local().format('HH:mm');
  const DeliveryWindowEnd = moment.utc(order?.estimatedDeliveryTime).local().format('HH:mm');

  const providersValues = [
    {
      provider: "Wallace and Carrey",
      valuesForCsv: [
        'CO',
        orderIdLast7Digits || '',
        '',
        '139860',
        'KiwiMe Inc',
        '',
        '',
        '',
        '',
        '',
        product.sku || '',
        product.name.substring(0, 48) || '',
        product.qty.toString() || '',
        product.upc || '',
        '',
        orderDate || '',
        '',
        product.catalogProvider || ''
      ],
    },
    {
      provider: "Pratts",
      valuesForCsv: [
        orderIdLast7Digits || '',
        product.sku || '',
        product.name || '',
        product.qty || '',
      ],
    },
    {
      provider: "BK Liquors",
      valuesForCsv: [
        orderIdLast7Digits || '',
        product.name || '',
        product.unit || '',
        product.qty || '',
        orderDate || '',
        DeliveryWindowStart || '',
        DeliveryWindowEnd || '',
      ],
    }
  ]

  const providerTemplate = providersValues.filter((providerValue: any): any => providerValue.provider === product.catalogProvider);

  return providerTemplate[0]?.valuesForCsv;
}

