import React, { useContext } from 'react';
import { View, Text } from '@react-pdf/renderer';

import formatToDollar from 'util/formatToDollar';
import { OrderDetailsContext } from '../../../../contexts/OrderDetailsContext';
import { styles } from './styles';

interface CADValuesProps {
  order: any;
}

interface Product {
  additionalInfo: string;
  name: string;
  price: number;
  productId: string;
  qty: number;
  sku: string;
  unavailable: boolean;
  unit: string;
  upc: string;
}

const CADValues: React.FC<CADValuesProps> = ({ order }) => {
  const { orderCoupon } = useContext(OrderDetailsContext);

  return (
    <View style={styles.section}>
      <View style={styles.spaceBetween}>
        <Text style={styles.boldText}>Description</Text>
        <Text style={styles.boldText}>Amount CAD</Text>
      </View>
      <View style={styles.line} />
      {order.items.map((product: Product) => (
        !product.unavailable &&
        <View key={product.productId}>
          <View style={styles.spaceBetween}>
            <Text style={styles.normalText}>{product.name}</Text>
            <Text style={styles.normalText}>{formatToDollar(product.price * product.qty)}</Text>
          </View>
          <View style={styles.thinLine} />
        </View>
      ))}
      <View style={styles.calculationView}>
        <View style={styles.flexEndView}>
          <Text style={styles.normalText}>Subtotal</Text>
          <Text style={[styles.normalText, styles.paddingLeft]}>{formatToDollar(order.subTotal)}</Text>
        </View>
        <View style={styles.flexEndView}>
          <Text style={styles.normalText}>TOTAL GST 5%</Text>
          <Text style={[styles.normalText, styles.paddingLeft]}>{formatToDollar(order.taxes)}</Text>
        </View>
        <View style={styles.flexEndView}>
          <Text style={styles.normalText}>Delivery Fee</Text>
          <Text style={[styles.normalText, styles.paddingLeft]}>{order.deliveryFee}</Text>
        </View>
        <View style={styles.flexEndView}>
          <Text style={styles.normalText}>Discount</Text>
          <Text style={[styles.normalText, styles.paddingLeft]}>
            {orderCoupon?.minimumOrderValue > order.subTotal ? formatToDollar(order.discount) : formatToDollar(0)}
          </Text>
        </View>
        <View style={styles.shorterLine} />
        <View style={styles.flexEndView}>
          <Text style={styles.boldText}>TOTAL CAD</Text>
          <Text style={[styles.normalText, styles.paddingLeft]}>{formatToDollar(order.total)}</Text>
        </View>
        <View style={styles.flexEndView}>
          <Text style={styles.normalText}>Less Amount Paid</Text>
          <Text style={[styles.normalText, styles.paddingLeft]}>{formatToDollar(order.total)}</Text>
        </View>
        <View style={styles.shorterLine} />
        <View style={styles.flexEndView}>
          <Text style={styles.boldText}>AMOUNT DUE CAD</Text>
          <Text style={[styles.normalText, styles.paddingLeft]}>0.00</Text>
        </View>
      </View>
    </View>
  );
};

export default CADValues;
