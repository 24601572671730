import { RootState } from "store";

function selectZonesFetching(state: RootState) {
  return state.zone.fetching;
}

function selectZones(state: RootState) {
  return state.zone.results;
}

function selectDefaultZone(state: RootState) {
  return state.zone.defaultZoneId;
}

export { selectZonesFetching, selectZones, selectDefaultZone }
