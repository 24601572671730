import React, { useContext, useEffect, useState } from 'react';

import { ClockCircleOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import AddShiftPopover from '../AddShiftPopover';
import Shift from '../Shift';
import styles from './WeeklyTimetable.module.css';
import { DriverShiftsContext } from '../../contexts/DriverShiftsContext';
import { CONCATED_WEEKLY_COLUMNS } from '../../constants';
import { Driver } from '../../interfaces';
import { getCorrespondingDriverShiftDuration, getColumnsWithWeekDays, checkTimeAndAddZero } from '../../utils';

const WeeklyTimeTable: React.FC = () => {
  const [selectedDrivers, setSelectedDrivers] = useState<Driver[]>([]);
  const [weekDaysData, setWeekDaysData] = useState<any[]>([]);

  const { drivers, shifts, selectedWeek, filteredDrivers, loading } = useContext(DriverShiftsContext);

  useEffect(() => {
    if (filteredDrivers.length > 0) {
      setSelectedDrivers(filteredDrivers);
    } else {
      setSelectedDrivers(drivers);
    }
  }, [filteredDrivers, drivers]);

  useEffect(() => {
    const generateWeekDays = getColumnsWithWeekDays(new Date(selectedWeek));
    setWeekDaysData(generateWeekDays);
  }, [selectedWeek]);

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loadingContainer}>
          <Spin size="large" />
        </div>
      ) : (
        <div className={styles.schedule} aria-labelledby="schedule-heading">
          {CONCATED_WEEKLY_COLUMNS.map((item) => (
            <div key={item} className={styles.gridColumn} style={{ gridColumn: `${item}`, gridRow: 'days' }} />
          ))}
          {weekDaysData.map((item: any) => (
            <div
              className={styles.dayContainer}
              style={{ gridColumn: `${item.first} / ${item.second}`, gridRow: 'days' }}
            >
              <div key={item.id} className={styles.day}>
                <p className={styles.dayNumber}>{checkTimeAndAddZero(item.day)}</p>
                <p className={styles.dayName}>{item.title}</p>
              </div>
            </div>
          ))}
          {selectedDrivers.length > 0 &&
            selectedDrivers.map((driver: Driver, driverIndex: number) => (
              <>
                <div
                  key={driver.id}
                  className={styles.driverSlot}
                  style={{ gridColumn: 'drivers', gridRow: driver.rowIndex }}
                >
                  <h2
                    className={driver.kiwiShift ? styles.kiwiShift : styles.driver}
                  >{`${driver.firstName} ${driver.lastName}`}</h2>
                  {!driver.kiwiShift && (
                    <div className={styles.shiftDurationContainer}>
                      <ClockCircleOutlined className={styles.shiftDurationIcon} />
                      <p className={styles.shiftDuration}>
                        {getCorrespondingDriverShiftDuration(driver.id, shifts) || 0}
                      </p>
                    </div>
                  )}
                </div>
                {CONCATED_WEEKLY_COLUMNS.map((item) => (
                  <AddShiftPopover
                    key={item}
                    column={item}
                    driverIndex={driverIndex}
                    driverId={driver.id}
                    drivers={drivers}
                    isKiwiShift={driver.kiwiShift || false}
                  />
                ))}
              </>
            ))}
          {shifts.length > 0 && shifts.map((item) => <Shift key={item.id} drivers={drivers} item={item} />)}
        </div>
      )}
    </div>
  );
};

export default WeeklyTimeTable;
