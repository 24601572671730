import { RootState } from "store";

function selectDriversFetching(state: RootState) {
  return state.driver.fetching;
}

function selectDriversStoreQuery(state: RootState) {
  return state.driver.currentQuery;
}

function selectDriversIsAddModalVisible(state: RootState) {
  return state.driver.isAddModalVisible;
}

function selectDriversIsUpdateModalVisible(state: RootState) {
  return state.driver.isUpdateModalVisible;
}

function selectPaginatedDrivers(state: RootState) {
  return state.driver.paginated;
}

export {
  selectDriversFetching,
  selectDriversStoreQuery,
  selectDriversIsAddModalVisible,
  selectDriversIsUpdateModalVisible,
  selectPaginatedDrivers
}
