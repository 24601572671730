import React, { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router';
import { Table } from 'antd';

import { ORDER_STATUSES } from '../Orders/interfaces';
import { formatDate } from 'util/dateAndTime';
import useCustomers from 'hooks/useCustomers';
import formatToDollar from 'util/formatToDollar';
import OrderStatusSelect from 'ui/components/OrderStatusSelect';
import EditOrderStatusModal from 'ui/components/EditOrderStatusModal';
import styles from './CustomerOrder.module.css';
import { ReactComponent as InfoCircle } from 'img/informationCircle.svg';

const CustomerOrders: React.FC = () => {
  const [orderToBeEditedId, setOrderToBeEditedId] = useState<any>(null);

  const { customerId }: any = useParams();
  const {
    fetchCustomerOrders,
    paginatedCustomerOrders,
    loadingCustomerOrders,
    changeModalVisibility,
    modalVisible,
    updateCustomerOrder,
    newStatus,
    storeNewStatus,
    setIsFetchingCouponOrders
  } = useCustomers();

  const history = useHistory();

  useEffect(() => {
    setIsFetchingCouponOrders(false);
    fetchCustomerOrders({ customerId })
  }, [])

  const ordersColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (orderId: string) => (
        <div className={styles.orderIdContainer}>
          <InfoCircle className={styles.goToDetailsButton} onClick={() => history.push(`/orders/${orderId}`)} />
          <p className={styles.text}>{orderId}</p>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 220,
      render: (currentStatus: string, record: any) => (
        <OrderStatusSelect
          style={{ width: 220 }}
          value={currentStatus}
          allowClear={false}
          onChange={(value: ORDER_STATUSES) => handleOrderStatusChange(value, record)}
        />
      ),
    },
    {
      title: 'Delivery Window',
      dataIndex: 'deliveryWindow',
      key: 'deliveryWindow',
      render: (value: number) => <p className={styles.text}>{formatDate(value)}</p>,
    },
    {
      title: 'City',
      dataIndex: 'city',
    },
    {
      title: 'Address',
      dataIndex: 'address',
    },
    {
      title: 'Subtotal',
      dataIndex: 'subTotal',
      render: (value: number) => <p className={styles.text}>${formatToDollar(value)}</p>
    },
    {
      title: 'Total',
      dataIndex: 'total',
      render: (value: number) => <p className={styles.text}>${formatToDollar(value)}</p>
    },
  ];

  const handleOrderStatusChange = (newStatus: any, order: any) => {
    changeModalVisibility(true)
    storeNewStatus(newStatus);
    setOrderToBeEditedId(order.id);
  }


  const handleSearch = async (page?: number) => {
    const params: any = {};
    params.customerId = customerId;

    if (page) params.page = page;

    fetchCustomerOrders(params);
  };

  return (
    <div className={styles.container}>
      <Table
        loading={loadingCustomerOrders}
        dataSource={paginatedCustomerOrders?.results}
        columns={ordersColumns}
        scroll={{ x: 1270 }}
        pagination={{
          onChange: (page: number) => {
            handleSearch(page);
          },
          total: paginatedCustomerOrders?.totalItems,
          pageSize: 10,
          showSizeChanger: false,
          current: paginatedCustomerOrders?.currentPage,
        }}
      />
      <EditOrderStatusModal
        orderId={orderToBeEditedId}
        changeModalVisibility={changeModalVisibility}
        isModalVisible={modalVisible}
        newStatus={newStatus}
        updateOrder={updateCustomerOrder}
      />
    </div>
  )
}

export default CustomerOrders;
