import React, { useContext, useEffect, useState } from 'react';

import { Modal, notification } from 'antd';
import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons';

import styles from './EditStockModal.module.css';
import { MODAL_TYPES } from '../../interfaces';
import useInventory from 'hooks/useInventory';
import { addStock } from 'services/Levels.service';

interface EditStockModalProps {
  productName: string;
  productId: string;
  locationId: string;
  availableStock: number;
  totalStock: number;
  handleSearch: () => void;
}

const EditStockModal: React.FC<EditStockModalProps> = ({
  productName,
  productId,
  locationId,
  availableStock,
  totalStock,
  handleSearch,
}) => {
  const [numberOfUnits, setNumberOfUnits] = useState<string>('0');
  const [disableSaveChanges, setDisableSaveChanges] = useState<boolean>(false);

  const { toggleModalVisibility, editStockModalVisible } = useInventory();

  useEffect(() => {
    const numberOfUnitsValue = Number(numberOfUnits);
    const availableStockNegative = availableStock * -1;
    if (numberOfUnitsValue < 0 && numberOfUnitsValue < availableStockNegative) setDisableSaveChanges(true);
    else setDisableSaveChanges(false);
  }, [numberOfUnits, availableStock]);

  const handleCancel = () => {
    toggleModalVisibility(MODAL_TYPES.editStock);
    setNumberOfUnits('0');
  };

  const handleAddStock = async () => {
    const body: any = {};

    body.locationId = locationId;
    body.productId = productId;
    body.availableStock = availableStock + Number(numberOfUnits);
    body.totalStock = totalStock + Number(numberOfUnits);

    try {
      const response = await addStock(body);
      if (response.error) throw new Error(response.message);
      else {
        notification.success({
          message: 'Stock Updated!',
          description: '',
        });
        handleSearch();
      }
    } catch (err) {
      const error: any = err;
      notification.error({
        message: 'Error updating stock!',
        description: error.message,
      });
    } finally {
      toggleModalVisibility(MODAL_TYPES.editStock);
      setNumberOfUnits('0');
    }
  };

  return (
    <Modal
      okText="Save Changes"
      okButtonProps={{ disabled: disableSaveChanges }}
      title=""
      visible={editStockModalVisible}
      onCancel={handleCancel}
      onOk={handleAddStock}
    >
      <h1 className={styles.title}>Editing Total Stock</h1>
      <p className={styles.subtitle}>{productName}</p>
      <div className={styles.editButtonsContainer}>
        <MinusCircleFilled
          className={styles.icon}
          onClick={() => {
            const newValue = Number(numberOfUnits) - 1;
            setNumberOfUnits(newValue.toString());
          }}
        />
        <input
          type="number"
          className={styles.numberOfUnits}
          onChange={(e) => {
            const newValue = Number(e.target.value);
            setNumberOfUnits(newValue.toString());
          }}
          value={numberOfUnits}
        />
        <PlusCircleFilled
          className={styles.icon}
          onClick={() => {
            const newValue = Number(numberOfUnits) + 1;
            setNumberOfUnits(newValue.toString());
          }}
        />
      </div>
      {disableSaveChanges && <p className={styles.warning}>The available stock can´t be negative!</p>}
      <br />
      <strong>*This quantity will be added to the pre existing stock</strong>
    </Modal>
  );
};

export default EditStockModal;
