import '@patternfly/react-core/dist/styles/base.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css';
import { unregister } from './registerServiceWorker';
import App from './ui/App';

const IndexMain = () => <App />;

ReactDOM.render(<IndexMain />, document.getElementById('root') as HTMLElement);

unregister();
