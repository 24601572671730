import { unixDateToTimeZone } from 'util/dateAndTime';

export const formatOrderWindows = (windowOrders: any, window: any) => {
  const ordersFormatted = windowOrders.map((order: any) => {

    return {
      windowId: order.deliveryWindowId,
      window: `${unixDateToTimeZone(window.startTime).format('HH:mm')}-${unixDateToTimeZone(window.endTime).format('HH:mm')}`,
      status: order.status,
    }
  }).filter((order: any) => {
    return order.windowId === window.id
  }).reduce((unique: any, o: any) => {
    if (!unique.some((obj: any) => obj.status === o.status)) {
      unique.push(o);
    }
    return unique;
  }, [])

  const setStatusQuantity = ordersFormatted.map((order: any) => {
    return {
      ...order,
      value: windowOrders.filter((item: any) => order.status === item.status && order.windowId === item.deliveryWindowId).length * 20,
      quantity: windowOrders.filter((item: any) => order.status === item.status && order.windowId === item.deliveryWindowId).length
    }
  })

  setStatusQuantity.unshift({
    windowId: window.id,
    window: `${unixDateToTimeZone(window.startTime).format('HH:mm')}-${unixDateToTimeZone(window.endTime).format('HH:mm')}`,
    value: window.totalCapacity - window.filledCapacity,
    quantity: 0,
    status: "TOTAL_CAPACITY"
  })

  return setStatusQuantity;
}
