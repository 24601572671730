import {
  createAction,
  createAsyncThunk,
  createReducer,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { notification } from 'antd';
import { getCustomers, getCustomerOrders, updateCustomerOrderStatus, getCustomerCoupon } from "services/Customer.service";
import { RootState } from "store";
import { Order, ORDER_STATUSES } from "ui/pages/Orders/interfaces";
import { RESPONSE_STATUSES } from "util/request";

export interface Customer {
  id: string,
  identityId: string,
  deviceId: string,
  cognitoId: string,
  name: string,
  email: string,
  providerName: string,
  birthday: number,
  phoneNumber: number,
  referralCouponName: string,
  addresses: any[],
  interests: any[],
  credentials: any,
}

interface CustomerPaginated {
  totalItems: number;
  currentPage: number;
  perPage: number;
  totalPages: number;
  results: Customer[],
}

interface OrderPaginated {
  totalItems: number;
  currentPage: number;
  perPage: number;
  totalPages: number;
  results: Order[],
}

interface Coupon {
  id: string,
  name: string,
  description: string,
  type: string,
  discountValue: number,
  status: string,
  expirationDate: number,
  minimumOrderValue: number,
  usageCount: number,
  maxAllowedUsages: number,
  calculatedDiscountValue: number,
}

interface CustomerSliceState {
  currentQuery: string,
  paginated?: CustomerPaginated;
  paginatedOrders?: OrderPaginated;
  couponOrders?: OrderPaginated;
  selectedCustomer: Customer;
  fetchingCustomers: boolean;
  fetchingOrders: boolean;
  customerCoupon: Coupon,
  isModalVisible: boolean;
  newStatus: string;
  fetchingCoupon: boolean,
  isFetchingCouponOrders: boolean;
}

const initialState: CustomerSliceState = {
  isModalVisible: false,
  fetchingCustomers: false,
  fetchingOrders: false,
  fetchingCoupon: false,
  isFetchingCouponOrders: false,
  customerCoupon: {} as Coupon,
  newStatus: '',
  currentQuery: '',
  selectedCustomer: {} as Customer,
  couponOrders: {} as OrderPaginated,
  paginatedOrders: {} as OrderPaginated,
  paginated: {
    totalItems: 0,
    currentPage: 0,
    perPage: 0,
    totalPages: 1,
    results: [],
  },
};

export const fetchCustomers = createAsyncThunk(
  "customer/fetchCustomers",
  async function (params?: any) {
    try {
      const response = await getCustomers(params);
      if (response.error || response.status == "error") {
        throw new Error(response.message);
      } else {

        return response;
      }
    } catch (err) {
      const error: any = err;
      notification['error']({
        message: 'Error fetching customers!',
        description: error.message,
      });
    }
  }
);

export const fetchCustomerOrders = createAsyncThunk(
  "customer/fetchCustomerOrders",
  async function (params: any,) {
    try {
      const response = await getCustomerOrders(params);
      if (response.error || response.status === 'error') {
        throw new Error(response.message);
      }
      else {
        const formattedResponse = response.results?.map((order: any) => {
          const {
            id,
            status,
            estimatedCollectTime,
            destination,
          } = order;
          return {
            ...order,
            orderID: id || '',
            status: status || '',
            deliveryWindow: estimatedCollectTime,
            city: 'Calgary',
            address: destination?.address || '-',
          };
        });
        response.results = formattedResponse;
        return response;
      }
    } catch (err) {
      const error: any = err;
      notification.error({
        message: 'Error fetching orders!',
        description: error.message,
      });
      return error.message
    }
  }
);

export const fetchCustomerCoupon = createAsyncThunk(
  "customer/fetchCoupon",
  async function (couponName: string) {
    try {
      const response = await getCustomerCoupon(couponName);
      if (response.error || response.status === 'error') {
        throw new Error(response.message);
      }
      else {
        return response;
      }
    } catch (err) {
      const error: any = err;
      notification.error({
        message: 'Error fetching coupon!',
        description: error.message,
      });
      return error.message
    }
  }
);

export const updateCustomerOrder = createAsyncThunk(
  "order/updateOrder",
  async function (params: string, { getState, dispatch }) {
    const {
      customer: { newStatus, isModalVisible, currentQuery },
    } = getState() as RootState;

    try {
      const response = await updateCustomerOrderStatus(params);

      if (response.error || response.status === 'error') {
        if (newStatus === ORDER_STATUSES.PREPARED && response.statusCode === RESPONSE_STATUSES.PAYMENT_REQUIRED)
          throw new Error('Could not process payment - Card Declined');
        else throw new Error(response.message);
      } else {
        dispatch(changeModalVisibility(!isModalVisible))
        dispatch(fetchCustomerOrders(currentQuery));
        notification.success({
          message: 'Success editing order status!',
          description: `Status changed to ${newStatus}`,
        });
      }
    } catch (err) {
      const error: any = err;
      notification.error({
        message: 'Error editing order status!',
        description: error.message,
      });
    }
  }
);

export const changeModalVisibility = createAction('order/changeModalVisibility', (payload: boolean) => ({ payload }));
export const storeNewStatus = createAction('order/storeNewStatus', (payload: string) => ({ payload }));
export const storeQuery = createAction('order/storeQuery', (payload: string) => ({ payload }));
export const storeSelectedCustomer = createAction('customer/storeSelectedCustomer', (payload: Customer) => ({ payload }));
export const isFetchingCouponOrders = createAction('customer/isFetchingCouponOrders', (payload: boolean) => ({ payload }));

export const customerReducer = createReducer(initialState, (builder) => {

  const successFetchingCustomers = isFulfilled(fetchCustomers);
  const errorFetchingCustomers = isRejected(fetchCustomers);
  const loadingCustomers = isPending(fetchCustomers);

  const successFetchingOrders = isFulfilled(fetchCustomerOrders);
  const errorFetchingOrders = isRejected(fetchCustomerOrders);
  const loadingOrders = isPending(fetchCustomerOrders);

  const successFetchingCoupon = isFulfilled(fetchCustomerCoupon);
  const errorFetchingCoupon = isRejected(fetchCustomerCoupon);
  const loadingCoupon = isPending(fetchCustomerCoupon);

  builder
    .addCase(isFetchingCouponOrders, (state, action) => {
      state.isFetchingCouponOrders = action.payload;
    })
    .addCase(changeModalVisibility, (state, action) => {
      state.isModalVisible = action.payload;
    })
    .addCase(storeNewStatus, (state, action) => {
      state.newStatus = action.payload;
    })
    .addCase(storeQuery, (state, action) => {
      state.currentQuery = action.payload;
    })
    .addCase(storeSelectedCustomer, (state, action) => {
      state.selectedCustomer = action.payload;
    })
    .addCase(fetchCustomers.fulfilled, (state, action) => {
      state.paginated = action.payload;
    })
    .addCase(fetchCustomerOrders.fulfilled, (state, action) => {
      if (state.isFetchingCouponOrders) state.couponOrders = action.payload;
      else state.paginatedOrders = action.payload;
    })
    .addMatcher(loadingCustomers, (state) => {
      state.fetchingCustomers = true;
    })
    .addMatcher(successFetchingCustomers, (state) => {
      state.fetchingCustomers = false;
    })
    .addMatcher(errorFetchingCustomers, (state) => {
      state.fetchingCustomers = false;
    })
    .addMatcher(loadingOrders, (state) => {
      state.fetchingOrders = true;
    })
    .addMatcher(successFetchingOrders, (state) => {
      state.fetchingOrders = false;
    })
    .addMatcher(errorFetchingOrders, (state) => {
      state.fetchingOrders = false;
    })
    .addMatcher(loadingCoupon, (state) => {
      state.fetchingCoupon = true;
    })
    .addMatcher(successFetchingCoupon, (state, action) => {
      state.fetchingCoupon = false;
      state.customerCoupon = action.payload.results[0] || '';
    })
    .addMatcher(errorFetchingCoupon, (state) => {
      state.fetchingCoupon = false;
    });
});
