import React, { useState, ReactNode, createContext, useEffect } from 'react';

import moment from 'moment';
import { useLocation } from 'react-router-dom';

import { ORDER_STATUSES } from 'ui/pages/Orders/interfaces';
import { DEFAULT_TIMEZONE } from 'util/dateAndTime';

interface OrdersPageGlobalFiltersContextData {
  statuses: ORDER_STATUSES[];
  setStatuses: (value: ORDER_STATUSES[]) => void;
  intervalDates: any;
  setIntervalDates: (dates: any) => void;
}
interface OrdersPageGlobalFiltersProviderProps {
  children: ReactNode;
}

export const OrdersPageGlobalFiltersContext = createContext({} as OrdersPageGlobalFiltersContextData);

export const defaultStatuses = [
  ORDER_STATUSES.CREATED,
  ORDER_STATUSES.IN_PICKING,
  ORDER_STATUSES.PREPARED,
  ORDER_STATUSES.COLLECTED,
];

const defaultIntervalStartTime = moment().tz(DEFAULT_TIMEZONE).hour(5).minutes(0);
const defaultIntervalEndTime = moment().tz(DEFAULT_TIMEZONE).hour(23).minutes(30);

const OrdersPageGlobalFiltersProvider = ({ children }: OrdersPageGlobalFiltersProviderProps) => {
  const [statuses, setStatuses] = useState<ORDER_STATUSES[]>(defaultStatuses);
  const [intervalDates, setIntervalDates] = useState<any>([defaultIntervalStartTime, defaultIntervalEndTime]);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/" && !location.pathname.includes("/orders")){
      setStatuses(defaultStatuses);
      setIntervalDates([defaultIntervalStartTime, defaultIntervalEndTime]);
    }
  }, [location])

  return (
    <OrdersPageGlobalFiltersContext.Provider
      value={{
        statuses,
        setStatuses,
        intervalDates,
        setIntervalDates
      }}
    >
      {children}
    </OrdersPageGlobalFiltersContext.Provider>
  );
};

export default OrdersPageGlobalFiltersProvider;
