import React, { memo } from 'react';

import { Select } from 'antd';

const { Option } = Select;

interface DriverStatusSelectProps {
  onChange?: any;
  value?: any;
  disabled?: boolean;
}

function DriverStatusSelect({ onChange, value, disabled }: DriverStatusSelectProps) {
  return (
    <Select
      showSearch
      optionFilterProp="children"
      onChange={onChange}
      filterOption={(input, option: any | null) =>
        option.props.children
          .toString()
          .toLowerCase()
          .indexOf(input.toString().toLowerCase()) >= 0
      }
      value={value}
      disabled={disabled}
    >
      <Option value="ACTIVE">ACTIVE</Option>
      <Option value="INACTIVE">INACTIVE</Option>
    </Select>
  );
}

export default memo(DriverStatusSelect);
