import { RootState } from "store";

function selectDeliveryWindowsFetching(state: RootState) {
  return state.deliveryWindow.fetching;
}

function selectOrdersFetching(state: RootState) {
  return state.deliveryWindow.fetchingOrders;
}

function selectDeliveryWindows(state: RootState) {
  return state.deliveryWindow.results;
}

function selectAddWindowModalVisible(state: RootState) {
  return state.deliveryWindow.isAddWindowModalVisible;
}

function selectEditWindowModalVisible(state: RootState) {
  return state.deliveryWindow.isEditWindowModalVisible;
}

function selectWindowVisualizationModalVisible(state: RootState) {
  return state.deliveryWindow.isWindowsVisualizationModalVisible;
}

function selectWindowOrders(state: RootState) {
  return state.deliveryWindow.windowOrders
}

function selectDate(state: RootState) {
  return state.deliveryWindow.selectedDate
}

function selectZone(state: RootState) {
  return state.deliveryWindow.selectedZone
}


export {
  selectAddWindowModalVisible,
  selectDeliveryWindows,
  selectDeliveryWindowsFetching,
  selectEditWindowModalVisible,
  selectWindowVisualizationModalVisible,
  selectOrdersFetching,
  selectWindowOrders,
  selectDate,
  selectZone
}
