import React, { useEffect, useState } from 'react';

import { Button, Col, Form, Input, Modal, notification, Row, Upload } from 'antd';
import useCampaigns from 'hooks/useCampaigns';
import Title from 'antd/lib/typography/Title';

const AddNotificationModal: React.FC = () => {

  const [csvFile, setCsvFile] = useState<any>();
  const { changeModalVisibility, isModalVisible, createCampaign } = useCampaigns();
  const [form] = Form.useForm();

  const handleOkAddModal = () => {
    form
      .validateFields()
      .then(async (values) => {

        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('title', values.title);
        formData.append('message', values.message);
        formData.append('file', csvFile);

        createCampaign(formData);
        changeModalVisibility(false);
      })
      .catch((error) => {
        notification['error']({
          message: 'Error adding a notification!',
          description: error.message,
        });
      }).finally(() => form.resetFields());
  };

  const handleCancelAddModal = () => {
    form.resetFields();
    changeModalVisibility(false)
  };

  return (
    <Modal
    okText="Create Campaign"
    visible={isModalVisible}
    onCancel={handleCancelAddModal}
    onOk={handleOkAddModal}
  >
    <Title level={3}>Add Notification</Title>
    <Form form={form} layout={'vertical'} autoComplete={'off'} scrollToFirstError={true}>
        <Row>
          <Col lg={24} md={24} xs={24}>
            <Form.Item
              label="Campaign"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input the campaign name!',
                },
              ]}
            >
              <Input placeholder="Campaign"/>
            </Form.Item>
          </Col>
          <Col lg={24} md={24} xs={24}>
            <Form.Item
              label="Notification Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Please input the notification title!',
                },
              ]}
            >
              <Input placeholder="Notification Title"/>
            </Form.Item>
          </Col>
          <Col lg={24} md={24} xs={24}>
            <Form.Item
              label="Notification Body"
              name="message"
              rules={[
                {
                  required: true,
                  message: 'Please input the notification message!',
                },
              ]}
            >
              <Input placeholder="Notification Body"/>
            </Form.Item>
          </Col>
          <Upload
            accept=".csv"
            showUploadList
            beforeUpload={file => {
                setCsvFile(file);
                // Prevent upload
                return false;
            }}
          >
            <Button>Clients Device Tokens CSV</Button>
          </Upload>
        </Row>
      </Form>
  </Modal>
  )
}

export default AddNotificationModal;
