import React, { memo, useContext, useEffect } from 'react';

import { Select } from 'antd';

import styles from './MultipleStatusSelect.module.css';
import { OrdersPageGlobalFiltersContext } from 'contexts/OrdersPageGlobalFiltersContext';
import { FILTER_STATUSES_ARRAY } from 'constants/orderStatuses';

const { Option } = Select;

const MultipleStatusSelect: React.FC = () => {
  const { statuses, setStatuses } = useContext(OrdersPageGlobalFiltersContext);

  return (
    <Select
      allowClear
      mode="multiple"
      className={styles.select}
      optionFilterProp="children"
      placeholder="Status"
      onChange={(values: any) => setStatuses(values)}
      filterOption={(input, option: any | undefined) =>
        option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
      }
      value={statuses}
    >
      {FILTER_STATUSES_ARRAY.map((orderStatus: string) => (
        <Option key={orderStatus} value={orderStatus}>{orderStatus}</Option>
      ))}
    </Select>
  );
};

export default memo(MultipleStatusSelect);
