/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Tabs, Table, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import PlusIcon from '../../../../../img/plusIcon.png';
import styles from './WindowsModal.module.css';
import { MODAL_TYPES } from '../../interfaces';
import AddModal from '../AddModal/AddModal';
import EditModal from '../EditModal/EditModal';
import useDeliveryWindows from 'hooks/useDeliveryWindows';
import { DeliveryWindowResponse } from 'store/DeliveryWindow.slice';

interface EditWindowModalProps{
  currentDateWindows: DeliveryWindowResponse
}

const EditWindowModal: React.FC<EditWindowModalProps> = ({ currentDateWindows }) => {

  const {
    changeModalVisibility,
    isWindowsVisualizationModalVisible,
    deleteDeliveryWindow,
    loading
  } = useDeliveryWindows();

  const [record, setRecord] = useState<any>();
  const [data, setData] = useState<any>();

  const { TabPane } = Tabs;

  const columns = useMemo(
    () => [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: 'Start Hour',
        dataIndex: 'windowStart',
        key: 'windowStart',
      },
      {
        title: 'End Hour',
        dataIndex: 'windowEnd',
        key: 'windowEnd',
      },
      {
        title: 'Capacity',
        dataIndex: 'totalCapacity',
        key: 'totalCapacity',
      },
      {
        title: 'Edit',
        dataIndex: 'edit',
        render: (_: any, record: any) => (
          <EditOutlined className={styles.editIcon} onClick={() => handleEditClickedWindow(record)} />
        ),
      },
      {
        title: 'Delete',
        dataIndex: 'delete',
        render: (_: any, record: any) =>
          record.filledCapacity > 0 ? (
            <DeleteOutlined className={styles.grayDeleteIcon} />
          ) : (
            <Popconfirm title="Sure to delete this window ?" onConfirm={() => handleDeleteClickedWindow(record)}>
              <DeleteOutlined className={styles.deleteIcon} />
            </Popconfirm>
          ),
      },
    ],
    [],
  );

  const handleAddNewWindow = () => {
    changeModalVisibility(MODAL_TYPES.addDeliveryWindows);
  };

  const handleEditClickedWindow = (record: any) => {
    setRecord(record);
    changeModalVisibility(MODAL_TYPES.editDeliveryWindows);
  };

  const handleDeleteClickedWindow = (window: any) => {
    const { id } = window;
    deleteDeliveryWindow(id);
  };

  useEffect(() => {
    if (currentDateWindows) setData(currentDateWindows.windows);
    else setData([]);
  }, [currentDateWindows]);

  return (
    <>
      <Modal
        width={800}
        visible={isWindowsVisualizationModalVisible}
        onCancel={() => changeModalVisibility(MODAL_TYPES.showDeliveryWindows)}
        onOk={() => changeModalVisibility(MODAL_TYPES.showDeliveryWindows)}
        cancelText="Cancel"
        okText="Ok"
      >
        <Tabs tabBarGutter={500} defaultActiveKey="1">
          <TabPane forceRender tab="Edit current day" key="1">
            <Table
              loading={loading}
              rowKey={(record: any) => record.id}
              pagination={false}
              columns={columns}
              dataSource={data}
              scroll={{ y: 240 }}
            />

            <div className={styles.addNewWindowContainer}>
              <div className={styles.addNewWindowCard} onClick={handleAddNewWindow}>
                <img className={styles.plusIcon} width="22px" height="22px" src={PlusIcon} alt="AddIcon" />
                <span className={styles.addNewWindowText}>Add new window</span>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </Modal>
      <AddModal />
      <EditModal record={record} />
    </>
  );
};

export default EditWindowModal;
