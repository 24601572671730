import React from 'react';

import styles from './Steps.module.css';
interface StepsProps{
  steps: any[],
  currentStep: number
}

const Steps: React.FC<StepsProps> = ({steps, currentStep}) => {
  return (
    <div className={styles.rowContainer}>
      {steps.map((item, index) => (
        <div key={index} className={styles.rowContainer}>
          <div className={index <= currentStep ? styles.stepCircleFilled : styles.stepCircle}/>
          {index !== steps.length - 1 && (
            <div className={styles.trace}/>
          )}
        </div>
      ))}
    </div>
  )
}

export default Steps;
