import React, { useContext } from 'react';

import { Modal, notification } from 'antd';
import Title from 'antd/lib/typography/Title';
import useInventory from 'hooks/useInventory';
import { editProduct } from 'services/Products.service';
import { MODAL_TYPES } from 'ui/pages/Inventory/interfaces';

interface ConfirmationSwitchModalProps {
  record: any;
  handleSearch: () => void;
}

const DiscontinuedItemModal: React.FC<ConfirmationSwitchModalProps> = ({ record, handleSearch }) => {

  const { toggleModalVisibility, discontinuedModalVisible } = useInventory();

  const handleConfirmChange = async () => {
    const body: any = {};
    body.isDiscontinued = !record.isDiscontinued;

    try {
      const response = await editProduct(record.id, body);

      if (response.error || response.status == "error") {
        throw new Error(response.message);
      } else {
        toggleModalVisibility(MODAL_TYPES.discontinued);
        handleSearch();
        notification['success']({
          message: 'Success!',
          description: 'The product continuity was updated.',
        });
      }
    } catch (err) {
      const error: any = err;
      notification['error']({
        message: 'Error editing product!',
        description: error.message,
      });
    }
  }

  return (
    <Modal
      title=""
      visible={discontinuedModalVisible}
      onCancel={() => toggleModalVisibility(MODAL_TYPES.discontinued)}
      onOk={handleConfirmChange}
      okText={`Yes, confirm as ${record?.isDiscontinued ? "continued" : "discontinued"}`}
      cancelText="Cancel"
    >
      <Title level={4}>Discontinued Item</Title>
      <Title level={5}>{`You are making ${record?.name} as ${record?.isDiscontinued ? "Continued" : "Discontinued"}`}</Title>
    </Modal>
  )
}

export default DiscontinuedItemModal;
