import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from 'store';
import GlobalStyle from '../styles/global';
import AppRoutes from '../routes';
import AppProvider from 'contexts';
import './App.css';

const App = (): any => (
  <BrowserRouter>
    <Provider store={store}>
      <AppProvider>
        <AppRoutes />
      </AppProvider>
    </Provider>
    <GlobalStyle />
  </BrowserRouter>
);

export default App;
