import moment from 'moment-timezone';
import { DriverShift } from './interfaces';

export const formatDatesAndSlotsResponse = (response: any[] = []) => {
  const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
  const today = new Date();

  const slotsByDate = response.map((item: any) => {
    let date = new Date(item.date); // returns one day off date
    date = new Date(date.getTime() + date.getTimezoneOffset() * 60000); // logic to return the correct date
    const weekDay = days[date.getDay()];
    const day = date.getDate();

    const dateInfo = {
      slots: item.windows, // in the response the slots variable come by the name "windows" variable
      date: item.date,
      day,
      weekDay,
      isToday: today.toDateString() === date.toDateString(),
    };

    return dateInfo;
  });

  return slotsByDate;
};

export const formatDriverShifts = (response: any, timezone?: string) => {
  const formattedDriverShifts = response.map((item: DriverShift) => {
    const TIMEZONE: any = timezone;

    return {
      ...item,
      driverFullName: `${item.driver?.firstName || 'No'} ${item.driver?.lastName || 'Driver'}`,
      driverPhone: item.driver?.phoneNumber || 'No phone number found',
      formattedStartTime: moment(item.estimatedStartTime).tz(TIMEZONE).format('DD/MM/YYYY'),
      shiftStartAndEndTime: formatStartAndEndTime(item.estimatedStartTime, item.estimatedEndTime, timezone),
    };
  });

  return formattedDriverShifts;
};

export const formatStartAndEndTime = (unixStartTime: number, unixEndTime: number, timezone?: string) => {
  const TIMEZONE: any = timezone;

  const isoStartTime = moment(unixStartTime).tz(TIMEZONE).format();
  const isoEndTime = moment(unixEndTime).tz(TIMEZONE).format();

  const startDate = getDateAndTimeString(isoStartTime);
  const endDate = getDateAndTimeString(isoEndTime);

  const startTimeLocalISOString = moment(startDate).format('YYYY-MM-DDTHH:mm:ss');
  const endTimeLocalISOString = moment(endDate).format('YYYY-MM-DDTHH:mm:ss');

  const startTime = startTimeLocalISOString.match(/\d\d:\d\d/);
  const endTime = endTimeLocalISOString.match(/\d\d:\d\d/);

  return `${startTime} - ${endTime}`;
};

export const formatLegsResponse = (legs: any) => {
  const formattedLegs = legs?.length > 0 ? legs.map((item: any) => ({ ...item })) : [];

  return formattedLegs;
};

export const formatDeliveryWindowsIds = (windowIds: any): string => {
  const windowsArray: any[] = [];
  Object.values(windowIds).forEach((dateArray: string[]) => {
    dateArray.forEach((id: string) => windowsArray.push(`deliveryWindowId=${id}`));
  });
  return windowsArray.join('&');
};

export function getDateAndTimeString(localStartTimeISO: string, fetchingShifts?: boolean) {
  const datesAndHours = localStartTimeISO?.split('T'); // Example of iso string: 2021-07-20T13:30:15.219267Z
  const dates = datesAndHours[0];
  const hoursForFetching = datesAndHours[1];
  let hours = '';

  if (fetchingShifts) hours = hoursForFetching;
  // keeps the Z in ISO String, cause the dates are in UTC (Z = zero-timezone offset)
  else hours = datesAndHours[1]?.split('.')[0]?.substr(0, 5);
  // contains timezone offset (removes the "Z" from the string)

  const string = `${dates} ${hours}`;

  return string;
}
