import React, { useContext, useEffect } from 'react';

import { DriverShiftsContext } from '../../contexts/DriverShiftsContext';
import { ViewType } from '../../interfaces';
import DateAndFilters from '../DateAndFilters';
import DailyTimeTable from '../DailyTimeTable';
import WeeklyTimetable from '../WeeklyTimetable';
import styles from './DriverShiftsPage.module.css';

const DriverShiftsPage: React.FC = () => {
  const { viewType, fetchDrivers, fetchLocations } = useContext(DriverShiftsContext);

  useEffect(() => {
    fetchDrivers();
    fetchLocations();
  }, []);

  return (
    <div className={styles.mainContainer}>
      <DateAndFilters />
      <div className={styles.gridContainer}>{viewType === ViewType.WEEKLY ? <WeeklyTimetable /> : <DailyTimeTable />}</div>
    </div>
  );
};

export default DriverShiftsPage;
