/* eslint-disable no-nested-ternary */
import React, { useContext, useState } from 'react';
import { InputNumber, Row, Col, Modal, Form, notification } from 'antd';
import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import _ from 'lodash';
import ProductsSelect from 'ui/components/ProductsSelect';
import { getProductsByIds } from '../../services/api';
import styles from './AddProductModal.module.css';
import { MODAL_TYPES } from '../../interfaces';
import { OrderDetailsContext } from '../../contexts/OrderDetailsContext';

function AddProductModal() {
  const [form] = Form.useForm();

  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [quantity, setQuantity] = useState<number>(1);

  const { order, toggleModalVisibility, addProductModalVisible, orderHasChanges } = useContext(
    OrderDetailsContext,
  );

  const transformBigDecimal = (value: any) => {
    const with2Decimals = Number(value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
    return with2Decimals;
  };

  const handleOkAddModal = () => {
    const { items, discount, tip } = order;
    const { id, sku, price, unitOfMeasurement, name, additionalInfo, upc } = selectedProduct;

    const productAlreadyExists = items.some((product: any) => product.productId === id);
    if (!productAlreadyExists) {
      form.validateFields().then(async (value) => {
        const newProduct = {
          productId: value.product,
          sku,
          price,
          unit: unitOfMeasurement,
          qty: quantity,
          name,
          additionalInfo,
          upc,
          unavailable: false,
        };
        let clonedOrderItems = [...order.items];

        const existingProducts = [...items, newProduct];
        clonedOrderItems = existingProducts;

        const availableProducts = clonedOrderItems.filter((product) => !product.unavailable);
        const hasAvailableProducts = availableProducts.length > 0;

        if (hasAvailableProducts) {
          const productsIds = availableProducts.map((product: any) => {
            return product.productId;
          });

          const productsListWithTaxes = await getProductsByIds({
            ids: productsIds,
          });

          const availableProductsWithTax = _.map(productsListWithTaxes, (item) => {
            return _.extend(item, _.find(availableProducts, { productId: item.id }));
          });

          const Newtaxes = transformBigDecimal(
            availableProductsWithTax.reduce((acc: any, curr: any) => {
              return acc + curr.qty * curr.tax;
            }, 0),
          );

          const newSubTotal = transformBigDecimal(
            availableProductsWithTax.reduce((acc: any, curr: any) => {
              return acc + curr.qty * curr.price;
            }, 0),
          );

          const totalMinusTaxes = transformBigDecimal(newSubTotal / 100 - Newtaxes / 100 - discount + tip / 100); // - newDiscount
          const newTotal = String(totalMinusTaxes).replace('.', '');

          order.taxes = Newtaxes;
          order.discount = Number(discount);
          order.subTotal = newSubTotal;
          order.total = Number(newTotal);
          order.items = clonedOrderItems;
          orderHasChanges(true);
          form.resetFields();
          setQuantity(1);
          toggleModalVisibility(MODAL_TYPES.addProduct);
        }
      });
    } else {
      notification.error({
        message: 'Error adding product!',
        description: 'This product already exists in this order.',
      });
    }
  };

  const handleCancelAddModal = () => {
    form.resetFields();
    setQuantity(1);
    toggleModalVisibility(MODAL_TYPES.addProduct);
  };

  return (
    <Modal
      title="Add New Product"
      visible={addProductModalVisible}
      onOk={handleOkAddModal}
      onCancel={handleCancelAddModal}
    >
      <Form form={form}>
        <Row className={styles.inputsRow}>
          <Col lg={24} md={24} xs={24}>
            <strong className={styles.title}>Product</strong>
            <Form.Item
              name="product"
              rules={[
                {
                  required: true,
                  message: 'Please select a product!',
                },
              ]}
            >
              <ProductsSelect
                onChange={(_: any, selected: any) => {
                  setSelectedProduct(selected.product);
                }}
              />
            </Form.Item>
          </Col>
          <Col lg={24} md={24} xs={24}>
            <strong className={styles.title}>Quantity</strong>
            <div className={styles.addQuantityContainer}>
              <MinusCircleFilled
                className={styles.icon}
                onClick={() => {
                  if (quantity >= 2) setQuantity(quantity - 1);
                }}
              />
              <InputNumber
                min={1}
                precision={0}
                type="number"
                className={styles.numberOfUnits}
                onChange={(e) => setQuantity(e)}
                value={quantity}
              />
              <PlusCircleFilled className={styles.icon} onClick={() => setQuantity(quantity + 1)} />
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default AddProductModal;
