/* eslint-disable no-shadow */
export interface Order {
  orderId: string;
  transactionId: string;
  storeId: string;
  createdTime: string;
  closedTime: string | null;
  numberOfItems: number;
  total: number;
  customerDeliveryFee: number;
  storeDeliveryFee: number;
  subsidizedDeliveryFee: number;
  size: string;
  weight: number;
  description: string;
  deliveryInstructions: string;
  orderStatus: string;
  shopType: string | null;
  deliverySlotId: string;
  customerDetails: {
    email: string;
    firstName: string;
    lastName: string;
    company: string;
    address: string;
    unitNumber: string;
    city: string;
    province: string;
    country: string;
    postalCode: string;
    phoneNumber: string;
    buzzCode: string | null;
    leaveAtMyDoor: string | null;
  };
  vendorDetails: {
    storeName: string;
    address: string;
    postalCode: string;
  };
  paymentStatus: string;
  paymentIntentId: string;
  instructions: [
    {
      id: string;
      orderId: string;
      packageNumber: number;
      option: string;
    },
  ];
  deliverySlot: {
    date: string;
    startTime: string;
    endTime: string;
  };
}

export interface Driver {
  active: boolean;
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  latitude: number;
  longitude: number;
  phoneNumber: string;
  provinceState: string;
}

export enum FilterTypes {
  CUSTOMER_NAME = 'name',
  CUSTOMER_PHONE = 'phone',
  CUSTOMER_EMAIL = 'email',
  ORDER_ID = 'id',
  WINDOW_ID = 'deliveryWindowId'
}

export enum ORDER_STATUSES {
  'CREATED' = 'CREATED',
  'IN_PICKING' = 'IN_PICKING',
  'PREPARED' = 'PREPARED',
  'COLLECTED' = 'COLLECTED',
  'DELIVERED' = 'DELIVERED',
  'CANCELLED_BY_CUSTOMER' = 'CANCELLED_BY_CUSTOMER',
  'CANCELLED_BY_KIWIME' = 'CANCELLED_BY_KIWIME',
}
