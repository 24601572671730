import React from 'react';

import { Modal, Form, InputNumber } from 'antd';
import moment from 'moment';

import { createUnixUtcDateTime } from 'util/dateAndTime';
import { MODAL_TYPES } from '../../interfaces';
import WindowsIntervalFormatItem from '../WindowsInvervalFormItem';
import ZoneSelect from 'ui/components/ZoneSelect';
import useDeliveryWindows from 'hooks/useDeliveryWindows';
import { DeliveryWindowResponse } from 'store/DeliveryWindow.slice';

const AddModal = () => {
  const [form] = Form.useForm();

  const {
    changeModalVisibility,
    isAddWindowModalVisible,
    selectedDate,
    addDeliveryWindow,
    changeZone
  } = useDeliveryWindows();

  const dateString = moment(selectedDate).format('MM/DD/YYYY');

  const handleConfirmUpdate = () => {
    form
      .validateFields()
      .then(({ windowsInterval, zoneId, totalCapacity }) => {
        const [startTime, endTime] = createUnixUtcDateTime(dateString, windowsInterval);

        const query = { startTime, endTime, zoneId, totalCapacity }

        addDeliveryWindow({ query, form });
      })
  };

  const handleCancelAddWindow = () => {
    form.resetFields();
    changeModalVisibility(MODAL_TYPES.addDeliveryWindows);
  };

  return (
    <Modal
      title="Add Delivery Window"
      visible={isAddWindowModalVisible}
      onOk={handleConfirmUpdate}
      onCancel={handleCancelAddWindow}
      cancelText="No, thanks"
      okText="Yes, add a window"
    >
      <Form form={form}>
        <strong>Start Time - End Time</strong>
        <WindowsIntervalFormatItem />
        <strong>Zone</strong>
        <Form.Item
          name="zoneId"
          rules={[
            {
              required: true,
              message: 'Please select the zone',
            },
          ]}
        >
          <ZoneSelect onChange={(zId) => changeZone(zId)} />
        </Form.Item>
        <strong>Total Capacity</strong>
        <Form.Item
          name="totalCapacity"
          rules={[
            {
              required: true,
              message: 'Please enter the total capacity',
            },
          ]}
        >
          <InputNumber style={{ width: '100%' }} placeholder="Total Capacity" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddModal;
