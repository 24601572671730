export interface Location {
  addressLine1: string,
  addressLine2: string,
  catalogId: string,
  city: string,
  country: string,
  id: string,
  latitude: number,
  longitude: number,
  name: string,
  province: string
}

export enum MODAL_TYPES {
  'confirmationSwitch' = 0,
  'editStock' = 1,
  'addBulk' = 2,
  'discontinued' = 3,
  'addProduct' = 4
}

export enum PRODUCT_STATUS_TYPES {
  'DISCONTINUED' = 'discontinued',
  'ON' = 'on',
  'OFF' = 'off',
  'ALL' = 'all',
}

export enum FILTER_TYPES {
  'SKU' = 'sku',
  'NAME' = 'name',
}

export enum PAYLOAD_TYPES {
  'SKU' = 'sku',
  'NAME' = 'name',
  'LOCATION_ID' = 'locationId'
}

export interface Product {
  active: boolean,
  isDiscontinued: boolean;
  brand: string,
  catalog: Catalog,
  category: Category,
  createdAt: number,
  description: string,
  id: string,
  levels: Level[],
  lgImageLink: string,
  marketPrice: number,
  mdImageLink: string,
  name: string,
  price: number,
  sku: string,
  upc: string,
  smImageLink: string,
  taxes: Tax[],
  unitOfMeasurement: string,
  updatedAt: number
}

export interface Catalog {
  country: string,
  id: string,
  locations: Location[],
  provider: string

}

export interface Category {
  id: string,
  name: string,
  parentCategoryId: string
}

export interface Level {
  availableStock: number,
  locationId: string,
  productId: string,
  totalStock: number
}

export interface Tax {
  id: string,
  name: string,
  taxRate: number
}
