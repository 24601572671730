import React, { useEffect } from 'react';

import { Input, Row, Col, Modal, Form, Select, notification } from 'antd';

import useWarehouses from 'hooks/useWarehouses';
import { editPickingUser } from 'services/PickingUsers.service';

interface EditPickingUserModalProps{
  modalVisible: boolean;
  setModalVisible: (value: boolean) => void;
  fetchPickingUsers: () => void;
  record: any;
}

const EditPickingUserModal: React.FC<EditPickingUserModalProps> = ({modalVisible, setModalVisible, fetchPickingUsers, record}) => {
  const [form] = Form.useForm();

  const { Option } = Select;

  const { paginatedWarehouses, fetchWarehouses, loading } = useWarehouses();

  useEffect(() => {
    fetchWarehouses();
  }, [])

  const handleOkEditModal = () => {
    form
      .validateFields()
      .then(async (values) => {

        const patchBody = {
          ...values,
          isActive: values.isActive === 'true' ? true : false
        }

        const response = await editPickingUser(record?.id, patchBody);

        if (response.error || response.status === 'error') throw new Error(response.message);

        notification['success']({
          message: 'Success!',
          description: 'The Picking User has been successfully edited.',
        });

        await fetchPickingUsers();

        form.resetFields();
        setModalVisible(!modalVisible);
      })
      .catch((error) => {
        notification['error']({
          message: 'Error editing Picking User!',
          description: error.message,
        });
      });
  };

  const handleCancelEditModal = () => {
    form.resetFields();
    setModalVisible(!modalVisible);
  };

  return (
    <Modal
      title="Add New Picking User"
      visible={modalVisible}
      onOk={handleOkEditModal}
      onCancel={handleCancelEditModal}
    >
      <Form form={form} layout='vertical' autoComplete={'off'} scrollToFirstError={true}>
        <Row>
          <Col lg={24} md={24} xs={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  type: 'string',
                  message: 'Please input a valid Name!',
                },
              ]}
            >
              <Input placeholder="Name" defaultValue={record?.name}/>
            </Form.Item>
          </Col>
          <Col lg={24} md={24} xs={24}>
            <Form.Item
              label="City Name"
              name="cityName"
              rules={[
                {
                  type: 'string',
                  message: 'Please input a valid City Name!',
                },
              ]}
            >
              <Input placeholder="City Name" defaultValue={record?.cityName}/>
            </Form.Item>
          </Col>
          <Col lg={24} md={24} xs={24}>
            <Form.Item
              label="Locations"
              name="locations"
            >
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Select the locations"
                optionLabelProp="label"
                defaultValue={record?.locations.map((item: any) => item.id)}
                loading={loading}
              >
                {paginatedWarehouses?.results.map(item => (
                  <Option key={item.id} value={item.id} label={item.name}>{item.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={24} md={24} xs={24}>
            <Form.Item
              label="Status"
              name="isActive"
            >
              <Select
                style={{ width: '100%' }}
                optionLabelProp="label"
                defaultValue={record?.isActive === true ? 'true' : 'false'}
              >
                <Option value={'true'} label={'ACTIVE'}>ACTIVE</Option>
                <Option value={'false'} label={'INACTIVE'}>INACTIVE</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default EditPickingUserModal;
