import {
  createAction,
  createAsyncThunk,
  createReducer,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { notification } from 'antd';
import { getDrivers } from "services/PickingApp.service";
import { PickingAppUserType } from "ui/pages/PickingApp/interfaces";

interface PickingAppUserPaginated {
  totalItems: number;
  currentPage: number;
  perPage: number;
  totalPages: number;
  results: [],
}

interface PickingAppUserSliceState {
  paginated?: PickingAppUserPaginated;
  fetching: boolean;
  currentQuery: string;
  isAddModalVisible: boolean;
  isUpdateModalVisible: boolean;
}

const initialState: PickingAppUserSliceState = {
  fetching: false,
  currentQuery: '',
  isAddModalVisible: false,
  isUpdateModalVisible: false,
  paginated: {
    totalItems: 0,
    currentPage: 0,
    perPage: 0,
    totalPages: 1,
    results: [],
  },
};

export const fetchPickingAppUsers = createAsyncThunk(
  "PickingAppUser/fetchPickingAppUsers",
  async function (query: string) {
    try {
      const response = await getDrivers(query);
      if (response.error || response.status == "error") {
        throw new Error(response.message);
      } else {
        const formattedResponse = response.results?.map((PickingAppUser: PickingAppUserType) => ({
          ...PickingAppUser,
          city: PickingAppUser.city || '',
          status: PickingAppUser.status,
        }))
        response.results = formattedResponse;
        return response
      }
    } catch (err) {
      const error: any = err;
      notification['error']({
        message: 'Error fetching PickingAppUsers!',
        description: error.message,
      });
    }
  }
);

export const storeQuery = createAction('pickingAppUser/storeQuery', (payload: string) => ({ payload }));

export const changeAddModalVisibility = createAction('pickingAppUser/changeAddModalVisibility', (payload: boolean) => ({ payload }));

export const changeUpdateModalVisibility = createAction('pickingAppUser/changeUpdateModalVisibility', (payload: boolean) => ({ payload }));

export const pickingAppReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(changeAddModalVisibility, (state, action) => {
      console.log(action.payload);
      state.isAddModalVisible = action.payload;
    })
    .addCase(changeUpdateModalVisibility, (state, action) => {
      state.isUpdateModalVisible = action.payload;
    })
    .addCase(storeQuery, (state, action) => {
      state.currentQuery = action.payload;
    })
    .addCase(fetchPickingAppUsers.fulfilled, (state, action) => {
      state.paginated = action.payload;
    })
    .addMatcher(isPending, (state) => {
      state.fetching = true;
    })
    .addMatcher(isFulfilled, (state) => {
      state.fetching = false;
    })
    .addMatcher(isRejected, (state) => {
      state.fetching = false;
    });
});
