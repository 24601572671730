import { StyleSheet, Font } from '@react-pdf/renderer';

import RubikBold from '../../../../../../../styles/fonts/Rubik-Bold.ttf';

Font.register({
  family: 'Rubik',
  fonts: [{ src: RubikBold, fontWeight: 700 }],
});

export const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
  },
  secondPage: {
    height: '100%',
    width: '100%',
    justifyContent: 'flex-end',
    padding: 30,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  subSection: {
    margin: 3,
    paddingRight: 20,
  },
  logo: {
    width: '200px',
    height: '100px',
    objectFit: 'contain',
    alignSelf: 'flex-end',
  },
  normalText: {
    fontSize: '10px',
  },
  boldText: {
    fontSize: '10px',
    fontWeight: 'bold',
    fontFamily: 'Rubik',
  },
  spaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  addressContainer: {
    paddingLeft: '30px',
    paddingTop: '10px',
  },
  title: {
    fontSize: '26px',
  },
  invoiceAddressText: {
    fontSize: '10px',
    lineHeight: '1.5px',
  },
  line: {
    width: '100%',
    height: 0.5,
    backgroundColor: '#000',
    marginVertical: 5,
  },
  thinLine: {
    width: '100%',
    height: 0.5,
    backgroundColor: '#cdcdcd',
    marginVertical: 5,
  },
  flexEndView: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-end',
    marginVertical: 5,
  },
  calculationView: {
    width: '100%',
  },
  paddingLeft: {
    paddingLeft: 30,
  },
  shorterLine: {
    width: '40%',
    height: 0.5,
    backgroundColor: '#000',
    marginVertical: 5,
    alignSelf: 'flex-end',
  },
});
