import React, { useState, useEffect, useContext } from 'react';

import moment from 'moment';
import { Table, Input, InputNumber, Tabs, DatePicker, Col, Select, Button } from 'antd';
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';

import { ReactComponent as LockIcon } from '../../../../../img/lock.svg';
import { ReactComponent as UnlockIcon } from '../../../../../img/unlock.svg';
import { MarketingContext } from '../../contexts/MarketingContext';
import AddDiscountModal from '../AddDiscountModal';
import { FilterTypes } from '../../interfaces'
import formatToDollar from 'util/formatToDollar';
import styles from './MarketingPage.module.css';

interface Item {
  id: string;
  name: string;
  description: string;
  type: string;
  discountValue: number;
  status: string;
  expirationDate: number;
  minimumOrderValue: number;
  usageCount: number;
  maxAllowedUsages: number;
  calculatedDiscountValue: number;
  createdAt: number;
  updatedAt: number;
}

const EditableTable = () => {
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [rowKey, setRowKey] = useState<string>('');
  const [filterType, setFilterType] = useState<FilterTypes>(FilterTypes.COUPON_ID);
  const [name, setName] = useState<string | null>(null);
  const [description, setDescription] = useState<string | null>(null);
  const [discountValue, setDiscountValue] = useState<number | null>(null);
  const [expirationDate, setExpirationDate] = useState<number | null>(null);
  const [status, setStatus] = useState<string | null>(null);
  const [minimumOrderValue, setMinimumOrderValue] = useState<number | null>(null);
  const [maxAllowedUsages, setMaxAllowedUsages] = useState<number | null>(null);
  const [searchText, setSearchText] = useState<string>('');

  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

  const {
    totalOfCoupons,
    couponsPerPage,
    updateDiscount,
    fetchCoupons,
    coupons,
    loading,
    setCurrentPage,
    currentPage,
    toggleAddDiscountModalVisibility,
  } = useContext(MarketingContext);

  const { TabPane } = Tabs;
  const { Option } = Select;

  function handleAddDiscount() {
    toggleAddDiscountModalVisibility();
  }

  async function handleLockRow(record: any) {
    const { id, type } = record;
    setIsLocked(!isLocked);
    if (name || description || discountValue || expirationDate || status || minimumOrderValue || maxAllowedUsages) {
      updateDiscount({
        id,
        body: {
          name: name ?? record.name,
          description: description ?? record.description,
          type: type ?? record.type,
          discountValue: discountValue || record.discountValue,
          expirationDate: expirationDate ?? moment(record.expirationDate).valueOf(),
          status: status ?? record.status,
          minimumOrderValue: minimumOrderValue || record.minimumOrderValue,
          maxAllowedUsages: maxAllowedUsages ?? record.maxAllowedUsages,
        },
      });
    }
  }

  async function handleUnlockRow(record: any) {
    setIsLocked(!isLocked);
    setRowKey(record.id);
  }

  useEffect(() => {
    fetchCoupons();
  }, []);

  const handleSearch = async (page?: number) => {
    const params: any = {};

    if (page) params.page = page;

    if (filterType === 'ids') {
      if (searchText !== '') params[filterType] = [searchText];
    }
    if (searchText !== '') params[filterType] = searchText;

    fetchCoupons(params);
  };

  function handleFormatValue(value: number) {
    setMinimumOrderValue(value);
  }

  const columns = [
    {
      dataIndex: 'action',
      key: 'action',
      render: (_: any, record: Item) => {
        return isLocked && rowKey === record.id ? (
          <div style={{ display: 'flex', cursor: 'pointer' }}>
            <UnlockIcon onClick={() => handleLockRow(record)} />
          </div>
        ) : (
          <div style={{ display: 'flex', cursor: 'pointer' }}>
            <LockIcon onClick={() => handleUnlockRow(record)} />
          </div>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (value: string, record: any) => (
        <>
          {isLocked && rowKey === record.id ? (
            <Input defaultValue={value} onChange={(e) => setName(e.target.value)} style={{ width: '150px' }} />
          ) : (
            <p className={styles.text}>{value}</p>
          )}
        </>
      ),
    },
    {
      title: 'Coupon ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Audience',
      dataIndex: 'audience',
      key: 'audience',
      render: (value: string) => <p className={styles.text}>{value || '-'}</p>
    },
    {
      title: 'Usage count',
      dataIndex: 'usageCount',
      key: 'usageCount',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (value: string, record: any) => (
        <>
          {isLocked && rowKey === record.id ? (
            <Input defaultValue={value} onChange={(e) => setDescription(e.target.value)} style={{ width: '150px' }} />
          ) : (
            <p className={styles.text}>{value}</p>
          )}
        </>
      ),
    },
    {
      title: 'Value',
      dataIndex: 'discountValueFormatted',
      key: 'discountValueFormatted',
      render: (value: number, record: any) => (
        <>
          {isLocked && rowKey === record.id ? (
            <InputNumber
              defaultValue={record.type === 'PERCENTAGE' ? record.discountValue : record.discountValue}
              placeholder={record.type === 'PERCENTAGE' ? '100%' : '1,000.00'}
              onChange={(e: number) => {
                if (record.type === 'VALUE_OFF') setDiscountValue(e);
                else setDiscountValue(e);
              }}
              style={{ width: '100px' }}
              formatter={(value) => (record.type === 'PERCENTAGE' ? `${value}%` : `$ ${formatToDollar(value || 0)}`)}
              parser={(value) => (record.type === 'PERCENTAGE' ? value : Number(value?.replace(/\D/g, '')))}
              min={0}
              max={record.type === 'PERCENTAGE' && 100}
            />
          ) : (
            <p className={styles.text}>{record.type === 'PERCENTAGE' ? `${value}%` : `$ ${formatToDollar(value)}`}</p>
          )}
        </>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Expirations',
      dataIndex: 'expirationDateFormatted',
      key: 'expirationDateFormatted',
      render: (value: number, record: any) => (
        <>
          {isLocked && rowKey === record.id ? (
            <DatePicker
              defaultValue={moment(value, dateFormatList[0])}
              format={dateFormatList}
              onChange={(e) => setExpirationDate(moment(e).valueOf())}
              style={{ width: '150px' }}
            />
          ) : (
            <p className={styles.text}>{value}</p>
          )}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (value: string, record: any) => (
        <>
          {isLocked && rowKey === record.id ? (
            <Select defaultValue={value} onChange={(e: string) => setStatus(e)} style={{ width: '100px' }}>
              <Option value="ACTIVE">ACTIVE</Option>
              <Option value="INACTIVE">INACTIVE</Option>
            </Select>
          ) : (
            <p className={styles.text}>{value}</p>
          )}
        </>
      ),
    },
    {
      title: 'MOV',
      dataIndex: 'minimumOrderValueFormatted',
      key: 'minimumOrderValueFormatted',
      render: (value: number, record: any) => (
        <>
          {isLocked && rowKey === record.id ? (
            <InputNumber
              defaultValue={record.minimumOrderValue}
              placeholder="1,000.00"
              onChange={handleFormatValue}
              style={{ width: '100px' }}
              min={0}
              formatter={(value) => `$ ${formatToDollar(value || 0)}`}
              parser={(value) => Number(value?.replace(/\D/g, ''))}
            />
          ) : (
            <p className={styles.text}>${formatToDollar(value)}</p>
          )}
        </>
      ),
    },
    {
      title: 'Max Client #',
      dataIndex: 'maxAllowedUsages',
      key: 'maxAllowedUsages',
      render: (value: number, record: any) => (
        <>
          {isLocked && rowKey === record.id ? (
            <InputNumber
              defaultValue={value}
              onChange={(e: number) => setMaxAllowedUsages(e)}
              style={{ width: '100px' }}
              min={1}
            />
          ) : (
            <p className={styles.text}>{value}</p>
          )}
        </>
      ),
    },
    {
      title: 'Last edition',
      dataIndex: 'updatedAtFormatted',
      key: 'updatedAtFormatted',
    },
  ];

  const selectAfter = (
    <Select
      className="select-after"
      style={{ width: '200px', backgroundColor: '#d9d9d6' }}
      value={filterType}
      onChange={(value) => setFilterType(value)}
    >
      <Option value={FilterTypes.COUPON_ID}>Coupon ID</Option>
      <Option value={FilterTypes.COUPON_NAME}>Coupon Name</Option>
    </Select>
  );

  return (
    <div className={styles.cardContainer}>
      <Col lg={24} md={24} xs={24}>
        <div className={styles.compoundFilter}>
          <Input
            className={styles.inputSearch}
            addonBefore={selectAfter}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            allowClear
          />
          <Button onClick={() => handleSearch()} type="primary" icon={<SearchOutlined />}>
            Search
          </Button>
        </div>
      </Col>
      <Col className={styles.addRow} lg={24} md={24} xs={24}>
        <div onClick={handleAddDiscount} className={styles.addDiscountButton}>
          <PlusCircleOutlined className={styles.iconButton} />
          <strong className={styles.addDiscountText}>Add discount</strong>
        </div>
      </Col>
      <Table
        loading={loading}
        pagination={{
          current: currentPage,
          defaultPageSize: couponsPerPage,
          onChange: (page: number) => {
            handleSearch(page);
            setCurrentPage(page);
          },
          showSizeChanger: false,
          pageSize: couponsPerPage || undefined,
          total: totalOfCoupons || undefined,
        }}
        rowKey={(record) => record.id}
        className={styles.table}
        dataSource={coupons}
        columns={columns}
        scroll={{ x: 1270 }}
      />
      <AddDiscountModal />
    </div>
  );
};

export default EditableTable;
