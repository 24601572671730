import React, { useEffect, useState } from 'react';

import { Button, Input, Select, Table, Tabs } from 'antd';
import { SearchOutlined } from '@ant-design/icons'
;
import styles from './WarehousePage.module.css';
import ConfirmationModal from './components/ConfirmationModal';
import DeliveryWindowsPage from '../DeliveryWindows';
import useWarehouses from 'hooks/useWarehouses';

const { Option } = Select;
const { TabPane } = Tabs;

const WarehousePage: React.FC = () => {
  const [selectedRecord, setSelectedRecord] = useState({});
  const [name, setName] = useState<string>('');
  const {
    fetchWarehouses,
    paginatedWarehouses,
    changeModalVisibility,
    isModalVisible,
    storeNewStatus,
    loading
  } = useWarehouses();

  useEffect(() => {
    fetchWarehouses({ page: paginatedWarehouses?.currentPage});
  }, []);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Warehouse ID',
      dataIndex: 'id',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (value: string, record: any) => (
        <Select
          value={value}
          className={styles.select}
          onChange={(newValue: string) => {
            setSelectedRecord(record);
            storeNewStatus(newValue);
            changeModalVisibility(!isModalVisible);
          }}
        >
          <Option value="ON">ON</Option>
          <Option value="OFF">OFF</Option>
          <Option value="TEMPORARILY_OFF">TEMPORARILY OFF</Option>
        </Select>
      ),
    },
  ];

  const handleSearch = async (page?: number) => {
    const params: any = {};

    if (name.length > 0) params.name = name;
    if (page) params.page = page;

    fetchWarehouses(params);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.compoundFilter}>
          <Input
            className={styles.inputSearch}
            value={name}
            allowClear
            placeholder="Type a location name..."
            onChange={(e) => setName(e.target.value)}
          />
          <Button onClick={() => handleSearch()} type="primary" icon={<SearchOutlined />}>
            Search
          </Button>
        </div>
        <Table
          rowKey={(record) => record.id}
          pagination={{
            onChange: (page: number) => {
              handleSearch(page);
            },
            total: paginatedWarehouses?.totalItems,
            pageSize: 10,
            showSizeChanger: false,
            current: paginatedWarehouses?.currentPage,
          }}
          columns={columns}
          dataSource={paginatedWarehouses?.results}
          loading={loading}
        />
      </div>
      <ConfirmationModal record={selectedRecord} />
    </>
  );
};

export default WarehousePage;
