/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';

import { Button, Col, Form, Input, InputNumber, notification, Row, Select } from 'antd';

import formatToDollar from 'util/formatToDollar';
import { editProduct } from 'services/Products.service';
import useInventory from 'hooks/useInventory';
import styles from './EditProduct.module.css';
import { Category, Product } from 'ui/pages/Inventory/interfaces';

const { Option } = Select;

interface ProductDetailsProps {
  record: Product;
  isEditingItem: boolean;
  setIsEditingItem: (value: boolean) => void;
}

const ProductDetails: React.FC<ProductDetailsProps> = ({
  setIsEditingItem,
  isEditingItem,
  record,
}) => {

  const [form] = Form.useForm();

  const [loadingEditProduct, setLoadingEditProduct] = useState<boolean>(false);
  const [productCategoryId, setProductCategoryId] = useState<string>(record?.category.id);

  const {
    categories,
    fetchAllCategories,
    loading,
    selectedProduct,
    fetchProduct,
  } = useInventory();

  useEffect(() => {
    if (categories.length === 0){
      fetchAllCategories();
    }
  }, [categories])

  const onFinish = async (values: any) => {
    setLoadingEditProduct(true);

    const body: any = values;

    const category = categories.find((item: Category) => item.id === productCategoryId);
    body.category = category;

    try {
      if (record.id) {
        const response = await editProduct(record.id, body);

        if (response.error || response.status === 'error') throw new Error(response.message);
        else {
          setIsEditingItem(false);
          notification.success({
            message: 'Success editing product!',
            description: `Product ${record.id} was updated.`,
          });
          fetchProduct(record.id);
        }
      } else {
        notification.error({
          message: 'Error editing product!',
          description: 'Product ID is undefined.',
        });
      }
    } catch (err) {
      const error: any = err;
      notification.error({
        message: 'Error editing product!',
        description: error.message,
      });
    } finally {
      setLoadingEditProduct(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log(errorInfo);
  };

  useEffect(() => {
    if (selectedProduct) {
      const {
        name,
        description,
        price,
        marketPrice,
        brand,
        unitOfMeasurement,
        taxes,
        upc,
        smImageLink,
        mdImageLink,
        lgImageLink,
      } = selectedProduct;

      form.setFieldsValue({
        name,
        description,
        price,
        marketPrice,
        brand,
        unitOfMeasurement,
        taxes,
        upc,
        smImageLink,
        mdImageLink,
        lgImageLink,
      });
    }
  }, [selectedProduct]);

  return (
    <div className={styles.container}>
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className={styles.form}
      >
        <div className={styles.imageAndDetails}>
          <div>
            <img src={selectedProduct?.mdImageLink} alt="product" className={styles.productImage} />
          </div>
          <Col className={styles.formItems}>
            <Row>
              <Col span={12}>
                <h2 className={styles.fieldTitle}>Name:</h2>
                {isEditingItem ? (
                  <Form.Item name="name">
                    <Input />
                  </Form.Item>
                ) : (
                  <p className={styles.text}>{selectedProduct?.name}</p>
                )}
              </Col>
              <Col span={12}>
                <h2 className={styles.fieldTitle}>Description:</h2>
                {isEditingItem ? (
                  <Form.Item name="description">
                    <Input />
                  </Form.Item>
                ) : (
                  <p className={styles.text}>{selectedProduct?.description}</p>
                )}
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <h2 className={styles.fieldTitle}>Category:</h2>
                {isEditingItem ? (
                  <Select
                    loading={loading}
                    value={productCategoryId}
                    onChange={(value) => setProductCategoryId(value)}
                  >
                    {categories?.map((productCategory: Category) => (
                      <Option key={productCategory.id} value={productCategory.id}>
                        {productCategory.name}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <p className={styles.text}>{selectedProduct?.category.name}</p>
                )}
              </Col>
              <Col span={6}>
                <h2 className={styles.fieldTitle}>Price:</h2>
                {isEditingItem ? (
                  <Form.Item name="price">
                    <InputNumber
                      formatter={(value: number) => `$ ${formatToDollar(value || 0)}`}
                      parser={(value) => Number(value?.replace(/\D/g, ''))}
                    />
                  </Form.Item>
                ) : (
                  <p className={styles.text}>${formatToDollar(selectedProduct?.price || 0)}</p>
                )}
              </Col>
              <Col span={6}>
                <h2 className={styles.fieldTitle}>Market Price:</h2>
                {isEditingItem ? (
                  <Form.Item name="marketPrice">
                    <InputNumber
                      formatter={(value: number) => `$ ${formatToDollar(value || 0)}`}
                      parser={(value) => Number(value?.replace(/\D/g, ''))}
                    />
                  </Form.Item>
                ) : (
                  <p className={styles.text}>${formatToDollar(selectedProduct?.marketPrice || 0)}</p>
                )}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <h2 className={styles.fieldTitle}>Brand:</h2>
                {isEditingItem ? (
                  <Form.Item name="brand">
                    <Input />
                  </Form.Item>
                ) : (
                  <p className={styles.text}>{selectedProduct?.brand}</p>
                )}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <h2 className={styles.fieldTitle}>Unit of Measurement:</h2>
                {isEditingItem ? (
                  <Form.Item name="unitOfMeasurement">
                    <Input />
                  </Form.Item>
                ) : (
                  <p className={styles.text}>{selectedProduct?.unitOfMeasurement}</p>
                )}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <h2 className={styles.fieldTitle}>Upc:</h2>
                {isEditingItem ? (
                  <Form.Item name="upc">
                    <InputNumber/>
                  </Form.Item>
                ) : (
                  <p className={styles.text}>{selectedProduct?.upc}</p>
                )}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <h2 className={styles.fieldTitle}>Taxes:</h2>
                <div className={styles.taxesContainer}>
                  {record.taxes.map((tax: any) => (
                    <p key={tax.id} className={styles.text}>
                      {tax.name}
                    </p>
                  ))}
                </div>
              </Col>
            </Row>

            {isEditingItem && (
              <Row className={styles.imageLinks}>
                <Col lg={8} md={24} sm={24} xs={24}>
                  <h2 className={styles.fieldTitle}>Small Image Link:</h2>
                  <Form.Item name="smImageLink">
                    <Input />
                  </Form.Item>
                </Col>
                <Col lg={8} md={24} sm={24} xs={24}>
                  <h2 className={styles.fieldTitle}>Medium Image Link:</h2>
                  <Form.Item name="mdImageLink">
                    <Input />
                  </Form.Item>
                </Col>
                <Col lg={8} md={24} sm={24} xs={24}>
                  <h2 className={styles.fieldTitle}>Large Image Link:</h2>
                  <Form.Item name="lgImageLink">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Col>
        </div>

        <div className={styles.lockButtonAndSaveChanges}>
          {isEditingItem && (
            <Form.Item>
              <Button
                className={styles.buttonSaveChanges}
                type="primary"
                htmlType="submit"
                loading={loadingEditProduct}
              >
                Save Changes
              </Button>
            </Form.Item>
          )}
        </div>
      </Form>
    </div>
  );
};

export default ProductDetails;
