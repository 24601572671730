import { request } from 'util/request';

export async function getDeliveryLegsByOrderId(orderId?: any) {
  return request('delivery/legs/search', orderId, 'POST');
}

export async function updateDeliveryLegs(params: any) {
  const { id, body } = params;
  return request(`delivery/legs/${id}`, body, 'PATCH');
}
