import React from 'react';

import { View, Text } from '@react-pdf/renderer';

import { styles } from './styles';
import { formatDate } from '../../utils';

interface InvoiceDetailsProps {
  order: any;
  customer: any;
}

const InvoiceDetails: React.FC<InvoiceDetailsProps> = ({ order, customer }) => {
  return (
    <>
      {order && (
        <View style={styles.spaceBetween}>
          <View>
            <Text style={styles.title}>INVOICE</Text>
            <View style={styles.addressContainer}>
              <Text style={styles.invoiceAddressText}>
                {order?.customerDetails.displayName || 'No customer name found'}
              </Text>
              {customer?.primaryAddress && (
                <Text style={styles.invoiceAddressText}>{customer?.primaryAddress}, Calgary, AB</Text>
              )}
              <Text style={styles.invoiceAddressText}>CALGARY</Text>
              <Text style={styles.invoiceAddressText}>CA</Text>
            </View>
          </View>

          <View style={styles.spaceBetween}>
            <View>
              <View style={styles.subSection}>
                <Text style={styles.boldText}>Invoice Date</Text>
                <Text style={styles.normalText}>{formatDate(order.estimatedDeliveryTime)}</Text>
              </View>
              <View style={styles.subSection}>
                <Text style={styles.boldText}>Invoice Number</Text>
                <Text style={styles.normalText}>{order?.id.slice(-7)}</Text>
              </View>
              <View style={styles.subSection}>
                <Text style={styles.boldText}>GST/HST</Text>
                <Text style={styles.normalText}>785492141</Text>
              </View>
            </View>
            <View>
              <Text style={styles.invoiceAddressText}>KiwiMe Inc.</Text>
              <Text style={styles.invoiceAddressText}>246 Stewart Green SW</Text>
              <Text style={styles.invoiceAddressText}>Unit #6152</Text>
              <Text style={styles.invoiceAddressText}>Calgary, AB T3H 3C8</Text>
            </View>
          </View>
        </View>
      )}
    </>
  );
};

export default InvoiceDetails;
