import React, { memo } from 'react';

import OrderDetailsPage from './components/OrderDetailsPage';
import OrderDetailsProvider from './contexts/OrderDetailsContext';


function OrderDetails() {
  return (
    <OrderDetailsProvider>
      <OrderDetailsPage/>
    </OrderDetailsProvider>
  )
}

export default memo(OrderDetails);
