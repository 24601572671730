import moment from 'moment-timezone';
import { getUnixTimeByTimezone } from 'util/dateAndTime';

import { DAYS_COLUMNS, WEEK_DAYS, WEEK_DAYS_ABBREVIATED } from './constants';
import { Driver, Shift, ViewType } from './interfaces';

export const convertIsoStringToDate = (isoString: string) => {
  const dateString = new Date(isoString);

  return dateString;
};

export const convertIsoStringToUnixMilliseconds = (isoString: string) => moment(isoString).valueOf();

export const convertDateToIsoString = (date: Date) => {
  const dateToConvert = date;
  dateToConvert.setTime(dateToConvert.getTime() - dateToConvert.getTimezoneOffset() * 60000);

  const isoString = dateToConvert.toISOString();
  return isoString;
};

// Generated string for shifts start and end columns in grid system
const getShiftStartAndEndColumnsString = (startTime: string, endTime: string, viewType: number | undefined) => {
  const startDate = convertIsoStringToDate(startTime);
  const endDate = convertIsoStringToDate(endTime);

  const startDateHours = checkTimeAndAddZero(startDate.getHours());
  const startDateMinutes = checkTimeAndAddZero(startDate.getMinutes());
  const endDateHours = checkTimeAndAddZero(endDate.getHours());
  const endDateMinutes = checkTimeAndAddZero(endDate.getMinutes());

  let string = '';
  if (viewType === ViewType.WEEKLY) {
    const weekDays = ['sun', 'mon', 'tues', 'wed', 'thurs', 'fri', 'sat'];
    // gets the corresponding week day abreviation
    const startDateWeekDay = weekDays[startDate.getDay()];
    const endDateWeekDay = weekDays[endDate.getDay()];

    // eslint-disable-next-line max-len
    string = `${startDateWeekDay}-time-${startDateHours}${startDateMinutes} / ${endDateWeekDay}-time-${endDateHours}${endDateMinutes}`;
  } else string = `time-${startDateHours}${startDateMinutes} / time-${endDateHours}${endDateMinutes}`;

  return string;
};

// Gets a column name (example: time-0930) from the grid system and returns the corresponding time formatted (09:30)
export const generateTimeByColumnName = (columnId: string) => {
  const number = columnId.replace(/\D/g, '');

  const hours = number.slice(0, 2);
  const minutes = number.slice(2, 4);
  const time = `${hours}:${minutes}`;

  return time;
};

// Gets shifts and return same array + formatted hours and dates for displaying in page
export const formatShiftData = (shifts: any[], drivers: any[], viewType: number | undefined) => {
  const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const formattedData = shifts.map((item) => {
    const shiftDurationString = getShiftStartAndEndColumnsString(item.startTime, item.endTime, viewType);

    const startTimeDate = convertIsoStringToDate(item.startTime);
    const endTimeDate = convertIsoStringToDate(item.endTime);
    const shiftDriverId = item.driver?.id;

    const startTimeFormatted = `${checkTimeAndAddZero(startTimeDate.getHours())}:${checkTimeAndAddZero(
      startTimeDate.getMinutes(),
    )}`;

    const endTimeFormatted = `${checkTimeAndAddZero(endTimeDate.getHours())}:${checkTimeAndAddZero(
      endTimeDate.getMinutes(),
    )}`;

    // For the popovers in the grid:
    const weekDay = days[startTimeDate.getDay()];
    const month = monthNames[startTimeDate.getMonth()];
    const day = startTimeDate.getDate();
    const dayOrdinal = getDayOrdinal(day);
    const dateString = `${weekDay}, ${month} ${day}${dayOrdinal}`;

    // For alignment of shifts in corresponding driver rows:
    const correspondingDriver = drivers.find((driver) => driver.id === shiftDriverId);
    const correspondingDriverRow = correspondingDriver?.rowIndex;

    const formattedDataObject = {
      ...item,
      startTimeFormatted,
      endTimeFormatted,
      dateString,
      shiftDurationString,
      row: correspondingDriverRow,
    };

    return formattedDataObject;
  });

  return formattedData;
};

export const checkTimeAndAddZero = (time: number | string) => {
  let formattedTime = time;
  if (time < 10) formattedTime = `0${time}`;
  return formattedTime;
};

export const getDateBasedOnColumnId = (columnId: string, weekStartDate: Date) => {
  const weekDay = columnId.split('-')[0];
  const index = WEEK_DAYS_ABBREVIATED.indexOf(weekDay);

  const weekStartAndEndDate = getWeekInitialAndFinalDate(weekStartDate);
  const startDate = new Date(weekStartAndEndDate[0]);

  const weekDays = WEEK_DAYS.map(() =>
    checkTimeAndAddZero(new Date(startDate.setDate(startDate.getDate() + 1)).getDate()),
  );

  const correspondingDate = weekDays[index];

  return correspondingDate;
};

// if the function receives a viewType WEEKLY, means that is a weekly view case,
// and needs to use a specific date by using the columnId
export const convertHoursToIsoString = (time: string, dayIsoString: string, columnId?: string, viewType?: ViewType) => {
  if (time) {
    const hoursAndMinutes = time.split(':');
    const day = convertIsoStringToDate(dayIsoString);

    if (viewType === ViewType.WEEKLY && columnId) {
      const date = getDateBasedOnColumnId(columnId, day);
      day.setDate(Number(date));
    }

    const hours = Number(hoursAndMinutes[0]);
    const minutes = Number(hoursAndMinutes[1]);
    day.setHours(hours, minutes, 0);

    const isoString = new Date(day.getTime() - day.getTimezoneOffset() * 60000).toISOString();

    return isoString;
  }
  return null;
};

export function getColumnsWithWeekDays(someDate: Date) {
  const weekStartAndEndDate = getWeekInitialAndFinalDate(someDate);
  const startDate = new Date(weekStartAndEndDate[0]);

  const addDaysToColumnsData = DAYS_COLUMNS.map((item, index) => ({
    ...item,
    day: new Date(startDate.setDate(startDate.getDate() + 1)).getDate(),
    id: index,
  }));

  return addDaysToColumnsData;
}

export function getWeekInitialAndFinalDate(someDate: Date, removeTimeSet?: boolean) {
  const monday = new Date(someDate.setDate(someDate.getDate() - someDate.getDay() + 1));
  const sunday = new Date(someDate.setDate(someDate.getDate() - someDate.getDay() + 7));

  if (!removeTimeSet) {
    monday.setUTCHours(5, 0, 0, 0);
    sunday.setUTCHours(23, 40, 0, 0);
  }

  return [convertDateToIsoString(monday), convertDateToIsoString(sunday)];
}

// Get drivers data and add rowIndex (for shifts grid system) and cityId (shorter path)
export const formatDrivers = (drivers: Driver[]) => {
  const formatted = drivers.map((item, index) => ({
    ...item,
    rowIndex: index + 2,
    cityId: item.address?.city.id,
  }));

  return formatted;
};

// Used in the "DateBox" component, to show current date (styled)
export const getDayOrdinal = (day: number) => {
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

// Used to calculate the average of all driver's shifts duration
const calculateDriversShiftAverageDuration = (shifts: Shift[]) => {
  const driversWithShiftDuration: any = {};

  shifts.forEach((item) => {
    const { driverId } = item;
    const startDate: Date = convertIsoStringToDate(item.startTime);
    const endDate: Date = convertIsoStringToDate(item.endTime);
    const shiftDuration = Math.abs(startDate.getTime() - endDate.getTime()) / 36e5;

    if (driverId) {
      if (!driversWithShiftDuration[driverId]) driversWithShiftDuration[driverId] = {};
      if (!driversWithShiftDuration[driverId].shifts) driversWithShiftDuration[driverId].shifts = [];
      driversWithShiftDuration[driverId].shifts.push(shiftDuration);
    }
  });

  const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;

  const driversArray = Object.keys(driversWithShiftDuration).map((key) => {
    return { driverId: key, shifts: driversWithShiftDuration[key].shifts };
  });

  const driversWithShiftAverage = driversArray.map((item: any) => {
    const sum = item.shifts.reduce(reducer);
    const shiftDurationAverage = sum / item.shifts.length;

    return {
      driverId: item.driverId,
      shiftDurationAverage: Math.round(shiftDurationAverage * 100) / 100,
    };
  });

  return driversWithShiftAverage;
};

// Returns the shift average duration for a specific driver
export const getCorrespondingDriverShiftDuration = (driverId: string, shifts: Shift[]) => {
  const driversAndShiftDurations = calculateDriversShiftAverageDuration(shifts);
  const driver = driversAndShiftDurations.find((item) => item.driverId === driverId);
  const shiftDuration = driver?.shiftDurationAverage;

  return shiftDuration;
};

export const generateFetchShiftsParams = (
  week: string,
  day: string,
  selectedDriverId?: string,
  viewType?: ViewType,
  timezone?: string,
) => {
  // Depending on the selected city´s timezone, need to calculate the correct
  // startDate and endDate to fetch all the shifts correctly.
  const TIMEZONE: any = timezone;
  const date = getUnixTimeByTimezone(day, 5, 0, TIMEZONE);
  const endDate = getUnixTimeByTimezone(day, 23, 40, TIMEZONE);

  const weekInitialAndFinalDates = getWeekInitialAndFinalDate(new Date(week), true);
  const weekStartDate = getUnixTimeByTimezone(weekInitialAndFinalDates[0], 5, 0, TIMEZONE);
  const weekEndDate = getUnixTimeByTimezone(weekInitialAndFinalDates[1], 23, 40, TIMEZONE);

  const params: any = {};
  if (selectedDriverId) params.driverId = selectedDriverId;

  if (viewType === ViewType.WEEKLY) {
    params.estimatedStartTime = weekStartDate;
    params.estimatedEndTime = weekEndDate;
  } else {
    params.estimatedStartTime = date;
    params.estimatedEndTime = endDate;
  }

  return params;
};

export function transformDatesFromUTCToLocalIsoString(shifts: Shift[], timezone: string) {
  const transformedDates = shifts.map((item) => {
    const startTimeISOString = convertFromUnixToIsoString(item.estimatedStartTime);
    const endTimeISOString = convertFromUnixToIsoString(item.estimatedEndTime);

    const startDate = getDateAndTimeString(startTimeISOString, true);
    const endDate = getDateAndTimeString(endTimeISOString, true);

    const startTimeISOStringUTC = moment.tz(startDate, timezone).format('YYYY-MM-DDTHH:mm:ss');
    const endTimeISOStringUTC = moment.tz(endDate, timezone).format('YYYY-MM-DDTHH:mm:ss');

    return {
      ...item,
      startTime: startTimeISOStringUTC,
      endTime: endTimeISOStringUTC,
    };
  });

  return transformedDates;
}

const convertFromUnixToIsoString = (unixTime: number) => moment(unixTime).format();

export function getDateAndTimeString(localStartTimeISO: string, fetchingShifts?: boolean) {
  const datesAndHours = localStartTimeISO.split('T'); // Example of iso string: 2021-07-20T13:30:15.219267Z
  const dates = datesAndHours[0];
  const hoursForFetching = datesAndHours[1];
  let hours = '';

  if (fetchingShifts) hours = hoursForFetching;
  // keeps the Z in ISO String, cause the dates are in UTC (Z = zero-timezone offset)
  else hours = datesAndHours[1].split('.')[0].substr(0, 5);
  // contains timezone offset (removes the "Z" from the string)

  const string = `${dates} ${hours}`;

  return string;
}
