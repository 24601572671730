import React, { useContext, useEffect, useState } from 'react';

import { Input, Table, Col, Typography, Card } from 'antd';

import { LegProps } from 'ui/pages/Routes/interfaces';
import { EditRouteContext } from 'ui/pages/Routes/contexts/EditRouteContext';
import { RoutesContext } from '../../../contexts/RoutesContext';
import styles from './UnassignedLegsTable.module.css';

const { Title } = Typography;

interface UnassignedLegsTableProps {
  checkboxesDisabled: boolean;
}

const UnassignedLegsTable: React.FC<UnassignedLegsTableProps> = ({ checkboxesDisabled }) => {
  const [selectedRow, setSelectedRows] = useState<LegProps[]>([]);
  const [searchText, setSearchText] = useState<string>('');

  const {
    deliveryLegs,
    setAssignedLegs,
    assignedLegs,
    loadingLegs,
    unassignedLegs,
    setUnassignedLegs,
    city,
    selectedRowKeys,
    setSelectedRowKeys,
  } = useContext(RoutesContext);

  const { isEditingRoute, editRoutesUnassignedLegs, setEditRoutesUnassignedLegs } = useContext(EditRouteContext);

  const UnassignedLegsColumns = [
    {
      title: 'Order Id',
      dataIndex: 'orderId',
      className: styles.dragVisible,
      render: (text: string) => <p className={styles.listItem}>{text}</p>,
    },
    {
      title: 'Order Number',
      dataIndex: 'orderNumber',
      className: styles.dragVisible,
      render: (text: string) => <p className={styles.listItem}>{text ?? '-'}</p>,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      className: styles.dragVisible,
      render: (text: string) => <p className={styles.listItem}>{text}</p>,
    },
    {
      title: 'Job Type',
      dataIndex: 'type',
      className: styles.dragVisible,
      render: (text: string) => <p className={styles.listItem}>{text}</p>,
    },
  ];

  const getLeftUnassignedLegs = () => {
    const assignedLegsIds = assignedLegs.map((item) => item.id);
    let leftUnassignedLegs = [];

    if (isEditingRoute)
      leftUnassignedLegs = editRoutesUnassignedLegs.filter((item) => !assignedLegsIds.includes(item.id));
    else leftUnassignedLegs = deliveryLegs.filter((item) => !assignedLegsIds.includes(item.id));

    return leftUnassignedLegs;
  };

  useEffect(() => {
    setEditRoutesUnassignedLegs(deliveryLegs);
  }, [deliveryLegs]);

  useEffect(() => {
    if (deliveryLegs) {
      const leftUnassignedLegs = getLeftUnassignedLegs();
      setSearchText('');
      setUnassignedLegs(leftUnassignedLegs);
    }
  }, [assignedLegs, deliveryLegs]);

  const rowSelection = {
    selectedRowKeys,
    selectedRow,
    onChange: (selectedRowKeys: string[], selectedRows: LegProps[]) => {
      const selectedRowsOrderIds = selectedRows.map((item) => item.orderId);

      const pairRows = unassignedLegs.filter((item) => selectedRowsOrderIds.includes(item.orderId));

      const pairRowsIds = pairRows.map((item) => item.id);

      setSelectedRowKeys(pairRowsIds);
      setSelectedRows(pairRows);
      setAssignedLegs([...assignedLegs, ...pairRows]);
    },
    getCheckboxProps: (record: any) => ({
      disabled: checkboxesDisabled,
    }),
  };

  const search = (value: string) => {
    const leftUnassignedLegs = getLeftUnassignedLegs();
    const filterTable = leftUnassignedLegs.filter((o: any) =>
      Object.keys(o).some((k) => String(o[k]).toLowerCase().includes(value.toLowerCase())),
    );

    setUnassignedLegs(filterTable);
  };

  return (
    <Col className={styles.unassignedLegsContainer}>
      <Title className={styles.title} level={5}>
        Unassigned Legs in {city.name}
      </Title>
      <Card>
        <div className={styles.inputRow}>
          <Input.Search
            className={styles.inputSearch}
            placeholder="Search by order id, address or job type"
            enterButton
            onSearch={search}
            onChange={(e) => setSearchText(e.target.value)}
            allowClear
            value={searchText}
          />
        </div>
        <Table
          rowSelection={rowSelection}
          loading={loadingLegs}
          columns={UnassignedLegsColumns}
          dataSource={unassignedLegs}
          rowKey="id"
          pagination={{ defaultPageSize: 6 }}
          scroll={{ x: 500, y: 450 }}
        />
      </Card>
    </Col>
  );
};

export default UnassignedLegsTable;
