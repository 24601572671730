import React, { useContext, useEffect, useState } from 'react';

import { Button, Layout, Menu } from 'antd';
import { LeftOutlined, LogoutOutlined, MenuOutlined } from '@ant-design/icons';
import { Redirect, Route, useLocation, Link } from 'react-router-dom';

import { AuthContext } from 'contexts/AuthContext';
import { logout } from 'util/auth';
import kiwiGroceryLogo from '../../../img/kiwi-grocery-logo.png';
import kiwiGrocerySmallLogo from '../../../img/small-logo.png';
import Drivers from '../Drivers';
import Marketing from '../Marketing';
import Orders from '../Orders';
import OrderDetails from '../OrderDetails';
import Routes from '../Routes';
import DriverShifts from '../DriverShifts';
import Catalog from '../Catalog';
import Warehouse from '../Warehouse';
import styles from './Navigation.module.css';
import MapView from '../MapView';
import Customers from '../Customers';
import CustomerTabs from '../Customers/CustomerTabs';
import Sider from 'antd/lib/layout/Sider';
import SubMenu from 'antd/lib/menu/SubMenu';
import PushNotifications from '../PushNotifications';
import DeliveryWindowsPage from '../DeliveryWindows';
import Inventory from '../Inventory';
import ProductDetails from '../ProductDetails';
import PickingAppAccounts from '../PickingAppAccounts';

const { Content } = Layout;

const Navigation: React.FC = () => {
  const [collapsed, setCollapsed] = useState(true);
  const { authenticated, setAuthenticated } = useContext(AuthContext);
  const location = useLocation();

  const logoutAndNavigateToLogin = () => {
    setAuthenticated(false);
    logout();
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            from: location,
          },
        }}
      />
    );
  };

  const handleClickContent = () => {
    if (!collapsed) setCollapsed(!collapsed);
  }

  return (
    <>
      <div className={styles.container}>
        {!collapsed && (
          <div className={styles.closeIconContainer} onClick={() => setCollapsed(!collapsed)}>
            <LeftOutlined className={styles.closeIcon} />
          </div>
        )}
        <Sider
          onClick={() => {
            if (collapsed) setCollapsed(!collapsed);
          }}
          trigger={null}
          theme="light"
          className={collapsed ? styles.sideBarWithPointer : styles.sideBar}
          collapsible
          collapsed={collapsed}
          onCollapse={() => setCollapsed(!collapsed)}
          onChange={() => setCollapsed(!collapsed)}
          width={200}
          collapsedWidth={60}
        >

          <div className="logo" />

          {!collapsed ? (
            <img src={kiwiGroceryLogo} alt="KiwiMe" className={styles.logo} />
          ) : (
            <img src={kiwiGrocerySmallLogo} alt="KiwiMe" className={styles.logoSmall} />
          )
          }

          <Menu defaultSelectedKeys={["4"]} mode="inline" className={styles.menu}>
            {collapsed && (
              <div className={styles.menuRow}>
                <MenuOutlined className={styles.menuIcon} onClick={() => setCollapsed(!collapsed)} />
              </div>
            )}
            {!collapsed && (
              <>
                <Menu.Item key="orders">
                  <Link to="/orders">Orders</Link>
                </Menu.Item>

                <Menu.Item key="mapView">
                  <Link to="/mapView">Map View</Link>
                </Menu.Item>

                <Menu.Item key="customers">
                  <Link to="/customers">Customers</Link>
                </Menu.Item>

                <Menu.Item key="inventory">
                  <Link to="/inventory">Inventory</Link>
                </Menu.Item>

                <SubMenu key="routesSubMenu" title="Routes">
                  <Menu.Item key="routes">
                    <Link to="/routes">Routes</Link>
                  </Menu.Item>
                  <Menu.Item key="shifts">
                    <Link to="/shifts">Shifts</Link>
                  </Menu.Item>
                  <Menu.Item key="drivers">
                    <Link to="/drivers">Drivers</Link>
                  </Menu.Item>
                </SubMenu>

                <SubMenu key="marketingSubMenu" title="Marketing">
                  <Menu.Item key="marketing">
                    <Link to="/marketing">Marketing</Link>
                  </Menu.Item>
                  <Menu.Item key="notifications">
                    <Link to="/notifications">Notifications</Link>
                  </Menu.Item>
                </SubMenu>


                <SubMenu key="warehouseSubMenu" title="Warehouse">
                  <Menu.Item key="warehouse">
                    <Link to="/warehouse">Warehouse</Link>
                  </Menu.Item>
                  <Menu.Item key="deliveryWindows">
                    <Link to="/deliveryWindows">Delivery Windows</Link>
                  </Menu.Item>
                  <Menu.Item key="pickingAppAccounts">
                    <Link to="/pickingAppAccounts">Picking App Accounts</Link>
                  </Menu.Item>
                </SubMenu>
              </>
            )}
          </Menu>

          {!collapsed && (
            <div className={styles.logoutContainer}>
              <Link to="/login">
                <Button className={styles.button} onClick={() => logoutAndNavigateToLogin()}>
                  <LogoutOutlined />
                  Log out
                </Button>
              </Link>
            </div>
          )}

        </Sider>

        <Content className={styles.layoutContent} onClick={handleClickContent}>
          {authenticated && (
            <>
              <Route exact path="/" component={Orders} />
              <Route exact path="/orders" component={Orders} />
              <Route path="/orders/:id" component={OrderDetails} />
              <Route exact path="/customers" component={Customers} />
              <Route path="/customers/:customerId" component={CustomerTabs} />
              <Route exact path="/mapView" component={MapView} />
              <Route path="/drivers" component={Drivers} />
              <Route path="/marketing" component={Marketing} />
              <Route exact path="/inventory" component={Inventory} />
              <Route path="/inventory/:productId" component={ProductDetails} />
              <Route path="/warehouse" component={Warehouse} />
              <Route path="/shifts" component={DriverShifts} />
              <Route path="/routes" component={Routes} />
              <Route path="/notifications" component={PushNotifications} />
              <Route path="/deliveryWindows" component={DeliveryWindowsPage} />
              <Route path="/pickingAppAccounts" component={PickingAppAccounts} />
            </>
          )}
        </Content>
      </div>
    </>
  );
};

export default Navigation;
