import { request } from 'util/request';

async function getWarehouses(params?: any) {
  return request('inventory/locations', params, 'GET');
}

async function editWarehouse(id: string, body: any) {
  return request(`inventory/locations/${id}`, body, 'PATCH');
}


export { getWarehouses, editWarehouse }
