import { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import { selectPaginatedWallets, selectWalletsFetching, selectCreateTransactionFetching, selectSuccessCreatingTransaction, selectCustomerDontHaveWallets } from "selectors/Wallets";

import * as WalletActions from "../store/Wallet.slice";

export default function useWallets() {
  const dispatch = useDispatch();

  const paginatedWallets = useSelector(selectPaginatedWallets);
  const loadingWallets = useSelector(selectWalletsFetching);
  const loadingCreateTransaction = useSelector(selectCreateTransactionFetching);
  const successCreateTransaction = useSelector(selectSuccessCreatingTransaction);
  const customerDontHaveWallets = useSelector(selectCustomerDontHaveWallets);

  const fetchWallets = useCallback(
    async function (params?: any) {
      dispatch(WalletActions.fetchWallets(params));
    },
    [dispatch]
  );

  const createWalletTransaction = useCallback(
    async function (params: any) {
      dispatch(WalletActions.createTransaction(params));
    },
    [dispatch]
  );

  const createWalletAndInitialTransaction = useCallback(
    async function (params: any) {
      dispatch(WalletActions.createWalletAndTransaction(params));
    },
    [dispatch]
  )

  return {
    createWalletTransaction,
    paginatedWallets,
    fetchWallets,
    loadingWallets,
    loadingCreateTransaction,
    successCreateTransaction,
    customerDontHaveWallets,
    createWalletAndInitialTransaction
  };
}
