import React from 'react';

import { GOOGLE_AUTH_URL } from '../../../constants/urls';
import Logo from '../../../img/kiwi-grocery-logo.png';
import styles from './Login.module.css';

const Login: React.FC = () => {
  return (
    <div className={styles.loginContainer}>
      <img src={Logo} alt="kiwiMe logo" className={styles.logo} />
      <a href={GOOGLE_AUTH_URL} className={styles.button}>SSO Login</a>
    </div>
  );
}

export default Login;
