import { request } from 'util/request';
import { RequestResponse } from 'interfaces';
import { Shift, GetDriverShiftsParams } from '../interfaces';

export async function getShifts(params?: GetDriverShiftsParams): Promise<RequestResponse<Shift[]>> {
  return request('driver/shifts', params, 'GET');
}

export async function updateShift(shiftId: string, body: any) {
  return request(`driver/shifts/${shiftId}`, body, 'PATCH');
}

export async function createShifts(body: any) {
  return request('driver/shifts', body, 'POST');
}

export async function deleteShift(shiftId: string, params?: any) {
  return request(`driver/shifts/${shiftId}`, params, 'DELETE');
}
