import React, { useContext } from 'react';
import formatToDollar from 'util/formatToDollar';
import { Table, Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import withBoundary from 'hoc/withBoundary';
import styles from './Table.module.css';
import { OrderDetailsContext } from '../../contexts/OrderDetailsContext';
import { ReactComponent as EditIcon } from '../../../../../img/editIcon.svg';
import { MODAL_TYPES, ProductType, TaxesType } from '../../interfaces';

const TableComponent: React.FC = () => {
  const { order, toggleModalVisibility } = useContext(OrderDetailsContext);

  function handleOpenEditModal(product: any) {
    toggleModalVisibility(MODAL_TYPES.changeOrder, product);
  }

  const columns = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      render: (skuValue: string, record: any) => (
        <div className={styles.editProductQuantityContainer}>
          <EditIcon onClick={() => handleOpenEditModal(record)} className={styles.editIcon} />
          <strong className={`${record.unavailable ? styles.textGray : styles.text}`}>{skuValue}</strong>
        </div>
      ),
    },
    {
      title: 'Product name',
      dataIndex: 'name',
      key: 'name',
      render: (value: string, record: any) => (
        <strong className={`${record.unavailable ? styles.textGray : styles.text}`}>{value}</strong>
      ),
    },
    {
      title: 'Additional Info',
      dataIndex: 'additionalInfo',
      key: 'additionalInfo',
      render: (text: number, record: any) => (
        <strong className={`${record.unavailable ? styles.textGray : styles.text}`}>{text || '-'}</strong>
      ),
    },
    {
      title: 'UPC',
      dataIndex: 'upc',
      key: 'upc',
      render: (value: string, record: any) => (
        <strong className={`${record.unavailable ? styles.textGray : styles.text}`}>{value || '-'}</strong>
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      key: 'qty',
      render: (value: string, record: any) => (
        <strong className={`${record.unavailable ? styles.textGray : styles.text}`}>{value}</strong>
      ),
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
      render: (value: string, record: any) => (
        <strong className={`${record.unavailable ? styles.textGray : styles.text}`}>{value}</strong>
      ),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (value: number, record: any) => (
        <strong className={`${record.unavailable ? styles.textGray : styles.text}`}>{formatToDollar(value)}</strong>
      ),
    },
    {
      title: 'Provider',
      dataIndex: 'catalogProvider',
      key: 'catalogProvider',
      render: (value: string, record: any) => (
        <strong className={`${record.unavailable ? styles.textGray : styles.text}`}>{value}</strong>
      ),
    },
    {
      title: 'Taxes',
      dataIndex: 'taxes',
      key: 'taxes',
      render: (_: string, record: any) => (
        <div className={styles.editProductQuantityContainer}>
          <Popover content={
            <div className={styles.popoverContentContainer}>
              {record.taxes.length ?
                record.taxes.map((tax: TaxesType) =>
                  <>
                    <strong key={tax.id}>{tax?.name}</strong>
                  </>
                ) : 'No Taxes'
              }
            </div>
          }>
            <InfoCircleOutlined style={{ color: "#97D700" }} />
          </Popover>
        </div>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      rowKey={(record: ProductType) => record.productId}
      dataSource={order?.items}
      className={styles.table}
      pagination={{ defaultPageSize: 50, hideOnSinglePage: true }}
    />
  );
};

export default withBoundary(TableComponent);
