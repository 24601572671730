import React from 'react';

import AuthProvider from './AuthContext';
import InventoryPageFiltersContext from './InventoryPageFiltersContext';
import OrdersPageGlobalFiltersContext from './OrdersPageGlobalFiltersContext';

const AppProvider: React.FC = ({ children }) => {
  return (
    <>
      <AuthProvider>
        <InventoryPageFiltersContext>
          <OrdersPageGlobalFiltersContext>
            {children}
          </OrdersPageGlobalFiltersContext>
        </InventoryPageFiltersContext>
      </AuthProvider>
    </>
  );
};

export default AppProvider;
