import React, { useContext } from 'react';

import { Col, Modal, notification, Row } from 'antd';

import { OrderDetailsContext } from '../../contexts/OrderDetailsContext';
import { ExpensesType, EXPENSES_VALUES, MODAL_TYPES } from '../../interfaces';
import styles from './ConfirmReplacementModal.module.css';
import { calculateTotalTaxes, generateExpensesArray, transformBigDecimal } from '../../utils';
import _ from 'lodash';
import { getCouponByName, getProductsByIds } from '../../services/api';

const ConfirmReplacementModal: React.FC = () => {
  const {
    order,
    setOrder,
    isReplacementConfirmationModalVisible,
    toggleModalVisibility,
    replacedProduct,
    replacementProduct,
    orderHasChanges,
    orderCoupon,
    setOrderExpenses,
    productRecord,
    quantity,
    availability,
    setWillReplaceProduct,
    setSelectedReplacementId
  } = useContext(OrderDetailsContext);

  const confirmReplacement = async () => {

    const editableStatuses = ['CREATED', 'IN_PICKING'];
    const { productId, originalQty } = productRecord;
    const { items, discount, deliveryFee, tip, walletBalanceUsed, deliveryFeeTax, serviceFee, serviceFeeTax } = order;
    const { type, name } = orderCoupon;

    if (editableStatuses.includes(order.status)) {
      const productToBeEditedIndex = items.findIndex((product: any) => product.productId === productId);

      const clonedOrderItems = [...order.items];

      clonedOrderItems[productToBeEditedIndex].qty = quantity;
      clonedOrderItems[productToBeEditedIndex].originalQty = originalQty;
      clonedOrderItems[productToBeEditedIndex].unavailable = availability;

      const availableProducts = clonedOrderItems.filter((product: any) => !product.unavailable);
      const newProduct = {
        ...replacementProduct,
        qty: replacedProduct.qty,
        originalQty: replacedProduct.originalQty,
        productId: replacementProduct.id,
        unit: replacementProduct.unitOfMeasurement,
        unavailable: false,
        price: replacementProduct.price > replacedProduct.price ? replacedProduct.price : replacementProduct.price,
        tax: replacementProduct.price > replacedProduct.price ? replacedProduct.tax : calculateTotalTaxes(replacementProduct.taxes, replacementProduct.price),
        taxes: replacementProduct.price > replacedProduct.price ? replacedProduct.taxes : replacementProduct.taxes
      };

      const availableProductsPlusNewItem = [...availableProducts, newProduct];
      const hasAvailableProducts = availableProductsPlusNewItem.length > 0;
      const productsIds = availableProducts.map((product: any) => {
        return product.productId;
      });

      const producIdsPlusNewItem = [...productsIds, replacementProduct.id]

      if (hasAvailableProducts) {
        const productsListWithTaxes = await getProductsByIds({
          ids: producIdsPlusNewItem,
        });

        const availableProductsWithTax = _.map(productsListWithTaxes, (item) => {
          return _.extend(item, _.find(availableProductsPlusNewItem, { productId: item.id }));
        });

        const deliveryFeeTaxExpense = order.expenses.find((expense: ExpensesType) => expense.type === EXPENSES_VALUES.DELIVERY_FEE_TAX);
        const deliveryFeeTaxValue = deliveryFeeTaxExpense?.value || 0;
        const newOrderExpenses = generateExpensesArray(productsListWithTaxes, deliveryFeeTaxValue);
        setOrderExpenses(newOrderExpenses);

        const Newtaxes = transformBigDecimal(
          availableProductsWithTax?.reduce((acc: any, curr: any) => {
            return acc + curr?.qty * curr?.tax;
          }, 0),
        );

        const newSubTotal = transformBigDecimal(
          availableProductsWithTax.reduce((acc: any, curr: any) => {
            return acc + curr.qty * curr.price;
          }, 0),
        );

        const totalPlusTaxesMinusKiwiCredits = transformBigDecimal(
            newSubTotal + Newtaxes + deliveryFee +  tip + deliveryFeeTax + serviceFee + serviceFeeTax - walletBalanceUsed
          );
        let newDiscount = 0;

        if (orderCoupon) {
          if (type === 'PERCENTAGE') {
            try {
              const response = await getCouponByName({ name, orderValue: newSubTotal, isOrderUpdate: true });
              newDiscount = response.calculatedDiscountValue;
            } catch (err) {
              const error: any = err;
              notification.error({
                message: 'Error getting coupon!',
                description: error.message,
              });
            }
          } else {
            newDiscount = discount;
          }
        }

        const newTotal = String(totalPlusTaxesMinusKiwiCredits - newDiscount).replace('.', '');

        const newDiscountFormatted = String(newDiscount).replace('.', '');
        order.taxes = Newtaxes;
        order.discount = Number(newDiscountFormatted);
        order.subTotal = newSubTotal;
        order.total = Number(newTotal) <= 0 ? 50 : Number(newTotal);

        const newCartItem = availableProductsWithTax.find(item => item.id === replacementProduct.id);

        order.items = [...clonedOrderItems, newCartItem];

        orderHasChanges(true);
        toggleModalVisibility(MODAL_TYPES.changeOrder);
      } else order.items = clonedOrderItems;

      const updatedOrder = {...order};

      updatedOrder.replacements = [
        ...order.replacements,
        { replacedItemId: replacedProduct.productId, replacementItemId: replacementProduct.id }
      ]

      setOrder(updatedOrder);
      orderHasChanges(true);
      toggleModalVisibility(MODAL_TYPES.confirmReplacement);
      toggleModalVisibility(MODAL_TYPES.changeOrder);
    } else {
      order.taxes = 0;
      order.subTotal = 0;
      order.total = 0;
      notification.error({
        message: 'Error editing product!',
        description: 'Trying to update Order with this Status is not possible. This order is already immutable.',
      });
    }

    setWillReplaceProduct(false);
    setSelectedReplacementId(undefined);
  };

  return (
    <Modal
      className={styles.modal}
      okText="Yes! Confirm the replacement"
      visible={isReplacementConfirmationModalVisible}
      onCancel={() => toggleModalVisibility(MODAL_TYPES.confirmReplacement)}
      onOk={confirmReplacement}
      forceRender
    >
      <Row>
        <Col sm={24} md={12} lg={12}>
          <h1 className={styles.replacementTitle}>You are replacing:</h1>
          <h2>{replacedProduct?.name}</h2>
          <h3>{replacedProduct?.catalogProvider || '-'}</h3>
          <img className={styles.productImage} src={replacedProduct?.mdImageLink}/>
        </Col>

        <Col sm={24} md={12} lg={12}>
          <h1 className={styles.replacementTitle}>With:</h1>
          <h2>{replacementProduct?.name}</h2>
          <h3>{replacementProduct?.catalog?.provider ?? '-'}</h3>
          <img className={styles.productImage} src={replacementProduct?.mdImageLink}/>
        </Col>
      </Row>

      <h1 className={styles.confirmationText}>Do you confirm these changes? When you click on "Save Changes" they cannot be undone</h1>

    </Modal>
  )
}

export default ConfirmReplacementModal;
