import { request } from 'util/request';

export { getShifts as getDriverShifts } from 'ui/pages/DriverShifts/services/api';
export { getDeliveryWindows as getDatesAndSlotsByCity } from 'services/DeliveryWindow.service';

export async function getDeliveryLegsBasedOnOrders(orderIds?: string[]) {
  return request('delivery/legs/search', { orderIds, unassigned: true }, 'POST');
}

export async function getOrders() {
  return request('order/orders', { status: 'CREATED' }, 'GET');
}

export async function getDriversByCity(cityId: string) {
  return request('drivers', { cityId }, 'GET');
}

export async function createRoute(body: any) {
  return request('delivery/routes', body, 'POST');
}

export async function getCities() {
  return request('inventory/locations');
}

export async function getRoutes(page: number = 1) {
  const statuses = 'CREATED&statuses=IN_ROUTE';
  return request('delivery/routes', { statuses, page }, 'GET');
}

export async function getOrdersByDeliveryWindowIds(deliveryWindowIds: string) {
  return request(`order/orders?status=CREATED&status=IN_PICKING&status=PREPARED&${deliveryWindowIds}`);
}

export async function editRoute(body: any, routeId: string) {
  return request(`delivery/routes/${routeId}`, body, 'PATCH');
}

export async function deleteRoute(routeId: string) {
  return request('delivery/routes/', routeId, 'DELETE');
}
