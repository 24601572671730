import { RootState } from "store";

function selectOrdersFetching(state: RootState) {
  return state.order.fetching;
}

function selectOrdersIsModalVisible(state: RootState) {
  return state.order.isModalVisible;
}

function selectOrdersStoreNewStatus(state: RootState) {
  return state.order.newStatus;
}

function selectOrdersStoreQuery(state: RootState) {
  return state.order.currentQuery;
}

function selectPaginatedOrders(state: RootState) {
  return state.order.paginated;
}

export {
  selectOrdersFetching,
  selectOrdersIsModalVisible,
  selectOrdersStoreNewStatus,
  selectOrdersStoreQuery,
  selectPaginatedOrders,
}
