import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap');

h1, h2, h3, h4, p{
  font-family: 'Rubik', sans-serif;
}

* {
margin: 0;
padding: 0;
outline: 0;
box-sizing: border-box;
}

.ant-btn-primary {
background-color: var(--green);
border-color: transparent;
}

.ant-btn-primary:hover {
background-color: var(--green);
border-color: transparent;
filter: brightness(96%);
}

.ant-typography{
  margin-bottom: 0 !important;
}

.ant-slider-disabled .ant-slider-mark-text, .ant-slider-disabled .ant-slider-dot{
  cursor: default !important;
}

.ant-slider  .ant-slider-mark-text, .ant-slider .ant-slider-dot{
  cursor: default !important;
}

body {
color: #fff;
-webkit-font-smoothing: antialiased;
}

iframe {
height: inherit;
}

button {
cursor: pointer;
}

//color themes
:root {
--green: #97D700;
--lightGreen: #e2f785;
--pastelGreen:#addfb3;
--white:#fff;
--black:#111;
--gray:#64676A;
--lightGray:#cdcdcd;
}

//Ant Design Styles
.ant-tabs-tab {
margin:4px !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
background-color: var(--green);
}

.ant-input-group-addon .ant-select-open .ant-select-selector, .ant-input-group-addon .ant-select-focused
.ant-select-selector{
  color: #000;
}

tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover > td{
  background-color: #fff;
}
`;
