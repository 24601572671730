import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDriversFetching,
  selectDriversIsAddModalVisible,
  selectDriversIsUpdateModalVisible,
  selectDriversStoreQuery,
  selectPaginatedDrivers
} from '../selectors/Drivers'

import * as DriverActions from "../store/Driver.slice";

export default function useDrivers() {
  const dispatch = useDispatch();

  const paginatedDrivers = useSelector(selectPaginatedDrivers);
  const loading = useSelector(selectDriversFetching);
  const query = useSelector(selectDriversStoreQuery)
  const isAddModalVisible = useSelector(selectDriversIsAddModalVisible)
  const isUpdateModalVisible = useSelector(selectDriversIsUpdateModalVisible)

  const fetchDrivers = useCallback(
    async function (query: any) {
      dispatch(DriverActions.fetchDrivers(query));
      dispatch(DriverActions.storeQuery(query))
    },
    [dispatch]
  );

  const changeAddModalVisibility = useCallback(
    async function (isAddModalVisible: boolean) {
      dispatch(DriverActions.changeAddModalVisibility(isAddModalVisible))
    },
    [dispatch]
  );

  const changeUpdateModalVisibility = useCallback(
    async function (isUpdateModalVisible: boolean) {
      dispatch(DriverActions.changeUpdateModalVisibility(isUpdateModalVisible))
    },
    [dispatch]
  );

  return {
    query,
    paginatedDrivers,
    loading,
    fetchDrivers,
    isAddModalVisible,
    changeAddModalVisibility,
    isUpdateModalVisible,
    changeUpdateModalVisibility,
  };
}
