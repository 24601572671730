import React, { useState, ReactNode, createContext, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { FILTER_TYPES, PRODUCT_STATUS_TYPES } from 'ui/pages/Inventory/interfaces';

interface InventoryPageFiltersContextData {
  productStatus: PRODUCT_STATUS_TYPES | undefined;
  setProductStatus: React.Dispatch<React.SetStateAction<PRODUCT_STATUS_TYPES>>;
  warehouseId: string | undefined;
  setWarehouseId: React.Dispatch<React.SetStateAction<string>>;
  filterType: FILTER_TYPES | undefined;
  setFilterType: React.Dispatch<React.SetStateAction<FILTER_TYPES>>;
  searchText: string | undefined;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  page: number | undefined;
  setPage: React.Dispatch<React.SetStateAction<number | undefined>>;
}
interface InventoryPageFiltersProviderProps {
  children: ReactNode;
}

export const InventoryPageFiltersContext = createContext({} as InventoryPageFiltersContextData);

const InventoryPageFiltersProvider = ({ children }: InventoryPageFiltersProviderProps) => {
  const [productStatus, setProductStatus] = useState<PRODUCT_STATUS_TYPES>();
  const [warehouseId, setWarehouseId] = useState<string>();
  const [filterType, setFilterType] = useState<FILTER_TYPES>(FILTER_TYPES.SKU);
  const [searchText, setSearchText] = useState<string>('');
  const [page, setPage] = useState<number>();

  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/" && !location.pathname.includes("inventory")){
      setProductStatus(undefined);
      setWarehouseId(undefined);
      setFilterType(FILTER_TYPES.SKU);
      setSearchText('');
      setPage(1);
    }
  }, [location])

  return (
    <InventoryPageFiltersContext.Provider
      value={{
        productStatus,
        setProductStatus,
        warehouseId,
        setWarehouseId,
        filterType,
        setFilterType,
        searchText,
        setSearchText,
        page,
        setPage,
      }}
    >
      {children}
    </InventoryPageFiltersContext.Provider>
  );
};

export default InventoryPageFiltersProvider;
