import { request } from 'util/request';

async function getProducts(params?: any) {
  return request('inventory/products', params, 'GET');
}

async function editProduct(productId: string, body?: any) {
  return request(`inventory/products/${productId}`, body, 'PATCH');
}

async function addProduct(body: any){
  return request('inventory/products', body, 'POST');
}

export async function getProductsCategories(params?: any) {
  return request('inventory/categories', params, 'GET');
}

export async function getProduct(id: string){
  return request(`inventory/products/${id}`);
}

export { getProducts, editProduct, addProduct }
