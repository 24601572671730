import React from 'react';

import { Select } from 'antd';

const { Option } = Select;

interface SelectProps {
  handleChange?: (value: string) => void;
  value: string;
  defaultValue?: string;
  options: any[];
  loading?: boolean,
  optionDisplayName: string,
}

const SelectComponent: React.FC<SelectProps> = (
  {
    defaultValue,
    options,
    handleChange,
    value,
    loading,
    optionDisplayName
  }) => {

  return (
    <Select
      loading={loading}
      defaultValue={defaultValue}
      style={{ width: 150 }}
      onChange={handleChange}
      value={value}
    >
      {options.map((item) => (
        <Option key={item.id} value={item.id}>{item[optionDisplayName]}</Option>
      ))}
    </Select>
  )
}

export default SelectComponent;
