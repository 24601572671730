import {
  createAction,
  createAsyncThunk,
  createReducer,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { notification } from 'antd';
import { getDrivers } from "services/Driver.service";
import { DriverType } from "ui/pages/Drivers/interfaces";

interface DriverPaginated {
  totalItems: number;
  currentPage: number;
  perPage: number;
  totalPages: number;
  results: [],
}

interface DriverSliceState {
  paginated?: DriverPaginated;
  fetching: boolean;
  currentQuery: string;
  isAddModalVisible: boolean;
  isUpdateModalVisible: boolean;
}

const initialState: DriverSliceState = {
  fetching: false,
  currentQuery: '',
  isAddModalVisible: false,
  isUpdateModalVisible: false,
  paginated: {
    totalItems: 0,
    currentPage: 0,
    perPage: 0,
    totalPages: 1,
    results: [],
  },
};

export const fetchDrivers = createAsyncThunk(
  "driver/fetchDrivers",
  async function (query: string) {
    try {
      const response = await getDrivers(query);
      if (response.error || response.status == "error") {
        throw new Error(response.message);
      } else {
        const formattedResponse = response.results?.map((driver: DriverType) => ({
          ...driver,
          addressLine1: driver.addressLine1 || '',
          city: driver.city || '',
          status: driver.status,
        }))
        response.results = formattedResponse;
        return response
      }
    } catch (err) {
      const error: any = err;
      notification['error']({
        message: 'Error fetching drivers!',
        description: error.message,
      });
    }
  }
);

export const storeQuery = createAction('driver/storeQuery', (payload: string) => ({ payload }));

export const changeAddModalVisibility = createAction('driver/changeAddModalVisibility', (payload: boolean) => ({ payload }));

export const changeUpdateModalVisibility = createAction('driver/changeUpdateModalVisibility', (payload: boolean) => ({ payload }));

export const driverReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(changeAddModalVisibility, (state, action) => {
      state.isAddModalVisible = action.payload;
    })
    .addCase(changeUpdateModalVisibility, (state, action) => {
      state.isUpdateModalVisible = action.payload;
    })
    .addCase(storeQuery, (state, action) => {
      state.currentQuery = action.payload;
    })
    .addCase(fetchDrivers.fulfilled, (state, action) => {
      state.paginated = action.payload;
    })
    .addMatcher(isPending, (state) => {
      state.fetching = true;
    })
    .addMatcher(isFulfilled, (state) => {
      state.fetching = false;
    })
    .addMatcher(isRejected, (state) => {
      state.fetching = false;
    });
});
