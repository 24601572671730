import React, { useContext, useState, useEffect } from 'react';

import { Button, Card, Row, Space, Spin, Typography } from 'antd';

import { LeftOutlined } from '@ant-design/icons';
import Steps from 'ui/components/Steps';
import DateAndSlotsSelect from '../DateAndSlotsSelect';
import { RoutesContext } from '../../contexts/RoutesContext';
import styles from './CreateRouteSteps.module.css';
import { DateAndSlotsProps, DriverShift } from '../../interfaces';
import AssignedLegsTable from '../Tables/AssignedLegsTable';
import ShiftsTable from '../Tables/ShiftsTable';
import OverviewTable from '../Tables/OverviewTable';

const { Title } = Typography;

const CreateRouteSteps: React.FC = () => {
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [selectedDateInfo, setSelectedDateInfo] = useState<DateAndSlotsProps>({} as DateAndSlotsProps);
  const [selectedSlots, setSelectedSlots] = useState<any>({});

  const {
    setDeliveryLegs,
    fetchUnassignedLegs,
    setCreateRouteStep,
    setAssignedLegs,
    setSelectedDriverShift,
    assignedLegs,
    createRouteStep,
    loadingCreateRoute,
    setSelectedRowKeys,
    selectedDriverShift,
    city,
    filteredTheLegs,
    unassignedLegs,
    setFilteredTheLegs,
    handleCreateRoute,
    setCheckedSlots,
  } = useContext(RoutesContext);

  useEffect(() => {
    switch (createRouteStep) {
      case 1:
        if(assignedLegs.length > 0) setButtonDisabled(false);
        else setButtonDisabled(true);

        break;

      case 2:
        if(Object.keys(selectedDriverShift).length > 0) setButtonDisabled(false);
        else setButtonDisabled(true);

      default:
        break;
    }
  }, [createRouteStep, assignedLegs, selectedDriverShift])

  useEffect(() => {
    if (createRouteStep === 0) {
      if ((unassignedLegs.length > 0 && filteredTheLegs) || assignedLegs.length > 0) setButtonDisabled(false);
      else setButtonDisabled(true);
    }
  }, [unassignedLegs, filteredTheLegs, createRouteStep]);

  const createRouteSteps = [
    {
      content: (
        <>
          <Title level={5} className={styles.title}>
            Select the date and the delivery slots of the legs to be assigned:
          </Title>
          <DateAndSlotsSelect
            setSelectedDateInfo={setSelectedDateInfo}
            selectedDateInfo={selectedDateInfo}
            selectedSlots={selectedSlots}
            setSelectedSlots={setSelectedSlots}
          />
        </>
      ),
    },
    {
      content: (
        <>
          <Row align="middle">
            <LeftOutlined className={styles.arrowIcon} onClick={() => setCreateRouteStep(createRouteStep - 1)} />
            <Title className={styles.title} level={5}>
              Sort legs
            </Title>
          </Row>
          <Card size="small">
            <AssignedLegsTable
              assignedLegs={assignedLegs}
              setAssignedLegs={setAssignedLegs}
              setSelectedRowKeys={setSelectedRowKeys}
            />
          </Card>
        </>
      ),
    },
    {
      content: (
        <>
          <Row align="middle">
            <LeftOutlined className={styles.arrowIcon} onClick={() => setCreateRouteStep(createRouteStep - 1)} />
            <Title className={styles.title} level={5}>
              Select a Shift:
            </Title>
          </Row>
          <ShiftsTable/>
        </>
      ),
    },
    {
      content: (
        <>
          <Row align="middle" className={styles.rowContainer}>
            <Row align="middle">
              <LeftOutlined
                className={styles.arrowIcon}
                onClick={() => !loadingCreateRoute && setCreateRouteStep(createRouteStep - 1)}
              />
              <Title className={styles.title} level={5}>
                Route overview
              </Title>
            </Row>
            <p className={styles.driverMessage}>This route was assigned to {selectedDriverShift?.driverFullName}</p>
          </Row>
          <OverviewTable assignedLegs={assignedLegs} />
        </>
      ),
    },
  ];

  function clearAllSteps() {
    setDeliveryLegs([]);
    setSelectedRowKeys([]);
    setAssignedLegs([]);
    setSelectedDriverShift({} as DriverShift);
    setCreateRouteStep(0);
    setFilteredTheLegs(false);
    setSelectedDateInfo({} as DateAndSlotsProps);
    setSelectedSlots({});
    setCheckedSlots([]);
    fetchUnassignedLegs();
  }

  useEffect(() => {
    clearAllSteps();
  }, [city]);

  return (
    <div className={styles.background}>
      <div className={styles.stepsContent}>{createRouteSteps[createRouteStep].content}</div>
      <Row align="middle" className={styles.stepAndButtonsContainer}>
        <Steps steps={createRouteSteps} currentStep={createRouteStep} />
        <Row align="middle" className={styles.rowContainer}>
          {createRouteStep > 0 && !loadingCreateRoute && (
            <Button className={styles.cancelButton} onClick={clearAllSteps}>
              Cancel
            </Button>
          )}
          {createRouteStep !== createRouteSteps.length - 1 && (
            <Button
              className={styles.button}
              disabled={buttonDisabled}
              onClick={() => setCreateRouteStep(createRouteStep + 1)}
            >
              Next
            </Button>
          )}
          {createRouteStep === createRouteSteps.length - 1 && (
            <>
              {loadingCreateRoute ? (
                <Row>
                  <Space>
                    <Spin />
                  </Space>
                </Row>
              ) : (
                <Button
                  className={styles.button}
                  disabled={loadingCreateRoute}
                  onClick={() => {
                    handleCreateRoute(
                      selectedDriverShift?.id,
                      assignedLegs,
                      setSelectedDateInfo,
                      setSelectedSlots,
                    );
                  }}
                >
                  Send
                </Button>
              )}
            </>
          )}
        </Row>
      </Row>
    </div>
  );
};

export default CreateRouteSteps;
