import React, { memo } from 'react';

import RoutesProvider from './contexts/RoutesContext';

import RoutesPage from './components/RoutesPage';
import EditRouteProvider from './contexts/EditRouteContext';

function Routes() {
  return (
    <RoutesProvider>
      <EditRouteProvider>
        <RoutesPage />
      </EditRouteProvider>
    </RoutesProvider>
  );
}

export default memo(Routes);
