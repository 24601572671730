import { configureStore } from "@reduxjs/toolkit";
import { deliveryWindowsReducer } from "./DeliveryWindow.slice";
import { orderReducer } from "./Order.slice";
import { warehouseReducer } from "./Warehouse.slice";
import { driverReducer } from "./Driver.slice";
import { zonesReducer } from "./Zone.slice";
import { customerReducer } from "./Customer.slice";
import { walletReducer } from "./Wallet.slice";
import { campaignReducer } from "./Campaign.slice";
import { inventoryReducer } from "./Inventory.slice";
import { pickingAppReducer } from "./PickingApp.slice";

const store = configureStore({
  reducer: {
    order: orderReducer,
    warehouse: warehouseReducer,
    driver: driverReducer,
    deliveryWindow: deliveryWindowsReducer,
    zone: zonesReducer,
    customer: customerReducer,
    wallet: walletReducer,
    campaign: campaignReducer,
    inventory: inventoryReducer,
    pickingApp: pickingAppReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
