import { request } from 'util/request';

export async function getWallets(params?: any) {
  return request('customer/wallets', params, 'GET');
}

//You can create a wallet and send a transaction at the same time
export async function createWallet(body: any) {
  return request('customer/wallets', body, 'POST');
}

export async function createWalletTransaction(walletId: string, body: any) {
  return request(`customer/wallet-transactions/${walletId}`, body, 'POST');
}
