import { request } from 'util/request';

async function getPickingUsers(params?: any) {
  return request(`inventory/picking-users`, params, 'GET');
}

async function addPickingUser(body: any) {
  return request(`inventory/picking-users`, body, 'POST');
}

async function editPickingUser(id: string, body: any) {
  return request(`inventory/picking-users/${id}`, body, 'PATCH');
}

export { getPickingUsers, addPickingUser, editPickingUser }
