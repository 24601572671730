import React, { useContext } from 'react';
import formatToDollar from 'util/formatToDollar';
import moment from 'moment';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Modal, Input, InputNumber, Select, DatePicker, Form, notification, Row, Col } from 'antd';

import styles from './AddDiscountModal.module.css';
import { MarketingContext } from '../../contexts/MarketingContext';

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

const AddDiscountModal: React.FC = () => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;
  const { createNewDiscount, isAddDiscountModalVisible, toggleAddDiscountModalVisibility } = useContext(
    MarketingContext,
  );

  function formatDiscountValue(type: string, discountValue: number) {
    if (type === 'PERCENTAGE') return discountValue;
    return Number(String(discountValue).replace('.', '').replace(',', ''));
  }

  function formatMinimumOrderValue(minimumOrderValue: number) {
    return Number(String(minimumOrderValue).replace('.', '').replace(',', ''));
  }

  const handleOkAddModal = () => {
    form.validateFields().then(async (values) => {
      const expirationDate = moment(values.expiration).valueOf();
      const discountValue = formatDiscountValue(values.type, values.value);
      const minimumOrderValue = formatMinimumOrderValue(values.mov);
      const result = await createNewDiscount({
        ...values,
        expirationDate,
        discountValue,
        minimumOrderValue,
      }); // the endpoint to create a discount
      if (result !== 'error') {
        form.resetFields();
        toggleAddDiscountModalVisibility();
      }
    });
  };

  const handleCancelAddDiscount = () => {
    form.resetFields();
    toggleAddDiscountModalVisibility();
  };

  return (
    <Modal
      okText="Add discount"
      cancelText="Cancel"
      visible={isAddDiscountModalVisible}
      onCancel={handleCancelAddDiscount}
      onOk={handleOkAddModal}
    >
      <div className={styles.titleContainer}>
        <PlusCircleOutlined className={styles.plusIcon} />
        <h1 className={styles.title}>Add discount</h1>
      </div>
      <Form style={{ display: 'flex' }} form={form}>
        <div className={styles.modalContent}>
          <div className={styles.fieldCard}>
            <strong>Name</strong>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input the Name!',
                },
              ]}
            >
              <Input className={styles.largeInput} placeholder="Name" />
            </Form.Item>
          </div>
          <div className={styles.fieldCard}>
            <strong>Description</strong>
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: 'Please input the Description!',
                },
              ]}
            >
              <TextArea rows={3} className={styles.largeInput} placeholder="Description" />
            </Form.Item>
          </div>

          <Row className={styles.row}>
            <Col xs={24} sm={24} md={11} lg={11} >
              <strong>Audience</strong>
              <Form.Item
                name="audience"
                rules={[
                  {
                    required: true,
                    message: 'Please select an audience!',
                  },
                ]}
              >
                <Select placeholder="Audience">
                  <Option value="OPEN">All users</Option>
                  <Option value="ACQUISITION">New users only</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={11} lg={11}>
              <strong>Type</strong>
              <Form.Item
                name="type"
                rules={[
                  {
                    required: true,
                    message: 'Please select a type!',
                  },
                ]}
              >
                <Select placeholder="Type">
                  <Option value="VALUE_OFF">VALUE_OFF</Option>
                  <Option value="PERCENTAGE">PERCENTAGE</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row className={styles.row}>
            <Col xs={24} sm={24} md={7} lg={7} xl={7}>
              <strong>Value</strong>
              <Form.Item shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}>
                {() => (
                  <Form.Item
                    name="value"
                    rules={[
                      {
                        required: true,
                        message: 'Please input the Discount Value!',
                      },
                    ]}
                  >
                    {form.getFieldValue('type') === 'PERCENTAGE' ? (
                      <InputNumber
                        style={{width: '100%'}}
                        placeholder="100%"
                        formatter={(value) => `${value}%`}
                        min={1}
                        max={100}
                      />
                    ) : (
                      <InputNumber
                        style={{width: '100%'}}
                        placeholder="1,000.00"
                        min={1}
                        formatter={(value: any) => `$ ${formatToDollar(value)}`}
                        parser={(value) => Number(value?.replace(/\D/g, ''))}
                      />
                    )}
                  </Form.Item>
                )}
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={7} lg={7} xl={7}>
              <strong>Expiration</strong>
              <Form.Item
                name="expiration"
                rules={[
                  {
                    required: true,
                    message: 'Please select a Expiration Date!',
                  },
                ]}
              >
                <DatePicker format={dateFormatList} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={7} lg={7} xl={7}>
              <strong>Status</strong>
              <Form.Item
                name="status"
                rules={[
                  {
                    required: true,
                    message: 'Please select a status!',
                  },
                ]}
              >
                <Select placeholder="Status">
                  <Option value="ACTIVE">ACTIVE</Option>
                  <Option value="INACTIVE">INACTIVE</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row className={styles.row}>
            <Col xs={24} sm={24} md={7} lg={7} xl={7}>
              <strong>MOV</strong>
              <Form.Item
                name="mov"
                rules={[
                  {
                    required: true,
                    message: 'Please input Minimum Order Value!',
                  },
                ]}
              >
                <InputNumber
                  className={styles.largeInput}
                  placeholder="1,000.00"
                  min={1}
                  formatter={(value: any) => `$ ${formatToDollar(value)}`}
                  parser={(value) => Number(value?.replace(/\D/g, ''))}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={7} lg={7} xl={7}>
              <strong>Max Client #</strong>
              <Form.Item
                name="maxAllowedUsages"
                rules={[
                  {
                    required: true,
                    message: 'Please input Max Clients!',
                  },
                ]}
              >
                <InputNumber className={styles.largeInput} placeholder="1" min={1} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={7} lg={7} xl={7}></Col>
          </Row>
          </div>
        </Form>
    </Modal>
  );
};

export default AddDiscountModal;
