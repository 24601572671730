import {
  createAction,
  createAsyncThunk,
  createReducer,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { notification } from 'antd';

import { RootState } from '.';
import {
  addWindow,
  deleteWindow,
  editDeliveryWindow,
  EditWindowParams,
  getDeliveryWindows,
  GetDeliveryWindowsParams
} from "services/DeliveryWindow.service";
import moment from "moment";
import { DEFAULT_TIMEZONE, getUnixTimeByTimezone, unixDateToTimeZone } from "util/dateAndTime";
import { getOrders } from "services/Order.service";
import { Order } from "ui/pages/Orders/interfaces";

export interface DeliveryWindow {
  id: string,
  startTime: string,
  endTime: string,
  locationId: string,
  zoneId: string,
  totalCapacity: number,
  filledCapacity: number,
  price: number,
  available: boolean,
  asap: boolean
  windowOrders?: Order[]
}

export interface DeliveryWindowResponse {
  date: string,
  windows: DeliveryWindow[]
}
interface DeliveryWindowSliceState {
  results: DeliveryWindowResponse[];
  windowOrders: Order[];
  selectedZone: string;
  selectedDate: number;
  fetching: boolean;
  fetchingOrders: boolean;
  currentQuery: GetDeliveryWindowsParams;
  isEditWindowModalVisible: boolean;
  isAddWindowModalVisible: boolean;
  isWindowsVisualizationModalVisible: boolean;
}

const initialState: DeliveryWindowSliceState = {
  fetching: false,
  fetchingOrders: false,
  selectedZone: '',
  selectedDate: getUnixTimeByTimezone(moment().valueOf(), 5, 0, DEFAULT_TIMEZONE),
  currentQuery: {} as GetDeliveryWindowsParams,
  isWindowsVisualizationModalVisible: false,
  isEditWindowModalVisible: false,
  isAddWindowModalVisible: false,
  windowOrders: [],
  results: [],
};

export const fetchDeliveryWindowsByDate = createAsyncThunk(
  "deliveryWindow/fetchDeliveryWindows",
  async function (query: GetDeliveryWindowsParams) {

    try {
      const response = await getDeliveryWindows(query);

      if (response.error) throw new Error(response.message);

      return response;

    } catch (err) {
      const error: any = err;
      notification.error({
        message: 'Error fetching delivery windows!',
        description: error.message,
      });
      return error.message
    }
  }
);


export const addDeliveryWindow = createAsyncThunk(
  "deliveryWindow/addDeliveryWindow",
  async function (params: any, { getState, dispatch }) {
    const {
      deliveryWindow: { selectedDate, selectedZone },
    } = getState() as RootState;

    try {
      const response = await addWindow(params.query);

      if (response.status === 'error') throw new Error(response.message);

      notification.success({
        message: 'Success!',
        description: 'The window has been successfully added.',
      });

      dispatch(fetchDeliveryWindowsByDate({ startTime: selectedDate, zoneId: selectedZone }));
      dispatch(toggleAddDeliveryWindowModal());
      dispatch(toggleVisualizeDeliveryWindowsModal());
      params.form.resetFields();

    } catch (err) {
      const error: any = err;
      notification.error({
        message: 'Error adding new window!',
        description: error.message,
      });
      return error.message;
    }
  }
);

export const updateDeliveryWindow = createAsyncThunk(
  "deliveryWindow/updateDeliveryWindow",
  async function (params: EditWindowParams, { getState, dispatch }) {
    const {
      deliveryWindow: { selectedDate, selectedZone },
    } = getState() as RootState;
    try {
      const response = await editDeliveryWindow(params);

      if (response.status === 'error') throw new Error(response.message);

      params.form.resetFields();
      dispatch(toggleEditDeliveryWindowModal());
      dispatch(toggleVisualizeDeliveryWindowsModal());
      dispatch(fetchDeliveryWindowsByDate({ startTime: selectedDate, zoneId: selectedZone }))

      notification.success({
        message: 'Success!',
        description: 'The delivery window was updated.',
      });
    } catch (err) {
      const error: any = err;
      notification.error({
        message: 'Error!',
        description: error.message,
      });
      return error.message;
    }
  }
);

export const deleteDeliveryWindow = createAsyncThunk(
  "deliveryWindow/deleteDeliveryWindow",
  async function (id: string, { dispatch, getState }) {
    const {
      deliveryWindow: { selectedDate, selectedZone },
    } = getState() as RootState;

    try {

      const response = await deleteWindow(id);

      if (response.status === 'error') throw new Error(response.message);

      dispatch(toggleVisualizeDeliveryWindowsModal());
      dispatch(fetchDeliveryWindowsByDate({ startTime: selectedDate, zoneId: selectedZone }))

    } catch (err) {
      const error: any = err;
      notification.error({
        message: 'Error deleting window!',
        description: error.message,
      });
    }
  }
)

export const fetchOrdersByDeliveryWindowId = createAsyncThunk(
  "deliveryWindow/fetchOrdersByWindowId",
  async function (query: string) {

    try {
      const response = await getOrders(query);
      return response.results;

    } catch (err) {
      const error: any = err;
      notification.error({
        message: 'Error editing order status!',
        description: error.message,
      });
      return error.message;
    }
  }
);

export const storeQuery = createAction('deliveryWindow/storeQuery', (payload: GetDeliveryWindowsParams) => ({ payload }));
export const storeZone = createAction('deliveryWindow/storeZone', (payload: string) => ({ payload }));
export const storeDate = createAction('deliveryWindow/storeDate', (payload: number) => ({ payload }));
export const toggleVisualizeDeliveryWindowsModal = createAction('deliveryWindow/toggleVisualizeDeliveryWindowsModal');
export const toggleEditDeliveryWindowModal = createAction('deliveryWindow/toggleEditDeliveryWindowModal');
export const toggleAddDeliveryWindowModal = createAction('deliveryWindow/toggleAddDeliveryWindowModal');

export const deliveryWindowsReducer = createReducer(initialState, (builder) => {

  const success = isFulfilled(fetchDeliveryWindowsByDate, addDeliveryWindow);
  const error = isRejected(fetchDeliveryWindowsByDate, addDeliveryWindow);
  const loading = isPending(fetchDeliveryWindowsByDate, addDeliveryWindow);

  const successOrders = isFulfilled(fetchOrdersByDeliveryWindowId);
  const errorOrders = isRejected(fetchOrdersByDeliveryWindowId);
  const loadingOrders = isPending(fetchOrdersByDeliveryWindowId);

  builder

    .addCase(toggleVisualizeDeliveryWindowsModal, (state) => {
      state.isWindowsVisualizationModalVisible = !state.isWindowsVisualizationModalVisible;
    })
    .addCase(toggleEditDeliveryWindowModal, (state) => {
      state.isEditWindowModalVisible = !state.isEditWindowModalVisible;
    })
    .addCase(toggleAddDeliveryWindowModal, (state) => {
      state.isAddWindowModalVisible = !state.isAddWindowModalVisible;
    })
    .addCase(storeQuery, (state, action) => {
      state.currentQuery = action.payload;
    })
    .addCase(storeZone, (state, action) => {
      state.selectedZone = action.payload;
    })
    .addCase(storeDate, (state, action) => {
      state.selectedDate = action.payload;
    })
    .addCase(fetchDeliveryWindowsByDate.fulfilled, (state, action) => {
      state.results = action.payload;
    })
    .addCase(fetchOrdersByDeliveryWindowId.fulfilled, (state, action) => {
      state.windowOrders = action.payload;
    })
    .addMatcher(loading, (state) => {
      state.fetching = true;
    })
    .addMatcher(success, (state) => {
      state.fetching = false;
    })
    .addMatcher(error, (state) => {
      state.fetching = false;
    })
    .addMatcher(loadingOrders, (state) => {
      state.fetchingOrders = true;
    })
    .addMatcher(successOrders, (state) => {
      state.fetchingOrders = false;
    })
    .addMatcher(errorOrders, (state) => {
      state.fetchingOrders = false;
    });
});
