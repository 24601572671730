import React, { useState, ReactNode, createContext, useEffect } from 'react';

import { isAuthenticated } from 'util/auth';


interface AuthContextData {
  authenticated: boolean;
  setAuthenticated: (value: boolean) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
}
interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext({} as AuthContextData);

const AuthProvider = ({ children, ...rest }: AuthProviderProps) => {
  const [authenticated, setAuthenticated] = useState<boolean>(isAuthenticated());
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        setAuthenticated,
        loading,
        setLoading
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
