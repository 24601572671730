import React, { memo, useEffect, useState } from 'react';
import { Select, notification } from 'antd';
import { getProducts } from './services/api';

const { Option } = Select;

interface ProductsSelectProps {
  onChange?: any;
  value?: any;
  disabled?: boolean;
}

function ProductsSelect({ onChange, value, disabled }: ProductsSelectProps) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);

  const loadData = async (params?: any) => {
    setLoading(true);
    try {
      const firstResponse = await getProducts({ ...params, perPage: 1 });

      if (firstResponse.error) throw new Error(firstResponse.message);

      const productsTotal = firstResponse.totalItems;

      const response = await getProducts({ ...params, perPage: productsTotal });

      if (response.error) throw new Error(response.message);

      setData(response.results);
    } catch (err) {
      const error: any = err;

      notification.error({
        message: 'Error fetching products!',
        description: error.message,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [value]);

  return (
    <Select
      showSearch
      style={{ width: '90%', marginLeft: '0.8rem' }}
      optionFilterProp="children"
      onChange={onChange}
      filterOption={(input, option: any | null) =>
        option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
      }
      loading={loading}
      value={value}
      disabled={disabled}
    >
      {data.map((item) => (
        <Option key={item.id} value={item.id} product={item}>
          {item.name}
        </Option>
      ))}
    </Select>
  );
}

export default memo(ProductsSelect);
