import React, { useContext, useEffect } from 'react';

import { Route } from 'react-router-dom';
import { Spin } from 'antd';

import OAuth2RedirectHandler from 'OAuth2RedirectHandler';
import { AuthContext } from 'contexts/AuthContext';
import Login from 'ui/pages/Login';
import Navigation from 'ui/pages/Navigation';
import styles from './routes.module.css';
import { logout } from 'util/auth';

const AppRoutes = () => {
  const { authenticated, loading } = useContext(AuthContext)

  useEffect(() => {
    if (!authenticated) logout();
  }, [])

  if (loading){
    return (
      <div className={styles.container}>
        <Spin size="large"/>
      </div>
    )
  } else {

    return (
      <>
        {authenticated ? (
            <Navigation/>
          ) : (
              <Route path={["/login", "/"]} component={Login}/>
          )}
          <Route
            path="/oauth2/redirect"
            component={OAuth2RedirectHandler}
          />
      </>

    )
  }
}

export default AppRoutes;
