import React, { useContext } from 'react';

import { Col, Row } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';

import DateBox from '../DateBox';
import Filters from '../Filters';
import styles from './DateAndFilters.module.css';
import { DriverShiftsContext } from '../../contexts/DriverShiftsContext';

const DateAndFilters: React.FC = () => {
  const { setSelectedDriverId, selectedDriverId, fetchDrivers, fetchShifts } = useContext(DriverShiftsContext);

  const handleClearFilters = () => {
    setSelectedDriverId(undefined);
    fetchDrivers();
    fetchShifts();
  };

  return (
    <Row align="middle" className={styles.rowContainer}>
      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
        <DateBox />
      </Col>
      <Col className={styles.filtersContainer} xl={12} lg={12} md={12} sm={24} xs={24}>
        <Filters />
        <CloseCircleFilled
          onClick={handleClearFilters}
          className={styles.clearIcon}
          style={{ visibility: selectedDriverId ? 'visible' : 'hidden' }}
        />
      </Col>
    </Row>
  );
};

export default DateAndFilters;
