import React, { memo } from 'react';

import MarketingProvider from './contexts/MarketingContext';

import MarketingPage from './components/MarketingPage';

function Marketing() {
  return (
    <MarketingProvider>
      <MarketingPage />
    </MarketingProvider>
  );
}

export default memo(Marketing);
