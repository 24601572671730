import { DeliveryWindow } from 'store/DeliveryWindow.slice';
import { request } from 'util/request';

export interface GetDeliveryWindowsParams {
  zoneId?: string,
  startTime?: number,
  endTime?: number,
}

export type EditWindowParams = {
  id: string;
  body: DeliveryWindow;
  form?: any;
};


export async function getDeliveryWindows(params?: GetDeliveryWindowsParams): Promise<any> {
  return request('schedule/delivery-windows', params, 'GET');
}

export async function addWindow(params: DeliveryWindow): Promise<any> {
  return request('schedule/delivery-windows', params, 'POST');
}

export async function editDeliveryWindow(params: EditWindowParams): Promise<any> {
  const { id, body } = params;
  return request(`schedule/delivery-windows/${id}`, body, 'PATCH');
}

export async function deleteWindow(windowId: string) {
  return request('schedule/delivery-windows/', windowId, 'DELETE');
}

export async function getOrders(params?: any) {
  return request(`order/orders?${params}`);
}

