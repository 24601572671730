import { request } from 'util/request';

export async function getDrivers(params?: any) {
  return request('driver/drivers', params, 'GET');
}

export async function getDriverById(driverId?: string) {
  return request(`driver/drivers/${driverId}`);
}

export async function createDriver(params: any) {
  return request('driver/drivers', params, 'POST');
}

export async function updateDriver(params: any) {
  const { id, body } = params;
  return request(`driver/drivers/${id}`, body, 'PATCH');
}
