import { request } from 'util/request';

export async function getCustomers(params?: any) {
  return request('customer/customers', params, 'GET');
}

export async function getCustomerOrders(params?: any) {
  return request('order/orders', params, 'GET');
}

export async function updateCustomerOrderStatus(params: any) {
  const { id, body } = params;
  return request(`order/orders/${id}/status`, body, 'PATCH');
}

export async function getCustomerCoupon(couponName: string) {
  const params = { name: couponName };
  return request('coupon/coupons', params, 'GET');
}
