import React, { useContext, useState, useEffect } from 'react';

import { Redirect, useLocation } from 'react-router-dom';

import { OAUTH2_REDIRECT_URI } from './constants/urls';
import { getAccessToken, logout, authenticate } from './util/auth';
import { AuthContext } from 'contexts/AuthContext';

const OAuth2RedirectHandler = () => {
    const [error, setError] = useState(null);

    const location = useLocation();
    const { setAuthenticated, setLoading } = useContext(AuthContext)

    function getUrlParameter(name) {
      const currentSearchParams = new URLSearchParams(location.search);
      return currentSearchParams.get(name);
    };

    const code = getUrlParameter('code');
    const token = getAccessToken().token;

    const handleRender = async () => {
      if (code && !token) {
        setLoading(true);
        try{
          await authenticate(code, OAUTH2_REDIRECT_URI);
          setAuthenticated(true);
        } catch (err) {
          setError(err);
          setAuthenticated(false);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
        setError('No code was found');
        logout();
      }
    }

    useEffect(() => {
      handleRender();
    }, [])

    return (
      <div>
        { error ? (
          <Redirect to={{
            pathname: '/login',
            state: { from: location, error: error }}}
          />
        ) : (
          <Redirect
          to={{
            pathname: '/',
            state: { from: location, authenticated: true }}}
          />
        )}
      </div>
    )
}

export default OAuth2RedirectHandler;
