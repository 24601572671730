import { Collapse } from 'antd';
import React from 'react'
import { LegType } from '../../interfaces';
import styles from './LegPanel.module.css';

function LegPanel(props: any) {
  const { Panel } = Collapse;
  return (
    <Panel header={`- ${props?.panelTitle}: ${props.legs?.filter((leg: any) =>
      leg.latitude === props.warehouse?.latitude &&
      leg.longitude === props.warehouse?.longitude).length}`} key="1">
      <ul className={styles.collapseList}>
        {props.legs?.map((leg: LegType) => {
          return (
            <li key={leg.id} className={styles.collapseListItem}>
              {(leg.latitude === props.warehouse?.latitude &&
                leg.longitude === props.warehouse?.longitude) ?
                `- ${leg.orderId}` :
                null}
            </li>
          )
        })}
      </ul>
    </Panel>
  )
}

export default LegPanel;
