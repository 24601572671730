import React from 'react';

import { Page, Text, View, Document, Image } from '@react-pdf/renderer';

import LogoGreen from '../../../../../img/logoGreen.png';
import CADValues from './components/CADValues';
import InvoiceDetails from './components/InvoiceDetails';
import { formatDate } from './utils';
import { styles } from './styles';

interface InvoiceProps {
  order: any;
  customer: any;
}

const Invoice: React.FC<InvoiceProps> = ({ order, customer }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Image src={LogoGreen} style={styles.logo} />
          <InvoiceDetails order={order} customer={customer} />
        </View>
        <CADValues order={order} />
        <View style={styles.section}>
          <Text style={styles.boldText}>Due Date: {formatDate(order?.estimatedDeliveryTime)}</Text>
        </View>
      </Page>
      {/* <PaymentAdvice order={order} /> */}
    </Document>
  );
};

export default Invoice;
