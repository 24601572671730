import React, { useState, ReactNode, createContext } from 'react';
import { notification } from 'antd';
import moment from 'moment'
import { getCoupons, addDiscount, editDiscount } from '../services/api';
interface MarketingContextData {
  isAddDiscountModalVisible: boolean;
  toggleAddDiscountModalVisibility: () => void;
  record: any;
  createNewDiscount: (data: any) => Promise<any>;
  updateDiscount: (data: any) => Promise<void>;
  loadingEditCoupon: boolean;
  loading: boolean;
  fetchCoupons: (params?: any) => void;
  coupons: any[];
  couponsPerPage: number;
  totalOfCoupons: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}
interface MarketingProviderProps {
  children: ReactNode;
}

export const MarketingContext = createContext({} as MarketingContextData);

const MarketingProvider = ({ children, ...rest }: MarketingProviderProps) => {
  const [isAddDiscountModalVisible, setIsAddDiscountModalVisible] = useState(
    false
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingEditCoupon, setLoadingEditCoupon] = useState<boolean>(false);
  const [record, setRecord] = useState([]);
  const [coupons, setCoupons] = useState<any>([]);
  const [couponsPerPage, setCouponsPerPage] = useState<number>(0);
  const [totalOfCoupons, setTotalOfCoupons] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const fetchCoupons = async (params?: any) => {
    setLoading(true);

    try {
      const response = await getCoupons(params);

      setTotalOfCoupons(response.totalItems);
      setCouponsPerPage(response.perPage);

      if (response.error || response.status == "error") {
        throw new Error(response.message);
      } else {
        const formattedResponse = response.results?.map((coupon: any, index: number) => ({
          ...coupon,
          expirationDateFormatted: coupon.expirationDate ? moment(coupon.expirationDate).format('DD/MM/YY') : '',
          updatedAtFormatted: coupon.updatedAt ? moment(coupon.updatedAt).format('DD/MM/YY') : '',
          discountValueFormatted: coupon.discountValue ? coupon.discountValue : '',
          minimumOrderValueFormatted: coupon.minimumOrderValue ? coupon.minimumOrderValue : '',
        }))

        setCoupons(formattedResponse);
      }
    } catch (err) {
      const error: any = err;
      notification['error']({
        message: 'Error fetching coupons!',
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  }

  const updateDiscount = async (data: any) => {
    const { id } = data

    setLoadingEditCoupon(true);

    try {
      if (id) {
        const response = await editDiscount(data);

        if (response.error || response.status == "error") {
          throw new Error(response.message);
        } else {
          notification['success']({
            message: 'Success editing coupon!',
            description: `Coupon ${id} was updated.`,
          });
          fetchCoupons({ page: currentPage });
        }
      } else {
        notification['error']({
          message: 'Error editing coupon!',
          description: "Coupon ID is undefined.",
        });
      }

    } catch (err) {
      const error: any = err;
      notification['error']({
        message: 'Error editing coupon!',
        description: error.message,
      });
    } finally {
      setLoadingEditCoupon(false);
    }
  }

  const createNewDiscount = async (data: any) => {
    setLoadingEditCoupon(true);

    try {
      const response = await addDiscount(data);

      if (response.error || response.status == "error") {
        const errorStringified = (JSON.stringify({ status: response.status, message: response.message }));
        throw new Error(errorStringified);
      } else {
        notification['success']({
          message: 'Success adding coupon!',
          description: `A new coupon was added.`,
        });
        fetchCoupons();
      }

    } catch (err) {
      const error: any = err;
      const status = JSON.parse(error.message).status
      const errorMessage = JSON.parse(error.message).message
      notification['error']({
        message: 'Error adding coupon!',
        description: errorMessage
      });
      return status
    } finally {
      setLoadingEditCoupon(false);
    }
  }


  const toggleAddDiscountModalVisibility = () => {
    setIsAddDiscountModalVisible(!isAddDiscountModalVisible);
  };

  return (
    <MarketingContext.Provider
      value={{
        isAddDiscountModalVisible,
        toggleAddDiscountModalVisibility,
        record,
        createNewDiscount,
        updateDiscount,
        fetchCoupons,
        coupons,
        couponsPerPage,
        totalOfCoupons,
        setCurrentPage,
        currentPage,
        loading,
        loadingEditCoupon
      }}
    >
      {children}
    </MarketingContext.Provider>
  );
};

export default MarketingProvider;
