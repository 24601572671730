/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { Spin } from 'antd';
import { LeftOutlined, RightOutlined, EditOutlined } from '@ant-design/icons';

import { MODAL_TYPES } from './interfaces';
import { DEFAULT_TIMEZONE, getUnixTimeByTimezone } from 'util/dateAndTime';
import ZoneSelect from 'ui/components/ZoneSelect';
import Charts from './components/Charts';
import WindowsModal from './components/WindowsModal/WindowsModal';
import useDeliveryWindows from 'hooks/useDeliveryWindows';
import styles from './DeliveryWindows.module.css';
import { extractFirstDeliveryWindow } from './utils';
import { DeliveryWindowResponse } from 'store/DeliveryWindow.slice';

function DeliveryWindowsPage() {
  const [currentDateWindows, setCurrentDateWindows] = useState<DeliveryWindowResponse>({} as DeliveryWindowResponse);

  const {
    fetchDeliveryWindows,
    changeModalVisibility,
    fetchOrdersByDeliveryWindowId,
    deliveryWindows,
    selectedDate,
    selectedZone,
    changeDate,
    changeZone,
    loading,
    loadingOrders
  } = useDeliveryWindows();

  const handleChangeDate = (way: string) => {
    if (way === 'next') {
      const nextDay = moment(selectedDate).add(1, 'days').format();
      const nextDayWithTimezone = getUnixTimeByTimezone(nextDay, 5, 0, DEFAULT_TIMEZONE);
      changeDate(nextDayWithTimezone);
    } else {
      const previousDay = moment(selectedDate).subtract(1, 'days').format();
      const previousDayWithTimezone = getUnixTimeByTimezone(previousDay, 5, 0, DEFAULT_TIMEZONE);
      changeDate(previousDayWithTimezone);
    }
  }

  const handleChangeZone = (zoneId: string) => {
    changeZone(zoneId);
  };

  useEffect(() => {
    if (selectedZone){
      const today = moment().valueOf();
      const startTime = getUnixTimeByTimezone(selectedDate || today, 5, 0, DEFAULT_TIMEZONE);
      const endTime = getUnixTimeByTimezone(selectedDate || today, 23, 40, DEFAULT_TIMEZONE);
      const query = { startTime, endTime, zoneId: selectedZone};
      fetchDeliveryWindows(query);
    }
  }, [selectedDate, selectedZone]);

  useEffect(() => {
    if (deliveryWindows) {
      const dateWindows = extractFirstDeliveryWindow(deliveryWindows);
      setCurrentDateWindows(dateWindows);

      const windowsIds = dateWindows?.windows?.map((item: any) => item?.id);
      const windowsQuery = `${windowsIds?.map((item: any) => `deliveryWindowId=${item}`).join('&')}`;

      fetchOrdersByDeliveryWindowId(windowsQuery);
    }

  }, [deliveryWindows]);

  return (
    <div className={styles.container}>
      <div className={styles.dateContainer}>
        <LeftOutlined className={styles.arrowIcon} onClick={() => handleChangeDate('previous')} />
        <div className={styles.dateCard}>
          <p className={styles.monthYearText}>{`${moment(selectedDate).format('MMM')}, ${moment(selectedDate).format(
            'YYYY',
          )}`}</p>
          <div className={styles.dayAndWeekContainer}>
            <p className={styles.dayText}>{moment(selectedDate).date()}</p>
            <div className={styles.weekCard}>
              <p className={styles.th}>{moment(selectedDate).format('Do').substr(-2)}</p>
              <p className={styles.weekText}>{moment(selectedDate).format('dddd')}</p>
            </div>
          </div>
        </div>
        <RightOutlined className={styles.arrowIcon} onClick={() => handleChangeDate('next')} />
      </div>
      <div className={styles.selectWarehouseContainer}>
        <div className={styles.selectWarehouseCard}>
          <p className={styles.selectWarehouseTitle}>Select a Zone:</p>
          <ZoneSelect onChange={handleChangeZone} />
        </div>
        <div
          onClick={() => changeModalVisibility(MODAL_TYPES.showDeliveryWindows)}
          className={styles.editWindowContainer}
        >
          <p className={styles.editWindowTitle}>Edit Windows</p>
          <div className={styles.editWindowIconCard}>
            <EditOutlined className={styles.editWindowIcon} />
          </div>
        </div>
      </div>
      {loading || loadingOrders ? (
        <div className={styles.spinContainer}>
          <Spin size="large" />
        </div>
      ) : (
        <Charts currentDateWindows={currentDateWindows}/>
      )}
      <WindowsModal currentDateWindows={currentDateWindows} />
    </div>
  );
}

export default DeliveryWindowsPage;
