import React, { useState, useEffect, useContext } from 'react';
import { Card, Table, Row, Col, Input, Button, Select } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { DriverType, DRIVER_STATUS } from './interfaces'
import styles from './DriversPage.module.css';
import { SearchOutlined } from '@ant-design/icons';
import { MaskedInput } from 'antd-mask-input';
import useDrivers from 'hooks/useDrivers';
import AddDriverModal from './components/AddDriverModal';
import UpdateDriverModal from './components/UpdateDriverModal';

const { Option } = Select

function Vehicles() {
  const { loading,
    fetchDrivers,
    paginatedDrivers,
    isAddModalVisible,
    changeAddModalVisibility,
    isUpdateModalVisible,
    changeUpdateModalVisibility } = useDrivers();

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [clickedDriver, setClickedDriver] = useState<any>(null);

  const openAddDriverModal = () => {
    changeAddModalVisibility(!isAddModalVisible);
  }

  const openUpdateDriverModal = (record: DriverType) => {
    setClickedDriver(record)
    changeUpdateModalVisibility(!isUpdateModalVisible);
  }

  useEffect(() => {
    handleSearch();
  }, []);

  const handleSearch = async (page?: number) => {
    const params: any = {};
    if (page) params.page = page;

    if (phoneNumber) params.phoneNumber = phoneNumber;
    if (firstName) params.firstName = firstName;
    if (email) params.email = email;
    if (status) params.status = status;
    if (city) params.city = city;
    fetchDrivers(params);
  }

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Address',
      dataIndex: 'addressLine1',
      key: 'addressLine1',
    },
    {
      title: 'Alcohol',
      dataIndex: 'alcoholDeliveryAllowed',
      key: 'alcoholDeliveryAllowed',
      render: (value: string) => {
        return <p>{value ? 'True' : 'False'}</p>;
      },
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'cit',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Edit',
      dataIndex: '',
      key: 'x',
      render: (record: DriverType) => {
        return <EditOutlined onClick={() => openUpdateDriverModal(record)} />;
      },
    },
  ];

  return (
    <>
      <div className={styles.cardContainer}>
        <div className={styles.compoundFilter}>
          <Input
            className={styles.input}
            allowClear
            placeholder="Driver Name"
            onChange={(e) => setFirstName(e.target.value.toLowerCase())}
          />
          <MaskedInput
            onChange={(e) => setPhoneNumber(e.target.value)}
            mask="+1 (111) 111-1111"
            name="phoneNumber"
            placeholder="Phone Number"
          />
          <Input
            className={styles.input}
            allowClear
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
          />
          <Input
            className={styles.input}
            allowClear
            placeholder="City"
            onChange={(e) => setCity(e.target.value.toLowerCase())}
          />
          <Select className={styles.input}
            allowClear
            placeholder="Status"
            onChange={(status: any) => setStatus(status)}>
            <Option value={DRIVER_STATUS.ACTIVE}>{DRIVER_STATUS.ACTIVE}</Option>
            <Option value={DRIVER_STATUS.INACTIVE}>{DRIVER_STATUS.INACTIVE}</Option>
          </Select>
          <Button
            onClick={() => handleSearch()}
            type="primary" icon={<SearchOutlined />}>
            Search
          </Button>
        </div>
        <Row className={styles.addButtonRow}>
          <Button
            disabled={loading}
            onClick={() => openAddDriverModal()}
            className={styles.addButton}
          >
            + Add Driver
          </Button>
        </Row>
        <Table
          loading={loading}
          rowKey={(record: DriverType) => record.id}
          className={styles.table}
          dataSource={paginatedDrivers?.results}
          columns={columns}
          scroll={{ x: 1270 }}
          pagination={{
            onChange: (page: number) => {
              handleSearch(page);
            },
            total: paginatedDrivers?.totalItems,
            pageSize: 10,
            showSizeChanger: false,
            current: paginatedDrivers?.currentPage,
          }}
        />
      </div>
      <AddDriverModal />
      <UpdateDriverModal clickedDriver={clickedDriver} />
    </>
  );
}

export default Vehicles;
