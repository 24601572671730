import { ACCESS_TOKEN, REFRESH_TOKEN, TOKEN_INVALIDATES_IN } from '../constants/tokens';
import { GENERATE_TOKENS_URL } from '../constants/urls';

export const getAccessToken = () => JSON.parse(localStorage.getItem(ACCESS_TOKEN) || '{}');
export const getRefreshToken = () => JSON.parse(localStorage.getItem(REFRESH_TOKEN) || '{}');
export const getTokenInvalidationTime = () => JSON.parse(localStorage.getItem(TOKEN_INVALIDATES_IN) || '{}');
const THRESHOLD_TO_REFRESH_TOKEN_IN_MILLIS = 3480000;//58 minutes

export const isAuthenticated = () => {

    const tokenInvalidatesIn = getTokenInvalidationTime();
    const now = new Date();

    if (tokenInvalidatesIn && tokenInvalidatesIn > now.getTime()) {
        return true;
    }

    return false;
}

export const tokenExpired = () => {
  const accessToken = getAccessToken();
  const now = new Date();

  if (accessToken && accessToken.expiresIn < now.getTime()) {
      return true;
  }

  return false;
}

export async function authenticate(code?: string, redirectURI?: string){

  const data: any = {
    grant_type: code ? 'authorization_code' : 'refresh_token',
    client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
    ...(code ? {code: code} : {refresh_token: getRefreshToken()}),
    ...(redirectURI && {redirect_uri: redirectURI})
  }

  const formBody = Object.keys(data).map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(data[k])).join("&");

  await fetch(`${GENERATE_TOKENS_URL}`,
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: formBody
  }).then(res => handleAuthenticationResponse(res, code));
}

async function handleAuthenticationResponse(res: any, code?: string) {
  const now = new Date().getTime();

  if (res.ok){
    const data = await res.json();
    const item: any = {
      token: data.id_token,
      expiresIn: now + THRESHOLD_TO_REFRESH_TOKEN_IN_MILLIS //token needs to be refreshed after 58 minutes
  }

  if (code) localStorage.setItem(TOKEN_INVALIDATES_IN, JSON.stringify(now + (data.expires_in * 1000))); //token becomes invalid after a day

    localStorage.setItem(ACCESS_TOKEN, JSON.stringify(item));
    if (data.refresh_token) localStorage.setItem(REFRESH_TOKEN, JSON.stringify(data.refresh_token));
  }

}

export function logout() {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(TOKEN_INVALIDATES_IN);
}
