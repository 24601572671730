import React from 'react';
import DriverShiftsPage from './components/DriverShiftsPage';
import DriverShiftsProvider from './contexts/DriverShiftsContext';

const DriverShifts: React.FC = () => {
  return (
    <DriverShiftsProvider>
      <DriverShiftsPage />
    </DriverShiftsProvider>
  );
};

export default DriverShifts;
