import React, { useContext } from 'react';

import { Slider } from 'antd';

import styles from './EstimatedAndRealTimesSteps.module.css';
import { OrderDetailsContext } from '../../contexts/OrderDetailsContext';
import { formatHours, formatDate } from '../../utils';

const EstimatedAndRealTimesSteps: React.FC = () => {
  const { order } = useContext(OrderDetailsContext);

  const bottomLabelStyle = {
    color: '#000',
    textAlign: 'center',
    fontSize: '10px',
    width: '80px',
  };

  const topLabelStyle = {
    ...bottomLabelStyle,
    marginTop: '-60px',
  };

  const marksEstimatedTimes = {
    0: {
      style: bottomLabelStyle,
      label: (
        <>
          <strong>Order Received</strong>
          <p className={styles.labelDate}>{formatDate(order?.createdAt)}</p>
          <p>{formatHours(order?.createdAt)}</p>
        </>
      ),
    },
    10: {
      style: topLabelStyle,
      label: (
        <>
          <strong>Picking</strong>
          <p className={styles.labelDate}>{formatDate(order?.estimatedPickingStartTime)}</p>
          <p>{formatHours(order?.estimatedPickingStartTime)}</p>
        </>
      ),
    },
    15: {
      style: bottomLabelStyle,
      label: (
        <>
          <strong>Prepared</strong>
          <p className={styles.labelDate}>{formatDate(order?.estimatedPickingEndTime)}</p>
          <p>{formatHours(order?.estimatedPickingEndTime)}</p>
        </>
      ),
    },
    20: {
      style: topLabelStyle,
      label: (
        <>
          <strong>Collected</strong>
          <p className={styles.labelDate}>{formatDate(order?.estimatedCollectTime)}</p>
          <p>{formatHours(order?.estimatedCollectTime)}</p>
        </>
      ),
    },
    30: {
      style: bottomLabelStyle,
      label: (
        <>
          <strong>Delivered</strong>
          <p className={styles.labelDate}>{formatDate(order?.estimatedDeliveryTime)}</p>
          <p>{formatHours(order?.estimatedDeliveryTime)}</p>
        </>
      ),
    },
  };

  const marksRealTimes = {
    0: {
      style: bottomLabelStyle,
      label: (
        <>
          <strong>Order Received</strong>
          <p className={styles.labelDate}>{formatDate(order?.createdAt)}</p>
          <p>{formatHours(order?.createdAt)}</p>
        </>
      ),
    },
    10: {
      style: topLabelStyle,
      label: (
        <>
          <strong>Picking</strong>
          <p className={order?.actualPickingStartTime > order?.estimatedPickingStartTime ? styles.late : styles.normal}>
            {formatDate(order?.actualPickingStartTime)}
          </p>
          <p className={order?.actualPickingStartTime > order?.estimatedPickingStartTime ? styles.late : styles.normal}>
            {formatHours(order?.actualPickingStartTime)}
          </p>
        </>
      ),
    },
    15: {
      style: bottomLabelStyle,
      label: (
        <>
          <strong>Prepared</strong>
          <p className={order?.actualPickingEndTime > order?.estimatedPickingEndTime ? styles.late : styles.normal}>
            {formatDate(order?.actualPickingEndTime)}
          </p>
          <p className={order?.actualPickingEndTime > order?.estimatedPickingEndTime ? styles.late : styles.normal}>
            {formatHours(order?.actualPickingEndTime)}
          </p>
        </>
      ),
    },
    20: {
      style: topLabelStyle,
      label: (
        <>
          <strong>Collected</strong>
          <p className={order?.actualCollectTime > order?.estimatedCollectTime ? styles.late : styles.normal}>
            {formatDate(order?.actualCollectTime)}
          </p>
          <p className={order?.actualCollectTime > order?.estimatedCollectTime ? styles.late : styles.normal}>
            {formatHours(order?.actualCollectTime)}
          </p>
        </>
      ),
    },
    30: {
      style: bottomLabelStyle,
      label: (
        <>
          <strong>Delivered</strong>
          <p className={order?.actualDeliveryTime > order?.estimatedDeliveryTime ? styles.late : styles.normal}>
            {formatDate(order?.actualDeliveryTime)}
          </p>
          <p className={order?.actualDeliveryTime > order?.estimatedDeliveryTime ? styles.late : styles.normal}>
            {formatHours(order?.actualDeliveryTime)}
          </p>
        </>
      ),
    },
  };

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1 className={styles.estimatedTimesTitle}>Estimated Times</h1>
        {order && (
          <Slider
            handleStyle={{ display: 'none' }}
            marks={marksEstimatedTimes}
            max={30}
            value={30}
            className={styles.estimatedTimesSlider}
            disabled
          />
        )}
      </div>

      <div className={styles.row}>
        <h1 className={styles.realTimesTitle}>Real Times</h1>
        <Slider
          handleStyle={{ display: 'none' }}
          marks={marksRealTimes}
          max={30}
          value={30}
          className={styles.realTimesSlider}
        />
      </div>
    </div>
  );
};

export default EstimatedAndRealTimesSteps;
