import React, { useState, ReactNode, createContext, useEffect } from 'react';

import { notification } from 'antd';

import { todayIsoString } from '../constants';
import { Driver, Shift, ViewType } from '../interfaces';
import { getDriverById, getDrivers } from 'services/Driver.service';
import { getShifts } from '../services/api';
import { getWarehouses } from 'services/Warehouse.service';
import {
  formatDrivers,
  formatShiftData,
  generateFetchShiftsParams,
  transformDatesFromUTCToLocalIsoString,
} from '../utils';
import { DEFAULT_TIMEZONE } from 'util/dateAndTime';

interface DriverShiftsContextData {
  fetchShifts: (selectedDay?: string, selectedWeek?: string) => void;
  fetchDrivers: (params?: any) => void;
  shifts: Shift[];
  drivers: Driver[];
  setDrivers: (drivers: Driver[]) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
  selectedDriverId: string | undefined;
  setSelectedDriverId: (driverId: string | undefined) => void;
  filteredDrivers: Driver[];
  viewType: ViewType | undefined;
  setViewType: (type: ViewType | undefined) => void;
  zoneId: string | undefined;
  fetchLocations: () => void;
  selectedDate: string;
  setSelectedDate: (day: string) => void;
  selectedWeek: string;
  setSelectedWeek: (week: string) => void;
}
interface DriverShiftsProviderProps {
  children: ReactNode;
}

export const DriverShiftsContext = createContext({} as DriverShiftsContextData);

const DriverShiftsProvider = ({ children }: DriverShiftsProviderProps) => {
  const [shifts, setShifts] = useState<Shift[]>([]);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [filteredDrivers, setFilteredDrivers] = useState<Driver[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDrivers, setLoadingDrivers] = useState<boolean>(true);
  const [loadingShifts, setLoadingShifts] = useState<boolean>(true);
  const [selectedDate, setSelectedDate] = useState<string>(todayIsoString);
  const [selectedWeek, setSelectedWeek] = useState<string>(todayIsoString);
  const [selectedDriverId, setSelectedDriverId] = useState<string | undefined>();
  const [viewType, setViewType] = useState<ViewType | undefined>();
  const [zoneId, setZoneId] = useState<string | undefined>();

  useEffect(() => {
    if (!loadingShifts && !loadingDrivers) setLoading(false);
    else setLoading(true);
  }, [loadingDrivers, loadingShifts]);

  // After drivers data was formatted or date changed, fetch shifts to insert in timetable
  useEffect(() => {
    if (drivers.length > 0 || filteredDrivers.length > 0) fetchShifts();
  }, [drivers, filteredDrivers, viewType]);

  /* When selecting a driver in the filter, should make a request to fetch only the
  driver selected and format the timetable again */
  useEffect(() => {
    if (selectedDriverId) fetchDrivers(selectedDriverId);
  }, [selectedDriverId]);

  async function fetchDrivers(driverId?: string) {
    setLoadingDrivers(true);
    setFilteredDrivers([]);

    const nullDriver = {
      id: 'null',
      firstName: 'Kiwi',
      lastName: 'Shift',
      kiwiShift: true,
    };

    // if should bring a filtered driver (single)
    if (driverId) {
      try {
        await getDriverById(driverId).then((response) => {
          if (response.status !== 'Error') {
            const formatFilteredDrivers = formatDrivers([nullDriver, response]);
            setFilteredDrivers(formatFilteredDrivers);
          } else throw new Error(response.message);
        });
      } catch (err) {
        const error: any = err;
        notification.error({
          message: 'Error fetching drivers!',
          description: error.message,
        });
        setLoadingDrivers(false);
      } finally {
        setLoadingDrivers(false);
      }
    } else {
      setDrivers([]);
      try {
        const { results } = await getDrivers({ perPage: 1000 });
        const activeDrivers = results.filter((driver: any) => driver.status === 'ACTIVE')
        const formattedDrivers = formatDrivers([nullDriver, ...activeDrivers]);
        setDrivers(formattedDrivers);
      } catch (err) {
        const error: any = err;
        notification.error({
          message: 'Error fetching drivers!',
          description: error.message,
        });
      } finally {
        setLoadingDrivers(false);
      }
    }
  }

  const fetchShifts = async (day = todayIsoString, week = todayIsoString) => {
    setLoadingShifts(true);

    const TIMEZONE: any = DEFAULT_TIMEZONE;
    const params = generateFetchShiftsParams(week, day, selectedDriverId, viewType, TIMEZONE);

    try {
      const initialFetch = await getShifts({ ...params });
      const totalShifts = initialFetch.totalItems;

      const fetchedShifts = await getShifts({ ...params, perPage: totalShifts });
      setShifts([]);

      // Converts utc dates to local time to display on screen
      const shiftsWithDatesTransformed = transformDatesFromUTCToLocalIsoString(fetchedShifts.results, TIMEZONE);

      let selectedDrivers;

      if (filteredDrivers.length > 0) selectedDrivers = filteredDrivers;
      else selectedDrivers = drivers;

      // If the page is using the filtered driver array, pass it as second param, else, use the normal formattedDrivers
      const formattedShifts = formatShiftData(shiftsWithDatesTransformed, selectedDrivers, viewType);

      setShifts(formattedShifts);
    } catch (err) {
      const error: any = err;
      notification.error({
        message: 'Error fetching shifts!',
        description: error.message,
      });
    } finally {
      setLoadingShifts(false);
    }
  };

  const fetchLocations = () => {
    try {
      getWarehouses().then((res) => {
        if (res.error) throw new Error(res.message);
        else {
          // TODO: fix this when we have zone selection on this page
          const found = res.results.find((location: any) => location.zoneId !== 'unknown-zone-id');
          setZoneId(found.zoneId);
        }
      });
    } catch (err) {
      const error: any = err;
      notification.error({
        message: 'Error fetching locations!',
        description: error.message,
      });
    }
  };

  return (
    <DriverShiftsContext.Provider
      value={{
        shifts,
        fetchDrivers,
        fetchShifts,
        drivers,
        setDrivers,
        selectedDriverId,
        setSelectedDriverId,
        filteredDrivers,
        viewType,
        setViewType,
        loading,
        setLoading,
        zoneId,
        fetchLocations,
        selectedDate,
        setSelectedDate,
        selectedWeek,
        setSelectedWeek,
      }}
    >
      {children}
    </DriverShiftsContext.Provider>
  );
};

export default DriverShiftsProvider;
