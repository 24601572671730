import React, { Key, useContext } from 'react';
import { notification, Table } from 'antd';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from 'react-sortable-hoc';
import { DeleteOutlined, MenuOutlined } from '@ant-design/icons';

import styles from './AssignedLegsTable.module.css';
import { LegProps } from '../../../interfaces';
import { RoutesContext } from '../../../contexts/RoutesContext';
import { EditRouteContext } from 'ui/pages/Routes/contexts/EditRouteContext';
interface AssignedLegsTableProps {
  assignedLegs: LegProps[],
  setSelectedRowKeys: (keys: Key[]) => void;
  setAssignedLegs: (legs: LegProps[]) => void;
}

const AssignedLegsTable: React.FC<AssignedLegsTableProps> = ({
  assignedLegs,
  setAssignedLegs,
  setSelectedRowKeys
}) => {

  const { unassignedLegs, setUnassignedLegs, deliveryLegs } = useContext(RoutesContext);

  const { isEditingRoute, setEditRoutesUnassignedLegs } = useContext(EditRouteContext);

  const deleteRow = (leg: any) => {
    const assignedLegsWithRowRemoval = assignedLegs.filter((item: any) => item.orderId !== leg.orderId);
    const pairLegs = assignedLegs.filter((item: any) => item.orderId === leg.orderId);
    setSelectedRowKeys([]);
    setAssignedLegs(assignedLegsWithRowRemoval);
    setUnassignedLegs([...pairLegs, ...unassignedLegs]);

    if (isEditingRoute) {
      const deliveryLegsIds = deliveryLegs?.map(item => item.id);
      const pairLegsIds = pairLegs?.map((item: any) => item.id);

      const foundPairLegs = deliveryLegsIds?.includes(pairLegsIds[0]);
      if (!foundPairLegs) {
        setEditRoutesUnassignedLegs([...pairLegs, ...deliveryLegs]);
      }
    }
  }

  const DragHandle = SortableHandle(() => (
    <MenuOutlined className={styles.dragHandleIcon} />
  ));

  const AssignedLegsColumns = [
    {
      title: '',
      dataIndex: 'sort',
      className: styles.dragVisible,
      width: '80px',
      render: (_: any, assignedLeg: LegProps) => assignedLeg.actualStartTime || assignedLeg.actualEndTime ? null : <DragHandle />
    },
    {
      title: 'Order Id',
      dataIndex: 'orderId',
      render: (text: string) => <p className={styles.listItem}>{text}</p>,
    },
    {
      title: 'Order Number',
      dataIndex: 'orderNumber',
      render: (text: string) => <p className={styles.listItem}>{text ?? '-'}</p>,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      render: (text: string) =>
        <p className={styles.listItem}>{text}</p>
      ,
    },
    {
      title: 'Job Type',
      dataIndex: 'type',
      render: (text: string) => <p className={styles.listItem}>{text}</p>,
    },
    {
      title: '',
      dataIndex: 'remove',
      width: '80px',
      render: (text: any, record: any) => <DeleteOutlined onClick={() => deleteRow(record)} />
    }
  ];

  const SortableItem = SortableElement((props: any) => <tr {...props} />);
  const SortableOrderContainer = SortableContainer((props: any) => (
    <tbody {...props} />
  ));

  const onSortEnd = (props: any) => {
    const { oldIndex, newIndex } = props;
    if (oldIndex !== newIndex) {
      if (newIndex < oldIndex) {
        if (assignedLegs[newIndex]?.actualStartTime || assignedLegs[newIndex]?.actualEndTime) {
          setAssignedLegs(assignedLegs);
          notification.error({
            message: 'Invalid Dragging!',
          });
          return;
        }
        else {
          const newData = arrayMove(
            ([] as LegProps[]).concat(assignedLegs),
            oldIndex,
            newIndex
          ).filter((el) => !!el);
          setAssignedLegs(newData);
        }
      }
      else {
        const newData = arrayMove(
          ([] as LegProps[]).concat(assignedLegs),
          oldIndex,
          newIndex
        ).filter((el) => !!el);
        setAssignedLegs(newData);
      }
    }
  };

  const DraggableContainer = (props: any) => (
    <SortableOrderContainer
      axis="y"
      lockAxis="y"
      lockToContainerEdges={true}
      lockOffset={["30%", "30%"]}
      useDragHandle={true}
      helperClass={styles.rowDragging}
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = (props: any) => {
    const { className, style, ...restProps } = props;
    const id = assignedLegs.findIndex((item: LegProps) => item.id === restProps['data-row-key']);
    return <SortableItem index={id} {...restProps} />;
  };

  return (
    <Table
      columns={AssignedLegsColumns}
      dataSource={assignedLegs}
      rowKey="id"
      pagination={{ defaultPageSize: 30, pageSizeOptions: ['30', '50', `${assignedLegs.length}`], showSizeChanger: true }}
      scroll={{ y: 450, x: 500 }}
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
    />
  )
}

export default AssignedLegsTable;
