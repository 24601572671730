import {
  createAsyncThunk,
  createReducer,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { notification } from 'antd';
import { createWalletTransaction, getWallets, createWallet } from "services/Wallet.service";
import { RootState } from "store";

export interface Wallet {
	id: string,
  customerId: string,
  currency: string,
  createdAt: number,
  updatedAt: number,
  walletTransactions: WalletTransaction[]
}

interface WalletTransaction{
  id: string,
  walletId: string,
  type: string,
  quantity: number,
  reason: string,
  expirationDate: number,
}

interface WalletPaginated {
  totalItems: number;
  currentPage: number;
  perPage: number;
  totalPages: number;
  results: Wallet[],
}

interface WalletSliceState {
  customerDontHaveWallets: boolean;
  paginated?: WalletPaginated;
  fetchingWallets: boolean;
  fetchingCreateTransaction: boolean;
  successCreateTransaction: boolean;
}

const initialState: WalletSliceState = {
  fetchingWallets: false,
  fetchingCreateTransaction: false,
  successCreateTransaction: false,
  customerDontHaveWallets: false,
  paginated: {
    totalItems: 0,
    currentPage: 0,
    perPage: 0,
    totalPages: 1,
    results: [],
  },
};

export const fetchWallets = createAsyncThunk(
  "wallet/fetchWallets",
  async function (params: any, { getState }) {
    const {
      customer: { selectedCustomer },
    } = getState() as RootState;

    try {
      const response = await getWallets({ customerId: selectedCustomer.id });
      if (response.error || response.status == "error") {
        throw new Error(response.message);
      } else {
        return response;
      }
    } catch (err) {
      const error: any = err;
      notification['error']({
        message: 'Error fetching wallets!',
        description: error.message,
      });
    }
  }
);

export const createTransaction = createAsyncThunk(
  "wallet/createWalletTransaction",
  async function (params: any, { getState, dispatch }) {
    const {
      customer: { selectedCustomer },
    } = getState() as RootState;

    try {
      const response = await createWalletTransaction(params.walletId, params.body);
      if (response.error || response.status == "error") {
        throw new Error(response.message);
      } else {
        dispatch(fetchWallets({customerId: selectedCustomer.id}));
        notification['success']({
          message: 'Transaction created with success!',
        });
        return response;
      }
    } catch (err) {
      const error: any = err;
      notification['error']({
        message: 'Error creating transaction!',
        description: error.message,
      });
    }
  }
);

export const createWalletAndTransaction = createAsyncThunk(
  "wallet/createWalletAndTransaction",
  async function (body: any, { getState, dispatch }) {
    const {
      customer: { selectedCustomer },
    } = getState() as RootState;

    try {
      const response = await createWallet(body);
      if (response.error || response.status == "error") {
        throw new Error(response.message);
      } else {
        dispatch(fetchWallets({customerId: selectedCustomer.id}));
        notification['success']({
          message: 'Wallet and transaction created with success!',
        });
        return response;
      }
    } catch (err) {
      const error: any = err;
      notification['error']({
        message: 'Error creating wallet and transaction!',
        description: error.message,
      });
    }
  }
);

export const walletReducer = createReducer(initialState, (builder) => {
  const successWallets = isFulfilled(fetchWallets);
  const errorWallets = isRejected(fetchWallets);
  const loadingWallets = isPending(fetchWallets);

  const successCreateTransaction = isFulfilled(createTransaction, createWalletAndTransaction);
  const errorCreateTransaction = isRejected(createTransaction, createWalletAndTransaction);
  const loadingCreateTransaction = isPending(createTransaction, createWalletAndTransaction);

  builder
    .addCase(fetchWallets.fulfilled, (state, action) => {
      if (action.payload.results.length === 0) state.customerDontHaveWallets = true;
      else state.customerDontHaveWallets = false;
      state.paginated = action.payload;
    })
    .addMatcher(loadingWallets, (state) => {
      state.fetchingWallets = true;
    })
    .addMatcher(successWallets, (state) => {
      state.fetchingWallets = false;
    })
    .addMatcher(errorWallets, (state) => {
      state.fetchingWallets = false;
    })
    .addMatcher(loadingCreateTransaction, (state) => {
      state.fetchingCreateTransaction = true;
      state.successCreateTransaction = false;
    })
    .addMatcher(successCreateTransaction, (state) => {
      state.fetchingCreateTransaction = false;
      state.successCreateTransaction = true;
    })
    .addMatcher(errorCreateTransaction, (state) => {
      state.fetchingCreateTransaction = false;
      state.successCreateTransaction = false;
    });
});
