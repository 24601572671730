import { RootState } from "store";

function selectWalletsFetching(state: RootState) {
  return state.wallet.fetchingWallets;
}

function selectCreateTransactionFetching(state: RootState) {
  return state.wallet.fetchingWallets;
}

function selectPaginatedWallets(state: RootState) {
  return state.wallet.paginated;
}

function selectSuccessCreatingTransaction(state: RootState) {
  return state.wallet.successCreateTransaction;
}

function selectCustomerDontHaveWallets(state: RootState) {
  return state.wallet.customerDontHaveWallets;
}

export {
  selectWalletsFetching,
  selectPaginatedWallets,
  selectCreateTransactionFetching,
  selectSuccessCreatingTransaction,
  selectCustomerDontHaveWallets
}
