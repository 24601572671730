import React, { useEffect, useState } from 'react';

import { Button, Col, DatePicker, Input, Row, Select, Table } from 'antd';
import useCampaigns from 'hooks/useCampaigns';
import { Campaign } from 'store/Campaign.slice';
import styles from './PushNotifications.module.css';
import AddNotificationModal from './components/AddNotificationModal';
import moment, { Moment } from 'moment';
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { DEFAULT_TIMEZONE, getUnixTimeByTimezone } from 'util/dateAndTime';
import { formatDate } from '../OrderDetails/utils';

const { Option } = Select;
const { RangePicker } = DatePicker;

export enum FilterTypes{
  'NAME' = 'name',
  'TITLE' = 'title',
  'MESSAGE' = 'message'
}

const PushNotifications: React.FC = () => {
  const [filterType, setFilterType] = useState<FilterTypes>(FilterTypes.NAME);
  const [searchText, setSearchText] = useState<string>('');
  const [intervalDates, setIntervalDates] = useState<any>();

  const { loading, fetchCampaigns, paginatedCampaigns, changeModalVisibility } = useCampaigns();

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const selectAfter = (
    <Select
      className="select-after"
      style={{ width: '200px', backgroundColor: '#d9d9d6' }}
      value={filterType}
      onChange={(value) => setFilterType(value)}
    >
      <Option value={FilterTypes.NAME}>Campaign</Option>
      <Option value={FilterTypes.TITLE}>Title</Option>
      <Option value={FilterTypes.MESSAGE}>Body</Option>
    </Select>
  );

  const columns = [
    {
      title: 'Campaign',
      dataIndex: 'name',
      render: (value: string) => <p className={styles.noMarginText}>{value || '-'}</p>
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Body',
      dataIndex: 'message',
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      render: (value: number) => <p className={styles.noMarginText}>{formatDate(value) || '-'}</p>
    },
  ];

  const handleSearch = async (page?: number) => {
    const params: any = {};

    if (page) params.page = page;
    if (searchText !== '') params[filterType] = searchText;

    if (intervalDates) {
      const deliveryDateStartTime = getUnixTimeByTimezone(intervalDates[0].format(), 5, 0, DEFAULT_TIMEZONE);
      const deliveryDateEndTime = getUnixTimeByTimezone(intervalDates[1].format(), 23, 40, DEFAULT_TIMEZONE);

      if (deliveryDateStartTime) params.startTime = deliveryDateStartTime;
      if (deliveryDateEndTime) params.endTime = deliveryDateEndTime;
    }

    fetchCampaigns(params);
  };

  return (
    <div className={styles.container}>
      <Row className={styles.row}>
        <Col lg={20} md={24} xs={24}>
            <div className={styles.compoundFilter}>
              <Input
                className={styles.inputSearch}
                addonBefore={selectAfter}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                allowClear
              />
              <RangePicker
                format="DD-MM-YYYY"
                value={intervalDates}
                allowEmpty={[true, true]}
                allowClear
                onChange={(e: any) => setIntervalDates(e)}
                className={styles.rangePicker}
              />
            <Button disabled={loading} onClick={() => handleSearch()} type="primary" icon={<SearchOutlined />}>
              Search
            </Button>
          </div>
        </Col>
        <Col className={styles.addRow} lg={4} md={24} xs={24}>
          <div onClick={() => changeModalVisibility(true)} className={styles.addDiscountButton}>
            <PlusCircleOutlined className={styles.iconButton} />
            <strong className={styles.addNotificationText}>Add Notification</strong>
          </div>
        </Col>
      </Row>
      <Table
        loading={loading}
        className={styles.table}
        rowKey={(record: Campaign) => record.id}
        dataSource={paginatedCampaigns?.results}
        columns={columns}
        scroll={{ x: 1270 }}
        pagination={{
          current: paginatedCampaigns?.currentPage,
          defaultPageSize: paginatedCampaigns?.perPage,
          onChange: (page: number) => {
            handleSearch(page);
          },
          showSizeChanger: false,
          pageSize: paginatedCampaigns?.perPage,
          total: paginatedCampaigns?.totalItems,
        }}
      />
      <AddNotificationModal/>
    </div>
  )
}

export default PushNotifications;
