import React, { useState, ReactNode, createContext, useEffect } from 'react';

import { notification } from 'antd';
import moment from 'moment';

import { DeliveryWindowsType, ExpensesType, MODAL_TYPES, ProductType, InventoryProduct } from '../interfaces';
import {
  getLocations,
  getOrder,
  updateOrder,
  updateOrderStatus,
  getCouponByName,
  getDeliveryLegsByOrderId,
  getCustomer,
  getRoute,
  updateOrderWindow,
  getProducts,
} from '../services/api';

import { DEFAULT_TIMEZONE, getUnixTimeByTimezone } from 'util/dateAndTime';
import { getDeliveryWindows } from 'services/DeliveryWindow.service';
import { mergeGSTTaxes } from '../utils';
interface OrderDetailsContextData {
  changeDriverModalVisible: boolean;
  confirmDriverModalVisible: boolean;
  changeOrderModalVisible: boolean;
  addProductModalVisible: boolean;
  isRouteInfoModalVisible: boolean;
  isEditWindowModalVisible: boolean;
  isReplacementConfirmationModalVisible: boolean;
  toggleModalVisibility: (modalName: MODAL_TYPES, clickedProduct?: any) => void;
  fetchOrder: (id: string) => void;
  updateOrderData: (data: any) => Promise<any>;
  changeOrderStatus: (data: any) => Promise<any>;
  changeOrderWindow: (data: any) => Promise<any>;
  order: any;
  setOrder: (order: any) => void;
  productRecord: any;
  loading: boolean;
  customer: any;
  orderHasChanges: (value: boolean) => void;
  hasChanges: boolean;
  firstOrderTotal: number;
  orderCoupon: any;
  getCoupon: (data: any) => void;
  getWindows: (zoneId: string) => void;
  deliveryWindows: Array<DeliveryWindowsType>
  setOrderExpenses: (orderExpenses: ExpensesType[]) => void;
  orderExpenses: ExpensesType[];
  loadingAllProducts: boolean;
  allProducts: InventoryProduct[];
  getAllProducts: () => void;
  replacementProduct: InventoryProduct;
  replacedProduct: InventoryProduct;
  setReplacementProduct: (item: InventoryProduct) => void;
  setReplacedProduct: (item: InventoryProduct) => void;
  quantity: number;
  setQuantity: (value: number) => void;
  availability: boolean;
  setAvailability: (available: boolean) => void;
  willReplaceProduct: boolean;
  setWillReplaceProduct: (value: boolean) => void;
  selectedReplacementId: string | undefined;
  setSelectedReplacementId: (value: string | undefined) => void;
}
interface OrderDetailsProviderProps {
  children: ReactNode;
}

export const OrderDetailsContext = createContext({} as OrderDetailsContextData);

const OrderDetailsProvider = ({ children }: OrderDetailsProviderProps) => {
  const [changeDriverModalVisible, setChangeDriverModalVisible] = useState(false);
  const [confirmDriverModalVisible, setConfirmDriverModalVisible] = useState(false);
  const [changeOrderModalVisible, setChangeOrderModalVisible] = useState(false);
  const [addProductModalVisible, setAddProductModalVisible] = useState(false);
  const [isRouteInfoModalVisible, setIsRouteInfoModalVisible] = useState(false);
  const [isEditWindowModalVisible, setIsEditWindowModalVisible] = useState(false);
  const [isReplacementConfirmationModalVisible, setIsReplacementConfirmationModalVisible] = useState(false);
  const [order, setOrder] = useState<any>();
  const [customer, setCustomer] = useState<any>();
  const [productRecord, setProductRecord] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [firstOrderTotal, setFirstOrderTotal] = useState<number>(0);
  const [orderCoupon, setOrderCoupon] = useState<any>('');
  const [deliveryWindows, setDeliveryWindows] = useState<DeliveryWindowsType[]>([]);
  const [orderExpenses, setOrderExpenses] = useState<ExpensesType[]>([]);
  const [loadingAllProducts, setLoadingAllProducts] = useState<boolean>(false);
  const [allProducts, setAllProducts] = useState<InventoryProduct[]>([]);
  const [replacementProduct, setReplacementProduct] = useState<any>();
  const [replacedProduct, setReplacedProduct] = useState<any>();
  const [quantity, setQuantity] = useState<number>(1);
  const [availability, setAvailability] = useState<boolean>(false);
  const [willReplaceProduct, setWillReplaceProduct] = useState<boolean>(false);
  const [selectedReplacementId, setSelectedReplacementId] = useState<string>();

  const orderHasChanges = (value: boolean) => setHasChanges(value);

  const fetchOrder = async (orderId: string) => {

    try {
      setLoading(true);
      const response = await getOrder(orderId);

      if (response.customerDetails.customerId !== '') {
        const orderCustomerResponse = await getCustomer(response.customerDetails.customerId);

        if (!orderCustomerResponse.error) setCustomer(orderCustomerResponse);
      }

      if (response.coupon !== '') await getCoupon({ name: response.coupon, orderValue: response.subTotal, isOrderUpdate: true });
      const locations = await fetchLocations();
      const warehouse = locations?.find((location: any) => location.id === response.locationId);
      setFirstOrderTotal(response.total);

      const warehouseName = warehouse?.name;

      const deliveryLegs = await getDeliveryLegs(response.id)

      if (deliveryLegs[0]?.routeId) {
        var { legs, shift: { driver } } = await getRoutes(deliveryLegs[0].routeId)
      }

      const actualDeliveryTimeFormatted =
        response.actualDeliveryTime === 0 ? 'No' : moment.utc(response.actualDeliveryTime).local().format('DD/MM/YYYY');

      const formattedOrder = {
        ...response,
        actualDeliveryTimeFormatted,
        warehouse: warehouseName,
        driverName: driver ? `${driver.firstName || ""} ${driver.lastName || ""}` : null,
        routeLegs: legs || [],
        expenses: mergeGSTTaxes(response.expenses)
      };

      setOrderExpenses(formattedOrder.expenses);
      setOrder(formattedOrder);
    } catch (error) {
      const err: any = error;
      notification.error({
        message: 'Error fetching order!',
        description: err.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchLocations = async (params?: any) => {
    try {
      const firstResponse = await getLocations({ perPage: 1 });

      if (firstResponse.error) throw new Error(firstResponse.message);

      const totalLocations = firstResponse.totalItems;

      const response = await getLocations({ ...params, perPage: totalLocations });

      if (response.error) throw new Error(response.message);

      return response.results;
    } catch (err) {
      const error: any = err;

      notification.error({
        message: 'Error fetching locations!',
        description: error.message,
      });
    }
    return 0;
  };

  const updateOrderData = async (data: any) => {
    try {
      setLoading(true);
      const response = await updateOrder(data);

      if (response.error || response.status === 'error') {
        const errorStringified = JSON.stringify({ status: response.status, message: response.message });
        throw new Error(errorStringified);
      } else {
        notification.success({
          message: 'Success updating order!',
          description: 'The product was updated.',
        });
        orderHasChanges(false);
        fetchOrder(order.id);
      }
    } catch (err) {
      const error: any = err;
      const { status } = JSON.parse(error.message);
      const errorMessage = JSON.parse(error.message).message;
      notification.error({
        message: 'Error updating order!',
        description: errorMessage,
      });
      return status;
    } finally {
      setLoading(false);
    }
    return 0;
  };

  const getDeliveryLegs = async (orderId: string) => {
    if (orderId) {
      try {
        const response = await getDeliveryLegsByOrderId({
          orderIds: [orderId],
        });

        if (response.error || response.status === 'error') throw new Error(response.message);
        else return response;
      } catch (err) {
        const error: any = err;
        notification.error({
          message: 'Error getting Delivery Legs!',
          description: error.message,
        });
      }
    }
  };

  const getRoutes = async (routeId: string) => {
    if (routeId) {
      try {
        const response = await getRoute(routeId);
        if (response.error || response.status === 'error') throw new Error(response.message);
        else return response

      } catch (err) {
        const error: any = err;
        notification.error({
          message: 'Error getting Routes!',
          description: error.message,
        });
      }
    }
  };

  const getWindows = async (zoneId: string) => {
    const startDate = moment().format();
    const endDate = moment().add(6, 'days').format();

    const unixStartTime = getUnixTimeByTimezone(startDate, 5, 0, DEFAULT_TIMEZONE);
    const unixEndTime = getUnixTimeByTimezone(endDate, 23, 40, DEFAULT_TIMEZONE);

    try {
      setLoading(true);
      const response = await getDeliveryWindows({ zoneId: zoneId, startTime: unixStartTime, endTime: unixEndTime });
      if (response.error || response.status === 'error') throw new Error(response.message);
      else setDeliveryWindows(response.slice(0, 6));
    } catch (err) {
      const error: any = err;
      notification.error({
        message: 'Error getting delivery windows!',
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const getCoupon = async (data: any) => {
    try {
      setLoading(true);
      const response = await getCouponByName(data);
      if (response.error || response.status === 'error') throw new Error(response.message);
      else return setOrderCoupon(response);
    } catch (err) {
      const error: any = err;
      notification.error({
        message: 'Error getting coupon!',
        description: error.message,
      });
      return null;
    } finally {
      setLoading(false);
    }
  };

  const changeOrderStatus = async (data: any) => {
    try {
      setLoading(true);
      const response = await updateOrderStatus(data);

      if (response.error || response.status === 'error') throw new Error(response.message);
      else {
        notification.success({
          message: 'Success updating order status!',
          description: 'The order status was changed.',
        });
        orderHasChanges(false);
        fetchOrder(order.id);
      }
    } catch (err) {
      const error: any = err;
      notification.error({
        message: 'Error changing order status!',
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const changeOrderWindow = async (data: any) => {
    try {
      setLoading(true);
      const response = await updateOrderWindow(data);

      if (response.status === "error" && response.statusCode) throw new Error(response.message);
      else {
        notification.success({
          message: 'Success updating order window!',
          description: 'The order window was changed.',
        });
        orderHasChanges(false);
        fetchOrder(order.id);
      }
    } catch (err) {
      const error: any = err;
      notification.error({
        message: 'Error changing order window!',
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const toggleModalVisibility = (modalName: MODAL_TYPES, clickedProduct?: any) => {
    switch (modalName) {
      case MODAL_TYPES.changeDriver:
        setChangeDriverModalVisible(!changeDriverModalVisible);
        break;
      case MODAL_TYPES.confirmDriver:
        setConfirmDriverModalVisible(!confirmDriverModalVisible);
        break;
      case MODAL_TYPES.changeOrder:
        setChangeOrderModalVisible(!changeOrderModalVisible);
        if (clickedProduct) setProductRecord(clickedProduct);
        break;
      case MODAL_TYPES.addProduct:
        setAddProductModalVisible(!addProductModalVisible);
        break;
      case MODAL_TYPES.showRouteInfo:
        setIsRouteInfoModalVisible(!isRouteInfoModalVisible);
        break;
      case MODAL_TYPES.editWindowModal:
        setIsEditWindowModalVisible(!isEditWindowModalVisible);
        break;
      case MODAL_TYPES.confirmReplacement:
        setIsReplacementConfirmationModalVisible(!isReplacementConfirmationModalVisible);
        break;
      default:
        break;
    }
  };

  const getAllProducts = async () => {
    setLoadingAllProducts(true);

    try {
      const requestToGetTotalProducts = await getProducts();
      const allProducts = await getProducts({ perPage: requestToGetTotalProducts.totalItems });
      setAllProducts(allProducts.results);

    } catch (err) {
      const error:any = err;

      notification.error({
        message: 'Error fetching products!',
        description: error.message,
      });
    } finally {
      setLoadingAllProducts(false);
    }
  }

  return (
    <OrderDetailsContext.Provider
      value={{
        changeDriverModalVisible,
        confirmDriverModalVisible,
        changeOrderModalVisible,
        addProductModalVisible,
        isRouteInfoModalVisible,
        isEditWindowModalVisible,
        isReplacementConfirmationModalVisible,
        toggleModalVisibility,
        fetchOrder,
        updateOrderData,
        changeOrderStatus,
        changeOrderWindow,
        order,
        setOrder,
        productRecord,
        loading,
        customer,
        orderHasChanges,
        hasChanges,
        firstOrderTotal,
        orderCoupon,
        getCoupon,
        getWindows,
        deliveryWindows,
        orderExpenses,
        setOrderExpenses,
        allProducts,
        loadingAllProducts,
        getAllProducts,
        replacementProduct,
        replacedProduct,
        setReplacementProduct,
        setReplacedProduct,
        quantity,
        setQuantity,
        availability,
        setAvailability,
        willReplaceProduct,
        setWillReplaceProduct,
        selectedReplacementId,
        setSelectedReplacementId,
      }}
    >
      {children}
    </OrderDetailsContext.Provider>
  );
};

export default OrderDetailsProvider;
