import { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import * as WarehouseActions from "../store/Warehouse.slice";
import {
  selectPaginatedWarehouses,
  selectWarehousesFetching,
  selectWarehousesIsModalVisible,
  selectWarehousesStoreNewStatus,
  selectWarehousesStoreQuery
} from "selectors/Warehouses";

export default function useWarehouses() {
  const dispatch = useDispatch();

  const paginatedWarehouses = useSelector(selectPaginatedWarehouses);
  const loading = useSelector(selectWarehousesFetching);
  const query = useSelector(selectWarehousesStoreQuery)
  const isModalVisible = useSelector(selectWarehousesIsModalVisible)
  const newStatus = useSelector(selectWarehousesStoreNewStatus)

  const fetchWarehouses = useCallback(
    async function (query?: any) {
      dispatch(WarehouseActions.fetchWarehouses(query));
      dispatch(WarehouseActions.storeQuery(query))
    },
    [dispatch]
  );

  const updateWarehouse = useCallback(
    async function (params: any) {
      dispatch(WarehouseActions.updateWarehouse(params));
    },
    [dispatch]
  );

  const changeModalVisibility = useCallback(
    async function (isModalVisible: boolean) {
      dispatch(WarehouseActions.changeModalVisibility(isModalVisible))
    },
    [dispatch]
  );

  const storeNewStatus = useCallback(
    async function (newStatus: string) {
      dispatch(WarehouseActions.storeNewStatus(newStatus))
    },
    [dispatch]
  );

  return {
    query,
    paginatedWarehouses,
    loading,
    fetchWarehouses,
    isModalVisible,
    changeModalVisibility,
    storeNewStatus,
    updateWarehouse,
    newStatus
  };
}
