import React, { useContext, useEffect, useState } from 'react';

import { ClockCircleOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { getCorrespondingDriverShiftDuration } from '../../utils';
import { DriverShiftsContext } from '../../contexts/DriverShiftsContext';
import { COLUMNS, FULL_HOURS } from '../../constants';
import { Driver } from '../../interfaces';
import AddShiftPopover from '../AddShiftPopover';
import Shift from '../Shift';
import styles from './DailyTimetable.module.css';

const DailyTimeTable: React.FC = () => {
  const [selectedDrivers, setSelectedDrivers] = useState<Driver[]>([]);

  const { drivers, shifts, filteredDrivers, loading } = useContext(DriverShiftsContext);

  useEffect(() => {
    if (filteredDrivers.length > 0) setSelectedDrivers(filteredDrivers);
    else setSelectedDrivers(drivers);
  }, [filteredDrivers, drivers]);

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loadingContainer}>
          <Spin size="large" />
        </div>
      ) : (
        <div className={styles.schedule} aria-labelledby="schedule-heading">
          <div />
          {COLUMNS.map((item) => (
            <div key={item} className={styles.gridColumn} style={{ gridColumn: `${item}`, gridRow: 'times' }} />
          ))}
          {FULL_HOURS.map((item: any) => (
            <div
              key={item.hour}
              className={styles.hours}
              style={{ gridColumn: `${item.first} / ${item.second}`, gridRow: 'times' }}
            >
              {item.title}h
            </div>
          ))}
          {selectedDrivers.length > 0 &&
            selectedDrivers.map((driver: Driver, driverIndex: number) => (
              <>
                <div
                  key={driver.id}
                  className={styles.driverSlot}
                  style={{ gridColumn: 'drivers', gridRow: driver.rowIndex }}
                >
                  <h2 className={driver.kiwiShift ? styles.kiwiShift : styles.driver}>
                    {`${driver.firstName} ${driver.lastName}`}
                  </h2>
                  {!driver.kiwiShift && (
                    <div className={styles.shiftDurationContainer}>
                      <ClockCircleOutlined className={styles.shiftDurationIcon} />
                      <p className={styles.shiftDuration}>
                        {getCorrespondingDriverShiftDuration(driver.id, shifts) || 0}
                      </p>
                    </div>
                  )}
                </div>
                {COLUMNS.map((item) => (
                  <AddShiftPopover
                    key={item}
                    column={item}
                    driverIndex={driverIndex}
                    driverId={driver.id}
                    drivers={drivers}
                    isKiwiShift={driver.kiwiShift || false}
                  />
                ))}
              </>
            ))}
          {shifts.length > 0 && shifts.map((item) => <Shift key={item.id} drivers={drivers} item={item} />)}
        </div>
      )}
    </div>
  );
};

export default DailyTimeTable;
