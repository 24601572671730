import React, { useContext } from 'react';

import { Modal, notification } from 'antd';

import styles from './ConfirmationSwitchModal.module.css';
import { MODAL_TYPES } from '../../interfaces';
import useInventory from 'hooks/useInventory';
import { editProduct } from 'services/Products.service';

interface ConfirmationSwitchModalProps {
  record: any;
  handleSuccess: () => void;
}

const ConfirmationSwitchModal: React.FC<ConfirmationSwitchModalProps> = ({ record, handleSuccess }) => {
  const { switchConfirmationModalVisible, toggleModalVisibility } = useInventory();

  const handleConfirmChange = async () => {
    const body: any = {};
    body.active = !record.active;

    try {
      const response = await editProduct(record.id, body);

      if (response.error || response.status == "error") {
        throw new Error(response.message);
      } else {
        toggleModalVisibility(MODAL_TYPES.confirmationSwitch);
        handleSuccess();
        notification['success']({
          message: 'Success!',
          description: 'The product status was updated.',
        });
      }
    } catch (err) {
      const error: any = err;
      notification['error']({
        message: 'Error editing product!',
        description: error.message,
      });
    }
  };

  return (
    <Modal
      title=""
      visible={switchConfirmationModalVisible}
      onOk={handleConfirmChange}
      onCancel={() => toggleModalVisibility(MODAL_TYPES.confirmationSwitch)}
      cancelText="No, thanks"
      okText={`Yes, ${record?.active ? 'turn off' : 'turn on'} the product`}
    >
      <h1 className={styles.title}>
        Do you want to {record?.active ? 'turn off' : 'turn on'} <br />
        SKU {record?.sku}?
      </h1>
      <p>The product {record?.active ? 'won´t' : 'will'} be available on the marketplace</p>
    </Modal>
  );
};

export default ConfirmationSwitchModal;
