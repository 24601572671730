import React, { useEffect, useState, useRef, memo } from 'react';

import { Column, ColumnConfig } from '@ant-design/charts';

import { STATUSES_TYPES } from '../../interfaces';
import useDeliveryWindows from 'hooks/useDeliveryWindows';
import { formatOrderWindows } from 'ui/pages/Warehouse/utils';
import { DeliveryWindow, DeliveryWindowResponse } from 'store/DeliveryWindow.slice';
import { useHistory } from 'react-router';
import moment from 'moment';
import { DEFAULT_TIMEZONE } from 'util/dateAndTime';

interface ChartsProps{
  currentDateWindows: DeliveryWindowResponse
}

const Charts: React.FC<ChartsProps> = ({ currentDateWindows }) => {
  const [chartData, setChartData] = useState<any>([]);

  const { windowOrders, selectedDate } = useDeliveryWindows();
  const chartRef = useRef(null);
  const history = useHistory();

  const config: ColumnConfig = {
    data: chartData,
    style: { cursor: 'pointer'},
    xField: 'window',
    yField: 'value',
    isGroup: true,
    isStack: true,
    seriesField: 'status',
    groupField: 'window',
    color: (item: any) => {
      const { status } = item;
      return STATUSES_TYPES[status]
    },
    tooltip: {
      showContent: false
    },
    label: {
      content: (item: any) => {
        const { status, quantity } = item;
        if (status === 'TOTAL_CAPACITY') return '';
        else return quantity;
      },
      position: 'bottom',
      style: {
        fontSize: 14,
        fontWeight: "bold",
        textAlign: 'center',
        color: "#111"
      },
    },
    onEvent: (chart, e) => {
      if (e.type === 'legend:click') {
        const target = e.target;
        const cfg = target.cfg;
        const attrs = cfg.attrs;
        const statusClicked = attrs.text;
        if (statusClicked === 'TOTAL_CAPACITY') return;

        history.push({
          pathname: '/orders',
          state: {
            orderStatus: statusClicked,
            startDate: moment(selectedDate).set({
              hour:   5,
              minute: 0,
              second: 0
          }).tz(DEFAULT_TIMEZONE).valueOf(),
            endDate: moment(selectedDate).set({
              hour:   23,
              minute: 30,
              second: 0
          }).tz(DEFAULT_TIMEZONE).valueOf(),
          }
        })
        return;
      }

      if (e.type === 'click' && e.data){
        const chartData = e.data;
        const windowData = chartData?.data;
        const windowId = windowData.windowId;
        const orderStatus = windowData.status;

        history.push({
          pathname: '/orders',
          state: {
            orderStatus,
            windowId
          }
        })
      }
    },
    columnStyle: { cursor: 'pointer' }
  };

  const formatData = async () => {

    if (currentDateWindows){
      const windowsByDate = currentDateWindows?.windows;
      const windowsWithOrderInfo = windowsByDate?.map((window: DeliveryWindow) => ({
        ...window,
        windowOrders: formatOrderWindows(windowOrders, window),
      }));

      if (windowsWithOrderInfo) {
        const ordersOfEachWindow: any = [];
        windowsWithOrderInfo.forEach((window: DeliveryWindow) => {
          ordersOfEachWindow.push(window.windowOrders)
        });

        const allWindowsOrders = [].concat.apply([], ordersOfEachWindow);
        setChartData(allWindowsOrders);
      } else setChartData([]);
    } else setChartData([]);
  };

  useEffect(() => {
    if (windowOrders) formatData();
  }, [windowOrders])

  return <Column {...config} ref={chartRef} />;
};

export default memo(Charts);
