import React, { useEffect, useState } from 'react';

import { Col, Checkbox } from 'antd';
import moment from 'moment-timezone';

import styles from './Slots.module.css';
import { DeliveryWindow, Slot } from '../../interfaces';
import { DEFAULT_TIMEZONE } from 'util/dateAndTime';

interface SlotsProps {
  selectedDateInfo: DeliveryWindow;
  setSlotIds: (slotIds: any) => void;
  slotIds: any;
}

const Slots: React.FC<SlotsProps> = ({ selectedDateInfo, setSlotIds, slotIds }) => {
  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    setOptions([]);

    if (selectedDateInfo.slots) {
      const formattedOptions = selectedDateInfo.slots.map((item: Slot) => ({
        label: `${moment(item.startTime).tz(DEFAULT_TIMEZONE).format('HH:mm')} to ${moment(item.endTime).tz(DEFAULT_TIMEZONE).format('HH:mm')}`,
        value: item.id,
      }));

      setOptions(formattedOptions);
    }
  }, [selectedDateInfo]);

  const handleChange = (checkedValues: any) => {
    setSlotIds({ [selectedDateInfo.date]: checkedValues });
  };

  return (
    <Col className={styles.container}>
      {options && (
        <Checkbox.Group
          className={styles.checkBoxGroup}
          value={slotIds[selectedDateInfo.date]}
          options={options}
          onChange={handleChange}
        />
      )}
    </Col>
  );
};

export default Slots;
