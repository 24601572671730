import React, { useEffect, useState } from 'react';

import { Col, Row, Select, Table } from 'antd';

import { Wallet } from 'store/Wallet.slice';
import AddTransactionModal from './components/AddTransactionModal';
import useWallets from 'hooks/useWallets';
import styles from './KiwiCredits.module.css';
import { useHistory } from 'react-router';
import formatToDollar from 'util/formatToDollar';
import { formatDate } from 'util/dateAndTime';

const { Option } = Select;

const KiwiCredits: React.FC = () => {
  const [addTransactionModalVisible, setAddTransactionModalVisible] = useState<boolean>(false);
  const [customerWallet, setCustomerWallet] = useState<any>();

  const { fetchWallets, paginatedWallets, loadingWallets, customerDontHaveWallets } = useWallets();
  const history = useHistory();
  const pageState: any = history.location.state;

  useEffect(() => {
    fetchWallets();
  }, [])

  useEffect(() => {
    if (paginatedWallets) setCustomerWallet(paginatedWallets.results[0]);
  }, [paginatedWallets])

  useEffect(() => {
    console.log("customerWallet", customerWallet);
  }, [customerWallet])


  const columns = [
    {
      title: 'Creation Date',
      dataIndex: 'createdAt',
      render: (value: number) => (
        <p className={styles.text}>{formatDate(value)}</p>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render: (value: number) => <p className={styles.text}>${formatToDollar(value)}</p>
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
    },
    {
      title: 'Expiration',
      dataIndex: 'expirationDate',
      render: (value: number) => (
        <p className={styles.text}>{value !== 0 ? formatDate(value) : '-'}</p>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <Row justify="start">
        {customerDontHaveWallets ? (
          <h1 className={styles.message}>
            {pageState.customer.name} doesn't have wallets yet! Create a transaction  to initialize a wallet.
          </h1>
        ) : (
          <>
            <Col lg={8} md={8} xs={24}>
              <h1 className={styles.title}>Wallet ID</h1>
              <p>{customerWallet?.id || "-"}</p>
            </Col>
            <Col lg={8} md={8} xs={24}>
              {paginatedWallets?.results && (
                <>
                  <h1 className={styles.title}>Currency</h1>
                  <Select
                    className={styles.select}
                    placeholder="-"
                    onChange={(value) => setCustomerWallet(value)}
                    value={customerWallet?.id}
                  >
                    {paginatedWallets?.results.map((wallet: Wallet) => (
                      <Option key={wallet.id} value={wallet.id}>{wallet.currency || 'CAD'}</Option>
                    ))}
                  </Select>
                </>
              )}
            </Col>
            <Col lg={8} md={8} xs={24}>
              <h1 className={styles.title}>Balance</h1>
              <p>{`$ ${formatToDollar(customerWallet?.balance)}` || '-'}</p>
            </Col>
          </>
        )}
      </Row>
      <button
        className={styles.button}
        type='button'
        onClick={() => setAddTransactionModalVisible(true)}
      >
        Create Transaction
      </button>
      <Table
        loading={loadingWallets}
        dataSource={customerWallet?.walletTransactions || []}
        columns={columns}
        scroll={{ x: 1270 }}
      />
      <AddTransactionModal
        visible={addTransactionModalVisible}
        setVisible={setAddTransactionModalVisible}
        selectedWallet={customerWallet}
      />
    </div>
  )
}

export default KiwiCredits;
