import { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import * as OrderActions from "../store/Order.slice";
import {
  selectOrdersFetching,
  selectOrdersIsModalVisible,
  selectOrdersStoreNewStatus,
  selectOrdersStoreQuery,
  selectPaginatedOrders ,
} from "selectors/Orders";

export default function useOrders() {
  const dispatch = useDispatch();

  const paginatedOrders = useSelector(selectPaginatedOrders);
  const loading = useSelector(selectOrdersFetching);
  const query = useSelector(selectOrdersStoreQuery)
  const isModalVisible = useSelector(selectOrdersIsModalVisible)
  const newStatus = useSelector(selectOrdersStoreNewStatus)

  const fetchOrders = useCallback(
    async function (query: any) {
      dispatch(OrderActions.fetchOrders(query));
      dispatch(OrderActions.storeQuery(query))
    },
    [dispatch]
  );

  const updateOrder = useCallback(
    async function (params: any) {
      dispatch(OrderActions.updateOrder(params));
    },
    [dispatch]
  );

  const changeModalVisibility = useCallback(
    async function (isModalVisible: boolean) {
      dispatch(OrderActions.changeModalVisibility(isModalVisible))
    },
    [dispatch]
  );

  const storeNewStatus = useCallback(
    async function (newStatus: string) {
      dispatch(OrderActions.storeNewStatus(newStatus))
    },
    [dispatch]
  );

  return {
    query,
    paginatedOrders,
    loading,
    fetchOrders,
    updateOrder,
    isModalVisible,
    changeModalVisibility,
    storeNewStatus,
    newStatus
  };
}
