/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { ReactElement, useState, useEffect, useContext } from 'react';

import { Row, Col, Typography } from 'antd';

import { isObjectFilled } from 'util/object';
import { RoutesContext } from '../../contexts/RoutesContext';
import { DriverShift } from '../../interfaces';
import { EditRouteContext } from '../../contexts/EditRouteContext';
import styles from './RoutesPage.module.css';
import CreateRouteSteps from '../CreateRouteSteps';
import EditRouteSteps from '../EditRouteSteps';
import UnassignedLegsTable from '../Tables/UnassignedLegsTable';
import ZoneSelect from 'ui/components/ZoneSelect';

const { Title } = Typography;

function Route(): ReactElement {
  const {
    deliveryLegs,
    fetchUnassignedLegs,
    getDatesAndSlots,
    setAssignedLegs,
    setSelectedDriverShift,
    setCreateRouteStep,
    defaultCity,
    fetchDriverShifts,
    setUnassignedLegs,
    createRouteStep,
    selectedDate,
    city,
    setCity,
    setSelectedRowKeys,
    generateDefaultCity,
  } = useContext(RoutesContext);

  const { editRouteStep, setEditRouteStep, setSelectedRoute, isEditingRoute, setIsEditingRoute } = useContext(
    EditRouteContext,
  );

  const [checkboxesDisabled, setCheckboxesDisabled] = useState<boolean>(false);
  const EDIT_ROUTE_ASSIGNING_LEGS_STEP = 2;
  const CREATE_ROUTE_ASSIGNING_LEGS_STEP = 1;

  useEffect(() => {
    if (!isObjectFilled(defaultCity)) generateDefaultCity();
  }, []);

  useEffect(() => {
    if (isEditingRoute) setCheckboxesDisabled(editRouteStep !== EDIT_ROUTE_ASSIGNING_LEGS_STEP);
    else setCheckboxesDisabled(createRouteStep !== CREATE_ROUTE_ASSIGNING_LEGS_STEP);
  }, [createRouteStep, isEditingRoute, editRouteStep]);

  useEffect(() => {
    if (isEditingRoute) {
      setAssignedLegs([]);
      setUnassignedLegs([]);
      fetchUnassignedLegs();
      setSelectedRowKeys([]);
      setSelectedDriverShift({} as DriverShift);
      setEditRouteStep(0);
      setCreateRouteStep(0);
      setSelectedRoute({});
    }
  }, [isEditingRoute]);

  useEffect(() => {
    if (isObjectFilled(defaultCity)) setCity(defaultCity);
  }, [defaultCity]);

  useEffect(() => {
    if (isObjectFilled(city)) {
      fetchUnassignedLegs();
      getDatesAndSlots(city.zoneId);
    }
  }, [city]);

  useEffect(() => {
    if (isObjectFilled(city)) fetchDriverShifts(city.zoneId);
  }, [selectedDate, city]);

  useEffect(() => {
    if (deliveryLegs && deliveryLegs.length) setUnassignedLegs(deliveryLegs);
  }, [deliveryLegs]);

  const handleChangeZone = (zone: string) => {
    fetchDriverShifts(zone);
  };

  return (
    <Row className={styles.cardContainer}>
      <Col span={12} className={styles.container} lg={12} md={24} xs={24}>
        <Col>
          <Title className={styles.title} level={3}>
            Choose a zone
          </Title>
          <ZoneSelect onChange={handleChangeZone} />
        </Col>
        <UnassignedLegsTable checkboxesDisabled={checkboxesDisabled} />
      </Col>
      <Col span={12} className={styles.stepsContainer} lg={12} md={24} xs={24}>
        <Row className={styles.rowContainer}>
          <Title level={3} className={styles.title}>
            {isEditingRoute ? 'Edit Routes' : 'Add a Route'}
          </Title>
          <a onClick={() => setIsEditingRoute(!isEditingRoute)} onKeyDown={() => setIsEditingRoute(!isEditingRoute)}>
            {isEditingRoute ? 'back to Add new Route' : 'Edit Routes'}
          </a>
        </Row>
        {isEditingRoute ? <EditRouteSteps /> : <CreateRouteSteps />}
      </Col>
    </Row>
  );
}

export default Route;
