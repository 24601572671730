import React, { useEffect } from 'react';

import { Modal, Form, InputNumber } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';

import { createUnixUtcDateTime, DEFAULT_TIMEZONE } from 'util/dateAndTime';
import { Record } from 'ui/pages/Warehouse/types';
import { MODAL_TYPES } from '../../interfaces';
import WindowsIntervalFormatItem from '../WindowsInvervalFormItem';
import useDeliveryWindows from 'hooks/useDeliveryWindows';

type EditModalProps = {
  record: Record;
};

const EditModal = ({ record }: EditModalProps): any => {
  const [form] = Form.useForm();
  moment.tz.setDefault(DEFAULT_TIMEZONE);

  const {
    updateDeliveryWindow,
    changeModalVisibility,
    isEditWindowModalVisible,
    selectedDate,
} = useDeliveryWindows();

const handleConfirmUpdate = () => {
  const { id, zoneId, dateMonthDay } = record;
  form
    .validateFields()
    .then(async ({ windowsInterval, totalCapacity }) => {
      const [startTime, endTime] = createUnixUtcDateTime(dateMonthDay, windowsInterval);
      const body = { startTime, endTime, zoneId,totalCapacity };

      updateDeliveryWindow({id, body, form});

    });
};

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        windowsInterval: [moment(_.get(record, 'startTime')), moment(_.get(record, 'endTime'))],
        zoneId: _.get(record, 'zoneId'),
        totalCapacity: _.get(record, 'totalCapacity'),
        date: _.get(record, 'date'),
      });
    }
  }, [form, record, selectedDate]);

  return (
    <Modal
      title="Edit Delivery Window"
      visible={isEditWindowModalVisible}
      onOk={handleConfirmUpdate}
      onCancel={() => changeModalVisibility(MODAL_TYPES.editDeliveryWindows)}
      cancelText="No, thanks"
      okText="Yes, change the window"
    >
      <Form form={form}>
        <WindowsIntervalFormatItem />
        <strong>Total Capacity</strong>
        <Form.Item
          name="totalCapacity"
          rules={[
            {
              required: true,
              message: 'Please enter the total capacity',
            },
          ]}
        >
          <InputNumber style={{ width: '100%' }} placeholder="totalCapacity" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditModal;
