import { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import * as CampaignActions from "../store/Campaign.slice";
import { selectAddCampaignModalVisible, selectCampaignsFetching, selectPaginatedCampaigns } from "selectors/Campaigns";

export default function useWarehouses() {
  const dispatch = useDispatch();

  const paginatedCampaigns = useSelector(selectPaginatedCampaigns);
  const loading = useSelector(selectCampaignsFetching);
  const isModalVisible = useSelector(selectAddCampaignModalVisible);

  const fetchCampaigns = useCallback(
    async function (query?: any) {
      dispatch(CampaignActions.fetchCampaigns(query));
    },
    [dispatch]
  );

  const createCampaign = useCallback(
    async function (body: any) {
      dispatch(CampaignActions.addCampaign(body));
    },
    [dispatch]
  );

  const changeModalVisibility = useCallback(
    async function (isModalVisible: boolean) {
      dispatch(CampaignActions.changeAddModalVisibility(isModalVisible))
    },
    [dispatch]
  );

  return {
    loading,
    paginatedCampaigns,
    isModalVisible,
    changeModalVisibility,
    fetchCampaigns,
    createCampaign
  };
}
