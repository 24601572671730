import {
  createAction,
  createAsyncThunk,
  createReducer,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { notification } from 'antd';
import { Catalog, Category, Product } from "ui/pages/Inventory/interfaces";
import { getProducts, getProductsCategories, getProduct } from "services/Products.service";
import { getCatalogs } from "services/Catalog.service";

interface Paginated {
  totalItems: number;
  currentPage: number;
  perPage: number;
  totalPages: number;
}
interface ProductsPaginated extends Paginated{
  results: Product[],
}

interface CatalogsPaginated extends Paginated{
  results: Catalog[],
}
interface InventorySliceState {
  selectedProduct: Product | null;
  paginatedProducts?: ProductsPaginated;
  paginatedCatalogs?: CatalogsPaginated;
  categories: Category[];
  expandedRowKeys: any[];
  fetching: boolean;
  currentQuery: string;
  isModalVisible: boolean;
  switchConfirmationModalVisible: boolean;
  editStockModalVisible: boolean;
  discontinuedModalVisible: boolean;
  addProductModalVisible: boolean;
}

const initialState: InventorySliceState = {
  selectedProduct: null,
  fetching: false,
  currentQuery: '',
  categories: [],
  isModalVisible: false,
  expandedRowKeys: [],
  switchConfirmationModalVisible: false,
  editStockModalVisible: false,
  discontinuedModalVisible: false,
  addProductModalVisible: false,
  paginatedProducts: {
    totalItems: 0,
    currentPage: 0,
    perPage: 0,
    totalPages: 1,
    results: [],
  },
};

export const fetchProducts = createAsyncThunk(
  "inventory/fetchProducts",
  async function (query?: any) {

      try {
        const response = await getProducts(query);
        const formattedResults = response.results?.map((product: any) => {

          const locationId = product.catalog?.locations[0].id || "No ID found";
          const locationName = product.catalog?.locations[0].name || "No location found";
          const availableStock = product.levels[0].availableStock ?? '-';
          const totalStock = product.levels[0].totalStock ?? '-';
          const description = product?.description ? product?.description : '-'

          return ({
            ...product,
            locationId,
            locationName,
            availableStock,
            totalStock,
            description,
            key: product.id
          })
        });

        const formattedResponse = {
          ...response,
          results: formattedResults
        }

        return formattedResponse

      } catch (error) {
        const errorMessage: any = error;
        notification['error']({
          message: 'Error fetching products!',
          description: errorMessage,
        });
      }
  }
);

export const fetchAllCategories = createAsyncThunk(
  "inventory/fetchAllCategories",
  async () => {

  try {
    const initialResponse = await getProductsCategories();

    const response = await getProductsCategories({ perPage: initialResponse.totalItems });

    if (response.error || response.status == "error") {
      throw new Error(response.message);
    } else {
      return response.results;
    }
  } catch (error) {
    notification['error']({
      message: 'Error fetching categories!',
      description: "Please check your internet connection or contact support.",
    });
  }
});

export const fetchCatalogs = createAsyncThunk(
  "inventory/fetchCatalogs",
  async () => {

  try {
    const response = await getCatalogs();

    if (response.error || response.status == "error") {
      throw new Error(response.message);
    } else {
      return response;
    }
  } catch (error) {
    notification['error']({
      message: 'Error fetching catalogs!',
      description: "Please check your internet connection or contact support.",
    });
  }
});

export const fetchProduct = createAsyncThunk(
  "inventory/fetchProduct",
  async (id: string) => {

  try {
    const response = await getProduct(id);

    if (response.error || response.status == "error") {
      throw new Error(response.message);
    } else {
      return response;
    }
  } catch (error) {
    notification['error']({
      message: 'Error fetching product!',
      description: "Please check your internet connection or contact support.",
    });
  }
});


export const storeQuery = createAction('inventory/storeQuery', (payload: string) => ({ payload }));

export const storeSelectedProduct = createAction('inventory/storeSelectedProduct', (payload: Product) => ({ payload }));

export const storeSwitchConfirmationModalVisible = createAction('inventory/storeSwitchConfirmationModalVisible', (payload: boolean) => ({ payload }));

export const storeEditStockModalVisible = createAction('inventory/storeEditStockModalVisible', (payload: boolean) => ({ payload }));

export const storeDiscontinuedModalVisible = createAction('inventory/storeDiscontinuedModalVisible', (payload: boolean) => ({ payload }));

export const storeAddProductModalVisible = createAction('inventory/storeAddProductModalVisible', (payload: boolean) => ({ payload }));

export const storeExpandedKeys = createAction('inventory/storeExpandedKeys', (payload: any[]) => ({ payload }));

export const inventoryReducer = createReducer(initialState, (builder) => {

  builder
    .addCase(fetchProducts.fulfilled, (state, action) => {
      state.paginatedProducts = action.payload;
    })
    .addCase(fetchProduct.fulfilled, (state, action) => {
      state.selectedProduct = action.payload;
    })
    .addCase(fetchCatalogs.fulfilled, (state, action) => {
      state.paginatedCatalogs = action.payload;
    })
    .addCase(fetchAllCategories.fulfilled, (state, action) => {
      state.categories = action.payload;
    })
    .addCase(storeQuery, (state, action) => {
      state.currentQuery = action.payload;
    })
    .addCase(storeSelectedProduct, (state, action) => {
      state.selectedProduct = action.payload;
    })
    .addCase(storeExpandedKeys, (state, action) => {
      state.expandedRowKeys = action.payload;
    })
    .addCase(storeSwitchConfirmationModalVisible, (state, action) => {
      state.switchConfirmationModalVisible = action.payload;
    })
    .addCase(storeEditStockModalVisible, (state, action) => {
      state.editStockModalVisible = action.payload;
    })
    .addCase(storeDiscontinuedModalVisible, (state, action) => {
      state.discontinuedModalVisible = action.payload;
    })
    .addCase(storeAddProductModalVisible, (state, action) => {
      state.addProductModalVisible = action.payload;
    })
    .addMatcher(isPending, (state) => {
      state.fetching = true;
    })
    .addMatcher(isFulfilled, (state) => {
      state.fetching = false;
    })
    .addMatcher(isRejected, (state) => {
      state.fetching = false;
    });
});
