import React from 'react';
import { Form, TimePicker } from 'antd';
import { disabledHours, minuteStep, timeFormat } from 'util/dateAndTime';

export default function WindowsIntervalFormatItem(): JSX.Element {
  return (
    <>
      <strong>Start Time - End Time</strong>
      <Form.Item
        name="windowsInterval"
        rules={[
          {
            required: true,
            message: 'Please select a window interval!',
          },
        ]}
      >
        <TimePicker.RangePicker
          disabledHours={disabledHours}
          minuteStep={minuteStep}
          format={timeFormat}
          style={{ width: '100%' }}
        />
      </Form.Item>
    </>
  );
}
