import { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  selectCouponOrders,
  selectCustomerCoupon,
  selectCustomerOrdersFetching,
  selectCustomerOrdersQuery,
  selectCustomersFetching,
  selectLoadingCustomerCoupon,
  selectModalVisibility,
  selectNewStatus,
  selectPaginatedCustomerOrders,
  selectPaginatedCustomers,
  selectSelectedCustomer
} from '../selectors/Customers'

import * as CustomerActions from "../store/Customer.slice";
import { Customer } from "../store/Customer.slice";

export default function useCustomers() {
  const dispatch = useDispatch();

  const paginatedCustomers = useSelector(selectPaginatedCustomers);
  const paginatedCustomerOrders = useSelector(selectPaginatedCustomerOrders);
  const loadingCustomers = useSelector(selectCustomersFetching);
  const loadingCustomerOrders = useSelector(selectCustomerOrdersFetching);
  const modalVisible = useSelector(selectModalVisibility);
  const newStatus = useSelector(selectNewStatus);
  const selectedCustomer = useSelector(selectSelectedCustomer);
  const loadingCoupon = useSelector(selectLoadingCustomerCoupon);
  const customerCoupon = useSelector(selectCustomerCoupon);
  const couponOrders = useSelector(selectCouponOrders);

  const fetchCustomers = useCallback(
    async function (params?: any) {
      dispatch(CustomerActions.fetchCustomers(params));
    },
    [dispatch]
  );

  const fetchCustomerOrders = useCallback(
    async function (params?: any) {
      dispatch(CustomerActions.fetchCustomerOrders(params));
      dispatch(CustomerActions.storeQuery(params));
          },
    [dispatch]
  );

  const fetchCustomerCoupon = useCallback(
    async function (couponName: string) {
      dispatch(CustomerActions.fetchCustomerCoupon(couponName));
          },
    [dispatch]
  );

  const storeSelectedCustomer = useCallback(
    async function (customer: Customer) {
      dispatch(CustomerActions.storeSelectedCustomer(customer));
    },
    [dispatch]
  );

  const updateCustomerOrder = useCallback(
    async function (body: any) {
      dispatch(CustomerActions.updateCustomerOrder(body));
    },
    [dispatch]
  );

  const changeModalVisibility = useCallback(
    async function (value: boolean) {
      dispatch(CustomerActions.changeModalVisibility(value));
    },
    [dispatch]
  );

  const storeNewStatus = useCallback(
    async function (newStatus: string) {
      dispatch(CustomerActions.storeNewStatus(newStatus))
    },
    [dispatch]
  );

  const setIsFetchingCouponOrders = useCallback(
    async function (newStatus: boolean) {
      dispatch(CustomerActions.isFetchingCouponOrders(newStatus))
    },
    [dispatch]
  );

  return {
    storeSelectedCustomer,
    selectedCustomer,
    paginatedCustomers,
    fetchCustomers,
    loadingCustomers,
    paginatedCustomerOrders,
    loadingCustomerOrders,
    fetchCustomerOrders,
    modalVisible,
    changeModalVisibility,
    updateCustomerOrder,
    newStatus,
    storeNewStatus,
    loadingCoupon,
    customerCoupon,
    fetchCustomerCoupon,
    setIsFetchingCouponOrders,
    couponOrders
  };
}
