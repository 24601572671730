import React, { useCallback, useEffect, useState } from 'react'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon } from "leaflet";
import { MAP_API_KEY } from '../../../constants/mapApiKey';
import styles from './MapView.module.css';
import {
  Layout, Input, Checkbox, Card, Divider, DatePicker, List, Typography, Row, Col, Collapse, Spin, Empty, notification,
} from 'antd';
import {
  SearchOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';
import { Moment } from 'moment';
import moment from 'moment';
import { DEFAULT_TIMEZONE, getUnixTimeByTimezone, unixDateToTimeZone } from 'util/dateAndTime';
import { getZones } from 'services/Zones.service';
import { getDeliveryWindows } from 'services/DeliveryWindow.service';
import { DriverLocationType, LegType, WarehousesType, WindowsType, ZonesType } from './interfaces';
import { getOrdersByFilters } from 'services/Order.service';
import { getDeliveryLegsByOrderId } from '../OrderDetails/services/api';
import { getWarehouses } from 'services/Warehouse.service';
import { OrderType } from '../OrderDetails/interfaces';
import { fetchDriversLocations } from 'services/Map.service';
import { getShifts } from 'services/Shifts.service';
import _ from 'lodash';
import { getDriverById } from 'services/Driver.service';
import { getRoutes } from 'services/Routes.service';
import LegPanel from './components/LegPanel';
const { Sider, Content } = Layout;
const { Title, Text } = Typography;
const { Panel } = Collapse;

function MapView() {
  const [activeDriver, setActiveDriver] = useState<any>(null);
  const [warehouses, setWarehouses] = useState<WarehousesType[]>([]);
  const [events, setEvents] = useState<any>(null);
  const [driversLocations, setDriversLocations] = useState<DriverLocationType[]>([]);
  const [deliverTypeLegs, setDeliverTypeLegs] = useState<any>([]);
  const [collectTypeLegs, setCollectTypeLegs] = useState<any>([]);
  const [zones, setZones] = useState<ZonesType[]>([]);
  const [zoneName, setZoneName] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
  const [deliveryWindows, setDeliveryWindows] = useState<WindowsType[]>([]);
  const [collectedLegs, setCollectedLegs] = useState<LegType[]>([]);
  const [uncollectedLegsRouted, setUncollectedLegsRouted] = useState<LegType[]>([]);
  const [uncollectedLegsUnrouted, setUncollectedLegsUnrouted] = useState<LegType[]>([]);
  const [loadingEvents, setLoadingEvents] = useState<boolean>(false);
  const [loadingZones, setLoadingZones] = useState<boolean>(false);
  const [loadingDeliveryWindows, setLoadingDeliveryWindows] = useState<boolean>(false);
  const [loadingMap, setLoadingMap] = useState<boolean>(false);

  const placeIconGreen = new Icon({
    iconUrl: "/place-green.png",
    iconSize: [30, 30]
  });

  const warehouseIcon = new Icon({
    iconUrl: "/place-red.png",
    iconSize: [30, 30]
  });

  const driverIcon = new Icon({
    iconUrl: "/aim.png",
    iconSize: [30, 30]
  });

  useEffect(() => {
    getAllZones();
  }, []);

  useEffect(() => {
    if (deliveryWindows?.length > 0) {
      getDeliveryLegs()
    }
  }, [deliveryWindows]);

  useEffect(() => {
    const selectedZones = zones.filter((zone: ZonesType) => zone.checked);
    if (selectedZones.length > 0) {
      getAllWarehouses(selectedZones[0].id);

      const unixDate = moment(selectedDate).valueOf();
      const startTime = getUnixTimeByTimezone(unixDate, 5, 0, 'America/Edmonton');
      const endTime = getUnixTimeByTimezone(unixDate, 23, 40, 'America/Edmonton');
      const query = { startTime, endTime, zoneId: zones[0].id };
      if (query.startTime && query.endTime) {
        getDeliveryWindowsByDate(query);
      }
    }
    else setDeliveryWindows([]);
  }, [selectedDate, zones]);

  const removeDuplicate = (legs: any) => {
    return Array.from(new Set(legs.map((a: LegType) => a.orderId))).map(id => legs.find((a: LegType) => a.orderId === id))
  }

  function isZoneChecked(zone: ZonesType) {
    return zone.checked === true;
  }

  const handleChangeZoneChecked = useCallback(
    (zoneId) => {
      setZones(
        zones.map((zone: ZonesType) =>
          zone.id === zoneId
            ? {
              ...zone,
              checked: !zone.checked
            }
            : zone
        )
      );
    },
    [zones]
  );

  const handleChangeWindowChecked = useCallback(
    (windowId) => {
      setDeliveryWindows(
        deliveryWindows.map((window: WindowsType) =>
          window.id === windowId
            ? {
              ...window,
              checked: !window.checked
            }
            : window
        )
      );
    },
    [deliveryWindows]
  );

  const getAllWarehouses = async (zoneId: string) => {
    try {
      setLoadingMap(true)
      const response = await getWarehouses({ zoneId: zoneId, perPage: 100 });
      getDriversLocations(zoneId);
      getEvents();
      if (response.error || response.status == "error") {
        throw new Error(response.message);
      } else {
        const warehousesFormatted = response.results?.map((warehouse: WarehousesType) => ({
          ...warehouse,
          iconType: 'warehouse'
        }));
        setWarehouses(warehousesFormatted);
      }
    } catch (err) {
      const error: any = err;
      notification['error']({
        message: 'Error fetching shift!',
        description: error.message,
      });
    } finally {
      setLoadingMap(false)
    }
  }

  const getAllZones = async () => {
    try {
      setLoadingZones(true);
      const response = await getZones();
      getEvents();
      if (response.error || response.status == "error") {
        throw new Error(response.message);
      } else {
        const zonesFormatted = response.map((item: ZonesType) => {
          return { ...item, checked: false }
        })
        setZones(zonesFormatted);
      }
    } catch (err) {
      const error: any = err;
      notification['error']({
        message: 'Error fetching zones!',
        description: error.message,
      });
    } finally {
      setLoadingZones(false);
    }
  }

  const getDriversLocations = async (zoneId: string) => {
    try {
      const driversTotal = await fetchDriversLocations({ zoneId: zoneId, perPage: 1 });
      if (driversTotal.error || driversTotal.status == "error") {
        throw new Error(driversTotal.message);
      } else {
        const response = await fetchDriversLocations({ zoneId: zoneId, perPage: driversTotal.totalItems });

        const driverLocationsFormatted = response.results?.map((driver: DriverLocationType) => ({
          ...driver,
          latitude: driver.lat,
          longitude: driver.lng,
          iconType: 'driver'
        }))

        const currentDateTime = moment().subtract(2, 'hours').format('YYYY-MM-DD HH:mm:ss');
        const currentTimeToTimezone = moment.tz(currentDateTime, DEFAULT_TIMEZONE).format('YYYY-MM-DD HH:mm:ss');

        const uniqueDriverLocations = Array.from(new Set(driverLocationsFormatted.map((a: any) => a.driverId)))
          .map(id => {
            return driverLocationsFormatted.reverse().find((a: any) => a.driverId === id)
          }).filter((driver: any) => {
            moment.tz(driver.updatedAt, DEFAULT_TIMEZONE).isAfter(currentTimeToTimezone) ||
              moment.tz(driver.createdAt, DEFAULT_TIMEZONE).isAfter(currentTimeToTimezone)
          });

        setDriversLocations(uniqueDriverLocations)
      }
    } catch (err) {
      const error: any = err;
      notification['error']({
        message: 'Error fetching driver locations!',
        description: error.message,
      });
    }
  }

  const getEvents = async () => {
    const today = moment().tz(DEFAULT_TIMEZONE).valueOf();
    const todayLessTwoHours = moment().tz(DEFAULT_TIMEZONE).subtract(2, 'hours').valueOf();
    try {
      setLoadingEvents(true);
      const ordersLastTwoHoursTotal = await getOrdersByFilters({ deliveryDateStartTime: todayLessTwoHours, deliveryDateEndTime: today, perPage: 1 });

      if (ordersLastTwoHoursTotal.error || ordersLastTwoHoursTotal.status == "error") {
        throw new Error(ordersLastTwoHoursTotal.message);
      } else {
        const ordersLastTwoHours = await getOrdersByFilters({ deliveryDateStartTime: todayLessTwoHours, deliveryDateEndTime: today, perPage: ordersLastTwoHoursTotal.totalItems });

        const ordersIdsLastTwoHours = ordersLastTwoHours?.results.map((order: OrderType) => {
          return order.id
        })

        if (ordersIdsLastTwoHours.length < 1) {
          setLoadingEvents(false);
          return;
        }
        const legsFromLastTwoHours = await getLegsFromLastTwoHours(ordersIdsLastTwoHours);
        setEvents(legsFromLastTwoHours);
      }
    } catch (err) {
      const error: any = err;
      notification['error']({
        message: 'Error fetching events!',
        description: error.message,
      });
    } finally {
      setLoadingEvents(false);
    }
  }

  const getLegsFromLastTwoHours = async (ordersIdsLastTwoHours: any) => {
    try {
      const legsLastTwoHours = await getDeliveryLegsByOrderId({
        orderIds: ordersIdsLastTwoHours,
      });
      if (legsLastTwoHours.error || legsLastTwoHours.status == "error") {
        throw new Error(legsLastTwoHours.message);
      } else {
        const sortedLegs = _.orderBy(legsLastTwoHours, (leg: LegType) => {
          return moment.tz(leg.updatedAt, DEFAULT_TIMEZONE).format('YYYY-MM-DDTHH:mm:ss');
        }, ['asc']).reverse();
        return sortedLegs;
      }
    } catch (err) {
      const error: any = err;
      notification['error']({
        message: 'Error fetching legs from last two hours!',
        description: error.message,
      });
    }
  }

  const getDeliveryWindowsByDate = async (query: any) => {
    setLoadingDeliveryWindows(true);
    try {
      const response = await getDeliveryWindows(query);
      if (response.error || response.status == "error") {
        throw new Error(response.message);
      } else {
        const windowsFormatted = response[0]?.windows?.map((item: WindowsType) => {
          return {
            ...item,
            checked: false,
            startTime: unixDateToTimeZone(item.startTime),
            endTime: unixDateToTimeZone(item.endTime),
          }
        })
        setDeliveryWindows(windowsFormatted);
      }
    } catch (err) {
      const error: any = err;
      notification['error']({
        message: 'Error fetching delivery windows!',
        description: error.message,
      });
    } finally {
      setLoadingDeliveryWindows(false);
    }
  }

  const getDeliveryLegs = async () => {
    const ordersIds = await getOrdersIdsByInterval();
    setLoadingMap(true);
    if (ordersIds?.length > 0) {
      try {
        const response = await getDeliveryLegsByOrderId({
          orderIds: ordersIds,
        });
        if (response.error || response.status == "error") {
          throw new Error(response.message);
        } else {
          const collectTypeLegs = response.filter((leg: LegType) => leg.type === 'COLLECT');
          const deliverTypeLegs = response.filter((leg: LegType) => (leg.type === 'DELIVER' && leg?.latitude && leg?.longitude)).map((leg: LegType) => ({
            ...leg,
            iconType: 'leg'
          }));

          const collectLegs = collectTypeLegs.filter((leg: LegType) => !warehouses.some((warehouse: WarehousesType) => leg.latitude === warehouse.latitude && leg.longitude === warehouse.longitude));

          const groupedCollectLegs = groupLegsByLocation(collectLegs);
          setCollectTypeLegs(groupedCollectLegs);

          const groupedDeliverLegs = groupLegsByLocation(deliverTypeLegs);
          setDeliverTypeLegs(groupedDeliverLegs);

          const collectedLegs = collectTypeLegs.filter((leg: LegType) => leg.actualEndTime);
          const uniqueCollectedLegs = removeDuplicate(collectedLegs);
          setCollectedLegs(uniqueCollectedLegs);

          const uncollectedLegsRouted = collectTypeLegs.filter((leg: LegType) => !leg.actualEndTime && leg.routeId);
          const uniqueUncollectedLegsRouted = removeDuplicate(uncollectedLegsRouted);
          setUncollectedLegsRouted(uniqueUncollectedLegsRouted);

          const uncollectedLegsUnrouted = collectTypeLegs.filter((leg: LegType) => !leg.actualEndTime && !leg.routeId);
          const uniqueUncollectedLegsUnrouted = removeDuplicate(uncollectedLegsUnrouted);
          setUncollectedLegsUnrouted(uniqueUncollectedLegsUnrouted);
        }
      } catch (err) {
        const error: any = err;
        notification['error']({
          message: 'Error fetching legs!',
          description: error.message,
        });
      }
    }
    setLoadingMap(false);
  }

  const getOrdersIdsByInterval = async () => {
    const checkedWindows = deliveryWindows.filter((window: WindowsType) => window.checked);
    if (checkedWindows.length > 0) {
      const windowInterval = getTimeInterval(checkedWindows)
      try {
        const response = await getOrdersByFilters({ zoneId: zones[0].id, deliveryDateStartTime: windowInterval.start, deliveryDateEndTime: windowInterval.end, perPage: 1 });
        if (response.error || response.status == "error") {
          throw new Error(response.message);
        } else {
          const ordersByWindowInterval = await getOrdersByFilters({ zoneId: zones[0].id, deliveryDateStartTime: windowInterval.start, deliveryDateEndTime: windowInterval.end, perPage: response.totalItems });

          const ordersIds = ordersByWindowInterval?.results?.map((order: OrderType) => {
            return order.id
          });
          return ordersIds;
        }
      } catch (err) {
        const error: any = err;
        notification['error']({
          message: 'Error fetching orders by interval!',
          description: error.message,
        });
      }
    }
  }

  const groupLegsByLocation = (legs: any) => {
    const grouped = _.groupBy(legs, (leg: LegType) => (leg.latitude && leg.longitude));

    var groupedLegs = []

    for (const key in grouped) {
      groupedLegs.push(grouped[key])
    }
    return groupedLegs;
  }

  const getTimeInterval = (checkedWindows: WindowsType[]) => {
    var earlyStartTime = checkedWindows[0]?.startTime;
    var laterEndTime = checkedWindows[checkedWindows.length - 1]?.endTime;

    checkedWindows.forEach((window: WindowsType) => {
      if (moment.tz(window.endTime, DEFAULT_TIMEZONE).isAfter(laterEndTime)) {
        laterEndTime = window.endTime;
      }
    });

    checkedWindows.forEach((window: WindowsType) => {
      if (moment.tz(window.startTime, DEFAULT_TIMEZONE).isBefore(laterEndTime)) {
        earlyStartTime = window.startTime;
      }
    });

    return {
      start: unixDateToTimeZone(earlyStartTime).valueOf(),
      end: unixDateToTimeZone(laterEndTime).valueOf(),
    }
  }

  const handleShowInfoOnDriverLocationClick = async (driverLocation: any) => {
    try {
      const response = await getDriverById(driverLocation.driverId);
      if (response.error || response.status == "error") {
        throw new Error(response.message);
      } else {
        var driverFullName = `${response.firstName} ${response.lastName}`;

        const driverShiftInterval = await getDriverShiftInterval(driverLocation.shiftId);
        const routeDetails = getRoute(driverLocation.shiftId);

        setActiveDriver({
          driverShiftInterval: driverShiftInterval ?? "-",
          name: driverFullName,
          routeDetails: routeDetails,
        });
      }
    } catch (err) {
      const error: any = err;
      notification['error']({
        message: 'Error fetching driver!',
        description: error.message,
      });
    }
  }

  const getRoute = async (shiftId: any) => {
    try {
      const response = await getRoutes(shiftId);
      if (response.error || response.status == "error") {
        throw new Error(response.message);
      } else {
        const { orders, legs } = response;

        const finishedOrders = orders.filter((order: any) => order.status === "DELIVERED").length;
        const unfinishedOrders = orders.filter((order: any) => order.status !== "DELIVERED").length;

        const totalAllLegs = legs.length;
        const finishedLegsTotal = legs.filter((leg: any) => leg.actualEndTime).length;
        const routeProgress = `${finishedLegsTotal * 100 / totalAllLegs}%`

        return {
          routeDetails: {
            finishedOrders,
            unfinishedOrders,
            routeProgress: routeProgress ? routeProgress : `0%`
          }
        };
      }
    } catch (err) {
      const error: any = err;
    }
  }

  const getDriverShiftInterval = async (shiftIds: string) => {
    try {
      const response = await getShifts({ shiftIds: [shiftIds] });
      if (response.error || response.status == "error") {
        throw new Error(response.message);
      } else {
        const { results: { estimatedStartTime, estimatedEndTime } } = response;
        const driverShiftIntervals = { startTime: moment.tz(estimatedStartTime, DEFAULT_TIMEZONE).format("HH:ss"), endTime: moment.tz(estimatedEndTime, DEFAULT_TIMEZONE).format("HH:ss") }
        return driverShiftIntervals;
      }
    } catch (err) {
      const error: any = err;
      notification['error']({
        message: 'Error fetching shift!',
        description: error.message,
      });
    }
  }

  return (
    <Layout className={styles.layoutContainer}>
      <Sider className={styles.siderContainer} >
        <Title level={4}>Filter By:</Title>
        <Collapse defaultActiveKey={['1', '2', '3']} ghost expandIconPosition="right">
          <Panel header={<Title level={5}>Zone</Title>} key="1">
            <Input onChange={(e) => setZoneName(e.target.value)} size="small" bordered={false} placeholder="zone name" prefix={<SearchOutlined />} />
            <List
              loading={loadingZones}
              className={styles.zonesFilterList}
              size="small"
              dataSource={zoneName ? zones.filter((zone: ZonesType) => zone.name.toLowerCase().includes(zoneName.toLowerCase())) : zones}
              renderItem={(item: ZonesType) => <List.Item className={styles.zoneListItem}>
                <Checkbox disabled={loadingMap ? true : false} checked={item.checked} onChange={() => handleChangeZoneChecked(item.id)}>{item.name}</Checkbox>
              </List.Item>}
            />
          </Panel>
          <Divider />
          <Panel header={<Title level={5}>Date</Title>} key="2">
            {loadingMap ? <DatePicker
              disabled
              className={styles.datePicker}
              onChange={(momentDate: Moment) => setSelectedDate(momentDate)}
              format={'DD/MM/YYYY'}
            /> :
              <DatePicker
                disabled={zones.some(isZoneChecked) ? false : true}
                className={styles.datePicker}
                onChange={(momentDate: Moment) => setSelectedDate(momentDate)}
                format={'DD/MM/YYYY'}
              />
            }
          </Panel>
          <Divider />
          <Panel header={<Title level={5}>Delivery Window</Title>} key="3">
            <List
              loading={loadingDeliveryWindows}
              className={styles.windowsFilterList}
              size="small"
              dataSource={deliveryWindows}
              renderItem={(item: any) => <List.Item style={{ padding: 0 }}>
                <Checkbox disabled={loadingMap ? true : false} checked={item.checked} onChange={() => handleChangeWindowChecked(item.id)}>{`${moment(item.startTime).format('HH:mm')} - ${moment(item.endTime).format('HH:mm')}`}</Checkbox>
              </List.Item>}
            />
          </Panel>
          <Divider />
        </Collapse>
      </Sider>
      <Layout className="site-layout">
        <Content
          className={styles.contentContainer}
        >
          <Input disabled={loadingMap ? true : false} className={styles.searchInput} placeholder="Search" prefix={<SearchOutlined />} />
          <Row className={styles.contentRow} gutter={[16, 16]}>
            <Col flex="auto" className={styles.mapContainer}>
              {activeDriver && <>
                <Row justify={"space-between"} className={styles.driverInfoContainer}>
                  <Col>
                    <Text strong>{activeDriver.name}</Text>
                    <Divider type="vertical" />
                    <Text>{`Shift: ${activeDriver?.startTime ?? "-"} - ${activeDriver?.endTime ?? "-"}`}</Text>
                  </Col>
                  <Col>
                    <CloseCircleOutlined onClick={() => setActiveDriver(null)} style={{ color: "black", cursor: 'pointer' }} />
                  </Col>
                  <Divider plain className={styles.infoRouteDivider} />
                </Row>
                <Row className={styles.routesInfoContainer}>
                  <Text>{`${activeDriver?.routeDetails?.routeProgress ?? '0%'} Route Progress`}</Text>
                  <Divider type="vertical" />
                  <Text>{`Finished Orders: ${activeDriver?.routeDetails?.finishedOrders ?? "-"}`}</Text>
                  <Divider type="vertical" />
                  <Text>{`Unfinished Orders: ${activeDriver?.routeDetails?.unfinishedOrders ?? "-"}`}</Text>
                  <Divider type="vertical" />
                  {/* <Text>Real Time 8:40 / Expected 8:45</Text> */}
                </Row>
              </>}
              {loadingMap ?
                <div className={styles.loadingMapContainer}>
                  <Spin size="large" />
                </div>
                : <Map center={[51.10310769185344, -114.04700905767238]} zoom={12} className={styles.map}>
                  <TileLayer
                    url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${MAP_API_KEY}`}
                  />

                  {warehouses.length > 0 && warehouses.map((warehouse: WarehousesType) => (
                    <Marker
                      key={warehouse.id}
                      position={[
                        warehouse?.latitude,
                        warehouse?.longitude
                      ]}
                      icon={warehouseIcon}
                    >
                      <Popup
                        position={[
                          warehouse?.latitude,
                          warehouse?.longitude
                        ]}
                      >
                        <strong>{`Warehouse: ${warehouse.name}`}</strong>
                        <Collapse style={{ padding: 0, margin: 0 }} defaultActiveKey={[]} ghost expandIconPosition="right">
                          <LegPanel panelTitle={`Collected legs routed`} legs={collectedLegs} warehouse={warehouse} />
                          <LegPanel panelTitle={`Uncollected Legs Routed`} legs={uncollectedLegsRouted} warehouse={warehouse} />
                          <LegPanel panelTitle={`Uncollected Legs Unrouted`} legs={uncollectedLegsUnrouted} warehouse={warehouse} />
                        </Collapse>
                      </Popup>
                    </Marker>
                  ))}

                  {deliverTypeLegs.length > 0 && deliverTypeLegs.map((leg: any) => (
                    <Marker
                      key={leg.id}
                      position={[
                        leg[0]?.latitude,
                        leg[0]?.longitude
                      ]}
                      icon={placeIconGreen}
                    >
                      <Popup
                        position={[
                          leg[0]?.latitude,
                          leg[0]?.longitude
                        ]}
                      >
                        {leg.map((item: any) => <div style={{ display: 'flex', flexDirection: "column" }}>
                          <strong>{`Order: ${item.orderNumber}`}</strong>
                        </div>)}
                      </Popup>
                    </Marker>
                  ))}

                  {collectTypeLegs.length > 0 && collectTypeLegs.map((leg: any) => (
                    <Marker
                      key={leg.id}
                      position={[
                        leg[0]?.latitude,
                        leg[0]?.longitude
                      ]}
                      icon={warehouseIcon}
                    >
                      <Popup
                        position={[
                          leg[0]?.latitude,
                          leg[0]?.longitude
                        ]}
                      >
                        {leg.map((item: any) => <div style={{ display: 'flex', flexDirection: "column" }}>
                          <strong>{`Order: ${item.orderNumber}`}</strong>
                        </div>)}
                      </Popup>
                    </Marker>
                  ))}

                  {driversLocations.length > 0 && driversLocations.map((driverLocation: DriverLocationType) => (
                    <Marker
                      key={driverLocation.id}
                      position={[
                        driverLocation?.latitude,
                        driverLocation?.longitude
                      ]}
                      onClick={() => handleShowInfoOnDriverLocationClick(driverLocation)}
                      icon={driverIcon}
                    />
                  ))}
                </Map>
              }
            </Col>
            <Col flex="250px" className={styles.eventsSider}>
              <Card className={styles.eventsCard}>
                <Title level={5}>Events(last 2h)</Title>
                {loadingEvents ?
                  <Card className={styles.loadingEventsContainer} bordered={false}>
                    <Spin size="default" />
                  </Card> :
                  events?.length > 0 ?
                    <List
                      size="small"
                      dataSource={events}
                      renderItem={(item: any) => <List.Item style={{ padding: 0 }} className={styles.eventListItem}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '2px' }}>
                          <p style={{ padding: 0, margin: 0 }}>{`Order ${item.orderNumber ? item.orderNumber : item.orderId} ${item.actualEndTime ? `${(item.type).toLowerCase()}ed` : ''}`}</p>
                        </div>
                      </List.Item>}
                    />
                    : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    </Layout>
  )
}

export default MapView
