import React, { useEffect, useState } from 'react';

import { Button, Col, Input, notification, Row, Select, Table } from 'antd';

import styles from './PickingAppAccounts.module.css';
import { getPickingUsers } from 'services/PickingUsers.service';
import AddPickingUserModal from './components/AddPickingUserModal';
import { ReactComponent as EditIcon } from '../../../img/editIcon.svg';
import EditPickingUserModal from './components/EditPickingUserModal';
import { SearchOutlined } from '@ant-design/icons';

interface PickingUser {
  id: string,
  email: string,
  name: string,
  isActive: boolean,
}

interface PaginatedPickingUsers {
  results: PickingUser[]
	totalItems: number,
	currentPage: number,
	perPage: number,
	totalPages: number
}

const { Option } = Select;

const PickingAppAccounts: React.FC = () => {
  const [usersDataPaginated, setUsersDataPaginated] = useState<PaginatedPickingUsers>();
  const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [searchText, setSearchText] = useState<string>('');
  const [filterType, setFilterType] = useState<string>('name');
  const [userStatus, setUserStatus] = useState<string>('active');

  const fetchPickingUsers = async (params?: any) => {
    setLoading(true);
    await getPickingUsers(params).then(response => {
      setUsersDataPaginated(response);
    }).catch(error => {
      notification['error']({
        message: 'Error fetching picking users!',
        description: error.message,
      });
    }).finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchPickingUsers();
  }, [])

  const handleSearch = (page?: number) => {
    const params: any = {};

    if (userStatus === 'active') params.isActive = true;
    else params.isActive = false;
    if (searchText.length > 0) params[filterType] = searchText;
    if (page) params.page = page;

    fetchPickingUsers(params);
  };

  useEffect(() => {
    handleSearch();
  }, [userStatus])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'City',
      dataIndex: 'cityName',
      key: 'cityName',
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive: boolean) => <p>{isActive ? 'ACTIVE' : 'INACTIVE'}</p>
    },
    {
      render: () => (
        <EditIcon onClick={() => setEditModalVisible(true)}/>
      )
    }
  ];

  return (
    <>
      <div className={styles.container}>
      <Row className={styles.rowContainer} gutter={[0, 15]} justify='space-between'>
        <Col span={6}>
          <Button
            disabled={loading}
            onClick={() => setAddModalVisible(true)}
            className={styles.addButton}
          >
            + Add Picking User
          </Button>
        </Col>

        <Col span={18}>
          <Row justify='end' gutter={15}>
            <Col xs={24} sm={24} md={24} lg={8} xl={4} xxl={3}>
              <Select
                onChange={(e) => {
                  setUserStatus(e);
                  handleSearch();
                }}
                className={styles.filtersseSelect}
                value={userStatus}
                placeholder="Product Status"
                defaultValue="active"
              >
                <Option key="active" value="active">Active</Option>
                <Option key="inactive" value="inactive">Inactive</Option>
              </Select>
            </Col>

            <Col xs={24} sm={24} md={24} lg={8} xl={4} xxl={3}>
              <Select
                onChange={(e) => {
                  setFilterType(e);
                }}
                className={styles.filtersseSelect}
                value={filterType}
                defaultValue="name"
              >
                <Option value="name">Name</Option>
                <Option value="email">E-mail</Option>
                <Option value="cityName">City Name</Option>
              </Select>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
              <Input
                placeholder="Search here..."
                onChange={(e) => setSearchText(e.target.value)}
                suffix={<SearchOutlined onClick={() => handleSearch()}/>}
                value={searchText}
                allowClear
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Table
        loading={loading}
        dataSource={usersDataPaginated?.results}
        columns={columns}
        scroll={{ x: 1270 }}
        onRow={(record: any) => {
          return {
            onClick: () => setSelectedRow(record),
          };
        }}
        style={{padding: '1rem 0'}}
      />
    </div>
      <AddPickingUserModal
        fetchPickingUsers={fetchPickingUsers}
        modalVisible={addModalVisible}
        setModalVisible={setAddModalVisible}
      />
      <EditPickingUserModal
        fetchPickingUsers={fetchPickingUsers}
        modalVisible={editModalVisible}
        setModalVisible={setEditModalVisible}
        record={selectedRow}
      />
    </>

  )
}

export default PickingAppAccounts;
