import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCatalogs } from "selectors/Catalogs";
import { selectCategories } from "selectors/Category";
import { MODAL_TYPES, Product } from "ui/pages/Inventory/interfaces";
import { selectAddProductModalVisible, selectDiscontinuedModalVisible, selectEditStockModalVisible, selectExpandedKeys, selectInventoryFetching, selectInventoryPaginatedProducts, selectInventoryQuery, selectSelectedProduct, selectSwitchConfirmationModalVisible } from '../selectors/Products'

import * as InventoryActions from "../store/Inventory.slice";

export default function useDrivers() {
  const dispatch = useDispatch();

  const paginatedProducts = useSelector(selectInventoryPaginatedProducts);
  const categories = useSelector(selectCategories);
  const paginatedCatalogs = useSelector(selectCatalogs);
  const loading = useSelector(selectInventoryFetching);
  const query = useSelector(selectInventoryQuery);
  const switchConfirmationModalVisible = useSelector(selectSwitchConfirmationModalVisible);
  const editStockModalVisible = useSelector(selectEditStockModalVisible);
  const discontinuedModalVisible = useSelector(selectDiscontinuedModalVisible);
  const addProductModalVisible = useSelector(selectAddProductModalVisible)
  const expandedRowKeys = useSelector(selectExpandedKeys);
  const selectedProduct = useSelector(selectSelectedProduct);

  const fetchProducts = useCallback(
    async function (query?: any) {
      dispatch(InventoryActions.fetchProducts(query));
      dispatch(InventoryActions.storeQuery(query))
    },
    [dispatch]
  );

  const fetchAllCategories = useCallback(
    async function () {
      dispatch(InventoryActions.fetchAllCategories());
    },
    [dispatch]
  );

  const fetchCatalogs = useCallback(
    async function () {
      dispatch(InventoryActions.fetchCatalogs());
    },
    [dispatch]
  );

  const fetchProduct = useCallback(
    async function (id: string) {
      dispatch(InventoryActions.fetchProduct(id));
    },
    [dispatch]
  );

  const setExpandedKeys = useCallback(
    async function (keys: any[]) {
      dispatch(InventoryActions.storeExpandedKeys(keys));
    },
    [dispatch]
  );

  const storeProduct = useCallback(
    async function (product: Product) {
      dispatch(InventoryActions.storeSelectedProduct(product));
    },
    [dispatch]
  );

  const toggleModalVisibility = (modalName: MODAL_TYPES) => {

    switch (modalName) {
      case MODAL_TYPES.confirmationSwitch:
        dispatch(InventoryActions.storeSwitchConfirmationModalVisible((!switchConfirmationModalVisible)));
        break;

      case MODAL_TYPES.editStock:
        dispatch(InventoryActions.storeEditStockModalVisible((!editStockModalVisible)));
        break;

      case MODAL_TYPES.discontinued:
        dispatch(InventoryActions.storeDiscontinuedModalVisible((!discontinuedModalVisible)));
        break;

      case MODAL_TYPES.addProduct:
        dispatch(InventoryActions.storeAddProductModalVisible((!addProductModalVisible)));
        break;

      default:
        break;
    }
  }


  return {
    toggleModalVisibility,
    paginatedProducts,
    categories,
    paginatedCatalogs,
    switchConfirmationModalVisible,
    editStockModalVisible,
    discontinuedModalVisible,
    addProductModalVisible,
    fetchProducts,
    fetchAllCategories,
    fetchCatalogs,
    setExpandedKeys,
    expandedRowKeys,
    fetchProduct,
    storeProduct,
    selectedProduct,
    loading,
    query
  };
}
