import { RootState } from "store";

function selectInventoryFetching(state: RootState) {
  return state.inventory.fetching;
}

function selectInventoryPaginatedProducts(state: RootState) {
  return state.inventory.paginatedProducts;
}

function selectInventoryQuery(state: RootState){
  return state.inventory.currentQuery
}

function selectExpandedKeys(state: RootState){
  return state.inventory.expandedRowKeys
}

function selectSwitchConfirmationModalVisible(state: RootState){
  return state.inventory.switchConfirmationModalVisible
}

function selectEditStockModalVisible(state: RootState){
  return state.inventory.editStockModalVisible
}

function selectDiscontinuedModalVisible(state: RootState){
  return state.inventory.discontinuedModalVisible
}

function selectAddProductModalVisible(state: RootState){
  return state.inventory.addProductModalVisible
}

function selectSelectedProduct(state: RootState){
  return state.inventory.selectedProduct
}

export {
  selectInventoryFetching,
  selectInventoryPaginatedProducts,
  selectInventoryQuery,
  selectExpandedKeys,
  selectSwitchConfirmationModalVisible,
  selectEditStockModalVisible,
  selectDiscontinuedModalVisible,
  selectAddProductModalVisible,
  selectSelectedProduct
}
