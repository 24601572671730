import React, { useContext, useEffect, useState } from 'react';

import { Input, Table } from 'antd';

import { DriverShift } from '../../../interfaces';
import styles from './ShiftsTable.module.css';
import { RoutesContext } from 'ui/pages/Routes/contexts/RoutesContext';

interface ShiftsTableProps{
  pageSize?: number
}
const ShiftsTable: React.FC<ShiftsTableProps> = (
  {
    pageSize
  }) => {

  const [filteredTable, setFilteredTable] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

  const { driverShifts, setSelectedDriverShift } = useContext(RoutesContext);

  const search = (value: string) => {

  const filterTable = driverShifts.filter((o: any) =>
    Object.keys(o).some(k =>
      String(o[k])
        .toLowerCase()
        .includes(value.toLowerCase())
      )
    );

    setFilteredTable(filterTable);
  };

  const driverSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: DriverShift[]) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedDriverShift(selectedRows[0]);
    },
  };

  const DriversColumns = [
    {
      title: 'Driver name',
      dataIndex: 'driverFullName'
    },
    {
      title: 'Phone number',
      dataIndex: 'driverPhone',
    },
    {
      title: 'Date',
      dataIndex: 'formattedStartTime',
    },
    {
      title: 'Shift Duration',
      dataIndex: 'shiftStartAndEndTime',
    }
  ];

  return (
    <>
      <Input.Search
        className={styles.inputSearch}
        placeholder="Search by..."
        enterButton
        onSearch={search}
        allowClear
      />
      <Table
        rowSelection={{
          type: 'radio',
          ...driverSelection
        }}
        columns={DriversColumns}
        dataSource={driverShifts}
        rowKey="id"
        pagination={{ defaultPageSize: pageSize || 8}}
        scroll={{ x: 500}}
      />
    </>
  )
}

export default ShiftsTable;
