import {
  createAction,
  createAsyncThunk,
  createReducer,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { notification } from 'antd';
import { createCampaign, getCampaigns } from "services/Campaign.service";

export interface Campaign {
  id: string,
  message: string,
  title: string,
  name: string,
  deviceTokens: string[]
}

interface CampaignsPaginated {
  totalItems: number;
  currentPage: number;
  perPage: number;
  totalPages: number;
  results: [],
}

interface CampaignSliceState {
  paginated?: CampaignsPaginated;
  fetching: boolean;
  currentQuery: string;
  isAddModalVisible: boolean;
  isUpdateModalVisible: boolean;
}

const initialState: CampaignSliceState = {
  fetching: false,
  currentQuery: '',
  isAddModalVisible: false,
  isUpdateModalVisible: false,
  paginated: {
    totalItems: 0,
    currentPage: 0,
    perPage: 0,
    totalPages: 1,
    results: [],
  },
};

export const fetchCampaigns = createAsyncThunk(
  "campaign/fetchCampaigns",
  async function (query?: string) {
    try {
      const response = await getCampaigns(query);
      if (response.error || response.status == "error") {
        throw new Error(response.message);
      } else {
        return response
      }
    } catch (err) {
      const error: any = err;
      notification['error']({
        message: 'Error fetching campaigns!',
        description: error.message,
      });
    }
  }
);

export const addCampaign = createAsyncThunk(
  "campaign/addCampaign",
  async function (body: any, { dispatch }) {
    try {

      const response = await createCampaign(body);
      if (response.error || response.status == "error") {
        throw new Error(response.message);
      } else {
        dispatch(fetchCampaigns());
        notification['success']({
          message: 'Success!',
          description: 'Campaign created!',
        });
        return response
      }
    } catch (err) {
      const error: any = err;
      notification['error']({
        message: 'Error creating campaign!',
        description: error.message,
      });
    }
  }
);

export const changeAddModalVisibility = createAction('driver/changeAddModalVisibility', (payload: boolean) => ({ payload }));

export const campaignReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(changeAddModalVisibility, (state, action) => {
      state.isAddModalVisible = action.payload;
    })
    .addCase(fetchCampaigns.fulfilled, (state, action) => {
      state.paginated = action.payload;
    })
    .addMatcher(isPending, (state) => {
      state.fetching = true;
    })
    .addMatcher(isFulfilled, (state) => {
      state.fetching = false;
    })
    .addMatcher(isRejected, (state) => {
      state.fetching = false;
    });
});
