import React, { useEffect } from 'react';

import { Table, Tabs } from 'antd';
import { useHistory } from 'react-router';
import { LeftOutlined } from '@ant-design/icons';

import { ReactComponent as InfoCircle } from 'img/informationCircle.svg';
import KiwiCredits from 'ui/pages/KiwiCredits';
import CustomerOrders from 'ui/pages/CustomerOrders';
import useCustomers from 'hooks/useCustomers';
import styles from './CustomerTabs.module.css';
import Referrals from 'ui/pages/Referrals';
import formatPhoneNumber from 'util/formatPhoneNumber';
import { formatDate } from 'util/dateAndTime';

const { TabPane } = Tabs;

const CustomerTabs: React.FC = () => {
  const history = useHistory();

  const pageState: any = history.location.state;
  const { storeSelectedCustomer } = useCustomers();

  useEffect(() => {
    if (pageState.customer) storeSelectedCustomer(pageState.customer);
  }, [pageState])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (id: string, record: any) => (
        <div className={styles.nameContainer}>
          <InfoCircle className={styles.infoCircle} />
          <p className={styles.text}>{id}</p>
        </div>
      ),
    },
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNumber',
      render: (value: string) => (
        <p className={styles.text}>{formatPhoneNumber(value) || "-"}</p>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (value: number) => (
        <p className={styles.text}>{formatDate(value)}</p>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.goBackContainer} onClick={() => history.push('/customers')}>
          <LeftOutlined className={styles.goBackIcon} />
          <h1 className={styles.goBackTitle}>Go back</h1>
        </div>
        <Table
          dataSource={[pageState.customer]}
          columns={columns}
          scroll={{ x: 1270 }}
          pagination={{
            hideOnSinglePage: true
          }}
        />
      </div>
      <div className={styles.tabsContainer}>
        <Tabs tabPosition="left" defaultActiveKey="1">
          <TabPane tab="Kiwi Credits" key="kiwi-credits">
            <KiwiCredits/>
          </TabPane>
          <TabPane tab="Referrals" key="referrals">
            <Referrals/>
          </TabPane>
          <TabPane tab="Orders" key="orders">
            <CustomerOrders/>
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}

export default CustomerTabs;
