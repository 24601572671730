import React, { useEffect } from 'react';

import { Col, Row, Spin, Table } from 'antd';
import styles from './Referrals.module.css';
import { useHistory } from 'react-router';
import useCustomers from 'hooks/useCustomers';
import formatToDollar from 'util/formatToDollar';
import { ReactComponent as InfoCircle } from 'img/informationCircle.svg';

const Referrals: React.FC = () => {

  const {
    fetchCustomerCoupon,
    loadingCoupon,
    customerCoupon,
    couponOrders,
    fetchCustomerOrders,
    loadingCustomerOrders,
    setIsFetchingCouponOrders
  } = useCustomers();

  const history = useHistory();
  const pageState: any = history.location.state;

  useEffect(() => {
    setIsFetchingCouponOrders(true);
    fetchCustomerCoupon(pageState.customer.referralCouponName);
    fetchCustomerOrders({ coupon: pageState.customer.referralCouponName })
  }, []);


  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (orderId: string) => (
        <div className={styles.orderIdContainer}>
          <InfoCircle className={styles.goToDetailsButton} onClick={() => history.push(`/orders/${orderId}`)} />
          <p className={styles.text}>{orderId}</p>
        </div>
      ),
    },
    {
      title: 'Total Value',
      dataIndex: 'total',
      render: (value: number) => <p>${formatToDollar(value)}</p>
    },
    {
      title: 'Order Status',
      dataIndex: 'status',
    },
    {
      title: 'Customer Name',
      dataIndex: 'customerDetails',
      render: (customerDetails: any) => <p>{customerDetails.displayName || '-'}</p>
    },
    {
      title: 'Email',
      dataIndex: 'customerDetails',
      render: (customerDetails: any) => <p>{customerDetails.email || '-'}</p>
    },
    {
      title: 'Delivery Address',
      dataIndex: 'destination',
      render: (destination: any) => <p>{destination.address || '-'}</p>
    },
  ];

  return (
    <div className={styles.container}>
      <Row justify="start">
        <Col lg={4} md={4} xs={24}>
          <h1 className={styles.title}>Code</h1>
          <p>{pageState.customer.referralCouponName}</p>
        </Col>
        <Col lg={4} md={4} xs={24}>
          <h1 className={styles.title}>Usage Count</h1>
          {loadingCoupon ? <Spin /> : <p>{customerCoupon.usageCount || '-'}</p>}
        </Col>
      </Row>
      <div className={styles.tableContainer}>
        <h1 className={styles.title}>Referral Coupon - Orders History</h1>
        <Table
          loading={loadingCustomerOrders}
          dataSource={couponOrders?.results}
          columns={columns}
          scroll={{ x: 1270 }}
          pagination={{
            defaultPageSize: couponOrders?.perPage,
            total: couponOrders?.totalItems || undefined,
            showSizeChanger: false,
            pageSize: couponOrders?.perPage || undefined,
          }}
        />
      </div>
    </div>
  )
}

export default Referrals;
